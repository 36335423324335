import React, { useEffect, useState } from "react";
import {
    Col,
    Button
  } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TYPE } from "../../api/pathUrl";

function OverlayTriggerComp({bookings, data, redirectBookingPage, show, visibility}) {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [modalShowAvailability, setModalShowAvailability] = useState(false)

    const handleGotoBookingEdit = () => {
        dispatch({ type: TYPE.GET_AVAILABLE_ROOMS, payload: [] })
        setModalShowAvailability(!modalShowAvailability)
        redirectBookingPage()
    }

    return (
        <>
            <Col lg={1} className="mb-1">
                <OverlayTrigger
                    placement="bottom"
                    trigger="click"
                    rootClose
                    name="s"
                    overlay={
                        <Popover
                            id="popover-basic"
                            style={{
                                backgroundColor:
                                    "#34495E",
                                color: "white",
                                width: "13rem",
                            }}
                        >
                            <Popover.Body
                                style={{
                                    color: "white",
                                }}
                            >
                                <div>
                                    <b>{data.status}</b>
                                    <br />
                                    <b>{data.acnonac}</b>
                                    <br />
                                    <b>{data.type}</b>
                                    <br />
                                    <b>
                                        {data.occupancy}{" "}
                                        Persons
                                    </b>
                                    <br />
                                    <b>
                                        {" "}
                                        {getSymbolFromCurrency(
                                            "INR"
                                        )}
                                        {data.price} / Day
                                    </b>
                                    <br />
                                    <p></p>
                                    {data.status ===
                                        "Available" ? (
                                        <>
                                            {" "}
                                            <Button
                                                className="btn-sm mx-2"
                                                onClick={() => {
                                                    // redirectBookingPage(
                                                    //     data
                                                    // );
                                                    handleGotoBookingEdit()
                                                }}
                                                disabled={
                                                    data.price ===
                                                    null
                                                }
                                            >
                                                Book
                                            </Button>{" "}
                                            <Button
                                                className="btn-sm mx-2"
                                                onClick={() => {

                                                    navigate(`/room/` + data.id, { state: data });

                                                    // redirectRoomPage(
                                                    //     data
                                                    // );
                                                }}
                                            >
                                                Pricing
                                            </Button>{" "}
                                        </>
                                    ) : (
                                        <>
                                            <br />
                                            {bookings &&
                                                bookings.find(
                                                    (ele) =>
                                                        ele.id ===
                                                        data.last_booking_id
                                                )
                                                ? moment(
                                                    bookings.find(
                                                        (ele) =>
                                                            ele.id ===
                                                            data.last_booking_id
                                                    )
                                                        .checkindate
                                                ).format(
                                                    "DD/MM/YYYY"
                                                ) +
                                                " - " +
                                                moment(
                                                    bookings.find(
                                                        (ele) =>
                                                            ele.id ===
                                                            data.last_booking_id
                                                    )
                                                        .checkoutdate
                                                ).format(
                                                    "DD/MM/YYYY"
                                                )
                                                : ""}
                                            <br />

                                            {bookings &&
                                                bookings.find(
                                                    (ele) =>
                                                        ele.id ===
                                                        data.last_booking_id
                                                ) ? (<>
                                                    <b>Booked</b>
                                                    <Button
                                                        className="btn-sm mx-2"
                                                        onClick={() => {

                                                            const gotoBooking = bookings.find(
                                                                (ele) =>
                                                                    ele.id ===
                                                                    data.last_booking_id
                                                            )

                                                            navigate(`/booking/` + gotoBooking.id, { state: gotoBooking });
                                                            // redirectBookingViewPage(
                                                            //     bookings.find(
                                                            //         (ele) =>
                                                            //             ele.id ===
                                                            //             data.last_booking_id
                                                            //     )
                                                            // );
                                                        }}
                                                    >
                                                        Goto
                                                    </Button>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    )}
                                </div>
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <Col
                        lg={12}
                        className={
                            data.status ===
                                "Available"
                                ? "availablestyle"
                                : "notavailblestyle"
                        }
                    >
                        <strong>
                            {data.roomno}
                        </strong>
                    </Col>
                </OverlayTrigger>
            </Col>
        </>
  )
}

export default OverlayTriggerComp