import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import InfoPill from "../InfoPill";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import InputGroup from "react-bootstrap/InputGroup";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRequest, deleteRequest } from "../../api/inventoryApiTwo";
import { PATH, TYPE } from "../../api/pathUrl";
import CashOutEdit from "../CashOutEdit";
import Confirm from "../Confirm";
import ExpensesEdit from "../Expense/ExpensesEdit";
import PaymentEdit from "../Payment/PaymentEdit";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";

const PaymentsAndExpenses = () => {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [body, setBody] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [filteredText, setFilteredText] = useState();
  const [filteredSlected, setfilteredSelected] = useState();
  const { transactionList, resultHandle } = useSelector(
    (state) => state.apiReducer
  );
  const [selectdate, setDate] = useState();
  const [selectvalue, setSelectvalue] = useState();
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [totalExpenseAmount, setTotalExpenseAmount] = useState(0);
  const [showTransactionModel, setShowTransactionModel] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowPayment, setModalShowPayment] = React.useState(false);
  const [payment, setPayment] = useState("");
  const [type, setType] = useState("Income");

  const deleteExpense = async () => {
    if (transaction.parentid) {
      dispatch(
        deleteRequest(PATH.paymentPath, undefined, transaction.id, true)
      );
    } else {
      dispatch(
        deleteRequest(PATH.transactionPath, undefined, transaction.id, true)
      );
    }
    dispatch(getRequest(PATH.transactionPath, TYPE.GET_ALL_TRANSACTIONS));
  };

  const submitPayment = () => {
    dispatch(getRequest(PATH.transactionPath, TYPE.GET_ALL_TRANSACTIONS));
    setModalShowPayment(false);
  };

  useEffect(() => {
    dispatch(getRequest(PATH.transactionPath, TYPE.GET_ALL_TRANSACTIONS));
    // paymentList();
  }, [resultHandle]);

  useEffect(() => {
    if (resultHandle.isModal) {
      setModalShow(false);
      dispatch({ type: "SET_RESULT_HANDLE", payload: {} });
    }
  }, [resultHandle]);

  useEffect(() => {
    
    // const paymentList = () => {
    setTotalPaidAmount(0);
    setTotalExpenseAmount(0);
    // async function init() {
      if (Object.keys(transactionList).length > 0) {
      //   dispatch(getRequest(PATH.transactionPath, TYPE.GET_ALL_TRANSACTIONS));
      // } else {
        const objectsList = [
          ...transactionList.payments,
          ...transactionList.transactions,
        ];
        transactionList.payments.forEach((e) => {
          if (e.amount != null && e.amount != "")
            setTotalPaidAmount(
              (prev) => parseFloat(prev) + parseFloat(e.amount)
            );
        });
        transactionList.transactions.forEach((e) => {
          if (e.type === "Expense")
            setTotalExpenseAmount(
              (prev) => parseFloat(prev) + parseFloat(e.amount)
            );
          else
            setTotalPaidAmount(
              (prev) => parseFloat(prev) + parseFloat(e.amount)
            );
        });
        for (let i = 0; i < objectsList.length; i++) {
          if (objectsList[i].parentid !== null) {
            objectsList[i].title = objectsList[i].auto_number;
            objectsList[i].category = objectsList[i].type;
          }
        }
        if (objectsList && objectsList.length > 0) {
          let sortedLeads = [...objectsList];
          sortedLeads.sort((a, b) =>
            moment(b.lastmodifieddate).diff(moment(a.lastmodifieddate))
          );
          //.log('bksH', sortedLeads);
          setBody([...sortedLeads]);
        }
      }
    // }
    
  //   init();
  // };
}, [transactionList])
  
  const editPayment = (row) => {
    setModalShowPayment(true);
    setPayment(row);
  };

  useEffect(() => {
    const headers = document.querySelectorAll(".thead-th");
    headers[1].style.textAlign = 'center';
    headers[4].style.textAlign = 'center';
  }, [])
  
  const header = [
    {
      title: "Title",
      prop: "title",
      isFilterable: true,
      //isSortable: true,
      cell: (row) => {
        return  row.parentid === null ? (
          <Link
            onClick={() => {
              setShowTransactionModel(true);
              setTransaction({ row });
            }}
            state={row}
          >
            {row.title}
          </Link>
        ) : (
          <Link onClick={() => editPayment({ row })}>{row.title}</Link>
        );
      },
    },
    {
      title: "Type",
      prop: "type",
      isFilterable: true,
      //isSortable: true,
      cell: (row) => (
        <span
          style={{
            paddingTop: "0.15rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "15px",
            paddingBottom: "5px",
            color: "black",
            fontWeight: "bold",
            fontSize: "0.9rem",
            background: row.type === "Expense" ? "#EF9F9F" : "#85d884",
          }}
        >
          {row.type === "Expense" ? "Expense" : "Income"}
        </span>
      ),
    },
    {
      title: "Status",
      prop: "status",
      isFilterable: true,
      //isSortable: true
    },
    {
      title: "Category",
      prop: "category",
      isFilterable: true,
      //isSortable: true,
    },
    //{title: "Transactiondate",prop: "transactiondate",isFilterable: true,isSortable: true },
    {
      title: "Amount",
      prop: "amount",
      isFilterable: true,
      cell: (row) => (
        <div style={{ width: "60%", textAlign: "end" }}>₹ {Number(row.amount).toFixed(0)}</div>
      ),
    },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <>
          <Button
            className="btn-sm mx-2"
            variant="danger"
            onClick={() => {
              setModalShow(true);
              setTransaction(row);
            }}
            title="Delete Transaction"
          >
            <i class="fa-regular fa-trash-can"></i>
          </Button>
        </>
      ),
    },
  ];
  const labels = {
    beforeSelect: " ",
  };

  return (
    <Row className="g-0">
      {modalShow && (
        <Confirm
          show={modalShow}
          onHide={() => setModalShow(false)}
          deleteExpense={deleteExpense}
          title="Confirm delete?"
          message="You are going to delete the Transaction.  Are you sure?"
          table="expense"
        />
      )}

      <Col lg={4} className="px-4">
        <CustomSeparator
          currentCmpName="Cash-In / Cash-Out"
          indexLength="0"
          url="/PaymentsandExpenses"
        ></CustomSeparator>
      </Col>

      <Col
        xs={12}
        sm={12}
        lg={8}
        className="d-flex flex-col justify-content-end align-items-end"
      >
        <Button
          className="btn-sm mx-2"
          variant="success"
          onClick={() => {
            setShowTransactionModel(true);
            setTransaction({});
            setType("Income");
          }}
        >
          Add Income
        </Button>

        <Button
          className="btn-sm mx-2"
          variant="danger"
          onClick={() => {
            setShowTransactionModel(true);
            setType("Expense");
            setTransaction({});
          }}
        >
          Add Expense
        </Button>
      </Col>

      <Col lg={12} className="p-lg-4">
        {body ? (
          <DatatableWrapper
            body={body}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 10,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                lg={2}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              {showTransactionModel && (
                <CashOutEdit
                  show={showTransactionModel}
                  onHide={() => {
                    setShowTransactionModel(false);
                  }}
                  transaction={transaction}
                  table="user"
                  type={type}
                  //  submitEvents={submitTransaction}
                />
              )}
              {modalShowPayment &&  (
                <PaymentEdit
                  show={modalShowPayment}
                  onHide={() => setModalShowPayment(false)}
                  parentid={payment.row.parentid}
                  payment={payment}
                  table="lead"
                  submitPayment={submitPayment}
                />
              )}
              <Col
                // xs={12}
                // sm={6}
                lg={1}
                // className="d-flex flex-col justify-content-start align-items-center"
              >
                <PaginationOptions labels={labels} />
              </Col>

              <Col
                // xs={12}
                // sm={6}
                lg={9}
                // className="d-flex flex-col justify-content-between align-items-center"
                className="d-flex flex-row"
                style={{ marginTop: "0.5rem" }}
              >
                {totalPaidAmount ? (
                  <InfoPill
                    left="Total Cash-In"
                    right={
                      <CurrencyFormat
                        displayType={"text"}
                        value={totalPaidAmount.toFixed(0)}
                        thousandSeparator={true}
                        prefix={"₹ "}
                      ></CurrencyFormat>
                    }
                  />
                ) : (
                  ""
                )}
                {totalExpenseAmount ? (
                  <InfoPill
                    left="Total Cash-Out"
                    right={
                      <CurrencyFormat
                        displayType={"text"}
                        value={totalExpenseAmount.toFixed(0)}
                        thousandSeparator={true}
                        prefix={"₹ "}
                      ></CurrencyFormat>
                    }
                  />
                ) : (
                  ""
                )}
                <InfoPill
                  className="ml-auto"
                  left="Total"
                  right={body.length}
                />
              </Col>
            </Row>
            <Table striped className="data-table">
              <TableHeader />
              <TableBody />
            </Table>
            {body && body.length > 0 ? <Pagination /> : ""}
          </DatatableWrapper>
        ) : (
          ""
        )}
      </Col>
      {/* <Col lg={2}></Col> */}
    </Row>
  );
};

export default PaymentsAndExpenses;
