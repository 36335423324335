import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation,useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useSelector } from "react-redux";
import inventoryApi from "../../api/inventoryApi";

const ProductCreate = (props) => {
    const [options, setOptions] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const [product, setproduct] = useState('');

    const handleChange = (e) => {
        setproduct({ ...product, [e.target.name]: e.target.value });
        //.log(product)
    };


    const handleSubmit = async (e) => {
        //.log('if handle submit for create product ');
        e.preventDefault();
        const result = await inventoryApi.createProduct(product);
        if (result) {
            navigate(`/products/${result.id}`, { state: result });
        }
    };

    const handleCancel = () => {
        navigate("/products/", { state: product });
    };

  return (
    <Container className="view-form">
    <Row>
        <Col></Col>
        <Col lg={8}>
            <Form className="mt-3" onSubmit={handleSubmit}>
                <Row className="view-form-header align-items-center">
                    <Col lg={3}>
                        Create product
                        <h4>Aslam Bari</h4>
                    </Col>
                    <Col lg={9} className="d-flex justify-content-end">
                        <Button className="btn-sm mx-2" onClick={handleSubmit}>
                            Save
                        </Button>
                        <Button
                            className="btn-sm"
                            variant="danger"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-between">
                        <Form.Group className="mx-3" controlId="formBasicFirstName">
                            <Form.Label
                                className="form-view-label"
                                htmlFor="formBasicFirstName"
                            >
                                Product Name
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Enter Product Name"
                                value={product.name}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mx-3" controlId="formBasicFirstName">
                            <Form.Label
                                className="form-view-label"
                                htmlFor="formBasicFirstName"
                            >
                                Product Code
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="productcode"
                                placeholder="Product Code"
                                value={product.productcode}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mx-3" controlId="formBasicLastName">
                            <Form.Label
                                className="form-view-label"
                                htmlFor="formBasicLastName"
                            >
                                Category
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="category"
                                placeholder="Enter Category"
                                value={product.category}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mx-2" controlId="formBasicEmail">
                            <Form.Label
                                className="form-view-label"
                                htmlFor="formBasicEmail"
                            >
                               Sub Category
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="subcategory"
                                placeholder="Enter SubCategory"
                                value={product.subcategory}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mx-3" controlId="formBasicPhone">
                            <Form.Label
                                className="form-view-label"
                                htmlFor="formBasicPhone"
                            >
                               Description 
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                placeholder="Enter Description"
                                value={product.description}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    
                </Row>
                <Row>
                    <Col lg={6}>
                        <Form.Group className="mx-2" controlId="formBasicTitle">
                            <Form.Label
                                className="form-view-label"
                                htmlFor="formBasicTitle"
                            >
                                Target Date
                            </Form.Label>
                            <Form.Control
                                type="date"
                                name="targetdate"
                                placeholder="Enter Date"
                                value={product.targetdate}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </Col>
        <Col></Col>
    </Row>
</Container>

  )
}

export default ProductCreate
