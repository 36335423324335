import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import Confirm from "../Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useSelector, useDispatch } from "react-redux";
import { getRequest, deleteRequest } from "../../api/inventoryApiTwo";
import { PATH, TYPE } from "../../api/pathUrl";
import {
  RelatedListTask,
  FilesCreate,
  RelatedListFiles,
  RelatedListBookings,
  TaskEdit,
} from "../index";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";

const ContactView = (props) => {
  const { contactDetail, resultHandle } = useSelector(
    (state) => state.apiReducer
  );
  let dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [contact, setContact] = useState(location.state ? location.state : {});
  const [modalShow, setModalShow] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [relatedListTasks, setRelatedListTasks] = useState(false);
  const [relatedListFiles, setRelatedListFiles] = useState(false);
  const [relatedListBookings, setRelatedListBookings] = useState(true);
  const [modalShowTaskfile, setModalShowFile] = useState(false);
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [refreshFileList, setRefreshFileList] = useState();
  const [refreshBookingList, setRefreshBookingList] = useState();
  const [tasks, setTasks] = useState([]);
  const [files, setFiles] = useState([]);
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    if (!contactDetail.id && Object.keys(contactDetail).length === 0) {
      dispatch(
        getRequest(
          PATH.contactPath + location.pathname.split("/")[2],
          TYPE.SET_CONTACT
        )
      );
    }
  }, []);

  useEffect(() => {
    if (resultHandle.success && !resultHandle.isModal) {
      navigate(`/contacts`);
      dispatch({ type: "SET_CONTACT", payload: {} });
      dispatch({ type: "SET_RESULT_HANDLE", payload: {} });
    }
  }, [resultHandle]);

  // const taskList = () => {
  //   async function init() {
  //     let task = await inventoryApi.fetchTasks(contact.id);
  //     if (task && task?.length > 0) {
  //       setTasks(task);
  //     } else {
  //       setTasks([]);
  //     }
  //   }
  //   init();
  // };
  // const filesList = () => {
  //   async function init() {
  //     let file = await inventoryApi.fetchFiles(contact.id);
  //     //.log(file);
  //     if (file && file?.length > 0) {
  //       setFiles(file);
  //     } else {
  //       setFiles([]);
  //     }
  //   }
  //   init();
  // };

  const deleteContact = async () => {
    dispatch(
      deleteRequest(
        PATH.contactPath,
        TYPE.GET_ALL_CONTACTS,
        location.pathname.split("/")[2]
      )
    );
  };

  const editContact = () => {
    navigate(`/contacts/${contactDetail.id}/e`, { state: contactDetail });
  };
  const submitTasks = () => {
    setModalShowTask(false);
    setRefreshTaskList(Date.now());
    setTasks([]);
    //taskList();
  };
  const submitfiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    setFiles([]);
    //filesList();
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setRelatedListTasks(true);
      setRelatedListFiles(false);
      setRelatedListBookings(false);
    } else if (key === "files") {
      setRelatedListTasks(false);
      setRelatedListFiles(true);
      setRelatedListBookings(false);
    } else if (key === "Details") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedListBookings(true);
    }
  };
  return (
    <div>
      <Col lg={4} className="px-4">
        <CustomSeparator
          cmpListName="Contacts"
          currentCmpName={
            (contact.salutation !== null ? contact.salutation : "") +
            "" +
            contact.firstname +
            " " +
            contact.lastname
          }
          indexLength="2"
          url="/Contacts"
        ></CustomSeparator>
      </Col>
      <br></br>
      {contact && (
        <Container>
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteLead={deleteContact}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="lead"
            />
          )}
          <Row className="view-form">
            <Col></Col>
            <Col lg={8} style={{ backgroundColor: "#fff" }}>
              <Row className="view-form-header align-items-center">
                <Col lg={3}>
                  Contact
                  <h4>
                    {(contact.salutation !== null ? contact.salutation : "") +
                      "" +
                      contact.firstname +
                      " " +
                      contact.lastname}
                  </h4>
                </Col>
                <Col lg={9} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editContact(true)}
                    title="Edit"
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </Button>
                  <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={() => setModalShow(true)}
                  >
                    Delete
                  </Button>
                  <Button
                    className="btn-sm mx-2"
                    variant="danger"
                    onClick={() => navigate(`/contacts`)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <label>Name</label>
                  <span>
                    {contact.salutation} {contact.firstname} {contact.lastname}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Title</label>
                  <span>{contact.title}</span>
                </Col>
                <Col lg={6}>
                  <label>Email</label>
                  <span>{contact.email}</span>
                </Col>
                <Col lg={6}>
                  <label>Phone</label>
                  <span>{contact.phone}</span>
                </Col>
                <Col lg={6}>
                  <label>Company</label>
                  <span>{contact.company}</span>
                </Col>


                <Col lg={6}>
                  <label>Street</label>
                  <span>{contact.street}</span>
                </Col>
                <Col lg={6}>
                  <label>City</label>
                  <span>{contact.city}</span>
                </Col>
                <Col lg={6}>
                  <label>State</label>
                  <span>{contact.state}</span>
                </Col>
                <Col lg={6}>
                  <label>Pincode</label>
                  <span>{contact.pincode}</span>
                </Col>
                <Col lg={6}>
                  <label>Country</label>
                  <span>{contact.country}</span>
                </Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>
          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="Details"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                {/* <Tab eventKey="tasks" title="Tasks"></Tab> */}
                <Tab eventKey="Details" title="Details"></Tab>
                <Tab eventKey="files" title="Files"></Tab>
              </Tabs>
              {relatedListTasks && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowTask(true)}
                >
                  New Task
                </Button>
              )}
              {modalShowTask && (
                <TaskEdit
                  show={modalShowTask}
                  onHide={() => setModalShowTask(false)}
                  parentid={contact.id}
                  table="lead"
                  submitTasks={submitTasks}
                />
              )}
              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  Upload File
                </Button>
              )}
              {modalShowTaskfile && (
                <FilesCreate
                  show={modalShowTaskfile}
                  onHide={() => setModalShowFile(false)}
                  parent={contact}
                  table="lead"
                  submitfiles={submitfiles}
                />
              )}
            </Card.Header>
            <Card.Body>
              {relatedListTasks ? (
                <RelatedListTask
                  refreshTaskList={refreshTaskList}
                  parent={contact}
                />
              ) : (
                ""
              )}
              {relatedListFiles ? (
                <RelatedListFiles
                  refreshFileList={refreshFileList}
                  parent={contact}
                  files={files}
                />
              ) : (
                ""
              )}
              {relatedListBookings ? (
                <RelatedListBookings
                  refreshBookingList={refreshBookingList}
                  parent={contact}
                  bookings={bookings}
                />
              ) : (
                ""
              )}
            </Card.Body>
          </Card>
        </Container>
      )}
    </div>
  );
};

export default ContactView;
