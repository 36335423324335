const initialState = {
  roomList: [],
  dashboardRoomData: [],
  availableRoomData:[],
  roomDetail: {},
  pricingsList: [],
  pricingDetail: {},
  tasksList: [],
  taskDetail: {},
  usersList: [],
  filesList: [],
  bookingList: [],
  advanceBookingsList: [],
  advanceBookingTab: [],
  bookingDetail: {},
  advancebookingDetail: {},
  paymentsList: [],
  paymentDetail: {},
  bookingRoomList: [],
  agentcommList: [],
  ordersList: [],
  expensesList: [],
  expenseDetail: {},
  orderDetail: {},
  billsData: {},
  billContactDetail: [],
  accounts: [],
  contactsList: [],
  contactDetail: {},
  userDetail: {},
  profileData: {},
  categoryList: [],
  categoryDetail: {},
  resultHandle: {},
  itemList: [],
  itemDetail: {},
  orderList: [],
  reportDetail: {},
  reportList: [],
  agentcommDetail: {},
  todosList: [],
  todoDetail: {},
  transactionList: [],
  transactionDetail: {},
  lineReport:[]
} 

const apiReducer = (state = initialState, action) => {
  //.log('action', action.type, action.payload)

  switch (action.type) {
    //************************ Rooms ***********************************//
    case "GET_ROOMS":
      return {
        ...state,
        roomList: action.payload,
      };
    case "SET_ROOM":
      return {
        ...state,
        roomDetail: action.payload,
      };
    case "GET_DASHBOARD_ROOMS":
      return {
        ...state,
        dashboardRoomData: action.payload,
      };

    //************************ Pricing ***********************************//
    case "GET_PRICING":
      return {
        ...state,
        pricingsList: action.payload,
      };
    case "SET_PRICING":
      return {
        ...state,
        pricingDetail: action.payload,
      };
    case "GET_AVAILABLE_ROOMS":
      return {
        ...state,
        availableRoomData: action.payload,
      };

    //************************ Tasks ***********************************//
    case "GET_TASKS":
      return {
        ...state,
        tasksList: action.payload,
      };
    case "SET_TASK":
      return {
        ...state,
        taskDetail: action.payload,
      };
    //************************ Transaction ***********************************//
    case "GET_TRANSACTIONS":
      return {
        ...state,
        transactionList: action.payload,
      };
    case "SET_TRANSACTION":
      return {
        ...state,
        transactionDetail: action.payload,
      };

    //************************ Files ***********************************//
    case "GET_FILES":
      return {
        ...state,
        filesList: action.payload,
      };
      case "SET_FILE":
        return {
          ...state,
          fileDetail : action.payload,
      };

    //************************ Bookings ***********************************//
    case "GET_BOOKINGS":
      return {
        ...state,
        bookingList: action.payload,
      };
    case "GET_ADV_BOOKINGS":
      return {
        ...state,
        advanceBookingsList: action.payload,
      };
    case "SET_BOOKING":
      return {
        ...state,
        bookingDetail: action.payload,
      };
    case "SET_ADVANCEBOOKING":
      return {
        ...state,
        advancebookingDetail: action.payload,
      };
    case "GET_BOOKING_ROOM":
      return {
        ...state,
        bookingRoomList: action.payload,
      };
    case "GET_BILLS_DATA":
      return {
        ...state,
        billsData: action.payload,
      };
    case "GET_ADVANCEBOOKINGS":
      return {
        ...state,
        advanceBookingTab: action.payload,
      };

    //************************ Payment ***********************************//
    case "GET_PAYMENTS":
      return {
        ...state,
        paymentsList: action.payload,
      };
    case "SET_PAYMENT":
      return {
        ...state,
        paymentDetail: action.payload,
      };

    //************************ Expense ***********************************//
    case "GET_EXPENSES":
      return {
        ...state,
        expensesList: action.payload,
      };
    case "SET_EXPENSE":
      return {
        ...state,
        expenseDetail: action.payload,
      };
      case "BILL_DATA_CONTACT":
        return {
          ...state,
          billContactDetail : action.payload,
      };

    //************************ Order ***********************************//
    case "GET_ORDERS":
      return {
        ...state,
        ordersList: action.payload,
      };
    case "SET_ORDER":
      return {
        ...state,
        orderDetail: action.payload,
      };

//************************ Agent Commission ***********************************//
case "GET_AGENTCOMM":
  return {
      ...state,
      agentcommList : action.payload,
  };
  case "SET_AGENTCOMM":
    return {
        ...state,
        agentcommDetail : action.payload,
    };

//************************ Users ***********************************//

case "GET_USERS":
  return {
      ...state,
      usersList : action.payload,
  };
case "SET_USER":
  return {
    ...state,
    userDetail : action.payload,
};

//************************ Contacts ***********************************//

      case "GET_CONTACTS":
        return {
            ...state,
            contactsList : action.payload,
        };
      case "SET_CONTACT":
        return {
          ...state,
          contactDetail : action.payload,
      };

          //************************ Profile ***********************************//
    case "GET_PROFILE":
      return {
        ...state,
        profileData: action.payload,
      };

//************************ Pricing ***********************************//
      case "GET_ITEM":
        return {
            ...state,
            itemList : action.payload,
        };
      case "SET_ITEM":
        return {
            ...state,
            itemDetail : action.payload,
        };  

//************************ Category ***********************************//

      case "GET_CATEGORY":
        return {
            ...state,
            categoryList : action.payload,
        };
      case "SET_CATEGORY":
        return {
          ...state,
          categoryDetail : action.payload,
      };
// //************************ Files ***********************************//
//       case "GET_FILES":
//         return {
//             ...state,
//             fileList : action.payload,
//         };

//         case "SET_FILE":
//           return {
//             ...state,
//             fileDetail : action.payload,
//         };
//************************ Orders ***********************************//
        // case "SET_ORDER":
        //   return {
        //       ...state,
        //       orderDetail : action.payload,
        //   };

        // case "GET_ORDER":
        // return {
        //     ...state,
        //     orderList : action.payload,
        // };

//************************ Reports ***********************************//
        case "SET_REPORT":
        return {
            ...state,
            reportDetail : action.payload,
        };

        case "GET_REPORT":
        return {
            ...state,
            reportList : action.payload,
        };
//************************ Todos ***********************************//
        case "GET_TODOS":
        return {
            ...state,
            todosList : action.payload,
        };
        case "SET_TODO":
        return {
            ...state,
            todoDetail : action.payload,
        };

  
          //************************ Set success ***********************************//
    case "SET_SUCCESS":
      return {
        ...state, resultHandle: action.payload
      };
    case "SET_ReportLine":
      return {
        ...state, lineReport: action.payload
      };

      
        case "SET_RESULT_HANDLE":
        return {
          ...state, resultHandle: {}
        };

        default:
            return state;
      }

};
export default apiReducer;