import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-toastify/dist/ReactToastify.css";
import inventoryApi from "../../api/inventoryApi";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
import CreateContactForBooking from "../Contact/CreateContactForBooking";
import { MSG } from "../Messages";
import { NAME_REGEX } from "../Regex";
import * as formik from "formik";
import * as yup from "yup";
import ToastMessage from "../ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import { createBooking, fetchBookings, updateBooking } from "../APIs";
import { calculateDays, calculateAmount } from "../Helper";
import { AdvancebookingEditInitialValues } from "../InitialValuesHelper";
import { getRequest, postRequest, putRequest } from "../../api/inventoryApiTwo";
import { PATH, TYPE } from "../../api/pathUrl";
import { AdvanceschemaBookingEdit } from "../ValidateSchemaHelper";

const AdvanceBookingEdit = (props) => {
  const ref = useRef();
  const refTwo = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [booking, setBooking] = useState(
    location.state
      ? location.state.room
        ? { isgst: false }
        : location.state
      : { isgst: false }
  );
  const [roomSelected, setRoomSelected] = useState("");
  const [listpricing, setListPricing] = useState();
  const [extrabed, setExtraBed] = useState();
  const [roomdetail, setRoomDetail] = useState();
  const [showContact, setShowContact] = useState(false);
  const [contactdetail, setContactDetail] = useState();
  const [checkin, setCheckin] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [checkout, setCheckout] = useState();
  const [availableRooms, setAvailableRooms] = useState(false);
  const [contactId, setContactId] = useState();
  const [contactList, setContactList] = useState([]);
  const [defaultContact, setDefaultContact] = useState([]);
  const [roomsList, setRoomList] = useState([]);
  const [defaultRoom, setdefaultRoom] = useState([]);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [toastError, setToastError] = useState();
  const [room, setRoom] = useState(
    location.state ? (location.state.room ? location.state.room.room : {}) : {}
  );

  const {
    bookingDetail,
    roomList,
    bookingRoomList,
    contactsList,
    resultHandle,
  } = useSelector((state) => state.apiReducer);

  useEffect(() => {
    async function intit1() {
      // dispatch(getRequest(PATH.roomPath, TYPE.GET_ALL_ROOMS));
      // //.log("All room for dates --> ",resultRoom)
      // //.log("booking.roomid --> ",booking.roomid)
      // if (resultRoom) {
      //   if (booking.id) {
      //     setAvailableRooms(true);
      //     setRoomDetail(resultRoom.find((ele) => ele.id === booking.roomid));
      //   }
      // }
    }
    intit1();
  }, []);

  useEffect(() => {
    // setDefaultContact([]);

    // async function init() {

    dispatch(getRequest(PATH.roomPath, TYPE.GET_ALL_ROOMS));

    dispatch(getRequest(PATH.contactPath, TYPE.GET_ALL_CONTACTS));

    // const conResult = await inventoryApi.fetchContacts();
    // //.log("Contact result --> ",conResult)

    // if (conResult.data) {
    //   setContactList(conResult.data.map((data) => {return {...data,contactname: data.firstname + "." + data.lastname + " - " + data.phone}}));
    //   if (booking.id) {
    //     setDefaultContact([{ id: booking.contactid, contactname: booking.contact_name }]);
    //     setContactDetail(conResult.data.find((ele) => ele.id === booking.contactid));
    //   } else if (contactId) {
    //     let contactDetail = conResult.data.find((ele) => ele.id === contactId);
    //     setDefaultContact([{ id: contactId,contactname:contactDetail.firstname +"." +contactDetail.lastname +" - " +contactDetail.phone}]);
    //     setBooking({ ...booking, contactid: contactId });
    //     setContactDetail(contactDetail);
    //   } else {
    //     setDefaultContact([{ id: "", contactname: "" }]);
    //   }
    // } else {
    //   setDefaultContact([{ id: "", contactname: "" }]);
    // }
    // }

    // init();
  }, []);

  useEffect(() => {
    //.log("rooms list in bookigEdit --> ", roomList);
    //.log("contacts list in bookigEdit --> ", contactsList);

    // async function intit1() {

    //   //.log("All room for dates --> ",resultRoom)
    //   //.log("booking.roomid --> ",booking.roomid)
    if (roomList.length > 0) {
      if (booking.id) {
        setAvailableRooms(true);
        setRoomDetail(roomList.find((ele) => ele.id === booking.roomid));
      }
    }

    if (booking.id) {
      //.log("inside if", booking);
      setdefaultRoom([{ id: booking.roomid, roomname: booking.roomname }]);
    } else if (room.id) {
      //.log("inside else if");
      setAvailableRooms(true);
      setdefaultRoom([
        { id: room.id, roomname: room.name + " - " + room.roomno },
      ]);
      setRoomDetail(room);
      setBooking({ ...booking, roomid: room.id });
    } else {
      //.log("inside else");
      setdefaultRoom([{ id: "", roomname: "" }]);
    }

    // if (contactsList.length > 0) {
    // if(contactsList.length>0) setContactList(contactsList.map((data) => { return { ...data, contactname: data.firstname + "." + data.lastname + " - " + data.phone } }));
    // if (booking.id) {
    //   setDefaultContact([{ id: booking.contactid, contactname: booking.contact_name }]);
    //   setContactDetail(contactsList.find((ele) => ele.id === booking.contactid));
    // } else if (contactId) {
    //   let contactDetail = contactsList.find((ele) => ele.id === contactId);
    //   setDefaultContact([{ id: contactId, contactname: contactDetail.firstname + "." + contactDetail.lastname + " - " + contactDetail.phone }]);
    //   setBooking({ ...booking, contactid: contactId });
    //   setContactDetail(contactDetail);
    // } else {
    //   setDefaultContact([{ id: "", contactname: "" }]);
    // }
    // } else {
    //   setDefaultContact([{ id: "", contactname: "" }]);
    // }

    // }
    // intit1();
  }, [roomList]);

  useEffect(() => {
    if (contactsList.length > 0)
      setContactList(
        contactsList.map((data) => {
          return {
            ...data,
            contactname:
              data.firstname + "." + data.lastname + " - " + data.phone,
          };
        })
      );
    if (booking.id) {
      setDefaultContact([
        { id: booking.contactid, contactname: booking.contact_name },
      ]);
      setContactDetail(
        contactsList.find((ele) => ele.id === booking.contactid)
      );
    } else if (contactId) {
      let contactDetail = contactsList.find((ele) => ele.id === contactId);
      setDefaultContact([
        {
          id: contactId,
          contactname:
            contactDetail.firstname +
            "." +
            contactDetail.lastname +
            " - " +
            contactDetail.phone,
        },
      ]);
      setBooking({ ...booking, contactid: contactId });
      setContactDetail(contactDetail);
      //  let contactDetail = contactsList.find((ele) => ele.id === contactId);
      //  //.log("contactDetail after contact insert",contactDetail);
      // "Shubham Patil - 6764567865"
      // setDefaultContact([{ id: contactDetail.id, contactname: `${contactDetail.firstname}.${contactDetail.lastname} - ${contactDetail.phone}` }]);
      // setDefaultContact([{ id: contactId, contactname: contactDetail.firstname + "." + contactDetail.lastname + " - " + contactDetail.phone }]);
      // setBooking({ ...booking, contactid: contactId });
      //setContactDetail(contactDetail);
    } else {
      setDefaultContact([{ id: "", contactname: "" }]);
    }
  }, [contactsList]);

  useEffect(() => {
    async function intit() {
      // var temp;
      if (checkin && checkout && availableRooms) {
        let url;
        if (booking.roomid)
          url = `getroomsbydate?startdate=${checkin}&enddate=${checkout}&roomid=${booking.roomid}&bookingid=${booking.id}`;
        else
          url = `getroomsbydate/?startdate='${checkin}'&enddate='${checkout}'`;

        //create path
        dispatch(
          getRequest(`${PATH.roomPath}${url}`, TYPE.GET_ROOM_FOR_BOOKING)
        );

        // const result = await inventoryApi.bookingRoom(
        //   checkin,
        //   checkout,
        //   booking.roomid,
        //   booking.id
        // );
        //   //.log("result by date---->", result);
        //   if (result.success && Array.isArray(result.data)) {
        //     setRoomList(
        //       result.data.map((data) => {
        //         return { ...data, roomname: data.name };
        //       })
        //     );
        //     setToastError();
        //   } else if (result.success && !result.response.success) {
        //     setToastError(result.message);
        //     ToastMessage(result.message, "error");
        //   }
        // }

        // if (temp) return;
        // if (booking.id) {
        //   //.log("inside if",booking)
        //   setdefaultRoom([{ id: booking.roomid, roomname: booking.roomname }]);
        // } else if (room.id) {
        //   //.log("inside else if")
        //   setAvailableRooms(true);
        //   setdefaultRoom([
        //     { id: room.id, roomname: room.name + " - " + room.roomno },
        //   ]);
        //   setRoomDetail(room);
        //   setBooking({ ...booking, roomid: room.id });
        // } else {
        //   //.log("inside else")
        //   setdefaultRoom([{ id: "", roomname: "" }]);
      }
    }

    intit();
  }, [availableRooms]);

  useEffect(() => {
    if (resultHandle.success && !resultHandle.isModal) {
      // setBookingSuccess(true);
      if (bookingSuccess === true) {
        navigate(`/AdvanceBooking/${resultHandle.data.id}`, {
          state: resultHandle.data,
        });
      }
      // else{
      //   setBookingSuccess(false);
      // }
      dispatch({ type: "SET_RESULT_HANDLE", payload: {} });
    }
  }, [resultHandle]);

  useEffect(() => {
    if (bookingRoomList.length > 0) {
      // navigate(`/booking/${resultHandle.data.id}`, { state: resultHandle.data });
      // dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })

      // if (result.success && Array.isArray(result.data)) {
      setRoomList(
        bookingRoomList.map((data) => {
          return { ...data, roomname: data.name };
        })
      );
      //   setToastError();
      // } else if (result.success && !result.response.success) {
      //   setToastError(result.message);
      //   ToastMessage(result.message, "error");
      // }
    }

    // if (booking.id) {
    //   //.log("inside if", booking)
    //   setdefaultRoom([{ id: booking.roomid, roomname: booking.roomname }]);
    // } else if (room.id) {
    //   //.log("inside else if")
    //   setAvailableRooms(true);
    //   setdefaultRoom([
    //     { id: room.id, roomname: room.name + " - " + room.roomno },
    //   ]);
    //   setRoomDetail(room);
    //   setBooking({ ...booking, roomid: room.id });
    // } else {
    //   //.log("inside else")
    //   setdefaultRoom([{ id: "", roomname: "" }]);
    // }
  }, [bookingRoomList]);

  const handleSubmitSave = async (values) => {
    //.log("Inside final submit", values);
    setBookingSuccess(true);
    //   const cleanData = Object.fromEntries(
    //     Object.entries(values).filter(([key, value]) => value !== undefined && value !== null && value !== 'Invalid date')
    // );
    // //.log("Inside final submiterr", cleanData);

    // let result = {};
    if (!toastError) {
      if (booking.id) {
        dispatch(
          putRequest(
            PATH.advancebookingPath,
            TYPE.GET_ALL_ADVANCEBOOKINGS,
            location.pathname.split("/")[2],
            values,
            false
          )
        );

        // const result = await dispatch(updateBooking(values));
        // if (result.success) {
        //   dispatch(fetchBookings());
        //   navigate(`/booking/${values.id}`, { state: values });
        // }

        // // values.id = booking.id;
        // result = await inventoryApi.saveBooking(values);
        // if (result.success) {
        //   // ToastMessage("Booking has been updated successfully!", "success");
        // }
      } else {
        //values.isgst = values.isgst ? true : false;
        // values["status"] = "Advance booking";
        // values["per_day_amount"] = listpricing;
        // values["per_day_extrabed_amount"] = extrabed;
        // result = await inventoryApi.createBooking(values);

        dispatch(
          postRequest(
            PATH.AdvancebookingPath,
            values,
            TYPE.GET_ALL_ADVANCEBOOKINGS
          )
        );

        // result = await dispatch(createBooking(values));
        // if (result.success) {
        // if (result.errors) {
        //   ToastMessage(result.errors, "error");
        // } else {
        // ToastMessage("Booking has been created successfully!", "success");
        // dispatch(fetchBookings());
        // navigate(`/booking/${result.data.id}`, { state: result.data });
        // }
        // }
      }
    } else {
      ToastMessage(toastError, "error");
    }
  };

  const handleCancel = () => {
    if (booking.id)
      navigate("/AdvanceBooking/" + booking.id, { state: booking });
    else navigate("/AdvanceBooking");
  };

  const { Formik } = formik;

  return (
    <Container className="view-form">
      <Row className="view-form">
        <Col></Col>
        <Col lg={9} style={{ backgroundColor: "#fff" }} className="pb-3">
          <Formik
            validationSchema={AdvanceschemaBookingEdit(booking)}
            onSubmit={handleSubmitSave}
            initialValues={AdvancebookingEditInitialValues(booking)}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              handleBlur,
              setFieldValue,
              getFieldProps,
              touched,
            }) => (
              <Form className="mt-3" onSubmit={handleSubmitSave}>
                <Row
                  className="view-form-header align-items-center"
                  style={{
                    marginTop: "-15px",
                  }}
                >
                  <Col lg={3}>
                    {booking.id ? (
                      <>Edit Advance booking</>
                    ) : (
                      "New Advance Booking"
                    )}
                  </Col>
                  <Col lg={9} className="d-flex justify-content-end">
                    <Button className="btn-sm mx-2" onClick={handleSubmit}>
                      Save
                    </Button>
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Group className="mx-3" controlId="timeformat">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="timeformat"
                    >
                      Time Format <b class="red-star">*</b>
                    </Form.Label>
                    <Form.Select
                      style={{ width: "100%" }}
                      aria-label="Enter"
                      name="timeformat"
                      onBlur={handleBlur}
                      value={values.timeformat}
                      onChange={(e) => {
                        if (e.target.value === "11 AM") {
                          setFieldValue("check_in_time", "11:00:00");
                          setFieldValue("check_out_time", "11:00:00");
                          handleChange(e);
                        } else {
                          setFieldValue("check_in_time", "");
                          setFieldValue("check_out_time", "");
                          handleChange(e);
                        }
                      }}
                    >
                      <option value="">--Select--</option>
                      <option value="24 hrs">24 Hrs</option>
                      <option value="11 AM">11 AM</option>
                    </Form.Select>
                    {touched.timeformat && (
                      <div className="form-error">{errors.timeformat}</div>
                    )}
                  </Form.Group>

                  <Form.Group className="mx-3" controlId="formBasicEmail">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicEmail"
                    >
                      Check-In-Date & Time <b class="red-star">*</b>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        style={{ width: "40%" }}
                        type="date"
                        name="checkindate"
                        placeholder="Enter"
                        required
                        onBlur={handleBlur}
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        value={values.checkindate}
                        onChange={(e) => {
                          if (e.target.value && values.checkoutdate)
                            setFieldValue(
                              "noofdays",
                              calculateDays(
                                e.target.value,
                                getFieldProps("checkoutdate").value
                              )
                            );

                          if (booking.id) {
                            setFieldValue(
                              "totalamount",
                              calculateAmount(
                                e.target.value,
                                getFieldProps("checkoutdate").value,
                                getFieldProps("extra_bed").value != null
                                  ? getFieldProps("extra_bed").value
                                  : 0,
                                booking.per_day_amount,
                                booking.per_day_extrabed_amount
                              )
                            );
                          }

                          if (
                            values.checkoutdate &&
                            values.roomid &&
                            e.target.value
                          ) {
                          }
                          setCheckin(e.target.value);
                          handleChange(e);
                        }}
                      />
                      <Form.Control
                        style={{ width: "35%" }}
                        type="time"
                        name="check_in_time"
                        placeholder=""
                        required
                        disabled={
                          values.timeformat === "11 AM" ||
                          values.timeformat === ""
                        }
                        value={values.check_in_time}
                        onChange={handleChange}
                      />
                      {touched.checkindate && (
                        <div className="form-error">{errors.checkindate}</div>
                      )}
                      {!errors.checkindate && touched.check_in_time && (
                        <div className="form-error">{errors.check_in_time}</div>
                      )}
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mx-3" controlId="formBasicPhone">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicPhone"
                    >
                      Check-Out-Date & Time <b class="red-star">*</b>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        style={{ width: "40%" }}
                        required
                        type="date"
                        onBlur={handleBlur}
                        name="checkoutdate"
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        placeholder="Enter"
                        value={values.checkoutdate}
                        onChange={(e) => {
                          if (values.checkindate && e.target.value) {
                            setFieldValue(
                              "noofdays",
                              calculateDays(
                                getFieldProps("checkindate").value,
                                e.target.value
                              )
                            );
                            if (booking.id) {
                              setFieldValue(
                                "totalamount",
                                calculateAmount(
                                  getFieldProps("checkindate").value,
                                  e.target.value,
                                  getFieldProps("extra_bed").value != null
                                    ? getFieldProps("extra_bed").value
                                    : 0,
                                  booking.per_day_amount,
                                  booking.per_day_extrabed_amount
                                )
                              );
                            }
                          }
                          setCheckout(e.target.value);
                          handleChange(e);
                        }}
                      />
                      <Form.Control
                        style={{ width: "35%" }}
                        required
                        type="time"
                        name="check_out_time"
                        disabled={
                          values.timeformat === "11 AM" ||
                          values.timeformat === ""
                        }
                        placeholder=""
                        value={values.check_out_time}
                        onChange={handleChange}
                      />
                      {touched.checkoutdate && (
                        <div className="form-error">{errors.checkoutdate}</div>
                      )}
                      {!errors.checkoutdate && touched.check_out_time && (
                        <div className="form-error">
                          {errors.check_out_time}
                        </div>
                      )}
                    </InputGroup>
                  </Form.Group>
                </div>

                <div>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mx-3" controlId="contact">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="contact"
                        >
                          Contact <b class="red-star">*</b>
                        </Form.Label>
                        {defaultContact.length > 0 ? (
                          <InputGroup>
                            <Typeahead
                              clearButton={values.contactid}
                              ref={refTwo}
                              required
                              id="basic-typeahead-single2"
                              defaultSelected={defaultContact}
                              name="contactid"
                              labelKey="contactname"
                              options={contactList}
                              onChange={(event) => {
                                if (event.length > 0) {
                                  //.log("event", event[0].id);
                                  setFieldValue("contactid", event[0].id);
                                  setContactDetail(
                                    contactList.find(
                                      (data) => data.id === event[0].id
                                    )
                                  );
                                } else {
                                  setFieldValue("contactid", "");
                                  setContactDetail();
                                }
                              }}
                              placeholder="Select a contact"
                            />
                            <Button
                              variant="primary"
                              onClick={() => {
                                setShowContact(true);
                              }}
                            >
                              <b>+</b>
                            </Button>

                            {touched.contactid && (
                              <div className="form-error">
                                {errors.contactid}
                              </div>
                            )}
                          </InputGroup>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                      {showContact && (
                        <CreateContactForBooking
                          show={showContact}
                          onHide={() => {
                            setShowContact(false);
                          }}
                          handleClose={(data) => {
                            if (data) {
                              setDefaultContact([]);
                              setContactId(data);
                              setFieldValue("contactid", data);
                              //.log("data in handleClose", data);

                              // let contactDetail = contactsList.find((ele) => ele.id === contactId);
                              // setDefaultContact([{ id: data.id, contactname: data.firstname + "." + data.lastname + " - " + data.phone }]);
                              // setBooking({ ...booking, contactid: data.id });
                              // setContactDetail(data);
                            }
                          }}
                        />
                      )}
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mx-3" controlId="formBasicStreet">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicStreet"
                        >
                          No of Guest
                        </Form.Label>
                        <Form.Control
                          required
                          type="number"
                          name="noofguest"
                          className="no-arrows"
                          min={1}
                          placeholder="Enter No of guest"
                          value={values.noofguest}
                          onChange={handleChange}
                        />
                        {touched.noofguest && (
                          <div className="form-error">{errors.noofguest}</div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mx-3" controlId="formBasicCity">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicCity"
                        >
                          Coming From
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="comingfrom"
                          placeholder="Coming from"
                          value={values.comingfrom}
                          onChange={handleChange}
                        />
                        {touched.comingfrom && (
                          <div className="form-error">{errors.comingfrom}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mx-3" controlId="formBasicState">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicState"
                        >
                          Going To
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="goingto"
                          placeholder="Going to"
                          value={values.goingto}
                          onChange={handleChange}
                        />
                        {touched.goingto && (
                          <div className="form-error">{errors.goingto}</div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col lg={6}>
                      <Form.Group className="mx-3" controlId="formBasicState">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicState"
                        >
                          Source
                        </Form.Label>
                        <Form.Select
                          required
                          name="source"
                          value={values.source}
                          onChange={handleChange}
                        >
                          <option value="">Select Source </option>
                          <option value="MakeMyTrip">MakeMyTrip</option>
                          <option value="Agoda">Agoda</option>
                          <option value="OYO">OYO</option>
                          <option value="Other">Other</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group
                        className="mx-3"
                        controlId="formBasicDiscount"
                      >
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicDiscount"
                        >
                          Advance
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            // min={0}
                            // max={100}
                            className="no-arrows"
                            name="advanceamount"
                            placeholder="Enter Advance"
                            value={values.advanceamount}
                            onChange={handleChange}
                            // aria-describedby="basic-addon"
                          />
                          <InputGroup.Text id="basic-addon">₹</InputGroup.Text>

                          {touched.advanceamount && (
                            <div className="form-error">
                              {errors.advanceamount}
                            </div>
                          )}
                        </InputGroup>
                      </Form.Group>
                    </Col>
                   { values.advanceamount && values.advanceamount > 0 ?
                   <Col>
                      <Form.Group
                        className="mx-3"
                        controlId="formBasicPriority"
                      >
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicPriority"
                        >
                          Method <b class="red-star">*</b>
                        </Form.Label>
                        <Form.Select
                          required
                          aria-label="Enter Priority"
                          name="method"
                          value={values.method}
                          onChange={handleChange}
                        >
                          <option value="">Select Method</option>
                          <option value="Cash">Cash</option>
                          <option value="Card">Card</option>
                          <option value="UPI">UPI</option>
                        </Form.Select>
                        {touched.method && (
                          <div className="form-error">{errors.method}</div>
                        )}
                      </Form.Group>
                    </Col> : ''}

                    <Col lg={6}>
                      <Form.Group
                        className="mx-3"
                        controlId="formBasicDescription"
                      >
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicDescription"
                        >
                          Description/Arrival
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          name="description"
                          placeholder="Enter Description/Arrival"
                          value={values.description}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>{" "}
                </div>
              </Form>
            )}
          </Formik>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default AdvanceBookingEdit;
