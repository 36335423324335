import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import InfoPill from "../InfoPill";
import InputGroup from "react-bootstrap/InputGroup";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRequest } from "../../api/inventoryApiTwo";
import { PATH, TYPE } from "../../api/pathUrl";
import moment from "moment";

const AdvanceBookingList = () => {
  let dispatch = useDispatch();
  const {  advanceBookingTab } = useSelector((state) => state.apiReducer);
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    dispatch(getRequest(PATH.advancebookingPath, TYPE.GET_ALL_ADVANCEBOOKINGS));
  
  }, [])
  

  useEffect(() => {
    // async function init() {
    //   dispatch({ type: TYPE.SET_ADVANCEBOOKING, payload: {} });
      // if (advanceBookingTab.length === 0) {
      // }
      //.log('advanceBookingTab',advanceBookingTab);
      if (advanceBookingTab.length > 0) {
        setBody(advanceBookingTab);
        setContacts(advanceBookingTab);
      }
    // }
    // init();
  }, [advanceBookingTab]);

  // Create table headers consisting of 4 columns.
  const header = [
    {
      title: "Booking Number",
      prop: "auto_number",
      isFilterable: true,
      cell: (row) => (
        <Link to={"/AdvanceBooking/" + row.id} state={row}  onClick={()=> dispatch({ type: TYPE.SET_ADVANCEBOOKING, payload: row })}>
          {row.auto_number}
        </Link>
      ),
    },
    // {
    //   title: "Room",
    //   prop: "roomname",
    //   isFilterable: true,
    //   //isSortable: true,
    // },
    { title: "Contact", prop: "contact_name", isFilterable: true },
    {
      title: "Check In date",
      prop: "checkindate",
      isFilterable: true,
      //isSortable: true,
      cell: (row) => moment(row.checkindate).format("DD/MM/YYYY"),
    },

    {
      title: "Check Out date",
      prop: "checkoutdate",
      isFilterable: true,
      //isSortable: true,
      cell: (row) => moment(row.checkoutdate).format("DD/MM/YYYY"),
    },
    { title: "No Of days", prop: "noofdays", isFilterable: true },
    { title: "No of guest", prop: "noofguest", isFilterable: true },
    { title: "Coming from", prop: "comingfrom", isFilterable: true },
    { title: "Going to", prop: "goingto", isFilterable: true },

    {
      title: "Advance Amount",
      prop: "advanceamount",
      isFilterable: true,
      cell: (row) => <div style={{width: "55%",textAlign: "end"}}>₹ {row.advanceamount}</div>,
    },

    // {
    //   title: "Total Amount",
    //   prop: "totalamount",
    //   isFilterable: true,
    //   cell: (row) => (
    //     <Link to={"/booking/" + row.id} state={row}>
    //       ₹{row.totalamount}
    //     </Link>
    //   ),
    // },
    // { title: "Status", prop: "status", isFilterable: true },
  ];

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };
  const handleFilter = (filterText) => {
    const filteredData = contacts.filter(
      (row) =>
        row?.contactname?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
        row?.email?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
        row?.city?.toLowerCase()?.includes(filterText.toLowerCase()) ||
        row?.state?.toLowerCase()?.includes(filterText.toLowerCase()) ||
        row?.phone?.toLowerCase()?.includes(filterText.toLowerCase())
    );
    //.log("filteredData", filteredData);
    setBody(filteredData);
  };

  const createContact = () => {
    navigate(`/AdvanceBooking/e`);
  };

  return (
    <Row className="g-0">
   <Col lg={4} className="px-4">
      <CustomSeparator
        currentCmpName="Advance Bookings"
        indexLength="0"
        url="/AdvanceBookings"
      ></CustomSeparator>
    </Col>
    <Col lg={12} className = "p-lg-4">

      {body ?
        <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10,
            options: [5, 10, 15, 20]
          }
        }}>
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-center"
            >
              <div style={{marginTop:"0.4rem"}}>

               <PaginationOptions labels={labels} />
              </div>
               <InfoPill  left="Total" right={body?.length} />

            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Button className="btn-sm" variant="outline-primary" onClick={() => createContact(true)}>New Advance Booking</Button>
            </Col>
          </Row>
          <Table striped className="data-table">
            <TableHeader />
            <TableBody />
          </Table>
          <Pagination />

        </DatatableWrapper> : ''}
    </Col>
    {/* <Col lg={2}></Col> */}
  </Row>
  );
};

export default AdvanceBookingList;
