import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import inventoryApi from "../../api/inventoryApi";
import { useLocation, useNavigate} from 'react-router-dom'
import moment from 'moment';


import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
  } from 'react-bs-datatable';
  import { Link } from "react-router-dom";
  
const ProductList = () => {
    const navigate = useNavigate();
    const [body, setBody] = useState([]);

    useEffect( () => {
        async function init(){
         const products = await inventoryApi.fetchProduct();
         if(products)
         
           setBody(products);
       }
       init();
 
   }, []);
 

    const header = [
        
        { title: 'Name', prop: 'name', isFilterable: true,
        cell: (row) => (
          <Link
            to={"/products/" + row.name}
            state={row}
  
  
          >
            {row.name}
          </Link>
        )
      
      
      } ,

        { title: 'Code', prop: 'productcode' , isFilterable: true },  
        { title: 'Category', prop: 'category ' , isFilterable: true },
        { title: 'Sub Category ', prop: 'subcategory  ' , isFilterable: true },
        { title: 'Description ', prop: 'description' , isFilterable: true },
        { title: 'Target Date ', prop: 'targetdate' , cell: (row) => (moment(row.targetdate).format('DD-MM-YYYY'))},
        { title: 'Material', prop: 'material' , isFilterable: true },
        { title: 'Color', prop: 'color' , isFilterable: true },
        //{ title: 'Dimension ', prop: 'dimension' , isFilterable: true },
        //{ title: 'Thickness', prop: 'thickness' , isFilterable: true },
        //{ title: 'Area', prop: 'area' , isFilterable: true },
        //{ title: 'location', prop: 'Location' , isFilterable: true },




      ];
      const labels = {
        beforeSelect: " "
      }
      const createProduct = () =>{
        navigate(`/products/e`);
      }
  return (
      <> 
       <Row className="g-0">
      <Col lg={2} className="mx-2">
        
      <Link className="nav-link">Home <i class="fa-solid fa-chevron-right"></i> Product</Link>
      </Col>
      <Col lg={10} className = "p-lg-4">
       
      {body ? 
      <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
        initialState: {
          rowsPerPage: 10,
          options: [5, 10, 15, 20]
        }
      }}>
      <Row className="mb-4">
        <Col
          xs={12}
          lg={4}
          className="d-flex flex-col justify-content-end align-items-end"
        >
          <Filter />
        </Col>
        <Col
          xs={12}
          sm={6}
          lg={4}
          className="d-flex flex-col justify-content-start align-items-start"
        >
        <PaginationOptions labels={labels}/>
        
        
        </Col>
        <Col
          xs={12}
          sm={6}
          lg={4}
          className="d-flex flex-col justify-content-end align-items-end"
        >
          <Button className="btn-sm" variant="outline-primary" onClick={() => createProduct(true)}>New Product</Button>
        </Col>
       
      </Row>
      <Table striped className="data-table">
        <TableHeader />
        <TableBody />
      </Table>
      <Pagination />
      
    </DatatableWrapper> : '' }
      </Col>
      <Col lg={2}></Col>
    </Row>
  
      </>
  )
}

export default ProductList
