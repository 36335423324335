import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Confirm from "../Confirm";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getRequest, deleteRequest } from "../../api/inventoryApiTwo";
import { PATH, TYPE } from "../../api/pathUrl";
import {
  RelatedListTask,
  FilesCreate,
  RelatedListFiles,
  PricingEdit,
  TaskEdit,
  RelatedListPricing,
} from "../index";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";

const RoomView = (props) => {
  let dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { roomDetail, resultHandle } = useSelector((state) => state.apiReducer);

  // const [room, setRoom] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [relatedListTasks, setRelatedListTasks] = useState(false);
  const [relatedListFiles, setRelatedListFiles] = useState(false);
  const [relatedListPricing, setRelatedListPricing] = useState(true);
  const [modalShowTaskfile, setModalShowFile] = useState(false);
  const [modalShowPricing, setModalShowPricing] = useState(false);
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [refreshFileList, setRefreshFileList] = useState();
  const [refreshPricingList, setRefreshPricingList] = useState();

  useEffect(() => {
    //.log('yes',roomDetail);
    // if (!roomDetail.id && Object.keys(roomDetail).length === 0) {
      dispatch(
        getRequest(
          `${PATH.roomPath}${location.pathname.split("/")[2]}`,
          TYPE.SET_ROOM
        )
      );
    // }
  }, [resultHandle]);

  useEffect(() => {
    if (resultHandle.success && !resultHandle.isModal) {
      navigate(`/rooms`);
      // dispatch({ type: TYPE.SET_ROOM, payload: {} });
      dispatch({ type: TYPE.SET_RESULT, payload: {} });
    }
  }, [resultHandle]);

  const deleteRoom = async () => {
    dispatch(
      deleteRequest(
        PATH.roomPath,
        TYPE.GET_ALL_ROOMS,
        location.pathname.split("/")[2]
      )
    );
  };

  const editRoom = () => {
    navigate(`/room/${roomDetail.id}/e`, { state: roomDetail });
  };

  const submitTasks = () => {
    setModalShowTask(false);
    setRefreshTaskList(Date.now());
  };
  const submitfiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
  };

  const submitpricing = () => {
    setModalShowPricing(false);
    setRefreshPricingList(Date.now());
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setRelatedListTasks(true);
      setRelatedListFiles(false);
      setRelatedListPricing(false);
    } else if (key === "files") {
      setRelatedListTasks(false);
      setRelatedListFiles(true);
      setRelatedListPricing(false);
    } else if (key === "pricing") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedListPricing(true);
    }
  };

  return (
    <div>
      <Col lg={4} className="px-4">
        <CustomSeparator
          cmpListName="Rooms"
          currentCmpName={`Room No. ${roomDetail.roomno}`}
          indexLength="2"
          url="/Rooms"
        ></CustomSeparator>
      </Col>
      <br></br>
      {roomDetail && (
        <Container>
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteRoom={deleteRoom}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="Room"
            />
          )}
          <Row className="view-form ">
            <Col></Col>
            <Col lg={8} style={{ backgroundColor: "#fff" }} className="pb-3">
              <Row className="view-form-header align-items-center">
                <Col lg={3}>
                  Room
                  <h4>{roomDetail.name}</h4>
                </Col>
                <Col lg={9} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editRoom(true)}
                    title="Edit"
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                  <Button
                    className="btn-sm mx-2"
                    variant="danger"
                    onClick={() => setModalShow(true)}
                  >
                    Delete
                  </Button>
                  <Button
                    className="btn-sm mx-2"
                    variant="danger"
                    onClick={() => navigate(`/rooms`)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <label>Name</label>
                  <span>{roomDetail.name}</span>
                </Col>
                <Col lg={6}>
                  <label>Room No</label>
                  <span>{roomDetail.roomno}</span>
                </Col>
                <Col lg={6}>
                  <label>Occupancy</label>
                  <span>{roomDetail.occupancy}</span>
                </Col>
                <Col lg={6}>
                  <label>Block</label>
                  <span>{roomDetail.block}</span>
                </Col>
                <Col lg={6}>
                  <label>Ac/Non-Ac</label>
                  <span>{roomDetail.acnonac}</span>
                </Col>
                <Col lg={6}>
                  <label>Type</label>
                  <span>{roomDetail.type}</span>
                </Col>
                <Col lg={6}>
                  <label>Floor</label>
                  <span>
                    {roomDetail.floor === "0" ? "G" : roomDetail.floor}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Status</label>
                  <span>{roomDetail.status}</span>
                </Col>
                <Col lg={6}>
                  <label>Description</label>
                  <span>{roomDetail.description}</span>
                </Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>

          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="pricing"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                <Tab eventKey="pricing" title="Pricing"></Tab>
                <Tab eventKey="tasks" title="Tasks"></Tab>
                <Tab eventKey="files" title="Files"></Tab>
              </Tabs>
              {relatedListTasks && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowTask(true)}
                >
                  New Task
                </Button>
              )}
              {modalShowTask && (
                <TaskEdit
                  show={modalShowTask}
                  onHide={() => setModalShowTask(false)}
                  parentid={roomDetail.id}
                  table="lead"
                  submitTasks={submitTasks}
                />
              )}
              {relatedListPricing && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowPricing(true)}
                >
                  New Pricing
                </Button>
              )}
              {modalShowPricing && (
                <PricingEdit
                  show={modalShowPricing}
                  onHide={() => setModalShowPricing(false)}
                  parentid={roomDetail.id}
                  table="lead"
                  submitPricing={submitpricing}
                />
              )}
              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  Upload File
                </Button>
              )}
              {modalShowTaskfile && (
                <FilesCreate
                  show={modalShowTaskfile}
                  onHide={() => setModalShowFile(false)}
                  parent={roomDetail}
                  table="lead"
                  submitfiles={submitfiles}
                />
              )}
            </Card.Header>
            <Card.Body>
              {relatedListTasks ? (
                <RelatedListTask
                  refreshTaskList={refreshTaskList}
                  parent={roomDetail}
                />
              ) : (
                ""
              )}
              {relatedListFiles ? (
                <RelatedListFiles
                  refreshFileList={refreshFileList}
                  parent={roomDetail}
                />
              ) : (
                ""
              )}
              {relatedListPricing ? (
                <RelatedListPricing
                  refreshPricingList={refreshPricingList}
                  parent={roomDetail}
                />
              ) : (
                ""
              )}
            </Card.Body>
          </Card>
        </Container>
      )}
    </div>
  );
};

export default RoomView;
