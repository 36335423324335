import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "react-bootstrap-typeahead/css/Typeahead.css";
import 'react-toastify/dist/ReactToastify.css';
import * as formik from 'formik';
import jwt_decode from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import { schemaTaskEdit } from "../ValidateSchemaHelper";
import { taskInitialValues } from "../InitialValuesHelper";
import { PATH , TYPE } from "../../api/pathUrl";
import { postRequest , putRequest , getRequest } from "../../api/inventoryApiTwo";
import moment from "moment";

const TaskEdit = (props) => {

    let dispatch = useDispatch();
    
    const decoded = localStorage.getItem("token")? jwt_decode(localStorage.getItem("token")) : '';
    const [validated, setValidated] = useState(false);
    const [task, setTask] = useState(props.task ? props.task.row : {parentid : props.parentid});
    const [ownerList, setownerList] = useState([]);
    const [defaultOwner, setDefaultOwner] = useState([]);
    const { usersList , resultHandle } = useSelector((state) => state.apiReducer);

    useEffect(() => {
        async function init() {
            dispatch(getRequest(PATH.userPath, TYPE.GET_ALL_USERS));
        }
        init();
    }, []);

    useEffect(() => {
        if (resultHandle.isModal) {
            props.submitTasks();
            dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })
        }
    }, [resultHandle])

    useEffect(() => {
        if (usersList.length > 0) {
            setownerList(usersList);
            if (task.id) {
                setDefaultOwner([{ 'id': task.ownerid, 'username': task.ownername }]);
            } else {
                setDefaultOwner([{ 'id': '', 'username': '' }]);
            }
        }
    }, [usersList])


    const handleSubmitSave = async (values) => {
        if(task.id){
            dispatch(putRequest(PATH.taskPath, undefined, task.id, values, true));      
        }else{
            dispatch(postRequest(PATH.taskPath, values, undefined, true));
        }
    };

    const { Formik } = formik;

    return (
        <Formik
        validationSchema={schemaTaskEdit()}
        onSubmit={handleSubmitSave}
        initialValues={taskInitialValues(task, decoded)}
      >
          {({ handleSubmit, handleChange, values,  errors , touched, setFieldValue}) => (
            //           <Form
            //     className="mt-3"
            //     onSubmit={handleSubmitSave}
            //     noValidate
            //     validated={validated}
            //   >
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {task.id ? (
                      <>
                        Edit Task
                        <h4>{task.name}</h4>
                      </>
                    ) : (
                      "New Task"
                    )} 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="view-form">
                    <Row>
                        <Col lg={12}>
                            <Form className="mt-3" noValidate validated={validated} onSubmit={handleSubmit} controlId="taskEdit">
                                <Row>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicTitle">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicTitle"
                                            >
                                               Title<b class="red-star">*</b>
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="title"
                                                placeholder="Enter title"
                                                value={values.title}
                                                onChange={handleChange}
                                            />
                                             {touched.title && <div className="form-error">
                                              {errors.title}
                                              </div>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicType">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicType"
                                            >
                                                Type <b class="red-star">*</b>
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Enter Payment Status"
                                                name="type"
                                                value={values.type}
                                                onChange={handleChange}
                                                
                                            >
                                                <option value=''>--Select--</option>
                                                <option value="Call">Call </option>
                                                <option value="Task">Task</option>
                                                <option value="Meeting">Meeting</option>
                                                <option value="Notes">Notes</option>
                                            </Form.Select>
                                            {touched.type && <div className="form-error">
                                              {errors.type}
                                              </div>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicPriority">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicPriority"
                                            >
                                                Priority <b class="red-star">*</b>
                                            </Form.Label>
                                            <Form.Select required aria-label="Enter Priority" name="priority" value={values.priority} onChange={handleChange} >
                                                <option value="">Select Priority</option>
                                                <option value="Low">Low</option>
                                                <option value="Normal">Normal </option>
                                                <option value="High">High</option>
                                            </Form.Select>
                                            {touched.priority && <div className="form-error">
                                              {errors.priority}
                                              </div>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicStatus">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicStatus"
                                            >
                                                Status <b class="red-star">*</b>
                                            </Form.Label>
                                            <Form.Select required aria-label="Enter status" name="status" value={values.status} onChange={handleChange} >
                                                <option value="">Select Status</option>
                                                <option value="	Not Started">	Not Started</option>
                                                <option value="In Progress">In Progress </option>
                                                <option value="Completed">Completed</option>
                                                <option value="Waiting on someone else">Waiting on someone else</option>
                                                <option value="Deferred">Deferred</option>
                                            </Form.Select>
                                            {touched.status && <div className="form-error">
                                              {errors.status}
                                              </div>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    
                                    <Col >
                                        <Form.Group className="mx-3" controlId="formBasicTargetdate">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicTargetdate"
                                            >
                                                Target date <b class="red-star">*</b> 
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="date"
                                                name="targetdate"
                                                placeholder="Enter targetdate"
                                                value={values.targetdate}
                                                onChange={handleChange}
                                                min={moment(new Date(values.targetdate)).format("YYYY-MM-DD")}
                                            />
                                             {touched.targetdate && <div className="form-error">
                                              {errors.targetdate}
                                              </div>}
                                        </Form.Group>
                                    </Col>
                                            <Col >
                                                <Form.Group className="mx-3" controlId="formBasicOwner">
                                                    <Form.Label className="form-view-label" htmlFor="formBasicOwner" >
                                                        Assign Staff <b class="red-star">*</b>
                                                    </Form.Label>

                                                    {defaultOwner.length > 0 ?
                                                        <Typeahead
                                                            id="basic-typeahead-single"
                                                            defaultSelected={defaultOwner}
                                                            name="ownerid"
                                                            labelKey="username"
                                                            options={ownerList}
                                                            onChange={(event) => {
                                                                if (event.length > 0) {
                                                                    setFieldValue("ownerid", event[0].id)
                                                                    // setTask({ ...task, ownerid:event[0].id});
                                                                } else {
                                                                    setFieldValue("ownerid", "")
                                                                    // setTask({ ...task, ownerid:''});
                                                                }
                                                            }}
                                                            placeholder="Choose a state..."
                                                        />
                                                        : ''}

                                                {/*     
                                                    <Form.Select aria-label="Select Assignee" name="ownerid" value={lead.ownerid} onChange={handleChange}>
                                                        <option value="">--Select--</option>
                                                        {ownerList}
                                                    </Form.Select> */}

                                                    {touched.ownerid && <div className="form-error">
                                                        {errors.ownerid}
                                                    </div>}
                                                </Form.Group>

                                                {/* <Form.Group className="mx-3" controlId="formBasicOwner">
                                                    <Form.Label
                                                        className="form-view-label"
                                                        htmlFor="formBasicTitle"
                                                    >
                                                        Assigned To
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="ownerid"
                                                        placeholder="Select Owner"
                                                        value={task.ownerid}
                                                        onChange={handleChange}

                                                    />
                                                </Form.Group> */}

                                            </Col> 
                                            <Col lg={12}>
                                                <Form.Group className="mx-3" controlId="formBasicDescription">
                                                    <Form.Label
                                                        className="form-view-label"
                                                        htmlFor="formBasicDescription"
                                                    >
                                                        Description
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        name="description"
                                                        placeholder="Enter description"
                                                        value={values.description}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleSubmit}  >Save</Button>
                <Button onClick={props.onHide} variant="light">Close</Button>
            </Modal.Footer>
        </Modal>
        // </Form>
         )}
    </Formik>
    )
}
export default TaskEdit;
