import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../../api/inventoryApi";
import ToastMessage from "../ToastMessage";
//import moment from "moment";
import jwt_decode from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import { postRequest , getRequest } from "../../api/inventoryApiTwo";
import { PATH , TYPE } from "../../api/pathUrl";

const ReportBuilderModal = (props) => {
  // //.log('props',props.reportData)
  const { reportDetail , resultHandle} = useSelector((state) => state.apiReducer);

  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [report, setReport] = useState( location.state ? location.state : {}); 
  const [isGet, setIsGet] = useState(false);
  const [reportName, setReportName] = useState(props.reportData ? props.reportData : {});
  let dispatch = useDispatch();
//.log('props.reportData',props.reportData);
  const [contact, setContact] = useState(location.state ? location.state : {});
  const schemaname =localStorage.getItem("token")? jwt_decode(localStorage.getItem("token")).hotel_name : '';
  //.log("Rwpoefhiv  ==>",schemaname);
  
  //===typeahead=== 


  const handleChange = (e) => { 
    
    setReportName({ ...reportName, [e.target.name]: e.target.value })
    //.log("TASKkkkk  ==>",report);

  }; 

  const checkRequredFields = () => {  

    //.log(reportName.name); 
    //.log(reportName.apiname);
    

    if((reportName.name && reportName.name.trim() !== '') && 
      //  (reportName.apiname && reportName.apiname.trim() !== '') &&
       (reportName.objects && reportName.objects.trim() !== '') 
       ) {
         return false;
    } 
    return true;
 }

  useEffect(() => {
if(isGet === true){
  if (resultHandle.success && !resultHandle.isModal ) { 
    //.log('resultHandle',resultHandle.data);
    submitReport();
    navigate("/reports");     
    dispatch({ type: 'SET_REPORT', payload: {} })
    dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })
  }
}
  
}, [resultHandle]) 

  const handleSubmit = async (e) => { 
    //.log("handleSubmit",reportName);
    e.preventDefault(); 

    if(checkRequredFields()) {
      setValidated(true);
      return;
    }
       dispatch({ type:  TYPE.SET_REPORT , payload: {} })
       //const result = await inventoryApi.createReport(reportName); 
       dispatch(postRequest(PATH.reportPath, reportName,TYPE.GET_ALL_REPORTS));
       //const reports = await inventoryApi.fetchReports();
       dispatch(getRequest(PATH.reportPath, TYPE.SET_REPORT));
       setIsGet(true);
    //      ToastMessage('Report Saved Succesfully!',"success");
    //      submitReport();
    //      navigate("/reports"); 
        
    //  }
  };

  const submitReport = () => {
    props.submitReport();
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"> Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="view-form">
          <Row>
            <Col lg={12}>
              <Form
                className="mt-3"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                controlId="ReportBuilderModal"
              >
                <Row>
                   <Col>
                        <Form.Group className="mx-3" controlId="formBasicName">
                        <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicName"
                        >
                            Name
                        </Form.Label>
                        <Form.Control    
                            type="text"
                            name="name" 
                            required
                            placeholder="Enter Name"
                            onChange={handleChange}
                            />
                        </Form.Group>
                   </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSubmit}>
          Save
        </Button> 
         <Button onClick={props.onHide} variant="light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ReportBuilderModal;
