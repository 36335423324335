import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table} from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import inventoryApi from "../../api/inventoryApi";
import {
DatatableWrapper,
Filter,
Pagination,
PaginationOptions,
TableBody,
TableHeader
} from 'react-bs-datatable';
import moment from 'moment';
import ToastMessage from "../ToastMessage";
import Confirm from "../Confirm";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRequest , deleteRequest } from "../../api/inventoryApiTwo";
import { PATH , TYPE } from "../../api/pathUrl";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";
import InfoPill from "../InfoPill";

const ReportList = () => {

  const { reportList , resultHandle} = useSelector((state) => state.apiReducer);
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [reports, setReports] = React.useState(reportList);
  let dispatch = useDispatch();

  const handleDelete = (row) => {
    setModalShow(true);
    setReports(row);

  };
  
  useEffect(() => {
    if (resultHandle.success && !resultHandle.isModal ) { 
      setReports("");
      setModalShow(false);
      dispatch({ type: 'SET_REPORT', payload: {} })
      dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })
    }
  }, [resultHandle]);

  const deleteReport = async () => {
    //.log('reportList',reports.id);
    dispatch(deleteRequest(PATH.reportPath, TYPE.GET_ALL_REPORTS ,reports.id));
    // const result = await inventoryApi.deleteReport(reports.id);
    // //.log('result',result);
    // if (result.success) {
    //   ToastMessage("Report Deleted successfully", "success");
    //   setReports("");
    //   setModalShow(false);
    // }

  };

useEffect(() => {
  async function init() {
    //const reports = await inventoryApi.fetchReports();
    dispatch({ type:  TYPE.SET_REPORT , payload: {} })
    if(reportList.length ===  0){
       dispatch(getRequest(PATH.reportPath, TYPE.GET_ALL_REPORTS));
    }
    if (reportList.length > 0) {
      setBody(reportList);
    }
  }
  init();
}, [modalShow , reportList]);

const header = [
    { title: 'Report Name', prop: 'name', isFilterable: true ,
    cell: (row) => (
      <Link
        to={"/reports/" + row.id}
        state={row}
        onClick={()=> dispatch({ type: "SET_REPORT", payload: {} })}>
        {row.name}
      </Link>
    )
},
    { title: 'Created Date', prop: 'createddate', cell: (row) => (moment(row.createddate).format('DD-MM-YYYY')),isFilterable: true },
    { title: 'LastModified Date', prop: 'lastmodifieddate',cell: (row) => (moment(row.lastmodifieddate).format('DD-MM-YYYY')), isFilterable: true },
    {
      title: (
        <div className="slide-left">
          Delete Report
        </div>
      ),
      prop: "id",
      cell: (row) => (
        <>
         <div>
          <Button 
            className="btn-sm mx-2"
            variant="danger"
            onClick={() => handleDelete(row)}
          >
            <i class="fa-regular fa-trash-can"></i> 

          </Button>
          </div>
        </>
      ),
    },]

const labels = {
    beforeSelect: " "
  }
  const createReport = () => {
    navigate(`/report/e`);
  };
return (
  <>
    {modalShow && (
      <Confirm
        show={modalShow}
        onHide={() => setModalShow(false)}
        deleteReport={deleteReport}
        title="Confirm delete?"
        message="You are going to delete the record. Are you sure?"
        table="reports"
      />
    )}
    <Row className="g-0">
      <Col lg={4} className="px-4">
        <CustomSeparator
          currentCmpName="Reports"
          indexLength="0"
          url="/Reports"
        ></CustomSeparator>
      </Col>
      <Col lg={12} className="p-lg-4">
        {body ? (
          <DatatableWrapper
            body={body}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 10,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-center"
            >
              <div style={{marginTop:"0.4rem"}}>

               <PaginationOptions labels={labels} />
              </div>
               <InfoPill  left="Total" right={body?.length} />

            </Col>
              <Col
                xs={6}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Button
                  className="btn-sm"
                  variant="outline-primary"
                  onClick={() => createReport(true)}
                >
                  New Report
                </Button>
              </Col>
            </Row>
            <Table striped className="data-table">
              <TableHeader />
              <TableBody />
            </Table>
            <Pagination />
          </DatatableWrapper>
        ) : (
          ""
        )}
      </Col>
      <Col lg={2}></Col>
    </Row>
  </>
);
}

export default ReportList
