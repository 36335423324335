import moment from "moment";
import ToastMessage from "../components/ToastMessage";

function formatTime(checkinoutTime) {
  let time = checkinoutTime?.split(':');
  if(time){

    let hour = parseInt(time[0]);
    let min = time[1];
    let part = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12;
    return `${hour}:${min} ${part}`;
  }else
  return ''
}

function calculateDays(checkindate, checkoutdate) {
  if (checkindate !== undefined && checkoutdate !== undefined) {
    return (new Date(checkoutdate).getTime() -
      new Date(checkindate).getTime()) / (1000 * 3600 * 24) === 0 ? 1 : (new Date(checkoutdate).getTime() - new Date(checkindate).getTime()) / (1000 * 3600 * 24);
  }
}

function calculateDaysRoomShift(checkindate, checkoutdate) {
  if (checkindate !== undefined && checkoutdate !== undefined) {
    return (new Date(checkoutdate).getTime() -
      new Date(checkindate).getTime()) /
      (1000 * 3600 * 24) ===
      0
      ? 1
      : (new Date(checkoutdate).getTime() - new Date(checkindate).getTime()) /
          (1000 * 3600 * 24);
  }
}

function calculateAmount(checkindate, checkoutdate, extrabed, perdayamount, perdayextrabedamount) {
  var days = calculateDays(checkindate, checkoutdate);
  let amt = parseFloat(perdayamount) * parseInt(days) + (parseInt(extrabed) && parseInt(extrabed) > 0 ? parseFloat(perdayextrabedamount) * parseInt(extrabed) * parseInt(days) : 0);
  return amt;
}

function calculateMoney(roomid, pricing, checkindate, checkoutdate) {
  //.log("roomid", roomid);
  //.log("roomid", checkindate);

  //.log("roomid", checkoutdate);
  if (checkoutdate !== undefined) {
    let price = pricing.filter(
      (ele) =>
        ele.parentid === roomid &&
        new Date(ele.startdate) <= new Date(checkindate) &&
        new Date(ele.enddate) >= new Date(checkoutdate)
    );
    //.log("price newe-->", price);

    if (price?.length <= 0 && roomid) {
      //.log('inode--->>>>>>>>>>>>>')
      ToastMessage(
        "Pricing is not set for this room on entered dates please enter valid dates !",
        "error"
      );
    } else {
      return price.length > 0 ? price[0] : [];
    }
  }
}

function parseDateWithMoment(date) {
  return date ? moment(date).format("YYYY-MM-DD") : "";
}

function validateUndefinedDate(date){
    return date ? parseDateWithMoment(date) : parseDateWithMoment(new Date());
}

export {
  formatTime,
  calculateDays,
  calculateDaysRoomShift,
  calculateAmount,
  calculateMoney,
  parseDateWithMoment,
  validateUndefinedDate
}