import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
//import * as formik from 'formik';
import { Field, Formik, ErrorMessage } from 'formik';

import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ConformBookingSchema } from "../ValidateSchemaHelper";
import { conformBookingInitialValues } from "../InitialValuesHelper";
import { useNavigate } from "react-router-dom";

//import { PATH } from "../api/pathUrl";
//import { postRequest , putRequest } from "../api/inventoryApiTwo";
import { getRequest , postRequest , putRequest } from "../../api/inventoryApiTwo";
import { PATH , TYPE } from "../../api/pathUrl";

const ConformBooking = (props) => {
 //.log('props',props);
    const location = useLocation();
    const navigate = useNavigate();
    let dispatch = useDispatch();
    const [booking, setBooking] = useState(location.state? location.state.room? { isgst: false }: location.state : { isgst: false });
    const [disabled, setDisabled] = useState(location.state ? location.state.status === "Not Available" ? true : false : false);
   // const { resultHandle } = useSelector((state) => state.apiReducer);
    const [checkin, setCheckin] = useState(moment(props.booking.checkindate).format("YYYY-MM-DD"));
    const [checkout, setCheckout] = useState(moment(props.booking.checkindate).format("YYYY-MM-DD"));
    const [availableRooms, setAvailableRooms] = useState(false);
    const { bookingDetail , roomList , bookingRoomList , contactsList , resultHandle } = useSelector((state) => state.apiReducer);
//.log('roomList',roomList,bookingRoomList);
    useEffect(() => {
      //.log('resultHandle',resultHandle);
        if (resultHandle.isModal) {
            // submitPricing(booking.id);
            // dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })
            navigate(`/booking/${resultHandle.data.id}`, { state: resultHandle.data });

          //  props.handleClose(resultHandle.data);
            props.onHide();
            dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })
        }
      }, [resultHandle]) 

      useEffect(() => {

        async function intit() {
    
            let url ;
            if(booking.roomid) url =  `getroomsbydate?startdate=${checkin}&enddate=${checkout}&roomid=${booking.roomid}&bookingid=${booking.id}`
            else url = `getroomsbydate/?startdate='${checkin}'&enddate='${checkout}'`
            dispatch(getRequest(`${PATH.roomPath}${url}`, TYPE.GET_ROOM_FOR_BOOKING));
        }
    
        intit();
      }, [roomList]);
   
    const handleSubmitSave = async (values) => {
        values.checkindate = moment(values.checkindate).format("YYYY-MM-DD")
        values.checkoutdate = moment(values.checkoutdate).format("YYYY-MM-DD")
        dispatch(postRequest(PATH.confirmbookingPath+values.id, values, undefined, true));
    };

    const submitPricing = (pricingId) => {
        try {
            if(pricingId)
                props.SubmitPricing();
            else
                props.SubmitPricing();
        }catch(e){
        }
        
    };

    //const { Formik } = formik;

    return (
      <Formik
      validationSchema={ConformBookingSchema()}
      onSubmit={handleSubmitSave}
      initialValues={conformBookingInitialValues(booking)}
    >
          {({ handleSubmit, handleChange, values,  errors,touched,setFieldValue }) => (

        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered

        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                     Confirm Booking
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="view-form">
                    <Row>
                        <Col lg={12}>
                            <Form className="mt-3" 
                          
                          onSubmit={handleSubmit}
                          noValidate
                            // onSubmit={handleSubmitSave} 
                             
                             >
                                <Row >
                                    <Col lg={6}>
                                        <label>Room No</label>
                                        <Field
                                        className="Field"
                                        as='select'
                                        name='roomid'
                                        value={values.roomid}
                                      onChange={(e)=>{
                                        if(e.target.value !== ''){
                                            setFieldValue("roomid",e.target.value );
                                            let room = bookingRoomList.find((ele) => ele.id === e.target.value)
                                            //.log('room',room.pricing_details[0].extra_bed);
                                            setFieldValue("totalamount",props.booking.noofdays * room.pricing_details[0].price );
                                           // //.log('props',props);
                                            setFieldValue("goingto",props.booking.goingto);
                                            setFieldValue("noofguest",props.booking.noofguest);
                                            setFieldValue("timeformat",props.booking.timeformat);
                                            setFieldValue("check_out_time",props.booking.check_out_time);
                                            setFieldValue("check_in_time",props.booking.check_in_time);
                                            setFieldValue("checkindate",props.booking.checkindate);
                                            setFieldValue("checkoutdate",props.booking.checkoutdate);
                                            setFieldValue("contactid",props.booking.contactid);
                                            setFieldValue("source",props.booking.source);
                                            setFieldValue("advanceamount",props.booking.advanceamount);
                                            setFieldValue("method",props.booking.method);
                                            setFieldValue("description",props.booking.description);
                                            setFieldValue("source",props.booking.source);
                                            setFieldValue("status",props.booking.status);
                                            setFieldValue("lastmodifieddate",props.booking.lastmodifieddate);
                                            setFieldValue("lastmodifiedbyid",props.booking.lastmodifiedbyid);
                                            setFieldValue("createdbyid",props.booking.createdbyid);
                                            setFieldValue("createdbyid",props.booking.createdbyid);
                                            setFieldValue("createdbyid",props.booking.createdbyid);
                                            setFieldValue("per_day_amount",room.pricing_details[0].price);
                                            setFieldValue("per_day_extrabed_amount",room.pricing_details[0].extra_bed);

                                        }else{
                                            setFieldValue("totalamount",'' );
                                            setFieldValue("roomid",'' );

                                        }
                                    }}
                                        >
                                            <option value=''>Select Room</option>
                                            {bookingRoomList.length  && bookingRoomList.map((room)=>(
                                                <option value={room.id}>{`${room.block} Block | Room No - ${room.roomno}`}</option>
                                            ))}
                                        </Field>
                                        <ErrorMessage
                                        name="roomid"
                                        component="div"
                                        className="error-message"
                                    />
                                    </Col>
                                    <Col lg={6}>
                                        <label>No Of Days</label>
                                        <Field
                                        className="Field"
                                        type='number'
                                        name='noofdays'
                                       disabled
                                        value={props.booking.noofdays}
                                        onChange={handleChange}
                                        />
                                    </Col>
                                    
                                    <Col lg={6}>
                                        <label>Extra Bed</label>
                                        <Field
                                        disabled={!values.roomid}
                                        min={1}
                                        className="Field"
                                        placeholder='Enter a number of extra beds'
                                        type='number'
                                        name='extra_bed'
                                        value={values.extra_bed}
                                        onChange={(e) => {
                                             handleChange(e)
                                            setFieldValue("extra_bed", e.target.value);
                                            if(values.roomid && values.roomid.trim !==''){
                                                let room = bookingRoomList.find((ele) => ele.id === values.roomid)
                                                //.log('room',room);
                                                setFieldValue("totalamount",e.target.value === 0?props.booking.noofdays * room.pricing_details[0].price+ (1)*room.pricing_details[0].extra_bed :props.booking.noofdays * room.pricing_details[0].price+ (e.target.value)*room.pricing_details[0].extra_bed);
                                          }}}
                                        />
                                      <ErrorMessage
                                        name="extra_bed"
                                        component="div"
                                        className="error-message"
                                    />
                                    </Col>
                                    <Col lg={6}>
                                        <label>Total Amount</label>
                                        <Field
                                        disabled
                                        className="Field"
                                        type='number'
                                        name='totalamount'
                                        value={values.totalamount}
                                        onChange={handleChange}
                                        />
                                    </Col>
                                    <Col lg={6}>
                        <Form.Check
                          inline-flex
                          label="Is GST apply"
                          name="isgst"
                          type="switch"
                          checked={values.isgst}
                          onChange={handleChange}
                         // style={{ paddingLeft: "3rem" }}
                        />
                      </Col>
                      <Col lg={6}>
                        <label>
                            Discount
                          </label>
                            <Field
                              type="number"
                              className="Field"
                              name="discount_percentage"
                              placeholder="Enter Discount"
                              value={values.discount_percentage}
                              onChange={handleChange}
                              //aria-describedby="basic-addon"
                            />
                        

                            {touched.discount_percentage && (
                              <div className="form-error">
                                {errors.discount_percentage}
                              </div>
                            )}
                        
                      </Col>
                                </Row>
                            </Form>  
                        </Col>        
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
            { disabled ? "" : <Button variant="success" onClick={handleSubmit}  >Save</Button>}
                <Button onClick={(e)=>props.onHide()} variant="light">Close</Button>
            </Modal.Footer>
        </Modal>
          )}
          </Formik>
    )
}

export default ConformBooking;
