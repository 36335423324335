import "./App.css";
import "./Sidebar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";

import {
  Header,
  Login,
  Home,
  Sidebar,
  MyDocument,
  LeadView,
  LeadEdit,
  LeadList,
  PropertyList,
  RoomList,
  RoomEdit,
  RoomView,
  BookingList,
  BookingEdit,
  BookingView,
  ProductList,
  ProductCreate,
  ProductView,
  ProductEdit,
  OrderList,
  OrderCreate,
  OrderEdit,
  OrderView,
  ContactList,
  ContactView,
  ContactEdit,
  MiniSidebar,
  PurchaseOrderList,
  PropertyEdit,
  PropertyView,
  ReportList,
  ReportView,
  BillList,
  BillView,
  UserEdit,
  UserView,
  UserList,
  Calendar,
  ChangePassword,
  ViewProfile,
  BookingEditFromHome,
  AdvanceBookingEdit,
  AdvanceBookingList,
  AdvanceBookingView,
  ReportBuilder,
  EditProfile,
  Signup,
  PaymentsAndExpenses,

} from "./components"


import BarChart from "./components/charts/BarChart";
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as constants from "./constants/CONSTANT";

import {SocketContext, socket} from './socket'
import ToDoList from "./components/ToDoComponents/ToDoList";
import ToDoCreate from "./components/ToDoComponents/ToDoCreate";
import ToDoView from "./components/ToDoComponents/ToDoView";
import TodoEdit from "./components/ToDoComponents/TodoEdit";


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(fetchAccounts());
  }, []);
  
  return (

    <SocketContext.Provider value={socket}>

    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/DD"
            element={
              <>
                <Sidebar />
                <Header />
                <Container className="my-3">
                  <Home />
                </Container>
                <ToastContainer transition={Slide} pauseOnHover />
              </>
            }
          />
          <Route
            path="/"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <Home />
                  </div>
                <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />

          <Route
            path="orders/:id/pdf"
            element={
              <>
                <div className="wrapper">
                  <div id="content"></div>
                </div>
              </>
            }
          />
          <Route
            path="/purchaseorder"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <PurchaseOrderList />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/contacts"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_CONTACT) >= 0
                                                          || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ? */}
                    <ContactList />
                    {/* : 'You have No permission for contact'} */}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="contacts/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ContactView />
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />
          <Route
            path="/orders"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    
                      <OrderList />
                  
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/products"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <MiniSidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem("permissions") &&
                    (localStorage
                      .getItem("permissions")
                      .indexOf(constants.VIEW_PRODUCT) >= 0 ||
                      localStorage
                        .getItem("permissions")
                        .indexOf(constants.MODIFY_ALL) >= 0) ? (
                      <ProductList />
                    ) : (
                      "You have no permission to access product"
                    )}
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/leads"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_LEAD) >= 0
                                                          || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ? */}
                    <LeadList /> : 'You have no permission to access lead'
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/properties"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_PROPERTY) >= 0
                                                          || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ? */}
                    <PropertyList />
                    {/* : 'You have no permission to access property'} */}
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/rooms"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_PROPERTY) >= 0
                                                          || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ? */}
                    <RoomList />
                    {/* : 'You have no permission to access property'} */}
                    {/* <BillView/> */}
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/reports"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ReportList />
                    {/* <CreatePage/> */}
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />
          <Route
            path="/dashboard"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <BarChart/>
                    {/* <CreatePage/> */}
                  </div>
                </div>
              </>
            }
          />
              <Route
            path="/changePassword"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ChangePassword/>
                    {/* <Changepassword/> */}
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />
          <Route
            path="/reports/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ReportView />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/bookings"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_PROPERTY) >= 0
                                                          || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ? */}
                    <BookingList />
                    {/* : 'You have no permission to access property'} */}
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="properties/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <PropertyView />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="room/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <RoomView />
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover/>
                </div>
              </>
            }
          />
          <Route
            path="booking/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <BookingView />
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover/>
                </div>
              </>
            }
          />
          <Route
            path="AdvanceBooking/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <AdvanceBookingView />
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover/>
                </div>
              </>
            }
          />
          <Route
            path="/room/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <RoomEdit />
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover/>
                </div>
              </>
            }
          />
          <Route
            path="/AdvanceBooking/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <AdvanceBookingEdit />
                  </div>
                <ToastContainer transition={Slide} pauseOnHover/>
                </div>
              </>
            }
          />
          <Route
            path="/booking/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <BookingEdit />
                  </div>
                <ToastContainer transition={Slide} pauseOnHover/>
                </div>
              </>
            }
          />
          <Route
            path="/properties/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <PropertyEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/properties/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <PropertyEdit />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/room/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <RoomEdit />
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover/>
                </div>
              </>
            }
          />
          <Route
            path="/bookings/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {/* <BookingEdit /> */}
                    <BookingEditFromHome/>
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover/>
                </div>
              </>
            }
          />
          <Route
            path="/booking/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <BookingEdit />
                    {/* <BookingEditFromHome/> */}
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover/>
                </div>
              </>
            }
          />
          <Route
            path="/document/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <MyDocument />
                  </div>
                <ToastContainer transition={Slide} pauseOnHover/>
                </div>
              </>
            }
          />
          <Route
            path="contacts/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ContactEdit />
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover/>
                </div>
              </>
            }
          />

          <Route
            path="/leads/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <LeadEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/products/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ProductCreate />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/contacts/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ContactEdit />
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />

          <Route
            path="/orders/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <OrderCreate />
                  </div>
                </div>
              </>
            }
          />
  <Route
            path="/profiles"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_CONTACT) >= 0
                                                          || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ? */}
                    <ViewProfile/>
                    {/* : 'You have No permission for contact'} */}
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />
  <Route
            path="/users"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_CONTACT) >= 0
                                                          || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ? */}
                    <UserList />
                    {/* : 'You have No permission for contact'} */}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="users/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <UserView/>
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />
            <Route
            path="/users/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <UserEdit />
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />
            <Route
            path="/users/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <UserEdit />
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />
          <Route
            path="leads/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <LeadView />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="products/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ProductView />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="orders/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <OrderView />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="leads/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <LeadEdit />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="profiles/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <EditProfile />
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />

          <Route
            path="products/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ProductEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="orders/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <OrderEdit />
                  </div>
                </div>
              </>
            }
          />
          
          <Route
            path="/bills"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_CONTACT) >= 0
                                                          || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ? */}
                    <BillList />
                    {/* : 'You have No permission for contact'} */}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="bills/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <BillView />
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />


          <Route
            path="meetings"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <Calendar/>
{/* 
                    <BillView /> */}
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />
         <Route
            path="report/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                  <ReportBuilder/>

                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />
        {/* <Route
            path="shifting"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <RoomShifting/>
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          /> */}
           {/* <Route
            path="/agentcommission"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <AgentCommissionList/>
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          /> */}
           <Route
            path="signup"
            element={
              <>
                <div className="wrapper">
                  <div id="content">

                    <Signup/>
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />

          {/* <Route
            path="Category/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar/>
                  <div id="content">
                  <Header/>
                   <CategoryEdit/>
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          /> */}
          {/* <Route
            path="Category/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar/>
                  <div id="content">
                  <Header/>
                   <CategoryView/>
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          /> */}
     
          <Route
            path="advanceBooking"
            element={
              <>
                <div className="wrapper">
                  <Sidebar/>
                  <div id="content">
                  <Header/>
                   <AdvanceBookingList/>
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />
       {/* <Route
            path="Category/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <CategoryEdit />
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover/>
                </div>
              </>
            }
          /> */}
       
       {/* <Route
            path="items"
            element={
              <>
                <div className="wrapper">
                  <Sidebar/>
                  <div id="content">
                  <Header/>
                  
                   <ItemList />
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          /> */}
         
          {/* <Route
            path="/item/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ItemView/>
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          /> */}
          {/* <Route
            path="/item/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ItemEdit/>
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          /> */}

          {/* <Route
            path="/item/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ItemEdit />
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          /> */}
         <Route
            path="/advanceBooking/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <AdvanceBookingEdit/>
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />
          <Route
          path="/profile/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ViewProfile></ViewProfile>
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />

{/*----------------Todo------------------------------ */}
<Route
            path="/Todo"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ToDoList></ToDoList>
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/TodoNew"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ToDoCreate></ToDoCreate>
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/TodoView/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ToDoView></ToDoView>
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />

          <Route
            path="/Todoedit/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <TodoEdit></TodoEdit>
                  </div>
                </div>
              </>
            }
          />

         <Route
            path="paymentsandexpenses"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <PaymentsAndExpenses />
                  </div>
                  <ToastContainer transition={Slide} pauseOnHover />
                </div>
              </>
            }
          />

        </Routes>
       
    </Router>
    </>

    </SocketContext.Provider>

  );
}

export default App;
