import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import inventoryApi from "../../api/inventoryApi";
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch , useSelector } from "react-redux";
import { PATH , TYPE } from "../../api/pathUrl";
import { getRequest } from "../../api/inventoryApiTwo";

const BillList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [body, setBody] = useState([]);

    const { billsData } = useSelector((state) => state.apiReducer);

    useEffect(() => {

        if (billsData.length > 0) {
            setBody(billsData);
        }

    }, [billsData])

    useEffect(() => {

        dispatch(getRequest(PATH.billPath, TYPE.GET_ALL_BILLS));
        // async function init() {
        //     const bills = await inventoryApi.fetchBills();
        //     if (bills)
        //         setBody(bills);
        // }
        // init();

    }, []);

    const header = [
        {
            title: 'Bill Number', prop: 'billnumber', isFilterable: true,
            cell: (row) => (
                <Link
                    to={"/bills/" + row.id}
                    state={row}
                >
                    {row.billnumber}
                </Link>
            )
        },

        // { title: 'Bill No', prop: 'total' , isFilterable: true }, 
        // { title: 'Bill Number', prop: 'billnumber' , isFilterable: true }, 
        {
            title: "Created date",
            prop: "createddate",
            isFilterable: true,
            cell: (row) => moment(row.checkoutdate).format("DD/MM/YYYY | h:mm A"),
        },
        {
            title: "Check Out date",
            prop: "checkoutdate",
            isFilterable: true,
            cell: (row) => moment(row.checkoutdate).format("DD/MM/YYYY | h:mm A"),
        },
        { title: 'Total', prop: 'total', isFilterable: true },
        { title: 'Room Id', prop: 'roomid' }
    ];

    const labels = {
        beforeSelect: " "
    }

    // const createContact = () =>{
    //   navigate(`/contacts/e`);
    // }

    return (
        <Row className="g-0">
            <Col lg={2} className="mx-2">
                <Link className="nav-link">Home <i class="fa-solid fa-chevron-right"></i> Bills</Link>
            </Col>
            <Col lg={12} className="p-lg-4">

                {body ?
                    <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 10,
                            options: [5, 10, 15, 20]
                        }
                    }}>
                        <Row className="mb-4">
                            <Col
                                xs={12}
                                lg={4}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                                <Filter />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-start align-items-start"
                            >
                                <PaginationOptions labels={labels} />

                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                                {/* <Button className="btn-sm" variant="outline-primary" onClick={() => createContact(true)}>New Contact</Button> */}
                            </Col>
                        </Row>
                        <Table striped className="data-table">
                            <TableHeader />
                            <TableBody />
                        </Table>
                        <Pagination />

                    </DatatableWrapper> : ''}
            </Col>
        </Row>
    );
};

export default BillList;