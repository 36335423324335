import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import Confirm from "../Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../../api/inventoryApi";


const OrderView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const order = location.state;
  const [modalShow, setModalShow] = useState(false);
  ///////
  const deleteOrder = async () => {
      const result = await inventoryApi.deletOrder(order.id);
      if (result.success) navigate(`/orders`);
    };
  
    const editOrder = () => {
      navigate(`/orders/${order.id}/e`, { state: order });
    };

  return (
    <div>
      {order && <Container>
        {modalShow &&
          <Confirm
            show={modalShow}
            onHide={() => setModalShow(false)}
            deleteOrder={deleteOrder}
            title="Confirm delete?"
            message="You are going to delete the record. Are you sure?"
            table="order"
          />}
        <Row className="view-form">
          <Col></Col>
          <Col lg={8}>
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                Order 
                <h4>Aslam Bari</h4>
              </Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={() => editOrder(true)}>
                  <i class="fa-regular fa-pen-to-square"></i>
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={() => setModalShow(true)}
                >
                  Delete
                </Button>
              </Col>
            </Row>
            <Row>
              
              <Col lg={6}>
                <label>Contact Name</label>
                <span>{order.id}</span>
              </Col>
              <Col lg={6}>
                <label>Product Id</label>
                <span>{order.id}</span>
              </Col>
              <Col lg={6}>
                <label>Order Date</label>
                <span>{order.orderdate}</span>
              </Col>
              <Col lg={6}>
                <label>Description</label>
                <span>{order.description}</span>
              </Col>
              <Col lg={6}>
                <label>Created Date</label>
                <span>{order.createddate}</span>
              </Col>
              
            </Row>
          </Col>
          <Col></Col>
        </Row>
      </Container>}
    </div>
  )
}

export default OrderView
