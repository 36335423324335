
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../../api/inventoryApi";
import moment from "moment";
import { Button, Col, Form, Row, Table, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
//import PubSub from 'pubsub-js';
// import Select from "react-select";
// Accordion from 'react-bootstrap/Accordion';
//import Card from 'react-bootstrap/Card';
//import CityState from  "../constants/CityState.json";
//import * as constants from "../constants/CONSTANT";  
import Confirm from "../Confirm";    
import ReportBuilderModal from "./ReportBuilderModal"
import ToastMessage from "../ToastMessage";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { useSelector, useDispatch } from "react-redux";
import { postRequest  } from "../../api/inventoryApiTwo";
import { PATH , TYPE } from "../../api/pathUrl";

const ReportBuilder = () => {
  const { resultHandle} = useSelector((state) => state.apiReducer);

  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectUser, setSelectUser] = useState({});
  const [reportName, setReportName] = useState('room');
  const [body, setBody] = useState([]);
  const [bodytemp, setBodyTemp] = useState([]);
  const [arrayLabels, setArrayLabels] = useState([]);
  const [isPost, setIsPost] = useState(false);
  const [modalShowReport, setmodalShowReport] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  let dispatch = useDispatch();

  const submitReport = () => {
    setmodalShowReport(false);
  };
  useEffect(() => {
    if(isPost === true && resultHandle?.data){
      //.log("reports : ", resultHandle.data.query);
      setReportName({ ...reportName, query: resultHandle.data.query });
      //.log("reportName : ", reportName);
      if (resultHandle?.data?.data && Object.keys(resultHandle?.data?.length > 0)) {
        //.log('resultHandle.data[0]',Object.keys(resultHandle.data.data[0]));
        setArrayLabels(Object.keys(resultHandle.data.data[0]));
        setBodyTemp(resultHandle.data.data);
        setBody(resultHandle.data.data);
      } else {
        setArrayLabels([]);
        setBodyTemp([]);
        setBody([]);
      }
    }
  },[resultHandle]);
  
  const handleChange = (e) => {
    setReportName({ ...reportName, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    //.log('in',e)

    //.log('in handleSubmit');
    e.preventDefault();

    if (checkRequredFields()) {
      setValidated(true);
      return;
    }


    try {
      //reports = await inventoryApi.runReport(reportName);
      dispatch({ type:  TYPE.SET_REPORT , payload: {} });
      dispatch(postRequest(`${PATH.reportPath}reportname/`, reportName, undefined));
      setIsPost(true);
      // //.log("reports : ", reports);
      // setReportName({ ...reportName, query: reports.query });
      // //.log("reportName : ", reportName);
    } catch (e) {
      //.log("e", e);
    }


    // if (reports?.data?.length > 0) {
    //   setArrayLabels(Object.keys(reports.data[0]));
    //   setBodyTemp(reports.data);
    //   setBody(reports.data);
    // } else {
    //   setArrayLabels([]);
    //   setBodyTemp([]);
    //   setBody([]);
    // }
    // if (reports.length === 0) {
    //   ToastMessage('No records found!',"error");
    // }
  };

  // const header = arrayLabels?.map((data) => ({
  //   title: data,
  //   prop: data,
  //   isFilterable: true,
  //   cell:
  //     data === "checkindate" || data === "checkoutdate"
  //       ? (row) => moment(row[data]).format("DD/MM/YYYY")
  //       : (row) => row[data],
  // }));
  const header = arrayLabels?.map((data) => ({
    title: data.charAt(0).toUpperCase() + data.slice(1),
    prop: data,
    isFilterable: true,
    cell:
      data === "checkindate" || data === "checkoutdate"
        ? (row) => moment(row[data]).format("DD/MM/YYYY")
        : (row) => row[data],
  }));

  const checkRequredFields = () => {
    if (reportName.objects && reportName.objects.trim() !== "") {
      //.log('in if')
      return false;
    }
    //.log('out if')
    return true;
  };

  return (
    <div>
      <Container className="view-form">
        {modalShow && (
          <Confirm
            show={modalShow}
            onHide={() => setModalShow(false)}
            title="Confirm delete?"
            message="You are going to delete the record. Are you sure?"
            table="report"
          />
        )}
        <Form className="mt-3" noValidate validated={validated} style={{backgroundColor : "#fff"}}>
              <Row style={{width : "100%",marginLeft:"0px"}} className="view-form-header align-items-center">
                <Col lg={8}>
                  <h4>
                    Create Report
                  </h4>
                </Col>
                <Col lg={4} className="d-flex justify-content-end"></Col>
              </Row>
            
             
              <Row>
                <Col lg={2}>
                  <Form.Group className="mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFees"
                      style={{ fontSize: "12px" }}
                    >
                      Table
                    </Form.Label>
                    <Form.Select
                      aria-label="select object"
                      name="objects"
                      required
                      onChange={handleChange}
                    >
                      <option value="">--Select-Object--</option>
                      <option value="room">Room</option>
                      <option value="booking">Booking</option>
                      <option value="contact">Contact</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Enter Report.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
               
                  <Col lg={2}>
                    <Form.Group className=" mt-3" controlId="formBasicStatus">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFees"
                      >
                        Status
                      </Form.Label>
                      <Form.Select
                        aria-label="Enter status"
                        name="status"
                        disabled= {reportName.objects !== "room" }
                        onChange={handleChange}
                       
                      >
                        <option value="">--Select Type--</option>
                        <option value="Available">Available</option>
                        <option value="Not Available">Not Available</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
               

                <Col lg={2}>
                  <Form.Group className="mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicStartDate"
                      style={{ fontSize: "12px" }}
                    >
                      Created Date From
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="startDate"
                      placeholder="Enter Start Date"
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Created Date.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={2}>
                  <Form.Group className="mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicDate"
                      style={{ fontSize: "12px" }}
                    >
                      Created Date To
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="lastDate"
                      placeholder="Enter Last Date"
                      min={reportName.startDate ? moment(new Date(reportName.startDate)).format("YYYY-MM-DD") : undefined}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Last Date.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>                
                <Col lg={3} className="text-end">
                  <Form.Group
                    className="mx-3 py-2 "
                    style={{ display: "inline-block" }}
                  >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicReport"
                    ></Form.Label>
                    <Button className="btn-sm my-3" onClick={handleSubmit}>
                      Run Report
                    </Button>
                  </Form.Group>
                  <Button
                    className="btn-sm my-3"
                    onClick={() => setmodalShowReport(true)}

                    disabled={body.length === 0}
                  >
                    Save
                  </Button>
                  {modalShowReport && (
                    <ReportBuilderModal
                      show={modalShowReport}
                      onHide={() => setmodalShowReport(false)}
                      reportData={reportName}
                      table="report"
                      submitReport={submitReport}
                    />
                  )}
                </Col>
                <Col lg={1}>
                </Col>
              </Row>
           
      
        <Row className="g-0">
          <Col lg={2} className="mx-2"></Col>

          <Col lg={12} className="p-lg-4">
          {body.length > 0 ? (
              <DatatableWrapper
                body={body}
                headers={header}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 100},
              }}
            >
              <Row className="mb-4">
              </Row>
                <Table striped className="data-table">
                <thead>
                <tr>
                {header.map((item, index) => (
                <th key={index} className="table-header-cell">
                {item.title}
                </th>
                 ))}
                </tr>
                </thead>
               <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
           <center><p style={{ fontSize: "20px" }}>{bodytemp.length > 0 ? "Loading..." : "No data available"}</p></center> 
          )}
          
        </Col>
        
        <Col lg={2}></Col>
      </Row>
      </Form>
    </Container>
  </div>
);
};

export default ReportBuilder;


   
