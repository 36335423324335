import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../../api/inventoryApi";
import ToastMessage from "../ToastMessage";
import jwt_decode from "jwt-decode";
import { schemaUserEdit } from "../ValidateSchemaHelper";
import { userEditValues } from "../InitialValuesHelper";
import { useDispatch , useSelector } from "react-redux";
import { getUser , updateUser , createUser} from "../APIs";
import * as formik from 'formik';
import * as yup from 'yup';
import { putRequest , postRequest} from "../../api/inventoryApiTwo";
import { PATH , TYPE } from "../../api/pathUrl";

const UserEdit = (props) => {
  const { resultHandle , userDetail } = useSelector((state) => state.apiReducer);

  const [validated] = useState(false);

  const decoded = localStorage.getItem("token")? jwt_decode(localStorage.getItem("token")) : '';

  const [userUpdated, setUserUpdated] = useState(false);

  const [password, setPassword] = useState("");

  
  const location = useLocation();
  const navigate = useNavigate();
  let dispatch = useDispatch();

  const [user, setUser] = useState(location.state ? location.state : {companyid : decoded.companyid,isactive:true});
  //.log('user------------------>',location.state );

  useEffect(() => {
    if (resultHandle.success) {
      //.log('resultHandle',resultHandle)
      navigate(`/users/${resultHandle.data.id}`, { state: resultHandle.data });
      dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })
    }
  }, [resultHandle]);

  const handleSubmitSave = async (values) => {
    //.log('values',values)

    dispatch({ type:  TYPE.SET_USER , payload: {} })
    if (userDetail.id) {
      //.log('userDetail.id',userDetail.id)
      dispatch(putRequest(PATH.userPath+"updateProfile/" ,undefined ,location.pathname.split('/')[2], values, false));
    } else {
      //.log('userDetail.id',userDetail)
      dispatch(postRequest(PATH.userPath+"createuser", values));
    }

  //   //.log('values',values);
  //   //========= Logic to perform Create or Edit ======
  //   let result2 = {};

  //   if (user.id) {
  //     //.log("values in update", values);
  //     const result = await dispatch(updateUser(values));
  //     //.log("edit result",values);
  //     if (result.success) {
  //       dispatch(getUser());
  //       navigate(`/users/${values.id}`, { state: values });
  //       ToastMessage("User has been updated successfully !", "success");
  //   } 
  // }else {
  //   //.log("--false---",user);
  //   //.log("values in create", values);
   
  //   result2 = await dispatch(createUser(values));
  //   //.log('result2',result2);
  //   if (result2.success) {
  //     //.log('result',result2)
  //     dispatch(getUser());
  //     navigate(`/users/${result2.data.id}`, { state: result2.data });
  //   }
  //   }
  };

  const handleCancel = () => {
    // navigate("/contacts/" + contact.id, { state: contact });
    navigate("/users");
  };

  const { Formik } = formik;

  return (
    <Container className="view-form">
      <Row>
        <Col></Col>
        <Col lg={8} style={{ backgroundColor : "#fff" }}>
        <Formik
    validationSchema={schemaUserEdit(user)}
    onSubmit={handleSubmitSave}
    initialValues={userEditValues(user,decoded)}
  >
      {({ handleSubmit, handleChange, values,  errors , touched }) => (
          <Form
            className="mt-3 pb-3"
            onSubmit={handleSubmitSave}
            noValidate
            validated={validated}
          >
            <Row className="view-form-header align-items-center">
              <Col lg={3}>{userDetail.id ? 'Edit User': 'User Create'}</Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                  Save
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicFirstName">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    First Name <b class="red-star">*</b>
                  </Form.Label>
                  <Form.Control
                    style={{textTransform:"capitalize"}}
                    required
                    type="text"
                    name="firstname"
                    placeholder="Enter firstname"
                    onChange={handleChange}
                    value={values.firstname}
                  />
                {touched.firstname && <div className="form-error">
                       {errors.firstname}
                     </div>}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicLastName">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicLastName"
                  >
                    Last Name <b class="red-star">*</b>
                  </Form.Label>
                  <Form.Control
                    style={{textTransform:"capitalize"}}
                    required
                    type="text"
                    name="lastname"
                    placeholder="Enter lastname"
                    value={values.lastname}
                    onChange={handleChange}
                    
                  />
               {touched.lastname && <div className="form-error">
                       {errors.lastname}
                     </div>}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicPhone">

                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicPhone"
                    >
                    Roles  <b class="red-star">*</b>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleChange}
                    required
                    name="rolename"
                    value={values.rolename}
                    disabled={user?.id? true : false} 
                    >
                    <option value="">Select Role</option>
                    <option value="ADMIN">ADMIN</option>
                    <option value="USER">USER</option>

                   </Form.Select>
                   
              
                   {touched.rolename && <div className="form-error">
                       {errors.rolename}
                     </div>}
                </Form.Group>
              </Col>

              

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicEmail">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicEmail"
                  >
                    Email  <b class="red-star">*</b>
                  </Form.Label>
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    onChange={handleChange}
                    value={values.email}
                    disabled={user?.id? true : false} 
                  />
                 {touched.email && <div className="form-error">
                       {errors.email}
                     </div>}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group
                  className="mx-3"
                  controlId="formBasicconfirmpassword"
                >
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicconfirmpassword"
                  >
                    Phone <b class="red-star">*</b>
                  </Form.Label>
                  <Form.Control
                    required
                    type="phone"
                    name="phone"
                    onChange={handleChange}
                    placeholder="Phone"
                     value={values.phone}
                     />
                   {touched.phone && <div className="form-error">
                       {errors.phone}
                     </div>}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group
                  className="mx-3"
                  controlId="formBasicconfirmpassword"
                >
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicconfirmpassword"
                  >
                    Active <b class="red-star">*</b>
                  </Form.Label>
                  <Form.Check
                  name="isactive"
                  checked={values.isactive}
                  type="switch"
                  onChange={handleChange}
                  style={{ fontSize: "15px", marginLeft: "5px" }}
                />
                </Form.Group>
              </Col>

              {!user?.id  && <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicpassword">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicpassword"
                  >
                    Password <b class="red-star">*</b>
                  </Form.Label>
                  <Form.Control
                    required
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    placeholder="Enter password"
                    
                  />
                {touched.password && <div className="form-error">
                       {errors.password}
                     </div>}
                </Form.Group>
              </Col>}
              {!user?.id && <Col lg={6}>
                <Form.Group
                  className="mx-3"
                  controlId="formBasicconfirmpassword"
                >
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicconfirmpassword"
                  >
                    Confirm password <b class="red-star">*</b>
                  </Form.Label>
                  <Form.Control
                    required
                    type="password"
                    name="confirmpassword"
                    placeholder="confirm password"
                    //  value={values.lastname}
                    onChange={handleChange}
                    
                  />
                 {touched.confirmpassword && <div className="form-error">
                       {errors.confirmpassword}
                     </div>}
                </Form.Group>
              </Col>}

              {/* <Col lg={6}>
                <Form.Check
                  // inline-flex
                  label="Is Active"
                  name="isactive"
                  checked={values.isactive}
                  type="switch"
                  onChange={handleChange}
                  // style={{ fontSize: "16px", margin: "38px" }}
                />
              </Col> */}
              
            </Row>
          </Form>
          )}
          </Formik>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default UserEdit;

