const initialState = {
    companySetting: []
} 

const Reducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_COMPANY_SETTING":
        return {
            ...state,
            companySetting : action.payload,
        }; 
      default:
        return state;
    }
  };
  export default Reducer;