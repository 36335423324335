import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import InfoPill from "../InfoPill";
import moment from "moment";
import InputGroup from 'react-bootstrap/InputGroup';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRequest } from "../../api/inventoryApiTwo";
import { PATH , TYPE } from "../../api/pathUrl";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";

const BookingList = () => {

  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [body, setBody] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [filteredText, setFilteredText] = useState();
  const [filteredSlected, setfilteredSelected] = useState();
  const { bookingList } = useSelector((state) => state.apiReducer);
  const [selectdate, setDate] = useState();
  const [selectvalue, setSelectvalue] = useState();

  useEffect(() => {

    // async function init() {
      dispatch({ type: TYPE.SET_BOOKING , payload: {} })
      dispatch({ type: TYPE.GET_ALL_PAYMENTS , payload: [] })
      dispatch({ type: TYPE.GET_ALL_EXPENSES , payload: [] })
      dispatch({ type:  TYPE.SET_ORDER, payload: {} })
      dispatch({ type: TYPE.GET_ALL_TASKS , payload: [] })
      // if(bookingList.length ===  0){
      dispatch(getRequest(PATH.bookingPath, TYPE.GET_ALL_BOOKINGS));
    // }
    // if (bookingList.length > 0) {
      // setBody(bookingList);
      // setBookings(bookingList);
    // }
  // }
  // init();
  }, []);

  useEffect(() => {
    // async function init() {
    //   if(bookingList.length ===  0){
    //   dispatch(getRequest(PATH.bookingPath, TYPE.GET_ALL_BOOKINGS));
    // }
    if (bookingList.length > 0) {
      setBody(bookingList);
      setBookings(bookingList);
    }
    // }
    // init();
  }, [bookingList]);

  // Create table headers consisting of 4 columns.
  const header = [
    {
      title: "Booking Number",
      prop: "auto_number",
      isFilterable: true,
      cell: (row) => (
        <Link to={"/booking/" + row.id} state={row}  onClick={()=> dispatch({ type: TYPE.SET_BOOKING, payload: row })}>
          {row.auto_number}
        </Link>
      ),
    },
    {
      title: "Room",
      prop: "roomname",
      isFilterable: true,
      //isSortable: true,
    },
    { title: "Contact", prop: "contact_name", isFilterable: true },
    {
      title: "Check In date",
      prop: "checkindate",
      isFilterable: true,
      //isSortable: true,
      cell: (row) => moment(row.checkindate).format("DD/MM/YYYY"),
    },

    {
      title: "Check Out date",
      prop: "checkoutdate",
      isFilterable: true,
      //isSortable: true,
      cell: (row) => moment(row.checkoutdate).format("DD/MM/YYYY"),
    },
    { title: "No Of days", prop: "noofdays", isFilterable: true },
    { title: "No of guest", prop: "noofguest", isFilterable: true },
    { title: "Coming from", prop: "comingfrom", isFilterable: true },
    { title: "Going to", prop: "goingto", isFilterable: true },
    // { title: "Total Amount", prop: "₹ totalamount", isFilterable: true },
    {
      title: "Total Amount",
      prop: "totalamount",
      isFilterable: true,
      cell: (row) => (
        <Link to={"/booking/" + row.id} state={row}>
          ₹{row.totalamount}
        </Link>
      ),
    },
    { title: "Status", prop: "status", isFilterable: true },
  ];

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const handleFilter = (filterSelected, filterText) => {
    //.log("filterSelected, filterText --> ",filterSelected, filterText)
    var filteredData = bookings;
      filteredData = bookings.filter((row) =>
        (filterText ?  (
        
          row?.contact_name?.toLowerCase().includes(filterText.toLowerCase()) 
          ||
          row?.auto_number.toLowerCase().includes(filterText.toLowerCase()) ||
          row?.roomname.toLowerCase().includes(filterText.toLowerCase()) ||
          String(row.noofdays).includes(filterText) || 
          String(row.noofguest).includes(filterText) || 
          row?.comingfrom.toLowerCase().includes(filterText.toLowerCase()) || 
          row?.goingto.toLowerCase().includes(filterText.toLowerCase()) || 
          row.totalamount.toLowerCase().includes(filterText.toLowerCase()) || 
          row?.checkindate.toLowerCase().includes(filterText.toLowerCase()) || 
          row?.checkoutdate.toLowerCase().includes(filterText.toLowerCase() )
          )
          : 
          row )).filter((row)=>(  (filterSelected ? (row.status || "").toLowerCase() ===
        ((filterSelected || "")?.toLowerCase()) : row) )
        );
        setBody(filteredData);
  };
  
  const createBooking = () => {
    navigate(`/booking/e`);
  };

  return (
    <Row className="g-0">
      <Col lg={4} className="px-4">
        <CustomSeparator
          currentCmpName="Bookings"
          indexLength="0"
          url="/Bookings"
        ></CustomSeparator>
      </Col>
      <Col lg={12} className="p-lg-4">
        {body ? (
          <DatatableWrapper
            body={body}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 10,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                lg={3}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              {/* <Col
                //xs={12}
                lg={3}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                 <InputGroup>
                
                <Form.Control
                  type="text"
                  className="inputbox"
                  placeholder="Enter text"
                  onChange={(e) => { setFilteredText(e.target.value);handleFilter(filteredSlected,e.target.value)}}/>
              
              <Button variant="primary" onClick={()=>{
              //.log('insdode', document.querySelector(".inputbox").value);
              document.querySelector(".inputbox").value ='';
              handleFilter('')
            }}><b>×</b></Button>
              </InputGroup>
                
              </Col> */}
              <Col
                // xs={12}
                // sm={6}
                lg={8}
                className="d-flex flex-col justify-content-start align-items-center"
              >
                <div style={{ marginTop: "0.4rem" }}>
                  <PaginationOptions labels={labels} />
                </div>
                <Form.Group className="mx-1 mt-4" controlId="formBasicStatus">
                  <Form.Select
                    aria-label="Enter status"
                    name="date"
                    onChange={(e) => {
                      setDate(e.target.value);
                      setBody(
                        bookings.filter((data) => {
                          return (
                            moment(data[e.target.value]).format(
                              "DD/MM/YYYY"
                            ) === moment(selectvalue).format("DD/MM/YYYY")
                          );
                        })
                      );
                    }}
                  >
                    <option value="">-Select-</option>
                    <option value="createddate">Booking Date</option>
                    <option value="checkindate">Checkin Date</option>
                    <option value="checkoutdate">Checkout Date</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mx-1 mt-4" controlId="formBasicStatus">
                  <Form.Control
                    disabled={!selectdate}
                    required
                    type="date"
                    name="checkoutdate"
                    placeholder="Enter"
                    //value={booking.checkoutdate}
                    // isInvalid={checkoutValid}
                    onChange={(event) => {
                      setSelectvalue(event.target.value);
                      setBody(
                        bookings.filter((data) => {
                          return (
                            moment(data[selectdate]).format("DD/MM/YYYY") ===
                            moment(event.target.value).format("DD/MM/YYYY")
                          );
                        })
                      );
                    }}
                  />
                </Form.Group>
                <Form.Group className="mx-1 mt-4" controlId="formBasicStatus">
                  <Form.Select
                    aria-label="Enter status"
                    name="status"
                    onChange={(e) => {
                      setfilteredSelected(e.target.value);
                      handleFilter(e.target.value, filteredText);
                    }}
                  >
                    <option value="">-Select Status-</option>
                    <option value="History">History</option>
                    <option value="Booked">Booked</option>
                    <option value="Advance booking">Advance Booking</option>
                    <option value="Cancel">Cancel</option>
                  </Form.Select>
                </Form.Group>
                <InfoPill left="Total" right={body?.length} />
              </Col>
              <Col
                //  xs={12}
                //sm={6}
                lg={1}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Button
                  style={{ marginLeft: "-31px" }}
                  className="btn-sm"
                  variant="outline-primary"
                  onClick={() => createBooking(true)}
                >
                  New Booking
                </Button>
              </Col>
            </Row>
            <Table striped className="data-table">
              <TableHeader />
              <TableBody />
            </Table>
            {body && body.length > 0 ? <Pagination /> : ""}
          </DatatableWrapper>
        ) : (
          ""
        )}
      </Col>
      {/* <Col lg={2}></Col> */}
    </Row>
  );
};

export default BookingList;
