import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-toastify/dist/ReactToastify.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import * as formik from "formik";
import { useSelector, useDispatch } from "react-redux";
import { paymentInitialValues } from "../InitialValuesHelper";
import { schemaPaymentEdit } from "../ValidateSchemaHelper";
import { PATH } from "../../api/pathUrl";
import { putRequest, postRequest } from "../../api/inventoryApiTwo";

const PaymentEdit = (props) => {
  let dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [payment, setPayment] = useState(
    props.payment
      ? props.payment.row
      : { parentid: props.parentid, amount: props.amount }
  );
  const { resultHandle } = useSelector((state) => state.apiReducer);
  // const [defaultDate, setDefaultDate] = useState(new Date());

  // useEffect(() => {
  //     if (props.payment !== null ) {

  //     }else {

  //         setDefaultDate(new Date());
  //         payment.date = defaultDate;

  //     }
  // }, []);

  useEffect(() => {
    if (resultHandle.isModal) {
      submitPayment();
      dispatch({ type: "SET_RESULT_HANDLE", payload: {} });
    }
  }, [resultHandle]);

  const handleSubmitSave = async (values) => {
    if (payment.id) {
      dispatch(
        putRequest(PATH.paymentPath, undefined, values.id, values, true)
      );
    } else {
      dispatch(postRequest(PATH.paymentPath, values, undefined, true));
    }

    // values.parentid = payment.parentid
    // if (payment.id) {
    //     values.id = payment.id
    //     const result = await inventoryApi.savePayment(values);
    //     if (result) {
    //         ToastMessage('Payment has been updated successfully !', 'success');
    //         submitPayment();
    //     }
    // } else {
    //     if (!payment.hasOwnProperty("date")) {
    //         payment["date"] = moment(new Date()).format('YYYY-MM-DD');
    //     }
    //     const result = await inventoryApi.createPayment(values);
    //     if (result) {
    //         if (result.errors) {
    //             for (const each of result.errors) {
    //                 ToastMessage(each.msg, 'error');
    //             }
    //         }
    //         else {
    //             ToastMessage("Payment has been done successfully !", 'success');
    //             submitPayment();
    //         }
    //     }
    // }
  };

  const submitPayment = () => {
    props.submitPayment();
  };

  const { Formik } = formik;

  return (
    <Formik
      validationSchema={schemaPaymentEdit()}
      onSubmit={handleSubmitSave}
      initialValues={paymentInitialValues(payment)}
    >
      {({ handleSubmit, handleChange, touched, values, errors }) => (
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {payment.id ? (
                <>
                  Edit Payment
                  <h4>{payment.name}</h4>
                </>
              ) : (
                "New Payment"
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="view-form">
              <Row>
                <Col lg={12}>
                  <Form
                    className="mt-3"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                    controlId="PaymentEdit"
                  >
                    <Row>
                      <Col>
                        <Form.Group className="mx-3" controlId="formBasicTitle">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicTitle"
                          >
                            Amount <b class="red-star">*</b>
                          </Form.Label>
                          <Form.Control
                            required
                            type="number"
                            name="amount"
                            placeholder="Enter amount"
                            value={values.amount}
                            onChange={handleChange}
                            className="no-arrows"
                            min={0}
                          />
                          {touched.amount && (
                            <div className="form-error">{errors.amount}</div>
                          )}
                          <Form.Control.Feedback type="invalid">
                            {errors.amount}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mx-3" controlId="formBasicTitle">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicTitle"
                          >
                            Date <b class="red-star">*</b>
                          </Form.Label>
                          {payment && (
                            <Form.Control
                              required
                              type="date"
                              name="date"
                              min={moment(new Date()).format("YYYY-MM-DD")}
                              placeholder="Enter title"
                              value={values.date}
                              onChange={handleChange}
                            />
                          )}
                          {touched.date && (
                            <div className="form-error">{errors.date}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          className="mx-3"
                          controlId="formBasicPriority"
                        >
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicPriority"
                          >
                            Method <b class="red-star">*</b>
                          </Form.Label>
                          <Form.Select
                            required
                            aria-label="Enter Priority"
                            name="method"
                            value={values.method}
                            onChange={handleChange}
                          >
                            <option value="">Select Method</option>
                            <option value="Cash">Cash</option>
                            <option value="Card">Card</option>
                            <option value="UPI">UPI</option>
                          </Form.Select>
                          {touched.method && (
                            <div className="form-error">{errors.method}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mx-3"
                          controlId="formBasicPriority"
                        >
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicPriority"
                          >
                            Type <b class="red-star">*</b>
                          </Form.Label>
                          <Form.Select
                            required
                            aria-label="Enter Type"
                            name="type"
                            value={values.type}
                            onChange={handleChange}
                          >
                            <option value="">Select Type</option>
                            <option value="Advance">Advance</option>
                            <option value="Laundry">Laundry</option>
                            <option value="Restaurant">Restaurant</option>
                            <option value="Full Payment">Full Payment</option>
                          </Form.Select>
                          {touched.type && (
                            <div className="form-error">{errors.type}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          className="mx-3"
                          controlId="formBasicDescription"
                        >
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicDescription"
                          >
                            Description
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            name="description"
                            placeholder="Enter description"
                            value={values.description}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleSubmit}>
              Save
            </Button>
            <Button onClick={props.onHide} variant="light">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};

export default PaymentEdit;
