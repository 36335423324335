import React, { useState, useEffect, Component, PropTypes } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../../api/inventoryApi";
import moment from "moment";
import RelatedListFiles from "../RelatedList/RelatedListFiles";
import RelatedListPayment from "../RelatedList/RelatedListPayment";

const BillView = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const [bill, setBill] = useState(location.state ? location.state : {});
    const [booking, setBooking] = useState(location.state ? location.state : {});
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [expenseListForinsert, setExpenseList] = useState();
    const [expensePaymentAmount, setExpensePaymentAmount] = useState(0);
    const [body, setBody] = useState([]);
    const [refreshPaymentList, setRefreshPaymentList] = useState();
    const [refreshExpenseList, setRefreshExpenseList] = useState();
    const [refreshViewPage, setRefreshViewPage] = useState(false);
    const [relatedListPayment, setRelatedListPayment] = useState(false);
    const [relatedListExpense, setRelatedListExpense] = useState(false);
    //
    useEffect(() => {
        //ViewBill();

    }, [refreshViewPage]);
    useEffect(() => {
        fetchBooking();
        ViewBill(booking);

    }, [refreshViewPage]);
    useEffect(() => {
        paymentList();
        setRefreshViewPage(!refreshViewPage);

    }, [refreshPaymentList]);
    useEffect(() => {
        expenseList();
        setRefreshViewPage(!refreshViewPage);
    }, [refreshExpenseList]);

    function ViewBill() {
        navigate(`/document/${bill.id}`, { state: booking });
        //    navigate(`/document/${booking.id}`, { state: booking });
    }

    async function init() {
        let payment = await inventoryApi.fetchPayment(booking.id);
        //.log("payemt", payment);
        if (payment && payment?.length > 0) {
            var paidAmount = 0;
            payment.forEach((element) => {
                //.log("element.price", element.amount);
                paidAmount += parseFloat(element.amount);
            });
            setPaymentAmount(paidAmount);
            //.log("paymentAmount", paymentAmount);
        } else {
            setPaymentAmount();
        }
    }

    init();
    const fetchBooking = () => {
        async function initBooking() {
            let result = await inventoryApi.fetchBooking(booking.id);
            //.log("result:", result);
            if (result) {
                if (result.id !== null) {
                    result.checkindate = moment(result.checkindate).format("YYYY-MM-DD");
                    result.checkoutdate = moment(result.checkoutdate).format(
                        "YYYY-MM-DD"
                    );
                }
                setBooking(result);
            } else {
                setBooking({});
            }
        }
        initBooking();
    };

    const paymentList = () => {
        async function init() {
            let payment = await inventoryApi.fetchPayment(booking.id);
            //.log("payemt", payment);
            if (payment && payment?.length > 0) {
                var paidAmount = 0;
                payment.forEach((element) => {
                    //.log("element.price", element.amount);
                    paidAmount += parseFloat(element.amount);
                });
                setPaymentAmount(paidAmount);
                //.log("paymentAmount", paymentAmount);
            } else {
                setPaymentAmount();
            }
        }
        init();
    };

    const expenseList = async () => {
        async function init() {
            let expense = await inventoryApi.fetchExpenseBasedParent(booking.id);
            //.log("expense", expense);
            if (expense && expense?.length > 0) {
                setBody(expense);
                var paidAmount = 0;
                expense.forEach((element) => {
                    //.log("element.price", element.amount);
                    paidAmount += parseFloat(element.amount);
                });
                setExpenseList(expense);
                setExpensePaymentAmount(paidAmount);
            } else {
                setBody([]);
            }
        }
        init();
    };

    const handleSelect = (key) => {
        if (key === "tasks") {
            // setRelatedListTasks(true);
            // setRelatedListFiles(false);
            setRelatedListPayment(false);
            setRelatedListExpense(false);
        } else if (key === "files") {
            // setRelatedListTasks(false);
            //setRelatedListFiles(true);
            setRelatedListExpense(false);
            setRelatedListPayment(false);
        } else if (key === "payment") {
            // setRelatedListTasks(false);
            // setRelatedListFiles(false);
            setRelatedListPayment(true);
            setRelatedListExpense(false);
        } else if (key === "expense") {
            //  setRelatedListTasks(false);
            //  setRelatedListFiles(false);
            setRelatedListPayment(false);
            setRelatedListExpense(true);
        }
    };

    return (
        <>
        </>

    )

};




export default BillView;