import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Table,
  Card,
} from "react-bootstrap";
import { DatatableWrapper, TableBody, TableHeader } from "react-bs-datatable";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../../api/inventoryApi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";
import { Link } from "react-router-dom";
import Confirm from "../Confirm";
import OverlayTriggerComp from "./OverlayTriggerComp";
import CheckRoomAvailability from "./CheckRoomAvailability";
import { checkboxData } from "../../constants/CONSTANT";
import ToastMessage from "../ToastMessage";
import { useSelector, useDispatch } from "react-redux";
import { getRequest, postRequest, putRequest } from "../../api/inventoryApiTwo";
import { PATH, TYPE } from "../../api/pathUrl";

const DashboardRoom = () => {

  let dispatch = useDispatch();
  const navigate = useNavigate();
  const [room, setRoom] = useState([]);
  const [roomValue, setRoomValue] = useState();
  const [filterRoom, setFilterRoom] = useState();
  const [allRooms, setAllRooms] = useState([]);
  const [check, setCheck] = useState(true);
  const [bookings, setBookings] = useState();
  const [body, setBody] = useState([]);
  const [bodyUpcoming, setBodyUpcoming] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [canBooking, setCanBooking] = useState("");
  const [showRoomMsg, setShowRoomMsg] = useState(false);
  const [modalShowAvailability, setModalShowAvailability] = useState(false)
  const { dashboardRoomData, advanceBookingsList, bookingList, roomList, resultHandle } = useSelector((state) => state.apiReducer);
  const [filterObj, setFilterObj] = useState({});
  const [filterObjblock, setFilterObjblock] = useState("A");
  const [tepmroom, setTempRoom] = useState();

  useEffect(() => {
    // if (resultHandle.success && resultHandle.isModal) { 
    //.log("resultHandle --> ", resultHandle)
    if (resultHandle.success) {

      if (Array.isArray(resultHandle.data)) {
        if (resultHandle.data.length > 0) {
          // //.log('filterRes',resultHandle.data);
          convertListOfObject(resultHandle.data);
          setShowRoomMsg(false);
        } else {
          setShowRoomMsg(true);
          setRoom([]);
        }
      }
      // const noRoomsAvailable =
      //   filterRes.hasOwnProperty("message") &&
      //   filterRes.message &&
      //   filterRes.message.length > 0;
      // if (!noRoomsAvailable) {
      //   setShowRoomMsg(true);
      // } else setShowRoomMsg(false);
      if (resultHandle.success && modalShow) {
        setModalShow(false);
      }

      dispatch({ type: TYPE.SET_RESULT, payload: {} })
    }
  }, [resultHandle])


  useEffect(() => {
    if (advanceBookingsList.length > 0) {
      setBody(
        advanceBookingsList.filter((ele) => {
          return moment(ele.checkindate).format("DD MMMM YYYY") >
            moment(new Date()).format("DD MMMM YYYY");
        })
      );
      setBodyUpcoming(
        advanceBookingsList.filter((ele) => {
          return moment(ele.checkindate).format("DD MMMM YYYY") ===
            moment(new Date()).format("DD MMMM YYYY");
        })
      );
    }
  }, [advanceBookingsList]);

  // useEffect(() => {



  //   //.log("Dashboard room data --> ")
  //   //.log("Dashboard room data --> ",dashboardRoomData)

  // }, [ dashboardRoomData ]);


  useEffect(() => {
    // fetchBookings();

    // "/api/booking/getTodayBooking"
    dispatch(getRequest(`${PATH.bookingPath}getTodayBooking`, TYPE.GET_ADV_BOOKINGS));
  }, [modalShow]);

  useEffect(() => {
    dispatch(getRequest(PATH.bookingPath, TYPE.GET_ALL_BOOKINGS));
    dispatch(getRequest(PATH.roomPath, TYPE.GET_ALL_ROOMS));
  }, []);

  // async function fetchBookings() {
  //   const bookings = await inventoryApi.fetchTodayBookings();
  //   if (bookings) {
  //     setBody(
  //       bookings.filter((ele) => {
  //         return moment(ele.checkindate).format("DD MMMM YYYY") >
  //           moment(new Date()).format("DD MMMM YYYY");
  //       })
  //     );
  //     setBodyUpcoming(
  //       bookings.filter((ele) => {
  //         return moment(ele.checkindate).format("DD MMMM YYYY") ===
  //           moment(new Date()).format("DD MMMM YYYY");
  //       })
  //     );
  //   }
  // }

  const handleCancel = (row) => {
    setModalShow(true);
    setCanBooking(row);
  };

  const cancelBooking = async () => {

    dispatch(putRequest(`${PATH.bookingPath}cancelbooking/`, undefined, canBooking.id, undefined, true));


    // const result = await inventoryApi.cancelBooking(canBooking.id);
    // if (result.success){
    //   ToastMessage("Booking cancelled successfully !","success");
    //   setModalShow(false);
    // } 
  };

  useEffect(() => {
    if (bookingList.length > 0) {
      setBookings(bookingList);
    }

    if (roomList.length > 0) {
      setAllRooms(roomList);
      convertListOfObject(roomList);
      setTempRoom(roomList);
    }
    // if (block) {
    //   const filteredRooms = roomList.filter((ele) => ele.block === block);
    //   setFilterRoom(filteredRooms);
    //   setRoom([filteredRooms.reduce((obj, entry) => {
    //     obj[entry.floor] = obj[entry.floor] ? [...obj[entry.floor], entry] : [entry];
    //     return obj;
    //   }, {})]);
    // }
  },
    [bookingList, roomList]);



  useEffect(() => {

    dispatch(getRequest(PATH.bookingPath, TYPE.GET_ALL_BOOKINGS));

    dispatch(getRequest(PATH.roomPath, TYPE.GET_ALL_ROOMS));

  }, []);

  // const fetchRoom = () => {


  //   async function init() {
  //     const result = await inventoryApi.fetchBookings();
  //     //.log('result all booking --> ',result);
  //     if (result) {
  //       setBookings(result);
  //     }
  //   }
  //   init();

  //   async function initRoom() {
  //     let result = await inventoryApi.fetchRooms();
  //     //.log('room result',result);
  //     if (result) {
  //       setAllRooms(result);
  //       convertListOfObject(result);
  //     } else {
  //       setRoom([]);
  //     }
  //   }

  //   initRoom();
  // };


  function handleBlockChange(e) {
    //.log('e.target.value', e.target.value)

    setFilterObjblock(e.target.value)

    dispatch(postRequest(`${PATH.roomPath}getroomsfordashboard`, { ...filterObj, block: e.target.value }));

    // var temp = tepmroom;
    // //.log('filterBlock',temp.filter((ele)=>ele.block === e.target.value))
    // setFilterRoom(temp.filter((ele)=>ele.block === e.target.value));
    // setRoom([temp.filter((ele)=>ele.block === e.target.value).reduce((obj, entry) => {
    //   obj[entry.floor] = obj[entry.floor]
    //     ? [...obj[entry.floor], entry]
    //     : [entry];
    //   return obj;
    // }, {})]);
  }





  function convertListOfObject(data) {
    //.log('data', data);

    let roomsByBlock = data.filter((data) => {
      return data.block === filterObjblock
    })

    let tempRoom = roomsByBlock.reduce((obj, entry) => {
      obj[entry.floor] = obj[entry.floor]
        ? [...obj[entry.floor], entry]
        : [entry];
      return obj;
    }, {});
    //.log('temp unarray',tempRoom)
    //.log('temp', [tempRoom])
    if(Object.values(tempRoom).length === 0){
      setShowRoomMsg(true)
    }else{
      setShowRoomMsg(false)
    }
    setRoom([tempRoom]);
    setFilterRoom(data);
  }

  function redirectBookingPage(room) {
    navigate(`/booking/e`, { state: { room: room } });
  }
  function redirectRoomPage(room) {
    navigate(`/room/` + room.id, { state: room });
  }

  function redirectBookingViewPage(booking) {
    navigate(`/booking/` + booking.id, { state: booking });
  }

  async function handleChange(e) {
    if (e.target.checked && e.target.name === "All") {
      setCheck(true);
      // setShowRoomMsg(!showRoomMsg);
      convertListOfObject(allRooms);
      //.log('All Room', convertListOfObject(allRooms))
      const checkboxes = document.querySelectorAll(".checkbox");
      //.log('checkboxes', checkboxes);
      checkboxes.forEach((checkbox) => {
        var checkBoxInput = checkbox.querySelector(".form-check-input");
        if (checkBoxInput.name !== "All") {
          checkBoxInput.checked = false;
        }
      });
    } else {
      // setShowRoomMsg(!showRoomMsg);
      const checkboxes = document.querySelectorAll(".checkbox");
      var objectTemp = {};
      checkboxes.forEach((checkbox) => {
        var checkBoxInput = checkbox.querySelector(".form-check-input");
        if (checkBoxInput.checked && checkBoxInput.name !== "All") {
          if (objectTemp?.hasOwnProperty(checkBoxInput?.name)) {
            var tempArray1 = objectTemp[checkBoxInput?.name] || [];
            tempArray1.push(checkBoxInput.value);
            objectTemp = { ...objectTemp, [checkBoxInput?.name]: tempArray1 };
          } else {
            objectTemp = {
              ...objectTemp,
              [checkBoxInput?.name]: [checkBoxInput.value],
            };
          }
        }
      });
      if (Object.keys(objectTemp).length !== 0) {
        setCheck(false);
        //.log('objectTemp', objectTemp)

        setFilterObj(objectTemp)

        getFilteredRooms(objectTemp, undefined);

        // dispatch(postRequest(`${PATH.roomPath}getroomsfordashboard`, {...objectTemp, block: filterObjblock}));


        // let filterRes = await inventoryApi.getFilteredRooms(objectTemp);
        // if (filterRes.message) {
        //   //.log('filterRes',filterRes);
        //   convertListOfObject(filterRes.data);
        // } else {
        //   setRoom([]);
        // }
        // const noRoomsAvailable =
        //   filterRes.hasOwnProperty("message") &&
        //   filterRes.message &&
        //   filterRes.message.length > 0;
        // if (!noRoomsAvailable) {
        //   setShowRoomMsg(true);
        // } else setShowRoomMsg(false);
      } else {

        setFilterObj();
        getFilteredRooms(undefined, filterObjblock)
        setCheck(true);
        convertListOfObject(allRooms);
      }
    }
  }


  const getFilteredRooms = (filters, block) => {

    let finalBody = {}

    if (filters && !block) finalBody = { ...filters, block: [filterObjblock] }
    if(filters && block)  finalBody =  { ...filterObj, block: block}
    //.log("getFilteredRooms --> ", finalBody);

    dispatch(postRequest(`${PATH.roomPath}getroomsfordashboard`, finalBody));
  }

  const header = [
    {
      title: "Booking",
      prop: "auto_number",
      isFilterable: true,
      cell: (row) => (
        <Link to={"/booking/" + row.id} state={row}>
          {row.auto_number}
        </Link>
      ),
    },
    { title: "Room", prop: "roomname", isFilterable: true },
    { title: "Guest Name", prop: "contact_name", isFilterable: true },
    {
      title: "Arriving date",
      prop: "checkindate",
      isFilterable: true,
      cell: (row) => moment(row.checkindate).format("dddd, DD MMMM YYYY"),
    },
    // { title: "Arriving from", prop: "comingfrom", isFilterable: true },

    {
      title: "Departure date",
      prop: "checkoutdate",
      isFilterable: true,
      cell: (row) => moment(row.checkoutdate).format("dddd, DD MMMM YYYY"),
    },
    { title: "Expected Stay", prop: "noofdays", isFilterable: true },
    {
      title: "Cancel Booking",
      prop: "id",
      cell: (row) => (
        <>
          <Button
            className="btn-sm mx-2"
            variant="danger"
            onClick={() => handleCancel(row)}
          >
            <i class="fa fa-times"></i>
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      {modalShow && (
        <Confirm
          show={modalShow}
          onHide={() => setModalShow(false)}
          cancelBooking={cancelBooking}
          title="Confirm Cancel?"
          message="You are going to Cancel the Booking. Are you sure?"
          table="Dashboard"
        />
      )}

      <Container className="home-main pt-2 pb-4">
        <Row
          style={{
            paddingTop: ".7rem",
          }}
        >
          {["checkbox"].map((type) => (
            <Col
              key={`inline-${type}`}
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
              
            >
              <Card className="">
                <Card.Header
                  style={{ backgroundColor: "rgba(108, 117, 125, 0.49)" }}
                >
                  <table style={{ tableLayout: "fixed", width: "98%" }}>
                    <tr>
                      <td>
                        <Form.Check
                          inline
                          className="checkbox"
                          checked={check}
                          label="All"
                          value="All"
                          name="All"
                          type="switch"
                          onChange={handleChange}
                          id="inline-checkbox-1"
                        />
                      </td>
                      {checkboxData.map((checkbox) => (
                        <td key={checkbox.id}>
                          <Form.Check
                            inline
                            className="checkbox"
                            label={checkbox.label}
                            value={checkbox.value}
                            name={checkbox.name}
                            type={checkbox.type}
                            id={checkbox.id}
                            onChange={handleChange}
                          />
                        </td>

                      ))}
                      <td>

                        <Form.Select
                          required
                          onChange={(e) => {
                            setFilterObjblock(e.target.value)
                            getFilteredRooms(filterObj, e.target.value)
                          }
                          }
                          defaultValue={filterObjblock}
                        >
                          {/* { <option value="">--Select Block--</option> } */}
                          <option value="A">A-Block</option>
                          <option value="B">B-Block</option>
                          <option value="C">C-Block</option>
                          <option value="D">D-Block</option>
                          <option value="E">E-Block</option>
                          <option value="F">F-Block</option>
                          <option value="G">G-Block</option>

                        </Form.Select>
                      </td>
                    </tr>
                  </table>
                </Card.Header>
                <Card.Body>
                  <Row style={{ paddingLeft: "1rem" }}>
                    {/* {!filterRoom && (
                      <h6 className="text-center my-5">No Room Available !</h6>
                    )} */}

                    {showRoomMsg && (
                      <h6 className="text-center my-5">No Room Available !</h6>
                    )}
                    <Col>
                      {room &&
                        room.map((elem) => {
                          return (
                            <>
                              {Object.keys(elem).map((ind) => {
                                return (
                                  <Row className="bg-white rounded mt-1 pb-1">
                                    <Col
                                      lg={1}
                                      style={{
                                        backgroundColor: "#6c757d",
                                        padding: ".5rem",
                                        color: "#fff",
                                        borderLeft:
                                          "10px solid rgb(241 170 42)",
                                      }}
                                    >
                                      Floor {ind === "0" ? "G" : ind}
                                    </Col>
                                    <Col lg={11}>
                                      <Row>
                                        {elem[ind].map((data) => {
                                          return (
                                            <>
                                              {/* {!modalShowAvailability && bookings &&  */}
                                              {!modalShowAvailability &&
                                                <OverlayTriggerComp
                                                  bookings={bookings}
                                                  data={data}
                                                  redirectBookingPage={() => {
                                                    // dispatch({ type: TYPE.GET_AVAILABLE_ROOMS, payload: [] })
                                                    setRoomValue(data);
                                                    setModalShowAvailability(!modalShowAvailability);
                                                  }}
                                                  // redirectRoomPage={(e) => {
                                                  //   redirectRoomPage(e);
                                                  // }}
                                                  // redirectBookingViewPage={(e) => {
                                                  //   redirectBookingViewPage(e);
                                                  // }}
                                                />
                                              }
                                            </>
                                          );
                                        })}
                                      </Row>
                                    </Col>
                                  </Row>
                                );
                              })}
                            </>
                          );
                        })}
                      {modalShowAvailability && (
                        <CheckRoomAvailability
                          show={modalShowAvailability}
                          onHide={() => setModalShowAvailability(false)}
                          room={roomValue}
                        />
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Row className="mt-3">
          <Col></Col>
          <Col lg={12} className="mt-2 p-0 m-0">
            <Card className="">
              <Card.Header
                style={{
                  backgroundColor: "rgba(108, 117, 125, 0.49)",
                  textAlign: "center",
                }}
              >
                Advance Booking
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <div style={{ height: "260px", overflow: "auto" }}>
                    <DatatableWrapper body={bodyUpcoming} headers={header}>
                      <Card.Header
                        style={{ backgroundColor: "rgba(108, 117, 125, 0.49)" }}
                      >
                        Today Booking
                      </Card.Header>
                      <Table width="80%" className="booking data-table striped">
                        <TableHeader />
                        <TableBody />
                      </Table>
                    </DatatableWrapper>
                    <DatatableWrapper body={body} headers={header}>
                      <Card.Header
                        style={{
                          backgroundColor: "rgba(108, 117, 125, 0.49)",
                          marginTop: "-1rem",
                        }}
                      >
                        Upcoming Booking
                      </Card.Header>
                      <Table width="80%" className="booking data-table striped">
                        <TableBody />
                      </Table>
                    </DatatableWrapper>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DashboardRoom;



