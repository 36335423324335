import React, { useState, useEffect} from "react";

import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Confirm from "../Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../../api/inventoryApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { RelatedListTask, FilesCreate, RelatedListFiles, TaskEdit } from "../index"


const PropertyView = (props) => {

  const location = useLocation();
  const navigate = useNavigate();

  const [property, setProperty] = useState(location.state ? location.state : {});
  const [modalShow, setModalShow] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [relatedListTasks, setRelatedListTasks] = useState(true);
  const [relatedListFiles, setRelatedListFiles] = useState(false);
  const [modalShowTaskfile, setModalShowFile] = useState(false);
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [refreshFileList, setRefreshFileList] = useState();

  useEffect(() => {
    if(!window.myMap){
    window.myMap = myMap;
    const googleMapScript = document.createElement('script');
    googleMapScript.src=`https://maps.googleapis.com/maps/api/js?key=AIzaSyBZsH0US1O6fSogoqBTUpUkvEWqs-rYMlY&callback=myMap`;
    googleMapScript.async = true;
    window.document.body.appendChild(googleMapScript);
    ////.log('refresh:');
    }else{
      myMap();
    }
    fetchProperty();
    
    //
  }, []);

  
  const fetchProperty = () => {
    ////.log('proepties ', property.id);
    async function initProperty() {
      let result = await inventoryApi.fetchProperty(property.id);
      //////.log('result:', result);
      if (result){
        ////.log('property: ',result);
        setProperty(result);
      }else{
        setProperty({});
      }
    }
    initProperty();
  }

  /*const taskList = () => {
    async function initTask() {
      let task = await inventoryApi.fetchTasks(property.id);
      if (task && task?.length > 0){
        setTasks(task);
      }else{
        setTasks([]);
      }
    }
    initTask();
  }

  const filesList = () => {
    async function initFiles() {
      let file = await inventoryApi.fetchFiles(property.id);
      ////.log(file)
      if (file && file?.length > 0) { 
        ////.log('file')
        setFiles(file);
      } else {
        setFiles([]);
      }
    }
    initFiles();
  }
  */
  const deleteLead = async () => {
    const result = await inventoryApi.deleteLead(property.id);
    if (result.success) navigate(`/properties`);
  };

  const editLead = () => {
    navigate(`/properties/${property.id}/e`, { state: property });
  };

  const submit = () => {
    setModalShowTask(false);
    navigate(`/properties/${property.id}`, { state: property });
  };


  const submitTasks = () => {
    setModalShowTask(false);
    setRefreshTaskList(Date.now());
  };
  const submitfiles = () => {
    ////.log('called:');
    setModalShowFile(false);
    setRefreshFileList(Date.now());
  };

  const handleSelect = (key) => {
    if (key === 'tasks') {
      setRelatedListTasks(true);
      setRelatedListFiles(false);
    } else if (key === 'files') {
      setRelatedListTasks(false);
      setRelatedListFiles(true);
    } 
  }

  
  const myMap = () =>{
    let contentString = `<h4>${property.name}</h4>
    <label className='maplabel'>Address:</label> <p>${property.street},${property.city},${property.state},${property.pincode},${property.country}</p>
    <label className='maplabel'>Contact Person:</label> <p> ${property.contactname} </p>
    <label className='maplabel'>Description:</label> <p> ${property.description} </p>`;
    const infowindow = new window.google.maps.InfoWindow({
      content: contentString,
      ariaLabel: "Uluru",
    });

    var mapProp= {
      center:new window.google.maps.LatLng(51.508742,-0.120850),
      zoom:14,
    };
    var map = new window.google.maps.Map(document.getElementById("googleMap"), mapProp);

   var geocoder = new window.google.maps.Geocoder();      
  var address = `${property.street},${property.city},${property.state},${property.pincode},${property.country}`;
  geocoder.geocode( { 'address': address}, function(results, status) {
  if (status == window.google.maps.GeocoderStatus.OK) {
      map.setCenter(results[0].geometry.location);
    var marker = new window.google.maps.Marker({
        map: map,
        position: results[0].geometry.location,
        title: `${property.name}`
    });

    marker.addListener("click", () => {
      infowindow.open({
        anchor: marker,
        map,
      });
    });
}
});  
    

    }

  return (
    <div>
      {property && <Container>
        {modalShow &&
          <Confirm
            show={modalShow}
            onHide={() => setModalShow(false)}
            deleteLead={deleteLead}
            title="Confirm delete?"
            message="You are going to delete the record. Are you sure?"
            table="lead"
          />}
        <Row className="view-form">
          <Col lg={8}>
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                Property
                <h4>{property.name}</h4>
              </Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={() => editLead(true)}>
                  <i className="fa-regular fa-pen-to-square"></i>
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={() => setModalShow(true)}
                >
                  Delete
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <label>Name</label>
                <span>
                  {property.name}
                </span>
              </Col>
              <Col lg={6}>
                <label>Code</label>
                <span>{property.code}</span>
              </Col>
              <Col lg={6}>
                <label>Email</label>
                <span>{property.email}</span>
              </Col>
              <Col lg={6}>
                <label>Phone</label>
                <span>{property.phone}</span>
              </Col>
              <Col lg={6}>
                <label>Type</label>
                <span>{property.type}</span>
              </Col>
              <Col lg={6}>
                <label>Total Area</label>
                <span>{property.area}</span>
              </Col>
              <Col lg={6}>
                <label>Contact Person</label>
                <span>{property.contactname}</span>
              </Col>
              <Col lg={6}>
                <label>Assigned Staff</label>
                <span>{property.ownername}</span>
              </Col>
              <Col lg={6}>
                <label>Street</label>
                <span>{property.street}</span>
              </Col>
              <Col lg={6}>
                <label>City</label>
                <span>{property.city}</span>
              </Col>
              <Col lg={6}>
                <label>State</label>
                <span>{property.state}</span>
              </Col>
              <Col lg={6}>
                <label>Pincode</label>
                <span>{property.pincode}</span>
              </Col>
              <Col lg={6}>
                <label>Country</label>
                <span>{property.country}</span>
              </Col>
              <Col lg={6}>
                <label>Description</label>
                <span>{property.description}</span>
              </Col>
            </Row>
          </Col>
          <Col>
          <div id="googleMap" style={{width:'100%',height:'500px'}}>

   

          </div>
          </Col>
        </Row>
       
        <Card bg="light" text="light" className="mb-2 mt-4">
          <Card.Header className="d-flex justify-content-between">
          <Tabs defaultActiveKey="tasks" id="uncontrolled-tab-example" onSelect={(key) => handleSelect(key)}>
                <Tab eventKey="tasks" title="Tasks"></Tab>
                <Tab eventKey="files" title="Files"></Tab>
              </Tabs>            
              {relatedListTasks && (
              <Button
                className="float-right btn-sm"
                onClick={() => setModalShowTask(true)}
              >
                New Task
              </Button>
            )}
            {modalShowTask && (
              <TaskEdit
              show={modalShowTask}
              onHide={() => setModalShowTask(false)}
              parentid={property.id}
              
              table="lead"
              submitTasks={submitTasks}
              />
            )}
            {relatedListFiles && (
              <Button
                className="float-right btn-sm"
                onClick={() => setModalShowFile(true)}
              >
                Upload File
              </Button>
            )}
            {modalShowTaskfile && (
              <FilesCreate show={modalShowTaskfile} onHide={() => setModalShowFile(false)} parent={property} table="lead"
                submitfiles={submitfiles} />
            )}
          </Card.Header>
          <Card.Body>
             {relatedListTasks ? <RelatedListTask refreshTaskList={refreshTaskList} parent={property}/> : ""}
            {relatedListFiles ? <RelatedListFiles refreshFileList={refreshFileList} parent={property}/> : ""} 

          </Card.Body>
        </Card>
      </Container>}
    </div>
  );
};

export default PropertyView;
