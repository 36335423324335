import React, { useState, useEffect, Component, PropTypes } from "react";
import ReactPDF from "@react-pdf/renderer";
import jwt_decode from "jwt-decode";
import getSymbolFromCurrency from "currency-symbol-map";
import { PDFViewer } from "@react-pdf/renderer";
import Form from "react-bootstrap/Form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Confirm from "../Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { formatTime } from "../Helper";
import { getRequest, postRequest, putRequest } from "../../api/inventoryApiTwo";
import { PATH, TYPE } from "../../api/pathUrl";
import {
  RelatedListTask,
  FilesCreate,
  RelatedListFiles,
  RelatedListPayment,
  TaskEdit,
  PaymentEdit,
  ExpensesEdit,
  MyDocument,
  RelatedListExpenses,
  Summary,
} from "../index";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";

const BookingView = (props) => {
  const { bookingDetail, resultHandle } = useSelector(
    (state) => state.apiReducer
  );
  let dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  // const [body, setBody] = useState([]);
  // const [booking, setBooking] = useState(location.state ? location.state : {});
  const [booking, setBooking] = useState(location.state ? location.state : {});
  //.log('booking',booking);
  // const [paymentAmount, setPaymentAmount] = useState(0);
  // const [expensePaymentAmount, setExpensePaymentAmount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [relatedListTasks, setRelatedListTasks] = useState(false);
  const [relatedListFiles, setRelatedListFiles] = useState(false);
  const [modalShowTaskfile, setModalShowFile] = useState(false);
  const [relatedListPayment, setRelatedListPayment] = useState(true);
  const [relatedListExpense, setRelatedListExpense] = useState(false);
  const [relatedOrderList, setRelatedOrderList] = useState(false);
  const [modalShowExpense, setModalShowExpense] = useState(false);
  const [modalShowPayment, setModalShowPayment] = useState(false);
  const [modalShowSummary, setModalShowSummary] = useState(false);
  const [modalShowCommision, setModalShowCommision] = useState(false);
  const [modalShowShiftRoom, setModalShowShiftRoom] = useState(false);
  const [modalShowRestaurant, setModalShowRestaurant] = useState(false);
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [refreshFileList, setRefreshFileList] = useState();
  const [refreshPaymentList, setRefreshPaymentList] = useState();
  const [refreshExpenseList, setRefreshExpenseList] = useState();
  const [refreshOrderList, setRefreshOrderList] = useState();
  const [refreshViewPage, setRefreshViewPage] = useState(false);
  const [commissionRec, setcommissionRec] = useState({});
  const [company, setCompany] = useState();
  // const [cancelButtonClicked, setCancelButtonClicked] = useState(false);

  useEffect(() => {
    if (resultHandle.success && resultHandle.isModal) {
      //.log('resultHandle',resultHandle);
      fetchBooking();
      if (refreshViewPage) setRefreshViewPage(!refreshViewPage);
      dispatch({ type: TYPE.SET_RESULT, payload: {} });
      if (modalShow) setModalShow(!modalShow);
    }
  }, [resultHandle]);

  useEffect(() => {
    fetchBooking();
  }, [
    modalShowPayment,
    modalShowExpense,
    modalShowRestaurant,
    modalShowShiftRoom,
    refreshViewPage,
  ]);

  // useEffect(() => {
  //   agentcommissionsRec();
  // }, [modalShowCommision]);

  // useEffect(() => {

  //   //.log("agentcommList in bookingView --> ",agentcommList)

  //   if (agentcommList.length > 0) {
  //     const rec = agentcommList.filter((ele) => ele.bookingid
  //       === booking.id)[0];
  //     if (rec) {
  //       setcommissionRec(rec);
  //     }
  //   }
  // }, [agentcommList]);

  useEffect(() => {
    if (localStorage.getItem("token"))
      setCompany(jwt_decode(localStorage.getItem("token"))?.companyinfo.at(0));
  }, [refreshPaymentList]);

  useEffect(() => {
    if (Object.keys(bookingDetail).length > 0) setBooking(bookingDetail);
  }, [bookingDetail]);

  // const agentcommissionsRec = () => {
  //   function init() {
  //     dispatch(getRequest(`${PATH.agentcommPath}`, TYPE.GET_AGENTCOMM));
  //   }
  //   init();
  // };

  const fetchBooking = () => {
    dispatch(
      getRequest(
        `${PATH.bookingPath}${location.pathname.split("/")[2]}`,
        TYPE.SET_BOOKING
      )
    );
  };

  const handleBookingAction = async (action) => {
    // if (!cancelButtonClicked) {
    //   setCancelButtonClicked(true);
      dispatch(
        putRequest(
          `${PATH.bookingPath}${action}/`,
          undefined,
          location.pathname.split("/")[2],
          undefined,
          true
        )
      );
    // }
  };

  const generateBill = async () => {
    // dispatch(
    //   postRequest(
    //     `${PATH.billPath}generate-bill/${booking.id}`,
    //     undefined,
    //     TYPE.GET_ALL_BOOKINGS
    //   )
    // );

    // let resultBillGen = await inventoryApi.generateBill(booking);
    // //.log("generateBill result --> ",resultBillGen);
    ViewBill();
  };

  const editbooking = () => {
    navigate(`/booking/${booking.id}/e`, { state: booking });
  };

  async function ViewBill() {
    navigate(`/document/${booking.id}`, { state: booking });
  }

  // const cancelBooking = async () => {
  //   dispatch(putRequest(PATH.bookingPath, TYPE.GET_BOOKINGS, location.pathname.split('/')[2], undefined, true, "cancelbooking/"));
  // };

  // const handleCheckout = async () => {
  //   dispatch(putRequest(PATH.bookingPath, TYPE.GET_BOOKINGS, location.pathname.split('/')[2], undefined, true, "checkout/"));
  // };

  // const handleCheckIn = async () => {
  //   dispatch(putRequest(PATH.bookingPath, TYPE.GET_BOOKINGS, location.pathname.split('/')[2], undefined, true, "checkin/"));
  // };

  const submitTasks = () => {
    setModalShowTask(false);
    setRefreshTaskList(Date.now());
  };
  const submitfiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
  };
  const submitPayment = () => {
    setModalShowPayment(false);
    setRefreshPaymentList(Date.now());
  };
  function submitExpense() {
    setModalShowExpense(false);
    setRefreshExpenseList(Date.now());
  }
  function submitOrder() {
    setModalShowPayment(false);
    setRefreshOrderList(Date.now());
  }
  function handlAction() {
    setRefreshExpenseList(Date.now());
    setRefreshOrderList(Date.now());
  }

  const handleSelect = (key) => {
    if (key === "tasks") {
      setRelatedListTasks(true);
      setRelatedListFiles(false);
      setRelatedListPayment(false);
      setRelatedListExpense(false);
      setRelatedOrderList(false);
    } else if (key === "files") {
      setRelatedListTasks(false);
      setRelatedListFiles(true);
      setRelatedListExpense(false);
      setRelatedListPayment(false);
      setRelatedOrderList(false);
    } else if (key === "payment") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedListPayment(true);
      setRelatedListExpense(false);
      setRelatedOrderList(false);
    } else if (key === "expense") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedListPayment(false);
      setRelatedListExpense(true);
      setRelatedOrderList(false);
    } else if (key === "order") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedListPayment(false);
      setRelatedListExpense(false);
      setRelatedOrderList(true);
    }
  };

  return (
    <div>
      <Col lg={4} className="px-4">
        <CustomSeparator
          cmpListName="Bookings"
          currentCmpName={booking.auto_number}
          indexLength="2"
          url="/Bookings"
        ></CustomSeparator>
      </Col>
      <br></br>
      {booking && (
        <Container>
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteBooking={() => handleBookingAction("cancelbooking")}
              title="Confirm Cancel?"
              message="You are going to Cancel the Booking. Are you sure?"
              table="Booking"
            />
          )}
          <Row className="view-form ">
            <Col></Col>
            <Col lg={7} className="pb-4" style={{ backgroundColor: "#fff" }}>
              <Row className="view-form-header align-items-center">
                <Col lg={3}>
                  Booking Number
                  <h4>{booking.auto_number}</h4>
                </Col>
                <Col lg={9} className="d-flex justify-content-end">
                  {/* {company  && company.active_features.includes("Agent_Commission") && (booking.status === 'Advance booking' || booking.status === 'Booked')  ?  (
                
                <Button
                    className="btn-sm mx-2"
                    onClick={() => {setModalShowCommision(true)}}
                  >
                   Agent Commission
                  </Button>
               ) : ''} */}
                  {/* { booking.status === 'Booked'  ?  (
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => setModalShowShiftRoom(true)}
                  >
                   Shift Room
                  </Button>
                  ):''} */}
                  {booking.status === "Advance booking" ||
                  booking.status === "Booked" ? (
                    <>
                      <Button
                        className="btn-sm mx-2"
                        onClick={() => editbooking(true)}
                        title="Edit Booking"
                      >
                        <i className="fa-regular fa-pen-to-square"></i>
                      </Button>

                      {booking.status !== "Booked" && booking.pending_amount !== "0.00" ? (
                        <Button
                          className="btn-sm"
                          variant="danger"
                          //disabled={cancelButtonClicked}
                          onClick={() => setModalShow(true)}
                        >
                          Cancel Booking
                        </Button>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  <Button
                    className="btn-sm mx-2"
                    variant="danger"
                    onClick={() => navigate(`/bookings`)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <label>Room</label>
                  <span>{booking.roomname}</span>
                </Col>
                <Col lg={6}>
                  <label>Contact</label>
                  <span>{booking.contact_name}</span>
                </Col>
                <Col lg={6}>
                  <label>Status</label>
                  <span>{booking.status}</span>
                </Col>
                <Col lg={6}>
                  <label>Time Format</label>
                  <span>{booking.timeformat}</span>
                </Col>
                <Col lg={6}>
                  <label>Check In Date & Time</label>
                  <span>
                    {" "}
                    {moment(booking.checkindate).format("DD/MM/YYYY")}
                    &nbsp;&nbsp;|&nbsp;&nbsp;{formatTime(booking.check_in_time)}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Check Out Date & Time</label>
                  <span>
                    {" "}
                    {moment(booking.checkoutdate).format("DD/MM/YYYY")}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    {formatTime(booking.check_out_time)}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>No of days </label>
                  <span>{booking.noofdays}</span>
                </Col>
                <Col lg={6}>
                  <label>No of guest</label>
                  <span>{booking.noofguest}</span>
                </Col>
                <Col lg={6}>
                  <label>Discount</label>
                  <span>{booking.discount_percentage}</span>
                </Col>
                <Col lg={6}>
                  <label>Total Amount</label>
                  <span>
                    {getSymbolFromCurrency("INR")}&nbsp;
                    {booking.totalamount}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Extra bed</label>
                  <span>{booking.extra_bed}</span>
                </Col>
                <Col lg={6}>
                  <label>GST Applied</label>
                  <span>{booking.isgst ? "Yes" : "No"}</span>
                </Col>
                <Col lg={6}>
                  <label>Coming from</label>
                  <span>{booking.comingfrom}</span>
                </Col>
                <Col lg={6}>
                  <label>Going to</label>
                  <span>{booking.goingto}</span>
                </Col>
                <Col lg={6}>
                  <label>Source</label>
                  <span>{booking.source}</span>
                </Col>
                <Col lg={6}>
                  <label>Description/Arrival</label>
                  <span>{booking.description}</span>
                </Col>
              </Row>
            </Col>
            <Col></Col>
            <Col id="divToPrint" lg="4" style={{ backgroundColor: "#fff" }}>
              <Row className="view-form-header align-items-center justify-content-center">
                <Col lg={9} className="d-flex justify-content-center">
                  <center>
                    <h4>
                      Balance Amount :<br></br>
                      <b>
                        {getSymbolFromCurrency("INR")}
                        {booking.pending_amount}
                      </b>{" "}
                    </h4>
                  </center>
                </Col>
              </Row>
              <div style={{ borderRadius: "5px", padding: "5px" }}>
                <Row style={{ paddingTop: "5px" }}>
                  <Col lg={6}>Room Amount &nbsp;:</Col>
                  <Col lg={4}>
                    {getSymbolFromCurrency("INR")}
                    {booking.totalamount}
                  </Col>
                </Row>

                <Row style={{ paddingTop: "5px" }}>
                  <Col lg={6}>GST (18%)&nbsp;:</Col>
                  <Col>
                    {getSymbolFromCurrency("INR")}
                    {booking.isgst === true
                      ? (
                          ((parseFloat(booking.totalamount) -
                            parseFloat(booking.discount_percentage)) *
                            18) /
                          100
                        ).toFixed(2)
                      : 0}
                  </Col>
                </Row>
                <Row style={{ paddingTop: "5px" }}>
                  <Col lg={6}>Expense Amount &nbsp;:</Col>
                  <Col lg={4}>
                    {getSymbolFromCurrency("INR")}
                    {booking.expense_amount}
                  </Col>
                </Row>
                <Row style={{ paddingTop: "5px" }}>
                  <Col lg={6}>Discount &nbsp;:</Col>
                  <Col>
                    {getSymbolFromCurrency("INR")}
                    {booking.discount_percentage}
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}></Col>
                  <Col lg={4}></Col>
                  <Col lg={1}>(+)</Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    SubTotal : {booking.isgst && <p>(with GST)</p>}&nbsp;
                  </Col>
                  <Col lg={4}>
                    {getSymbolFromCurrency("INR")}
                    {(
                      parseFloat(booking.totalamount_with_gst) +
                      parseFloat(booking.expense_amount)
                    ).toFixed(2)}
                  </Col>
                </Row>
                <Row style={{ paddingTop: "5px" }}>
                  <Col lg={6}>Paid Amount &nbsp;:</Col>
                  <Col lg={4}>
                    {getSymbolFromCurrency("INR")}
                    {booking.paid_amount}
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}></Col>
                  <Col lg={4}></Col>
                  <Col lg={1}>(-)</Col>
                </Row>
                <Row>
                  <Col lg={6}>Pending Amount &nbsp;:</Col>
                  <Col>
                    {getSymbolFromCurrency("INR")}
                    {booking.pending_amount}
                  </Col>
                </Row>

                <Row className="mt-5">
                  {booking.status === "Advance booking" &&
                  booking.paid_amount > 0.0 ? (
                    <Col lg={4}>
                      <Button
                        className="btn-sm"
                        // variant="danger"
                        onClick={() => handleBookingAction("checkin")}
                        // disabled={(booking.paid_amount >= 0.00 && booking.status ==='Booked') && booking.checkoutdate === moment(new Date()).format('YYYY-MM-DD') ? false : true}
                      >
                        Check In
                      </Button>
                    </Col>
                  ) : (
                    ""
                  )}
                  {booking.status === "Booked" ? (
                    <Col lg={4}>
                      <Button
                        className="btn-sm"
                        // variant="danger"
                        onClick={() => handleBookingAction("checkout")}
                        disabled={
                          booking.pending_amount <= 0.0 &&
                          booking.status === "Booked"
                            ? false
                            : true
                        }
                      >
                        Check Out
                      </Button>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col lg={4}>
                    {booking.is_bill_generated ? (
                      <Button
                        className="btn-sm"
                        // variant="danger"
                        onClick={() => ViewBill()}
                        // onClick={printDocument()}
                      >
                        View Bill
                      </Button>
                    ) : (
                      <Button
                        className="btn-sm"
                        // variant="danger"
                        onClick={() => generateBill()}
                        // onClick={printDocument()}
                        disabled={booking.status !== "History" ? true : false}
                      >
                        Bill Generate
                      </Button>
                    )}
                  </Col>
                  <Col lg={4}></Col>
                </Row>
              </div>
            </Col>
            <Col></Col>
          </Row>
          {modalShowSummary && (
            <Summary
              show={modalShowSummary}
              onHide={() => setModalShowSummary(false)}
              ShowPayment={() => {
                setModalShowSummary(false);
                setModalShowPayment(true);
              }}
            />
          )}
          {/* {modalShowCommision && (
          
            //  <AgentCommision
            //   show={modalShowCommision}
            //   onHide={() => setModalShowCommision(false)}
            //   booking={booking}
            //   agentcommision={commissionRec}
            //  />
              
          )} */}
          {/* {modalShowShiftRoom && (
          
          <RoomShifting
           show={modalShowShiftRoom}
           onHide={(booking) =>{if(booking)setBooking(booking);setModalShowShiftRoom(false);}}
           booking={booking}
          />
          ) } */}
          {/* {modalShowRestaurant && (
          
            <Restaurant
             show={modalShowRestaurant}
             onHide={() => setModalShowRestaurant(false)}
             submitOrder={submitOrder}
             refreshOrder={()=>{setRefreshViewPage(!refreshViewPage)}}
             booking={booking}
            />
           
       )} */}
          <Row>
            <Col>
              <a
                onClick={() => {
                  window.open("https://indianfrro.gov.in/frro/FormC");
                }}
                class="active"
              >
                C Form
              </a>
              {/* <Button onClick={()=>{window.open("https://indianfrro.gov.in/frro/FormC")}}>C Form Link</Button> */}
            </Col>
          </Row>
          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="payment"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                <Tab eventKey="payment" title="Payment"></Tab>
                {/* {company  && company.active_features.includes("Restaurant") && <Tab eventKey="order" title="Order"></Tab>} */}
                <Tab eventKey="expense" title="Expense"></Tab>
                <Tab eventKey="tasks" title="Tasks"></Tab>
                <Tab eventKey="files" title="Files"></Tab>
              </Tabs>
              {relatedListTasks && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowTask(true)}
                >
                  New Task
                </Button>
              )}
              {relatedOrderList && (
                <Button
                  className="btn-sm"
                  onClick={() => setModalShowRestaurant(true)}
                  disabled={
                    booking.status === "History" ||
                    booking.status === "Room Shifted" ||
                    booking.status === "Cancel" ||
                    booking.status === "Advance booking"
                  }
                >
                  New Order
                </Button>
              )}

              {modalShowTask && (
                <TaskEdit
                  show={modalShowTask}
                  onHide={() => setModalShowTask(false)}
                  parentid={booking.id}
                  table="lead"
                  submitTasks={submitTasks}
                />
              )}
              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  Upload File
                </Button>
              )}

              {modalShowTaskfile && (
                <FilesCreate
                  show={modalShowTaskfile}
                  onHide={() => setModalShowFile(false)}
                  parent={booking}
                  table="lead"
                  submitfiles={submitfiles}
                />
              )}
              {relatedListPayment && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowPayment(true)}
                  disabled={
                    booking.status === "History" || booking.status === "Cancel"
                  }
                >
                  New Payment
                </Button>
              )}
              {modalShowPayment && (
                <PaymentEdit
                  show={modalShowPayment}
                  onHide={() => setModalShowPayment(false)}
                  parentid={booking.id}
                  table="lead"
                  amount={booking.pending_amount}
                  submitPayment={submitPayment}
                />
              )}

              {relatedListExpense && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowExpense(true)}
                  disabled={
                    booking.status === "History" ||
                    booking.status === "Cancel" ||
                    booking.status === "Advance booking"
                  }
                >
                  New Expense
                </Button>
              )}
              {modalShowExpense && (
                <ExpensesEdit
                  show={modalShowExpense}
                  onHide={() => setModalShowExpense(false)}
                  parentid={booking.id}
                  submitExpense={submitExpense}
                />
              )}
            </Card.Header>
            <Card.Body>
              {relatedListTasks ? (
                <RelatedListTask
                  refreshTaskList={refreshTaskList}
                  parent={booking}
                />
              ) : (
                ""
              )}
              {relatedListFiles ? (
                <RelatedListFiles
                  refreshFileList={refreshFileList}
                  parent={booking}
                />
              ) : (
                ""
              )}
              {relatedListPayment && bookingDetail ? (
                <RelatedListPayment
                  refreshPaymentList={refreshPaymentList}
                  refreshPayment={() => {
                    setRefreshViewPage(!refreshViewPage);
                  }}
                  parent={bookingDetail}
                />
              ) : (
                ""
              )}
              {relatedListExpense ? (
                <RelatedListExpenses
                  refreshExpenseList={refreshExpenseList}
                  parent={booking}
                  handlAction={handlAction}
                  refreshExpense={() => {
                    setRefreshViewPage(!refreshViewPage);
                  }}
                />
              ) : (
                ""
              )}
              {/* {relatedOrderList ? (
                <RelatedorderList
                refreshOrderList={refreshOrderList}
                  parent={booking}
                  handlAction={handlAction}
                  refreshOrder={()=>{setRefreshViewPage(!refreshViewPage)}}
                />
              ) : (
                ""
              )} */}
            </Card.Body>
          </Card>
        </Container>
      )}
    </div>
  );
};

export default BookingView;
