import React, {useState, useEffect} from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import timeGridPlugin from '@fullcalendar/timegrid'
import { Container } from 'react-bootstrap'
import EventEdit from './EventRecEdit'
import inventoryApi from "../../api/inventoryApi";
import momentTimezonePlugin from '@fullcalendar/moment-timezone';

const Calendar = () => {
    const [events, setEvents] = useState([]);
    const [showEventModel, setShowEventModel] = useState(false);
    const calendarRef = React.createRef();
    const [event, setEvent] = useState({})

    const handleDateClick = (selectInfo) => { // bind with an arrow function
        //createEvent('selectInfo',selectInfo.date);
        //.log('selectInfo',selectInfo.date)
        setEvent({startdatetime : selectInfo.date,enddatetime : selectInfo.date});
        setShowEventModel(true);
      }
    
    const createEvent = (selectInfo) => {
        let title = prompt('Please enter a new title for your event')
      let calendarApi = selectInfo.view.calendar
  
      calendarApi.unselect() // clear date selection
  
      if (title) {
        const event = {
            id: Math.floor(Math.random() * 1000),
            title,
            start: new Date(), //selectInfo.startStr,
            end: selectInfo.endStr,
            allDay: selectInfo.allDay
          };

        calendarApi.addEvent(event);
      }
    }
      const handleEventClick = (arg) => { // bind with an arrow function
        //.log('arg',arg)
        //.log('arg',arg.event.date,arg.event.title,arg.event.end)
        setEvent({title: arg.event.title, description: arg.event.extendedProps.description , id : arg.event.id ,type : arg.event.extendedProps.type,priority : arg.event.extendedProps.priority,status : arg.event.extendedProps.status,startdatetime : arg.event.start,enddatetime : arg.event.end ? arg.event.end : arg.event.start, ownerid : arg.event.extendedProps.ownerid, ownername : arg.event.extendedProps.ownername});
        setShowEventModel(true);
      }

      const submitEvents = (eventRec) => {
        //.log('eventRec',eventRec)
        let calendarApi = calendarRef.current.getApi();
        //.log('calendarApi',calendarApi)
         calendarApi.unselect() // clear date selection
         calendarApi.addEvent(eventRec);
         //.log('success')
         setShowEventModel(false);
      }

      const handleEventAdded = (data) => {
      }

      const handleEvents = (meetings) => {
      }

      useEffect(() => {
        taskList()
      }, []);

      let arrayOfTask = [];
      const taskList = () => {
        async function init() {
          let tasks = await inventoryApi.fetchTasksWithoutParent();
          //.log(JSON.stringify(tasks));
          //.log('tasks',tasks)
          
          let taskFilter = tasks.filter((value, index, array) => value.type === 'Meeting' && value.startdatetime != null);
          //.log('taskFilter',taskFilter);
          
          taskFilter.map((value, index, array) => {
            let taskObj = {
              id : value.id,
              title: value.title,
              start: value.startdatetime,
              end: value.enddatetime,
              description: value.description,
              type : 'Meeting',
              priority : value.priority,
              status  : value.status,
              ownerid  : value.ownerid,
              ownername  : value.ownername
            };
            arrayOfTask.push(taskObj);
          });
          

          setEvents(arrayOfTask);
          //.log('arrayOfTask',arrayOfTask)
        }
        init();
      }

      useEffect( () => {
    //     async function init(){
    //      const products = await pathLabApi.fetchProduct();
    //      if(products)
         
    //        setBody(products);
    //    }
    //    init();
        let meetings =[
            { title : "Task" , prop : "title"},
          ];
        
        
 
   }, []);


  
  


   const renderEventContent = (eventInfo) => {
    return (
      <>
        <div style={{backgroundColor: "red"}}>
        
        <b>{eventInfo.event.title}</b>
        <p>{eventInfo.timeText}</p>
        </div>
      </>
    )
  }


  return (
    <Container>
        
        {showEventModel && 

    <EventEdit
          show={showEventModel}
          onHide={() => setShowEventModel(false)}
          parentid="abc"
          eventRec={event}
          table="user"
          submitEvents={submitEvents}
        />
        }
    <FullCalendar
        plugins={[ dayGridPlugin, interactionPlugin, timeGridPlugin, momentTimezonePlugin  ]}
        initialView="dayGridMonth"
        events = {events}
        timeZone='UTC'
        dateClick={handleDateClick}
        eventClick={handleEventClick}
       // timeZone='Asia/Kolkata'
        headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          eventsSet={handleEvents}
        eventAdd={handleEventAdded}
        ref={calendarRef}
        eventBackgroundColor="#0d6efd"
        eventBorderColor="#0d6efd"
        eventDisplay='block'
      />
      </Container>
  )
}

export default Calendar