import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useSelector } from "react-redux";
import inventoryApi from "../../api/inventoryApi";


const ProductEdit = (props) => {
    const [options, setOptions] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    const [product, setProduct] = useState(location.state);
    const handleChange = (e) => {
        setProduct({ ...product, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await inventoryApi.saveProduct(product);
        if (result.success) {
            navigate(`/products/${product.id}`, { state: product });
        }
    };
    const handleCancel = () => {
        navigate("/products/" + product.id, { state: product });
    };

  return (
    <Container className="view-form">
            <Row>
                <Col></Col>
                <Col lg={8}>
                    <Form className="mt-3" onSubmit={handleSubmit}>
                        <Row className="view-form-header align-items-center">
                            <Col lg={3}>
                                Edit Lead
                                <h4>Aslam Bari</h4>
                            </Col>
                            <Col lg={9} className="d-flex justify-content-end">
                                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                                    Save
                                </Button>
                                <Button
                                    className="btn-sm"
                                    variant="danger"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-between">
                                <Form.Group className="mx-3" controlId="productName">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="productName"
                                    >
                                       Product Name
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={product.name}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mx-3" controlId="nameId">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="nameId"
                                    >
                                        Product Code
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="productcode"
                                        placeholder="Enter firstname"
                                        value={product.productcode}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mx-3" controlId="productCategory">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="productCategory"
                                    >
                                       Category
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="category"
                                        placeholder="Product Category"
                                        value={product.category}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mx-3" controlId="formBasicEmail">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicEmail"
                                    >
                                        Product SubCategory
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="subcategory"
                                        placeholder="Enter Category "
                                        value={product.subcategory}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mx-3" controlId="formBasicPhone">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicPhone"
                                    >
                                        Description
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="description"
                                        placeholder="Enter phone"
                                        value={product.description}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="targetDate">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="targetDate"
                                    >
                                    Target Date
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="targetdate"
                                        placeholder="Enter title"
                                        value={product.targetdate}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            
                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicStreet">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicStreet"
                                    >
                                       Created Date
                                    </Form.Label>
                                    <Form.Control
                                        type="Date"
                                        name="company"
                                        placeholder="Enter company"
                                        value={product.createddate }
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                     </Row>                    
                    </Form>
                </Col>
                <Col></Col>
            </Row>
        </Container>

  )
}

export default ProductEdit
