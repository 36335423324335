import inventoryApi from "../api/inventoryApi";
import ToastMessage from "./ToastMessage";

//************************ Rooms ***********************************//
const fetchRooms = () => async (dispatch) => {
    const rooms = await inventoryApi.fetchRooms();
    dispatch({ type: "GET_ROOMS", payload: rooms })
};

const createRooms = (event) => async (dispatch) => {
    const result = await inventoryApi.createRoom(event);
    //.log("res in api --> ",result)
    dispatch({ type: "INSERT_ROOMS", payload: result.data })
    if (result) {
        if (result.success) {
            ToastMessage("Room has been created successfully !", "success");
            return result;
        } else {
            ToastMessage(result.errors, "error");
        }
      }
};

const updateRoom = (room) => async (dispatch) => {
    //.log("update res in api --> ",room)
    const result = await inventoryApi.saveRoom(room);
    //.log("update res in api --> ",result)
    //.log(result);
    if (result.success) {
        dispatch({ type: "UPDATE_ROOM", payload:{} })
        ToastMessage("Room has been updated successfully !", "success");
        return result;
    } else if (result.errors) {
        ToastMessage(result.errors,  "error");
    }
};

const getRoomById = (room) => async (dispatch) => {
    //.log("get room")
    const result = await inventoryApi.fetchRoom(room.id);
    //.log(result);
    if (result.success) {
        dispatch({ type: "UPDATE_ROOM", payload: result.data[0] })
        return result.data;
    } else if (result.errors) {
        ToastMessage(result.errors, "error");
    }
};

const deleRoom = (id) => async (dispatch) => {
    const result = await inventoryApi.deleteRoom(id);
    //.log("del res --> ",result)
    if (result.success) {
        ToastMessage(result.message,  "success");
        return result;
    } else if (result.errors) {
        ToastMessage(result.errors, "error");
    }
};

//************************ Bookings ***********************************//
const fetchBookings = () => async (dispatch) => {
    const bookings = await inventoryApi.fetchBookings();
    //.log("booking inside redux api", bookings)
    dispatch({ type: "GET_BOOKINGS", payload: bookings })
};

const createBooking = (event) => async (dispatch) => {
    //.log("create booking event in apis --> ",event)
    const result = await inventoryApi.createBooking(event);
    //.log("create booking res in apis --> ",result)
    dispatch({ type: "INSERT_BOOKING", payload: result.data })
    if (result) {
        if (result.success) {
            ToastMessage("Booking has been created successfully!", "success");
            return result;
        } else {
            ToastMessage(result.errors, "error");
        }
      }
};

const updateBooking = (booking) => async (dispatch) => {
    //.log("update booking before in api --> ",booking)
    const result = await inventoryApi.saveBooking(booking);
    //.log("update booking res in api --> ",result)
    //.log(result);
    if (result.success) {
        dispatch({ type: "UPDATE_BOOKING", payload:{} })
        ToastMessage("Booking has been updated successfully!", "success");
        return result;
    } else if (result.errors) {
        ToastMessage(result.errors,  "error");
    }
};

const getBookingById = (booking) => async (dispatch) => {
    //.log("get booking inside apis before --> ",booking);

    const result = await inventoryApi.fetchBooking(booking.id);
    //.log("get booking inside apis --> ",result);
    if (result.success) {
        dispatch({ type: "UPDATE_BOOKING", payload: result.data })
        return result.data;
    } else if (result.errors) {
        ToastMessage(result.errors, "error");
    }
};

//************************ Contacts ***********************************//

const fetchContacts = () => async (dispatch) => {
    const contacts = await inventoryApi.fetchContacts();
    //.log("inside fetchContacts",contacts);
    dispatch({ type: "GET_CONTACTS", payload: contacts })
};

const createContacts = (event) => async (dispatch) => {
    const result = await inventoryApi.createContact(event);
    //.log("res in api --> ",result)
    dispatch({ type: "INSERT_CONTACTS", payload: event })
    if (result) {
        if (result.success) {
            ToastMessage("Contact has been created successfully !", "success");
            return result;
        } else {
            ToastMessage(result.errors, "error");
        }
      }
};

const updateContact = (contact) => async (dispatch) => {
    //.log("update res in api --> ",contact)
    const result = await inventoryApi.saveContact(contact);
    //.log("update res in api --> ",result)
    //.log(result);
    if (result.success) {
        dispatch({ type: "UPDATE_CONTACTS", payload:{} })
        ToastMessage("Contact has been updated successfully !", "success");
        return result;
    } else if (result.errors) {
        ToastMessage(result.errors, "error");
    }
};

const getContactById = (contacts) => async (dispatch) => {
    const result = await inventoryApi.fetchContact(contacts.id);
    //.log(result);
    if (result.success) {
        dispatch({ type: "UPDATE_CONTACT", payload: result.data[0] })
        return result.data;
    } else if (result.errors) {
        ToastMessage(result.errors, "error");
    }
};

const deleContact = (id) => async (dispatch) => {
    const result = await inventoryApi.deleteContact(id);
    //.log("del res --> ",result)
    if (result.success) {
        ToastMessage(result.message, "success");
        return result;
    } else if (result.errors) {
        ToastMessage(result.errors, "error");
    }
};
//************************ User ***********************************//
const getUser = () => async (dispatch) => {
    const users = await inventoryApi.fetchUsers();
    //.log("inside fetchUser",users);
    dispatch({ type: "GET_USER", payload: users })
};


const createUser = (event) => async (dispatch) => {
    //.log('user in api',event);
    const result = await inventoryApi.createUser(event);
    //.log("res in api --> ",result)
    dispatch({ type: "INSERT_USER", payload: event })
    if (result) {
        if (result.success) {
            ToastMessage("User has been created successfully !", "success");
            return result;
        } else {
            ToastMessage(result.errors, "error");
        }
      }
};

const updateUser = (user) => async (dispatch) => {
    const result = await inventoryApi.updateUser(user);
    //.log("update res in api --> ",result)
    //.log(result);
    if (result.success) {
        dispatch({ type: "GET_PROFILE", payload:{} })
        //ToastMessage("User has been updated successfully !", "success");
        return result;
    } else if (result.errors) {
        ToastMessage(result.errors, "error");
    }
};

const getUserById = (user) => async (dispatch) => {
    const result = await inventoryApi.fetchUser(user.id);
    //.log(result);
    if (result.success) {
        dispatch({ type: "UPDATE_USER", payload: result.data[0] })
        return result.data;
    } else if (result.errors) {
        ToastMessage(result.errors, "error");
    }
};

//************************ User ***********************************//
const getProfileData = (data) => async (dispatch) => {
    const profile = await inventoryApi.getLoginUserData(data);
    //.log("inside fetchProfile in api",profile);
    dispatch({ type: "GET_PROFILE", payload: profile })
};

//************************ Category ***********************************//
const getCategory = () => async (dispatch) => {
    const category = await inventoryApi.fetchCategory();
    //.log("inside fetchCategory",category);
    dispatch({ type: "GET_CATEGORY", payload: category })
};

const insertCategory = (event) => async (dispatch) => {
    const result = await inventoryApi.createCategory(event);
    //.log("res in api --> ",result)
    dispatch({ type: "INSERT_CONTACTS", payload: event })
    if (result) {
        if (result.success) {
            ToastMessage("Category has been created successfully !", "success");
            return result;
        } else {
            ToastMessage(result.errors, "error");
        }
      }
};

const updateCategory = (category) => async (dispatch) => {
    //.log("update res in api --> ",category.id)
    const result = await inventoryApi.saveCategory(category);
    //.log("update res in api --> ",result)
    //.log(result);
    if (result.success) {
        dispatch({ type: "UPDATE_CATEGORY", payload:{} })
        ToastMessage("Category has been updated successfully !", "success");
        return result;
    } else if (result.errors) {
        ToastMessage(result.errors, "error");
    }
};

const getCategoryId = (category) => async (dispatch) => {
    const result = await inventoryApi.fetchCategoryId(category.id);
    //.log(result);
    if (result.success) {
        dispatch({ type: "UPDATE_CATEGORY", payload: result.data[0] })
        return result.data;
    } else if (result.errors) {
        ToastMessage(result.errors, "error");
    }
};

const deleteCategory = (id) => async (dispatch) => {
    const result = await inventoryApi.deleteCategory(id);
    //.log("del res --> ",result)
    if (result.success) {
        ToastMessage(result.message, "success");
        return result;
    } else if (result.errors) {
        ToastMessage(result.errors, "error");
    }
};
export {
    fetchRooms,
    getRoomById,
    createRooms,
    updateRoom,
    deleRoom,
    fetchBookings,
    createBooking,
    updateBooking,
    getBookingById,
    fetchContacts,
    createContacts,
    updateContact,
    getContactById,
    deleContact,
    getUser,
    createUser,
    updateUser,
    getUserById,
    getProfileData,
    getCategory,
    insertCategory,
    updateCategory,
    getCategoryId,
    deleteCategory
}
