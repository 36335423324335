import React, { useState, useEffect, Component, PropTypes } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../../api/inventoryApi";
import Table from "react-bootstrap/Table";
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";
import { useSelector, useDispatch } from "react-redux";
import { getRequest } from "../../api/inventoryApiTwo";
import { PATH , TYPE } from "../../api/pathUrl";

const Summary = (props) => {

  const location = useLocation();
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [expense, setExpense] = useState([]);
  const [booking, setBooking] = useState(location.state ? location.state : {});
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [expensePaymentAmount, setExpensePaymentAmount] = useState(0);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [modalShowTaskfile, setModalShowFile] = useState(false);
  const [modalShowExpense, setModalShowExpense] = useState(false);
  const [modalShowPayment, setModalShowPayment] = useState(false);
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [refreshFileList, setRefreshFileList] = useState();
  const [refreshPaymentList, setRefreshPaymentList] = useState();
  const [refreshExpenseList, setRefreshExpenseList] = useState();
  const [showSummary, setShowSummary] = useState(false);

  const { bookingDetail , expensesList , paymentsList } = useSelector((state) => state.apiReducer);

  // useEffect(() => {
  //   fetchBooking();
  //   //
  // }, []);

  // useEffect(() => {
  //   paymentList();
  // }, [refreshPaymentList]);

  const paymentList = () => {
    async function init() {
      let payment = await inventoryApi.fetchPayment(booking.id);
      //.log("payemt", payment);
      if (payment && payment?.length > 0) {
        var paidAmount = 0;
        payment.forEach((element) => {
          //.log("element.price", element.amount);
          paidAmount += parseFloat(element.amount);
        });
        setPaymentAmount(paidAmount);
        //.log("paymentAmount", paymentAmount);
      } else {
        setPaymentAmount();
      }
    }
    init();
  };
  // useEffect(() => {
  //   expenseList();
  // }, [refreshExpenseList]);

  const expenseList = () => {
    async function init() {
      let expense = await inventoryApi.fetchExpenseBasedParent(booking.id);
      //.log("expense", expense);
      if (expense && expense?.length > 0) {
        setExpense(expense);
        var paidAmount = 0;
        expense.forEach((element) => {
          //.log("element.price", element.amount);
          paidAmount += parseFloat(element.amount);
        });
        setExpensePaymentAmount(paidAmount);
      } else {
        setExpense([]);
      }
    }
    init();
  };

  const fetchBooking = () => {
    ////.log('proepties ', booking.id);
    async function initBooking() {
      let result = await inventoryApi.fetchBooking(booking.id);
      //.log("result:", result);
      if (result) {
        if (result.id !== null) {
          result.checkindate = moment(result.checkindate).format("YYYY-MM-DD");
          result.checkoutdate = moment(result.checkoutdate).format(
            "YYYY-MM-DD"
          );
        }
        setBooking(result);
      } else {
        setBooking({});
      }
    }
    initBooking();
  };

  useEffect(() => {
    if (Object.keys(bookingDetail).length > 0) {

      let result = bookingDetail
      // if (result.id !== null) {
      result.checkindate = moment(result.checkindate).format("YYYY-MM-DD");
      result.checkoutdate = moment(result.checkoutdate).format("YYYY-MM-DD");
      setBooking(result);

    }
  }, [bookingDetail]);

  useEffect(() => {

    if (paymentsList.length > 0) {
      let paidAmount = 0;
      paymentsList.forEach((element) => {
        //.log("element.price", element.amount);
        paidAmount += parseFloat(element.amount);
      });
      setPaymentAmount(paidAmount);
      //.log("paymentAmount", paymentAmount);
    }

    if (expensesList.length > 0) {
      setExpense(expense);
      let paidAmount = 0;
      expense.forEach((element) => {
        //.log("element.price", element.amount);
        paidAmount += parseFloat(element.amount);
      });
      setExpensePaymentAmount(paidAmount);
    }

  }, [ paymentsList, expensesList ]);


  useEffect(() => {

    dispatch(getRequest(`${PATH.bookingPath}${booking.id}`, TYPE.SET_BOOKING));
  }, []);

  useEffect(() => {

    dispatch(getRequest(`${PATH.paymentPath}${booking.id}`, TYPE.GET_ALL_PAYMENTS));
  }, [refreshPaymentList]);


  useEffect(() => {

    dispatch(getRequest(`${PATH.expensePath}${booking.id}`, TYPE.GET_ALL_EXPENSES));
  }, [refreshExpenseList]);




  const handleCheckout = () => {
    navigate(`/document/${booking.id}`, { state: booking });
  };

  return (
    <>
      {booking && (
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <b className="p-3">Bill Summary</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="view-form">
              <>
                <Table responsive="sm">
                  <thead>
                    <tr>
                      <th>Description </th>
                      <th>No of days</th>
                      <th>Per Day</th>
                      <th></th>
                      <th>Charges</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Room Charges</td>
                      <td>{booking.noofdays}</td>
                      <td>
                        {getSymbolFromCurrency("INR")}
                        {booking.totalamount / booking.noofdays}
                      </td>
                      <td></td>
                      <td>
                        {getSymbolFromCurrency("INR")}
                        {booking.totalamount}{" "}
                      </td>
                    </tr>

                    {expense &&
                      expense.map((data) => {
                        return (
                          <tr>
                            <td>{data.type}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              {getSymbolFromCurrency("INR")}
                              {data.amount}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <Row>
                  <Col lg={4}></Col>
                  <Col>
                    <Row>
                      <Col>Sub Total :</Col>
                      <Col>
                        {getSymbolFromCurrency("INR")}
                        {booking.totalamount + booking.expense_amount}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>Discount:</Col>
                      <Col>
                        {getSymbolFromCurrency("INR")}
                        {(
                          (booking.totalamount * booking.discount_percentage) /
                          100
                        ).toFixed(2)}
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>GST (18%) :</Col>
                      <Col>
                        {getSymbolFromCurrency("INR")}{" "}
                        {(
                          ((expensePaymentAmount +
                            parseFloat(booking.totalamount)) *
                            18) /
                          100
                        ).toFixed(2)}
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row className="mt-2">
                      <Col>
                        <b>Total</b>
                      </Col>
                      <Col>
                        <b>
                          {getSymbolFromCurrency("INR")}
                          {booking.totalamount_with_gst }
                        </b>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            {booking.pending_amount ===
            '0.00'? (
              <>
                
              </>
            ) : (
              <>
                <Col>
                  Pending amount: {getSymbolFromCurrency("INR")}
                  {booking.pending_amount}
                </Col>
                <Col lg={7}>
                  <Button
                    className="btn-sm"
                    onClick={() => props.ShowPayment()}
                    // onClick={printDocument()}
                  >
                    Pay
                  </Button>
                </Col>
              </>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default Summary;
