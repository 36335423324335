import React, { useEffect, useState } from "react";
import inventoryApi from "../../api/inventoryApi";
import { useNavigate, Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import moment from "moment";
import { Col, Row, Container } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import { getRequest } from "../../api/inventoryApiTwo";
import { PATH , TYPE } from "../../api/pathUrl";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";
import LineChart from "./LineChart";

const BarChart = () => {

  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [room, setRoom] = useState();
  const [bookings, setBookings] = useState();
  const [availRoomData, setAvailRoomData] = useState();
  const [bookedRoomData, setBookedRoomData] = useState();

  const { roomList , bookingList } = useSelector((state) => state.apiReducer);

  // useEffect(() => {

  //   async function init() {
  //     let result = await inventoryApi.fetchRooms();
  //     if (result) {
  //       setRoom(result);
  //       setAvailRoomData([result?.filter((data) => data.status === "Available").length]);
  //       setBookedRoomData([result?.filter((data) => data.status === "Not Available").length]);
  //     } else {
  //       setRoom([]);
  //     }
  //   }
  //   async function init1() {
  //     const result = await inventoryApi.fetchBookings();
  //     //.log("result:", result);
  //     //if(result && result.errors && result.errors)
  //     if (result) {
  //       //setBody(result);
  //       setBookings(result);
  //     }
  //   }
  //   init();
  //   init1();
  // }, []);

  useEffect(() => {

      dispatch(getRequest(PATH.bookingPath, TYPE.GET_ALL_BOOKINGS));
      dispatch(getRequest(PATH.roomPath, TYPE.GET_ALL_ROOMS));
    }, []);

  useEffect(() => {

    if (bookingList.length > 0) {
      setBookings(bookingList);
    }

    if (roomList.length > 0) {
      setRoom(roomList);
      setAvailRoomData([roomList?.filter((data) => data.status === "Available").length]);
      setBookedRoomData([roomList?.filter((data) => data.status === "Not Available").length]);
    }
  }, [bookingList, roomList]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    scales: {
      x: {
        grid: {
          offset: true,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Room Detail",
      },
    },
  };

  const labels = ["Rooms"];

  const data = {
    labels,
    datasets: [
      {
        label: "Available Rooms",
        data: availRoomData,
        backgroundColor: "Green",
      },
      {
        label: "Booked Rooms",
        data: bookedRoomData,
        backgroundColor: "Red",
      },
    ],
  };
  return (
    <Container>
      <Col lg={4} className="mx-2">
        <CustomSeparator
          currentCmpName="Dashboard"
          indexLength="0"
          url="/"
        ></CustomSeparator>
      </Col>
      <br></br>
      <Row className="justify-content-between">
        <Col xs={6} sm={6} lg={3}>
          <Link to="/rooms" className="text-decoration-none text-reset">
            <div
              className="p-3 d-flex align-items-center "
              style={{
                backgroundColor: "white",
                borderLeft: "4px solid #849ad8",
              }}
            >
              <span class="fa-stack fa-2x mt-1">
                <i
                  class="fa-solid fa-circle fa-stack-2x"
                  style={{ color: "#447c85" }}
                ></i>
                <i
                  class="fa-solid fa-door-open fa-stack-1x"
                  style={{ color: "white", fontSize: "2rem" }}
                ></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">Available Rooms</h6>
                <h1 className="mb-0 d-inline ">
                  {room &&
                    room.filter((data) => data.status === "Available").length}
                  {!room && 0}
                </h1>
              </div>
            </div>
          </Link>
        </Col>
        <Col xs={6} sm={6} lg={3}>
          <Link to="/rooms" className="text-decoration-none text-reset">
            <div
              className="p-3 d-flex align-items-center "
              style={{
                backgroundColor: "white",
                borderLeft: "4px solid #85d884",
              }}
            >
              <span class="fa-stack fa-2x mt-1">
                <i
                  class="fa-solid fa-circle fa-stack-2x"
                  style={{ color: "#FF7D7D" }}
                ></i>
                <i
                  class="fa-solid fa-door-closed fa-stack-1x"
                  style={{ color: "white", fontSize: "2rem" }}
                ></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1 ">Booked Rooms</h6>
                <h1 className="mb-0 d-inline ">
                  {" "}
                  {room &&
                    room.filter((data) => data.status === "Not Available")
                      .length}
                  {!room && 0}
                </h1>
              </div>
            </div>
          </Link>
        </Col>
        <Col xs={6} sm={6} lg={3}>
          <Link to="/bookings" className="text-decoration-none text-reset">
            <div
              className="p-3 d-flex align-items-center "
              style={{
                backgroundColor: "white",
                borderLeft: "4px solid #5d975c",
              }}
            >
              <span class="fa-stack fa-2x mt-1">
                <i
                  class="fa-solid fa-circle fa-stack-2x"
                  style={{ color: "rgb(247, 152, 28)" }}
                ></i>
                <i
                  class="fa-solid far fa-calendar-check fa-stack-1x"
                  style={{ color: "white", fontSize: "2rem" }}
                ></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">Check Out Today</h6>
                <h1 className="mb-0 d-inline ">
                  {bookings &&
                    bookings.filter(
                      (data) =>
                        moment(data.checkoutdate).format("DD/MM/YYYY") ===
                          moment(new Date()).format("DD/MM/YYYY") &&
                        data.status === "History"
                    ).length}
                  {!bookings && 0}
                </h1>
              </div>
            </div>
          </Link>
        </Col>

        <Col xs={6} sm={6} lg={3}>
          <Link to="/bookings" className="text-decoration-none text-reset">
            <div
              className="p-3 d-flex align-items-center "
              style={{
                backgroundColor: "white",
                borderLeft: "4px solid #5c9795",
              }}
            >
              <span class="fa-stack fa-2x mt-1">
                <i
                  class="fa-solid fa-circle fa-stack-2x"
                  style={{ color: "#636262" }}
                ></i>
                <i
                  class="fa-solid fa-calendar-days fa-stack-1x"
                  style={{ color: "white", fontSize: "2rem" }}
                ></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">Check In Today</h6>
                <h1 className="mb-0 d-inline ">
                  {bookings &&
                    bookings.filter(
                      (data) =>
                        moment(data.checkindate).format("DD/MM/YYYY") ===
                        moment(new Date()).format("DD/MM/YYYY")
                    ).length}
                  {!bookings && 0}
                </h1>{" "}
              </div>
            </div>
          </Link>
        </Col>
      </Row>

      
        <Row className="mt-4 justify-content-between">
          <Col lg={6}>
            <Card style={{ height: "30rem" }}>
              <Card.Header style={{ backgroundColor: "white", color: "black" }}>
                <b>
                  {" "}
                  <center>Daily Report</center>
                </b>
              </Card.Header>
              <Card.Body>
                <Bar options={options} data={data} />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
          <Card style={{ height: "30rem" }}>
              <Card.Header style={{ backgroundColor: "white", color: "black" }}>
                <b>
                  {" "}
                  <center>Booking Report By Month</center>
                </b>
              </Card.Header>
              <Card.Body>
               <LineChart/>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4 justify-content-between">
          <Col lg={6}>
          <Card style={{ height: "15rem" }}>
              <Card.Header style={{ backgroundColor: "white", color: "black" }}>
                <b>
                  <center>Quick Actions</center>
                </b>
              </Card.Header>
              <Card.Body>
                <Row className="mb-4 row align-items-end justify-content-between">
                  <Col lg={6}>
                    <center>
                      <button
                        type="button"
                        className="btn"
                        style={{
                          backgroundColor: "#DDE279",
                          width: "15rem",
                          color: "black",
                        }}
                        onClick={() => navigate(`/room/e`)}
                      >
                        <i className="fa-solid fa-door-closed"></i> New Room
                      </button>
                    </center>
                  </Col>

                  <Col lg={6}>
                    <center>
                      <button
                        type="button"
                        className="mt-2 btn"
                        style={{
                          backgroundColor: "#DDE279",
                          width: "15rem",
                          color: "black",
                        }}
                        onClick={() => navigate(`/booking/e`)}
                      >
                        <i className="fa-solid fa-book"></i>&nbsp; New Booking
                      </button>
                    </center>
                  </Col>

                  <Col lg={6} className="mt-3">
                    <center>
                      <button
                        type="button"
                        className="mt-2 btn"
                        style={{
                          backgroundColor: "#DDE279",
                          width: "15rem",
                          color: "black",
                        }}
                        onClick={() => navigate(`/contacts/e`)}
                      >
                        <i className="fa-solid fa-address-book"></i>&nbsp; New
                        Contact
                      </button>
                    </center>
                  </Col>

                  <Col lg={6} className="mt-3">
                    <center>
                      <button
                        type="button"
                        className="mt-2 btn"
                        style={{
                          backgroundColor: "#DDE279",
                          width: "15rem",
                          color: "black",
                        }}
                        onClick={() => navigate(`/AdvanceBooking/e`)}
                      >
                        <i className="fa-solid fa-book-bookmark"></i>&nbsp; New
                        Advance Booking
                      </button>
                    </center>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
           
          </Col>
        </Row>
        
      
    </Container>
  );
};

export default BarChart;
