import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import moment from "moment";
import Confirm from "../Confirm";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { PATH, TYPE } from "../../api/pathUrl";
import { getRequest, deleteRequest } from "../../api/inventoryApiTwo";
import { TaskEdit, TaskView } from "../index";

const RelatedListTask = ({ parent, refreshTaskList }) => {
  const location = useLocation();
  let dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);
  const [task, setTask] = React.useState("");
  const [modalShowTask, setModalShowTask] = React.useState(false);
  const [modalShowTaskView, setModalShowTaskView] = React.useState(false);

  const { tasksList, resultHandle } = useSelector((state) => state.apiReducer);

  const [body, setBody] = useState([]);

  useEffect(() => {
    if (resultHandle.isModal) {
      taskList();
      setModalShow(false);
      dispatch({ type: "SET_RESULT_HANDLE", payload: {} });
    }
  }, [resultHandle]);

  useEffect(() => {
    taskList();
  }, [refreshTaskList]);

  useEffect(() => {
    if (tasksList.length > 0) {
      setBody(tasksList);
    } else {
      setBody([]);
    }
  }, [tasksList]);

  const taskList = () => {
    async function init() {
      dispatch(
        getRequest(
          `${PATH.taskPath}${location.pathname.split("/")[2]}/*`,
          TYPE.GET_ALL_TASKS
        )
      );
    }
    init();
  };

  const deleteTask = async () => {
    dispatch(deleteRequest(PATH.taskPath, undefined, task.id, true));
  };

  const submitTasks = () => {
    setModalShowTask(false);
    taskList();
  };

  const header = [
    {
      title: "Title",
      prop: "title",
      cell: (row) => (
        <Link
          onClick={() => {
            setModalShowTaskView(true);
            setTask({ row });
          }}
          state={parent}
        >
          {row.title}
        </Link>
      ),
    },
    { title: "Description", prop: "description" },
    { title: "Status", prop: "status" },
    {
      title: "Target Date",
      prop: "targetdate",
      cell: (row) => moment(row.targetdate).format("DD-MM-YYYY"),
    },
    {
      title: "Created Date",
      prop: "createddate",
      cell: (row) => moment(row.createddate).format("DD-MM-YYYY"),
    },
    { title: "Created By", prop: "createdbyname" },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <>
          <Button
            className="btn-sm mx-2"
            onClick={() => {
              setModalShowTask(true);
              setTask({ row });
            }}
            title="Edit"
          >
            <i className="fa-regular fa-pen-to-square"></i>
          </Button>
          <Button
            className="btn-sm mx-2"
            variant="danger"
            onClick={() => {
              setModalShow(true);
              setTask(row);
            }}
            title="Delete"
          >
            <i class="fa-regular fa-trash-can"></i>
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      {modalShow && (
        <Confirm
          show={modalShow}
          onHide={() => setModalShow(false)}
          deleteTask={deleteTask}
          title="Confirm delete?"
          message="You are going to delete the task. Are you sure?"
          table="task"
        />
      )}
      {modalShowTask && (
        <TaskEdit
          show={modalShowTask}
          onHide={() => setModalShowTask(false)}
          parentid={parent.id}
          task={task}
          table="lead"
          submitTasks={submitTasks}
        />
      )}

      {modalShowTaskView && (
        <TaskView
          show={modalShowTaskView}
          onHide={() => setModalShowTaskView(false)}
          parentid={parent.id}
          task={task}
          table="lead"
          submitTasks={submitTasks}
        />
      )}

      {body ? (
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 5,
              options: [5, 10, 15, 20],
            },
          }}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            ></Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            ></Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            ></Col>
          </Row>
          <Table striped className="related-list-table">
            <TableHeader />
            <TableBody />
          </Table>
          {body && body.length > 0 ? <Pagination /> : ""}
        </DatatableWrapper>
      ) : (
        ""
      )}
    </>
  );
};

export default RelatedListTask;
