import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { schemaEditProfile } from "../ValidateSchemaHelper";
import inventoryApi from "../../api/inventoryApi";
import jwt_decode from "jwt-decode";
import ToastMessage from "../ToastMessage";
import * as formik from "formik";
//import ViewProfile from "./ViewProfile";
import { profileInitialValues } from "../InitialValuesHelper";
import { getProfileData, updateUser } from "../APIs";
import { PATH, TYPE } from "../../api/pathUrl";
import {
  getRequest,
  putRequest,
  getDownloadRequest,
} from "../../api/inventoryApiTwo";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ChangePasswordProfile } from "../index";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";

const ViewProfile = (props) => {
  const { profileData, resultHandle } = useSelector(
    (state) => state.apiReducer
  );
  let dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const fileInputRef = useRef();
  const decoded = localStorage.getItem("token")
    ? jwt_decode(localStorage.getItem("token"))
    : "";
  const [profile, setProfile] = useState([]);
  const [body, setBody] = useState();
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [isView, setIsView] = useState(false);
  const [isProfileUpdate, setIsProfileUpdate] = useState(false);
  const [modalShowChangePassword, setmodalShowChangePassword] = useState(false);

  // useEffect(() => {
  //   if (resultHandle.success) {
  //     setIsView(!isView);

  //     if (isProfileUpdate) {
  //       setIsProfileUpdate(!isProfileUpdate);
  //       setProfile(resultHandle.data);
  //     }
  //     else {
  //       downloadProfileImg();
  //     }

  //     dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })
  //   }
  // }, [resultHandle])

  useEffect(() => {
    // if (Object.keys(profileData).length > 0) {
    // setProfile();
    setProfile(profileData);
    // }
  }, [profileData]);

  useEffect(() => {
    async function init() {
      // //.log("profile data before --> ",profile)
      // // try {
      //   if(!profileData?.id)
      // dispatch({ type: TYPE.SET_PROFILE, payload: {} });
      dispatch(
        getRequest(`${PATH.userPath}getuser/${decoded?.id}`, TYPE.SET_PROFILE)
      );

      downloadProfileImg();
      // dispatch(getProfileData(decoded?.id));
      //  else {
      //   //.log('profileDetail in edit 38',profileData)
      //   setProfile(profileData);
      //  }
      // } catch (error) {
      // }
      // let result1 = await getDownloadRequest(`${PATH.userPath}/myimage`);
      // if (result1) {
      //   setBody(URL.createObjectURL(result1));
      // }
      // let result1 = await inventoryApi.fetchMyImage();
      // if (result1) {
      //   setBody(URL.createObjectURL(result1));
      // }
    }

    init();
  }, [isView]);

  const downloadProfileImg = async () => {
    let result1 = await getDownloadRequest(`${PATH.userPath}myimage`);
    if (result1) {
      setBody(URL.createObjectURL(result1));
    }
  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    const imageRegex = /\.(gif|jpe?g|png|webp|bmp)$/i;
    if (!file) return;
    if (!imageRegex.test(file?.name)) {
      // Show an error message
      ToastMessage(
        "Invalid file format. Please upload an image file.",
        "error"
      );
      return;
    }
    setBody(URL.createObjectURL(file));
    setSelectedFiles(file);
  };

  const editprofile = () => {
    navigate(`/profiles/${profile.id}/e`, { state: profile });
  };

  const { Formik } = formik;
  return (
    <div>
      <Container>
        <Col lg={4} className="px-4">
          <CustomSeparator
            currentCmpName="My Profile"
            indexLength="0"
            url="/"
          ></CustomSeparator>
        </Col>
        <br></br>
        <Row className="view-form ">
          <Col lg={1}></Col>
          <Col lg={3}>
            <Card>
              <Card.Body className="text-center">
                <Card.Title style={{ textAlign: "center" }}>
                  {profile.firstname} {profile.lastname}
                </Card.Title>
                {body && (
                  <Image
                    variant="top"
                    src={body}
                    className="rounded-circle"
                    thumbnail
                    style={{ height: "200px", maxWidth: "200px" }}
                  />
                )}
                <br />
                {/* <Button
                  className="btn my-3 "
                  variant="secondary"
                  style={{ width: "100%", display: "block" }}
                  onClick={() => fileInputRef.current.click()}
                  disabled={true}
                >
                  Image Upload
                </Button>
                <Button
                  className="btn"
                  style={{ width: "100%", display: "block" }}
                  variant="danger"
                  onClick={() => { setmodalShowChangePassword(!modalShowChangePassword) }}
                  disabled={true}
                >
                  Change Password
                </Button> */}
                <input
                  onChange={handlePhotoUpload}
                  name="profilephotourl"
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  hidden
                />
              </Card.Body>
            </Card>
          </Col>

          <Col style={{ backgroundColor: "#fff" }} lg={7}>
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                <h4>Profile </h4>
                <h4>
                  {profile.firstname + " "}
                  {profile.lastname}
                </h4>
              </Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button
                  className="btn-sm mx-2"
                  onClick={() => editprofile(true)}
                  title="Edit Profile"
                >
                  <i className="fa-regular fa-pen-to-square"></i>
                </Button>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <label>First Name</label>
                <span>{profile.firstname}</span>
              </Col>
              <Col lg={6}>
                <label>Last Name</label>
                <span>{profile.lastname}</span>
              </Col>
              <Col lg={6} className="pb-4">
                <label>Email</label>
                <span>{profile.email}</span>
              </Col>
              <Col lg={6} className="pb-4">
                <label>Phone</label>
                <span>{profile.phone}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Col lg={1}></Col>
      </Container>

      {modalShowChangePassword && (
        <ChangePasswordProfile
          show={modalShowChangePassword}
          profile={profile}
          onHide={() => setmodalShowChangePassword(!modalShowChangePassword)}
        />
      )}
    </div>
  );
};

export default ViewProfile;
