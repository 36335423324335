import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import Confirm from "../Confirm";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../../api/inventoryApi";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import { getRequest, deleteRequest } from "../../api/inventoryApiTwo";
import { PATH, TYPE } from "../../api/pathUrl";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";

const UserView = (props) => {
  const { userDetail, resultHandle } = useSelector((state) => state.apiReducer);
  let dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  //const user = location.state;
  //const [user, setUser] = useState(location.state ? location.state : {});
  const [body, setBody] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    fetchUser();
    //.log('user------------------>',userDetail)
    //   async function init(){
    //    //const users = await inventoryApi.fetchUsers();
    //    if (!userDetail.id && Object.keys(userDetail).length === 0){
    //       dispatch(getRequest(PATH.userPath, TYPE.SET_USER));
    //   }
    //  }
    //  init();
  }, []);

  useEffect(() => {
    if (resultHandle.success && !resultHandle.isModal) {
      navigate(`/users`);
      dispatch({ type: "SET_USER", payload: {} });
      dispatch({ type: "SET_RESULT_HANDLE", payload: {} });
    }
  }, [resultHandle]);

  const editUser = () => {
    navigate(`/users/${userDetail.id}/e`, { state: userDetail });
  };
  const fetchUser = () => {
    async function initUser() {
      //.log('userDetail',userDetail)
      if (!userDetail.id && Object.keys(userDetail).length === 0) {
        dispatch(
          getRequest(
            PATH.userPath + "getuser/" + location.pathname.split("/")[2],
            TYPE.SET_USER
          )
        );
      }
    }
    initUser();
  };
  return (
    <div>

      <Col lg={4} className="px-4">
        <CustomSeparator
          cmpListName="Users"
          currentCmpName={userDetail.username}
          indexLength="2"
          url="/users"
        ></CustomSeparator>
      </Col>
      <br></br>

      {userDetail.id && (
        <Container>
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              // deleteRoom={deleteRoom}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="Room"
            />
          )}
          <Row className="view-form ">
            <Col></Col>
            <Col lg={8} style={{ backgroundColor: "#fff" }} className="pb-3">
              <Row className="view-form-header align-items-center">
                <Col lg={3}>
                  User
                  <h4>{userDetail.username}</h4>
                </Col>
                <Col lg={9} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editUser(true)}
                    title="Edit"
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <label>Name</label>
                  <span>
                    {userDetail.firstname + " "}
                    {userDetail.lastname}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Email</label>
                  <span>{userDetail.email}</span>
                </Col>
                <Col lg={6}>
                  <label>Roles</label>
                  <span>{userDetail.rolename}</span>
                </Col>
                <Col lg={6}>
                  <label>Hotel Name</label>
                  <span>{userDetail.hotel_name}</span>
                </Col>
                <Col lg={6}>
                  <label>Phone</label>
                  <span>{userDetail.phone}</span>
                </Col>
                <Col lg={6}>
                  <label>Active</label>
                  <span>{userDetail.isactive ? "Yes" : "No"}</span>
                </Col>
                {/* <Col>
              <Form>
              <Form.Check 
                disabled
                type="switch"
                id="custom-switch"
                label="is Active"
                checked={user.isactive}
                style={{"fontSize":"16px",'marginLeft': '25px'}}
              />
      
              </Form>
      </Col> */}
              </Row>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default UserView;
