import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import { Button, Col, Row, Table } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import inventoryApi from "../../api/inventoryApi";
import moment from "moment";
import Confirm from "../Confirm";
import { PATH, TYPE } from "../../api/pathUrl";
import { getRequest } from "../../api/inventoryApiTwo";
import { useSelector, useDispatch } from "react-redux";
import { PaymentEdit } from "../index";

const RelatedListPayment = (props) => {
  const location = useLocation();
  let dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);
  const [payment, setPayment] = React.useState("");
  const [booking, setBooking] = React.useState(props.parent);
  const [body, setBody] = useState([]);
  const [modalShowPayment, setModalShowPayment] = React.useState(false);

  const { paymentsList, resultHandle } = useSelector(
    (state) => state.apiReducer
  );

  //.log("props.parent inside rel list pay --> ",props.parent)

  useEffect(() => {
    if (resultHandle.isModal) {
      paymentList();
      setModalShow(false);
      dispatch({ type: "SET_RESULT_HANDLE", payload: {} });
    }
  }, [resultHandle]);

  useEffect(() => {
    paymentList();
  }, [props.refreshPaymentList]);

  useEffect(() => {
    if (paymentsList.length > 0) {
      setBody(paymentsList);
    } else {
      setBody([]);
    }
  }, [paymentsList]);

  const paymentList = () => {
    //.log("props.parent.id --> ",props.parent.id)
    async function init() {
      dispatch(
        getRequest(
          `${PATH.paymentPath}${location.pathname.split("/")[2]}`,
          TYPE.GET_ALL_PAYMENTS
        )
      );

      // let payment = await inventoryApi.fetchPayment(location.pathname.split('/')[2]);
      // //.log("payemt", payment);
      // if (payment && payment.data?.length > 0) {
      //   setBody(payment.data);

      //   let booking = await inventoryApi.fetchBooking(payment.data[0].parentid);
      //   setBooking(booking);

      // } else {
      //   setBody([]);
      // }
    }
    init();
  };

  const handleDelete = (row) => {
    setModalShow(true);
    setPayment(row);
  };

  const labels = {
    beforeSelect: " ",
  };

  const deletePayment = async () => {
    const result = await inventoryApi.deletePayment(payment.id);
    if (result.success) {
      setPayment("");
      setModalShow(false);
      paymentList();
    }
  };

  const submitPayment = () => {
    paymentList();
    props.refreshPayment();
    setModalShowPayment(false);
  };

  const editPayment = (row) => {
    setModalShowPayment(true);
    setPayment(row);
  };

  const header = [
    {
      title: "Method",
      prop: "method",
      cell: (row) => (
        <Link onClick={() => editPayment({ row })} state={props.parent}>
          {row.method}
        </Link>
      ),
    },

    {
      title: "Amount",
      prop: "amount",
      cell: (row) => <>₹&nbsp;{row.amount}</>,
    },
    {
      title: "Date",
      prop: "date",
      cell: (row) => moment(row.date).format("DD-MM-YYYY"),
    },
    { title: "Payment Type", prop: "type" },
    { title: "Description", prop: "description" },
    {
      title: "Created Date",
      prop: "createddate",
      cell: (row) => moment(row.createddate).format("DD-MM-YYYY"),
    },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <>
          <Button
            disabled={booking.status === "History"}
            className="btn-sm mx-2"
            onClick={() => editPayment({ row })}
            title="Edit Payment"
          >
            <i className="fa-regular fa-pen-to-square"></i>
          </Button>
          {/* <Button
            className="btn-sm mx-2"
            variant="danger"
            onClick={() => handleDelete(row)}
          >
            <i class="fa-regular fa-trash-can"></i>
          </Button> */}
        </>
      ),
    },
  ];

  return (
    <>
      {modalShow && (
        <Confirm
          show={modalShow}
          onHide={() => setModalShow(false)}
          deletePayment={deletePayment}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="payment"
        />
      )}
      {modalShowPayment && (
        <PaymentEdit
          show={modalShowPayment}
          onHide={() => setModalShowPayment(false)}
          parentid={props.parent.id}
          payment={payment}
          table="lead"
          submitPayment={submitPayment}
        />
      )}

      <DatatableWrapper
        body={body}
        headers={header}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: 5,
          },
        }}
      >
        <Row className="mb-4">
          <Col
            xs={12}
            sm={6}
            lg={4}
            className="d-flex flex-col justify-content-start align-items-start"
          ></Col>
          <Col
            xs={12}
            sm={6}
            lg={4}
            className="d-flex flex-col justify-content-start align-items-start"
          ></Col>
          <Col
            xs={12}
            sm={6}
            lg={4}
            className="d-flex flex-col justify-content-end align-items-end"
          ></Col>
        </Row>
        <Table striped className="related-list-table">
          <TableHeader />
          <TableBody />
        </Table>
        {body && body.length > 0 ? <Pagination /> : ""}
      </DatatableWrapper>
    </>
  );
};

export default RelatedListPayment;
