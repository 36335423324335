import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table} from "react-bootstrap";
import {useNavigate} from 'react-router-dom'
import inventoryApi from "../../api/inventoryApi";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import { Link } from "react-router-dom";


const PropertyList = () => {

  
  const navigate = useNavigate();
  const [body, setBody] = useState();
  const [properties, setProperties] = useState();
  useEffect(() => {
    async function init() {
      const result = await inventoryApi.fetchProperties();
      ////.log('result:', result);
      //if(result && result.errors && result.errors) 
      if (result){
        setBody(result);
        setProperties(result);
       }
         
    }
    init();

  }, []);

  const onFilterType = (event) => {
     if (event.target.value === '') {
      setBody(properties);
     } else {
      setBody(
        properties.filter((data) => {
       if ((data.type || '').toLowerCase() === (event.target.value || '').toLowerCase()) {
     return data;
       }
      })
      );
     }
     };
    
    

  // Create table headers consisting of 4 columns.
  const header = [
    {
      title: 'Name', prop: 'name', isFilterable: true,
      cell: (row) => (
        <Link
          to={"/properties/" + row.id}
          state={row}


        >
          {row.name}
        </Link>
      )
    },
    {
      title: 'Type', prop: 'type', isFilterable: true},
    { title: 'Area', prop: 'area', isFilterable: true },
    { title: 'Street', prop: 'street', isFilterable: true },
    { title: 'City', prop: 'city', isFilterable: true },
    { title: 'Contact', prop: 'contactname', isFilterable: true },
    { title: 'Assigned To', prop: 'ownername', isFilterable: true },
    { title: 'Phone', prop: 'phone', isFilterable: true }    
  ];

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " "
  }

  const createProperty = () =>{
    navigate(`/properties/e`);
  }

  return (
    <Row className="g-0">
      <Col lg={2} className="mx-2">
        <Link className="nav-link">Home <i className="fa-solid fa-chevron-right"></i> Properties</Link>
      </Col>
      <Col lg={10} className = "p-lg-4">

        {body ?
          <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
            initialState: {
              rowsPerPage: 10,
              options: [5, 10, 15, 20]
            }
          }}>
            <Row className="mb-4">
              <Col
                xs={12}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
                
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-start align-items-start"
              >
                <PaginationOptions labels={labels} />
                <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">
             
                                            <Form.Select aria-label="Enter status" name="type" onChange={onFilterType}>
                                                <option value="">--Select Type--</option>
                                                <option value="Flat">Flat </option>
                    <option value="Villa">Villa</option>
                    <option value="Plot">Plot</option>
                    <option value="Land">Land</option>
                    <option value="House">House</option>
                    <option value="Shop">Shop</option>                         
                    <option value="Apartment">Apartment </option>               
                    <option value="Commercial Space">Commercial Space</option>
                                            </Form.Select>
                                        </Form.Group>
                                       

              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Button className="btn-sm" variant="outline-primary" onClick={() => createProperty(true)}>New Property</Button>
              </Col>
            </Row>
            <Table striped className="data-table">
              <TableHeader />
              <TableBody />
            </Table>
            <Pagination />

          </DatatableWrapper> : ''}
      </Col>
      <Col lg={2}></Col>
    </Row>
  );
};

export default PropertyList;
