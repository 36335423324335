import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import inventoryApi from "../../api/inventoryApi";
import moment from "moment";
import Confirm from "../Confirm";
import { ExpensesEdit } from "../index";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PATH, TYPE } from "../../api/pathUrl";
import { getRequest } from "../../api/inventoryApiTwo";

const RelatedListExpenses = ({
  parent,
  refreshExpenseList,
  handlAction,
  refreshExpense,
}) => {
  let dispatch = useDispatch();
  const location = useLocation();
  const [body, setBody] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [expense, setExpense] = useState("");
  const [booking, setBooking] = React.useState(parent);
  const [modalShowExpense, setModalShowExpense] = React.useState(false);

  const { expensesList, resultHandle } = useSelector(
    (state) => state.apiReducer
  );

  useEffect(() => {
    if (resultHandle.isModal) {
      expenseList();
      setModalShow(false);
      dispatch({ type: "SET_RESULT_HANDLE", payload: {} });
    }
  }, [resultHandle]);

  useEffect(() => {
    expenseList();
  }, [refreshExpenseList]);

  useEffect(() => {
    if (expensesList.length > 0) {
      setBody(expensesList);
    } else {
      setBody([]);
    }
  }, [expensesList]);

  // useEffect(() => {
  //   ////.log(parent.id);
  //   expenseList();
  // }, []);

  // useEffect(() => {
  //   expenseList();
  // }, [refreshExpenseList]);

  const expenseList = () => {
    async function init() {
      dispatch(
        getRequest(
          `${PATH.expensePath}${location.pathname.split("/")[2]}`,
          TYPE.GET_ALL_EXPENSES
        )
      );

      // let expense = await inventoryApi.fetchExpenseBasedParent(parent.id);
      // //.log("expense", expense);
      // if (expense && expense.data?.length > 0) {
      //   setBody(expense.data);

      //   let booking = await inventoryApi.fetchBooking(expense.data[0].booking_id);
      //   setBooking(booking);

      // } else {
      //   setBody([]);
      // }
    }
    init();
  };

  const handleDelete = (row) => {
    setModalShow(true);
    setExpense(row);
  };

  const labels = {
    beforeSelect: " ",
  };

  const deleteExpense = async () => {
    const result = await inventoryApi.deleteExpense(expense.id);
    if (result.success) {
      setExpense("");
      setModalShow(false);
      expenseList();
      handlAction();
    }
  };

  const submitExpense = () => {
    setModalShowExpense(false);
    expenseList();
    refreshExpense();
  };

  const editExpense = (row) => {
    setModalShowExpense(true);
    setExpense(row);
  };

  const header = [
    {
      title: "Number",
      prop: "auto_number",
      cell: (row) => (
        <Link onClick={() => editExpense({ row })} state={parent}>
          {row.auto_number}
        </Link>
      ),
    },

    { title: "Type ", prop: "type" },
    {
      title: "Amount",
      prop: "amount",
      cell: (row) => <>₹&nbsp;{row.amount}</>,
    },
    { title: "Remarks ", prop: "remarks" },
    {
      title: "Created Date",
      prop: "createddate",
      cell: (row) => moment(row.createddate).format("DD-MM-YYYY"),
    },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <>
          <Button
            className="btn-sm mx-2"
            disabled={booking.status === "History"}
            onClick={() => {
              editExpense({ row });
              expenseList();
              handlAction();
            }}
            title="Edit Expenses"
          >
            <i className="fa-regular fa-pen-to-square"></i>
          </Button>
          {/* <Button
            className="btn-sm mx-2"
            variant="danger"
            onClick={() => {
              handleDelete(row);
            }}
          >
            <i class="fa-regular fa-trash-can"></i>
          </Button> */}
        </>
      ),
    },
  ];

  return (
    <>
      {modalShow && (
        <Confirm
          show={modalShow}
          onHide={() => setModalShow(false)}
          deleteExpense={deleteExpense}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="expense"
        />
      )}
      {modalShowExpense && (
        <ExpensesEdit
          show={modalShowExpense}
          onHide={() => setModalShowExpense(false)}
          parentid={parent.id}
          expense={expense}
          table="lead"
          submitExpense={submitExpense}
        />
      )}

      {body ? (
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 5,
            },
          }}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            ></Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            ></Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            ></Col>
          </Row>
          <Table striped className="related-list-table">
            <TableHeader />
            <TableBody />
          </Table>
          {body && body.length > 0 ? <Pagination /> : ""}
        </DatatableWrapper>
      ) : (
        ""
      )}
    </>
  );
};

export default RelatedListExpenses;
