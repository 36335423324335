import React from "react";
import { io } from 'socket.io-client';
import { API_BASE_URL } from './constants/CONSTANT';

const URL = API_BASE_URL.replace('/ibs', '');
//.log('URL for websocket :>> ', URL);
let tempSocket;

// created a method to connect after login. 
let  connectToSocket = () => {
    try{
        const token = localStorage.getItem("token");
        tempSocket =  io(URL, {auth: {token : token}, transports: ['websocket'], upgrade: false}).connect();
        tempSocket.on('connect', () => {
            //.log('Connected to socket');
        })
        tempSocket.on("connect_error", (err) => {
            //.log(`connect_error due to ${err.message}`);
          });
    }catch(e){
        //.log('e.message:', e.message);
    }
}
// to automatically connect when page loads
try{
    const token = localStorage.getItem("token");
    tempSocket =  io(URL, {auth: {token : token}, transports: ['websocket'], upgrade: false}).connect();
    tempSocket.on('connect', () => {
        //.log('Connected to socket');
    })
    tempSocket.on("connect_error", (err) => {
        //.log(`connect_error due to ${err.message}`);
      });
}catch(e){
    //.log('e.message:', e.message);
}

export const socket = tempSocket
export const createSocketConnection = connectToSocket
export const SocketContext = React.createContext(socket);