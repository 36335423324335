import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Image from 'react-bootstrap/Image'
import { schemaEditProfile } from "../ValidateSchemaHelper";
import inventoryApi from "../../api/inventoryApi";
import jwt_decode from "jwt-decode";
import ToastMessage from "../ToastMessage";
import * as formik from 'formik';
//import ViewProfile from "./ViewProfile";
import { profileInitialValues } from "../InitialValuesHelper";
import { useSelector, useDispatch } from "react-redux";
import { getProfileData, updateUser } from "../APIs";
import { PATH, TYPE } from "../../api/pathUrl";
import { getRequest, putRequest, getDownloadRequest } from "../../api/inventoryApiTwo";
import { useNavigate } from "react-router-dom";
import { ChangePasswordProfile } from "../index"


const EditProfile = (props) => {
  //.log('props', props)
  const { profileData, resultHandle } = useSelector((state) => state.apiReducer);
  let dispatch = useDispatch();

  const [validated, setValidated] = useState(false);
  const fileInputRef = useRef();
  const decoded = localStorage.getItem("token") ? jwt_decode(localStorage.getItem("token")) : '';
  const [profile, setProfile] = useState();
  const [body, setBody] = useState();
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [isView, setIsView] = useState(false);
  const [isProfileUpdate, setIsProfileUpdate] = useState(false);
  const [modalShowChangePassword, setmodalShowChangePassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (resultHandle.success) {
      //setIsView(!isView);

      // if (isProfileUpdate) {
      //   //.log("inside success")
      //   setIsProfileUpdate(!isProfileUpdate);
        setProfile(resultHandle.data);
        navigate('/profiles')
      // }
      // else {
        downloadProfileImg();
      // }

      dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })
    }
  }, [ resultHandle ])


  useEffect(() => {

    // if (Object.keys(profileData).length > 0) {
    // setProfile();
    setProfile(profileData);
    // }
  }, [profileData]);

  useEffect(() => {
    async function init() {

      // //.log("profile data before --> ",profile)
      // // try {
      //   if(!profileData?.id)
      dispatch(getRequest(`${PATH.userPath}getuser/${decoded?.id}`, TYPE.SET_PROFILE));

      downloadProfileImg();
      // dispatch(getProfileData(decoded?.id));
      //  else {
      //   //.log('profileDetail in edit 38',profileData)
      //   setProfile(profileData);
      //  }
      // } catch (error) {
      // }
      // let result1 = await getDownloadRequest(`${PATH.userPath}/myimage`);
      // if (result1) {
      //   setBody(URL.createObjectURL(result1));
      // }
      // let result1 = await inventoryApi.fetchMyImage();
      // if (result1) {
      //   setBody(URL.createObjectURL(result1));
      // }
    }

    init();
  }, []);

  const downloadProfileImg = async () => {

    let result1 = await getDownloadRequest(`${PATH.userPath}myimage`);
    if (result1) {
      setBody(URL.createObjectURL(result1));
    }

  }

  const handleSubmitSave = async (values) => {


    const formData = new FormData();

    // values.id = profile.id;
    // //.log('selectedFiles', selectedFiles);
    // if (selectedFiles) {
    //   setIsProfileUpdate(!isProfileUpdate)
      formData.append('file', selectedFiles);
    // }

      formData.append('staffRecord', JSON.stringify(JSON.stringify(values)));

      dispatch(putRequest(PATH.userPath, undefined, `${profile.id}/profile`, formData, false, true));

      // let result1 = await inventoryApi.saveStaffMemberEditProfile(profile.id, selectedFiles, JSON.stringify(JSON.stringify(values)));

      // //.log('result1',result1);
      localStorage.setItem('myimage', body);
      // ToastMessage('Profile has been updated successfully !', "success");
      // setIsView(!isView);
    // }
    // else {

      // setIsProfileUpdate(!isProfileUpdate)
      // dispatch(putRequest(`${PATH.userPath}updateProfile/`, undefined, values.id, values, false));


      // let result = {};
      // result = await dispatch(updateUser(values));
      // if (result.success) {
      //   //.log("edit result",result);
      //  // setProfile(values);
      //   ToastMessage('Profile has been updated successfully !', "success");
      //  }

      // result = await inventoryApi.updateUser(values);
      // //.log('result',result);
      // //.log('values',values);
      // setProfile(values);
      // ToastMessage('Profile has been updated successfully !', "success");
      // setIsView(!isView);
    // }
  }

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    const imageRegex = /\.(gif|jpe?g|png|webp|bmp)$/i;
    if (!file)
      return
    if (!imageRegex.test(file?.name)) {
      // Show an error message
      ToastMessage('Invalid file format. Please upload an image file.', 'error');
      return;
    }
    setBody(URL.createObjectURL(file));
    setSelectedFiles(file);
  };

  const { Formik } = formik;
  return (
    <> {
      profile &&
      <Formik
        validationSchema={schemaEditProfile()}
        onSubmit={handleSubmitSave}
        initialValues={profileInitialValues(profile)}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
          <Form
            className="mt-3"
            onSubmit={handleSubmitSave}
            noValidate
            validated={validated}
          >
            <Container>
              <Row>
                <Col lg={1}></Col>
                <Col lg={3}>
                  <Card>
                    <Card.Body className="text-center">
                      <Card.Title style={{ textAlign: "center" }}>
                        {values.firstname + ' ' + values.lastname}
                      </Card.Title>
                      {body && <Image
                        variant="top"
                        src={body}
                        className="rounded-circle"
                        thumbnail
                        style={{ height: "200px", maxWidth: "200px" }}
                      />}
                      <br />
                      <Button
                        className="btn my-3 "
                        variant="secondary"
                        style={{ width: "100%", display: "block" }}
                        onClick={() => fileInputRef.current.click()}
                      >
                        Image Upload
                      </Button>
                      <Button
                        className="btn"
                        style={{ width: "100%", display: "block" }}
                        variant="danger"
                        onClick={() => { setmodalShowChangePassword(!modalShowChangePassword) }}
                      >
                        Change Password
                      </Button>
                      <input
                        onChange={handlePhotoUpload}
                        name="profilephotourl"
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        hidden
                      />
                    </Card.Body>
                  </Card>
                </Col>

                <Col style={{ backgroundColor: "#fff" }} lg={7}>
                  <Row className="view-form-header align-items-center">
                    <Col lg={6}>Edit Profile</Col>
                    <Col lg={6} className="d-flex justify-content-end">
                      <Button className="btn-sm mx-2" onClick={handleSubmit}>
                        Save
                      </Button>
                      <Button className="btn-sm mx-2" onClick={() => navigate('/profiles')}>
                        Cancel
                      </Button>
                    </Col>
                  </Row>

                  <Row className="pb-5 py-3">
                    <Row>
                      <Col>
                        <Form.Group className="mx-3" controlId="formBasicFirstName">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicFirstName"
                          >
                            First Name <b className="red-star">*</b>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="firstname"
                            placeholder="Enter firstname"
                            required
                            style={{ textTransform: "capitalize" }}
                            value={values.firstname}
                            onChange={handleChange}
                            isInvalid={!!errors.firstname}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.firstname}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mx-3" controlId="formBasicLastName">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicLastName"
                          >
                            Last Name <b className="red-star">*</b>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            required
                            name="lastname"
                            style={{ textTransform: "capitalize" }}
                            placeholder="Enter lastname"
                            value={values.lastname}
                            onChange={handleChange}
                            isInvalid={!!errors.lastname}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.lastname}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mx-3" controlId="formBasicEmail">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicEmail"
                          >
                            Email <b className="red-star">*</b>
                          </Form.Label>
                          <Form.Control
                            required
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                            disabled={!!values.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mx-3" controlId="formBasicPhone">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicPhone"
                          >
                            Phone <b className="red-star">*</b>
                          </Form.Label>
                          <Form.Control
                            required
                            type="number"
                            name="phone"
                            placeholder="Enter phone"
                            value={values.phone}
                            onChange={(e) => {
                              setFieldValue("phone", 
                              e.target.value.slice(0, 10)
                              )
                            }}
                            className="no-arrows"
                            isInvalid={!!errors.phone}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.phone}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Row>
                </Col>

                <Col lg={1}></Col>
              </Row>
            </Container>
          </Form>
        )}
      </Formik>

    }

      {modalShowChangePassword &&
        <ChangePasswordProfile
          show={modalShowChangePassword}
          profile={profile}
          onHide={() => setmodalShowChangePassword(!modalShowChangePassword)}
        />}

    </>
  );
};

export default EditProfile;