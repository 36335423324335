import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../../api/inventoryApi";
import moment from "moment";
const PurchaseOrderEdit = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [purchase, setpurchase] = useState(props.purchase.row);

    const handleChange = (e) => {
        setpurchase({ ...purchase, [e.target.name]: e.target.value });
          //.log(setpurchase({ ...purchase, [e.target.name]: e.target.value }))
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await inventoryApi.savePurchaseOrder(purchase);
        if (result) {
            //.log(result)
            submitPurchaseOrder();
           
        }
    };

    const submitPurchaseOrder = () => {
        props.submitPurchaseOrder();
    };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Purchase Order
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="view-form">
          
              <Form className="mt-3"  controlId="Create">
                <Row>
                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicCustomer">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicCustomer"
                      >
                        Invoice
                      </Form.Label>
                      <Form.Control
                                type="text"
                                name="invoicenumber"
                                placeholder="Enter Invoice # "
                                value={purchase.invoicenumber}
                                onChange={handleChange}
                            />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicInvoice">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicInvoice"
                      >
                        Payment Status
                      </Form.Label>
                      <Form.Control
                                type="text"
                                name="paymentstatus"
                                placeholder="Enter paymentstatus"
                                value={purchase.paymentstatus}
                                onChange={handleChange}
                        />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicorderdate">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicorderdate"
                      >
                        Quantity
                      </Form.Label>
                      <Form.Control
                                type="text"
                                name="quantity"
                                
                                value={purchase.quantity}
                                onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicQuantity">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicQuantity"
                      >
                        Total Payment
                      </Form.Label>
                      <Form.Control
                                type="text"
                                name="totalpayment"
                                value={purchase.totalpayment}
                                onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col >
                    <Form.Group className="mx-3" controlId="formBasicpaymentreceived">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicpaymentreceived"
                      >
                        Payment Received
                      </Form.Label>
                      <Form.Control
                                type="text"
                                name="paymentreceived"
                                value={purchase.paymentreceived}
                                onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col >
                    <Form.Group className="mx-3" controlId="formBasicPaymentStatus">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPaymentStatus"
                      >
                        Payment Due
                      </Form.Label>
                      
                      <Form.Control
                                type="text"
                                name="paymentdue"
                                value={purchase.paymentdue}
                                onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col >
                    <Form.Group className="mx-3" controlId="formBasiccreateddate">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasiccreateddate"
                      >
                        Created Date
                      </Form.Label>
                      <Form.Control
                                type="Date"
                                name="createddate"
                                value={purchase.createddate}
                                onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col >
                    <Form.Group className="mx-3" controlId="formBasicDuePayment">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicDuePayment"
                      >
                        Due Payment
                      </Form.Label>
                      
                      <Form.Text id="paymentdue">
                        788
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                <Col >
                    <Form.Group className="mx-3" controlId="formBasicorderdate">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicorderdate"
                      >
                        Order Date
                      </Form.Label>
                      <Form.Control
                                type="Date"
                                name="orderdate"
                                value={purchase.orderdate}
                                onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col >
                    <Form.Group className="mx-3" controlId="formBasicDuePayment">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicDuePayment"
                      >
                        Contact Name
                      </Form.Label>
                      
                      <Form.Control
                                type="text"
                                name="contactid"
                                placeholder="Enter "
                                value={purchase.contactid}
                                onChange={handleChange}
                            />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
       
        <Button variant="success" onClick={handleSubmit} >Save</Button>
        <Button onClick={props.onHide} variant="light">Close</Button>
      </Modal.Footer> 
    </Modal>
  )
}

export default PurchaseOrderEdit
