import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  DatatableWrapper,
  Pagination,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import moment from "moment";
import Confirm from "../Confirm";
import FilesEdit from "../Files/FilesEdit";
import FilesView from "../Files/FilesView";
import fileDownload from "js-file-download";
import Image from "react-bootstrap/Image";
import * as constants from "../../constants/CONSTANT";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getRequest,
  getDownloadRequest,
  deleteRequest,
} from "../../api/inventoryApiTwo";
import { PATH, TYPE } from "../../api/pathUrl";

const RelatedListFiles = (props) => {
  let dispatch = useDispatch();
  const location = useLocation();
  const [modalShow, setModalShow] = React.useState(false);
  const [file, setFile] = React.useState();
  const [downloads, setDownloads] = React.useState([]);
  const [modalShowFile, setModalShowFile] = React.useState(false);
  const [modalShowFileView, setModalShowFileView] = React.useState(false);

  const { filesList, resultHandle } = useSelector((state) => state.apiReducer);

  const [body, setBody] = useState([]);

  const MIMEType = new Map([
    ["text/csv", "csv"],
    ["application/msword", "doc"],
    [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "docx",
    ],
    ["image/gif", "gif"],
    ["text/html", "html"],
    ["image/jpeg", "jpeg"],
    ["image/jpg", "jpg"],
    ["application/json", "json"],
    ["audio/mpeg", "mp3"],
    ["video/mp4", "mp4"],
    ["image/png", "png"],
    ["application/pdf", "pdf"],
    ["application/vnd.ms-powerpoint", "ppt"],
    [
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "pptx",
    ],
    ["image/svg+xml", "svg"],
    ["text/plain", "txt"],
    ["application/vnd.ms-excel", "xls"],
    [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "xlsx",
    ],
    ["text/xm", "xml"],
    ["application/xml", "xml"],
    ["application/atom+xml", "xml"],
    ["application/zip", "zip"],
  ]);

  useEffect(() => {
    if (resultHandle.isModal) {
      fileList();
      setModalShow(false);
      dispatch({ type: "SET_RESULT_HANDLE", payload: {} });
    }
  }, [resultHandle]);

  useEffect(() => {
    fileList();
  }, [props.refreshFileList]);

  useEffect(() => {
    async function init() {
      if (filesList.length > 0) {
        //.log('files',filesList);

        let arr = [];
        for (let i = 0; i < filesList.length; i++) {
          let file = filesList[i];
          if (
            file.filetype === "jpg" ||
            file.filetype === "jpeg" ||
            file.filetype === "png"
          ) {
            let result = await getDownloadRequest(
              `${PATH.filePath}${file.id}/download`
            );

            file["body"] = window.URL.createObjectURL(result);
          } else {
            file["body"] = "";
          }
          arr.push(file);
        }
        setBody([...arr]);
      } else {
        setBody([]);
      }
    }
    init();
  }, [filesList]);

  const viewDownloadFile = async (row, view) => {
    const result = await getDownloadRequest(
      `${PATH.filePath}${row.id}/download`
    );
    //.log('result', result)

    if (result) {
      const reader = new FileReader();
      reader.addEventListener("loadend", () => {
        //.log('reader', reader.result);

        if (view) window.open(reader.result);
      });
      reader.readAsDataURL(result);
      if (!view) fileDownload(result, row.title);
    }
  };

  const fileList = () => {
    dispatch(
      getRequest(
        `${PATH.filePath}${location.pathname.split("/")[2]}/all`,
        TYPE.GET_ALL_FILES
      )
    );
  };

  const deleteFile = async () => {
    dispatch(deleteRequest(PATH.filePath, undefined, file.id, true));
  };

  const fileSize = (bytes) => {
    var exp = bytes / 1024 / 1024;
    return exp.toFixed(2) + " MB";
  };

  const renderPreview = (type, body) => {
    ////.log(type);
    switch (type) {
      case constants.PDF:
        return <i className="fa-solid fa-file-pdf fa-2xl file-thumbnail"></i>;
      case constants.DOC:
      case constants.DOCX:
        return <i className="fa-solid fa-file-word fa-2xl file-thumbnail"></i>;
      case constants.XLS:
      case constants.XLSX:
        return <i className="fa-solid fa-file-excel fa-2xl file-thumbnail"></i>;
      case constants.PNG:
      case constants.JPG:
      case constants.JPEG:
        return <Image src={body} thumbnail width="200px" />;
      default:
        return <i className="fa-solid fa-file-file fa-2xl file-thumbnail"></i>;
    }
  };

  const submitFiles = () => {
    setModalShowFile(false);
    fileList();
  };

  const header = [
    {
      title: "Title",
      prop: "title",
      cell: (row) => (
        <>
          <Link
            onClick={() => viewDownloadFile(row, false)}
            state={props.parent}
          >
            {row.title}
          </Link>
        </>
      ),
    },
    {
      title: "",
      prop: "body",
      cell: (row) => (
        <>
          <center>{renderPreview(row.filetype, row.body)}</center>
        </>
      ),
    },
    { title: "File Type", prop: "filetype", cell: (row) => row.filetype },
    {
      title: "Description",
      prop: "description",
      cell: (row) => row.description,
    },

    {
      title: "File Size",
      prop: "filesize",
      cell: (row) => fileSize(row.filesize),
    },
    {
      title: "Created Date",
      prop: "createddate",
      cell: (row) => moment(row.createddate).format("DD-MM-YYYY"),
    },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <>
          <Button
            className="btn-sm mx-2"
            onClick={() => {
              setModalShowFile(true);
              setFile({ row });
            }}
            title="Edit"
          >
            <i className="fa-regular fa-pen-to-square"></i>
          </Button>
          <Button
            className="btn-sm mx-2"
            variant="danger"
            onClick={() => {
              setModalShow(true);
              setFile(row);
            }}
            title="Delete"
          >
            <i className="fa-regular fa-trash-can"></i>
          </Button>
          <Button
            className="btn-sm mx-2"
            variant="danger"
            onClick={() => viewDownloadFile(row, false)}
            title="Download"
          >
            <i className="fa fa-cloud-download"></i>
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      {modalShow && (
        <Confirm
          show={modalShow}
          onHide={() => setModalShow(false)}
          deleteFile={deleteFile}
          title="Confirm delete?"
          message="You are going to delete the file.   Are you sure?"
          table="file"
        />
      )}
      {modalShowFile && (
        <FilesEdit
          show={modalShowFile}
          onHide={() => setModalShowFile(false)}
          parent={props.parent}
          file={file}
          table="lead"
          submitFiles={submitFiles}
        />
      )}
      {modalShowFileView && (
        <FilesView
          show={modalShowFileView}
          onHide={() => setModalShowFileView(false)}
          file={file}
        />
      )}
      {body ? (
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 5,
            },
          }}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            ></Col>

            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            ></Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            ></Col>
          </Row>
          <Table striped className="related-list-table">
            <TableHeader />
            <TableBody />
          </Table>
          {body && body.length > 0 ? <Pagination /> : ""}
        </DatatableWrapper>
      ) : (
        ""
      )}
      <p style={{ color: "red" }}>
        {downloads?.length > 0
          ? downloads.map((row, index) => <img src={row} />)
          : ""}
      </p>
      {/* 
        <ReactPDF
          file={{}}
        /> */}
    </>
  );
};

export default RelatedListFiles;
