import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate } from "react-router-dom";

import inventoryApi from "../../api/inventoryApi";
import moment from "moment";

const PurchaseOrderCreate = (props) => {
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  const [purchase, setpurchase] = useState("");
  const navigate = useNavigate();
  const dueAmount =
    purchase.totalpayment && purchase.paymentreceived
      ? purchase.totalpayment - purchase.paymentreceived
      : 0;

  const handleChange = (e) => {
    setpurchase({ ...purchase, [e.target.name]: e.target.value });
    //.log(setpurchase({ ...purchase, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    purchase.paymentdue = dueAmount !== 0 ? dueAmount : 0;
    const result = await inventoryApi.createPurchaseOrder(purchase);
    if (result) {
      //.log(result);
      submitPurchaseOrder();
    }
  };

  const submitPurchaseOrder = () => {
    props.submitPurchaseOrder();
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Purchase Order
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="view-form">
          <Form className="mt-3" controlId="Create">
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicCustomer">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCustomer"
                  >
                    Contact Id
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="contactid"
                    value={purchase.contactid}
                    placeholder="Enter Contact id"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicInvoice">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicInvoice"
                  >
                    Invoice
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="invoicenumber"
                    placeholder="Enter Invoice"
                    value={purchase.invoicenumber}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicorderdate">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicorderdate"
                  >
                    Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="orderdate"
                    placeholder="Enter Date"
                    value={purchase.orderdate}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicQuantity">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicQuantity"
                  >
                    Quantity
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="quantity"
                    placeholder="Enter Quantity"
                    min="1"
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                    value={purchase.quantity}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasictotalpayment">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasictotalpayment"
                  >
                    Total Amount
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="totalpayment"
                    placeholder="Enter Total Amount"
                    className="no-spinner"
                    value={purchase.totalpayment}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicPaymentStatus">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicPaymentStatus"
                  >
                    Payment Status
                  </Form.Label>

                  <Form.Select
                    aria-label="Enter Payment Status"
                    name="paymentstatus"
                    value={purchase.paymentstatus}
                    onChange={handleChange}
                  >
                    <option>Payment Status</option>
                    <option value="online">Online </option>
                    <option value="cash">Cash</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className="mx-3"
                  controlId="formBasicpaymentreceived"
                >
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicpaymentreceived"
                  >
                    Received Payment
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="paymentreceived"
                    placeholder="Enter Payment Received"
                    className="no-spinner"
                    value={purchase.paymentreceived}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicDuePayment">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicDuePayment"
                  >
                    Due Payment
                  </Form.Label>

                  <Form.Text id="paymentdue">{dueAmount}</Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicParticulars">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicParticulars"
                  >
                    Particulars
                  </Form.Label>

                  <Form.Control
                    as="textarea"
                    name="particulars"
                    placeholder="Enter Particulars"
                    value={purchase.particulars}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSubmit}>
          Save
        </Button>
        <Button onClick={props.onHide} variant="light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default PurchaseOrderCreate;
