import React, { useState } from "react";
import { Col, Container, Row, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../../api/inventoryApi";
import ToastMessage from "../ToastMessage";
import * as formik from 'formik';
import { schemaSignUp } from "../ValidateSchemaHelper";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { PATH } from "../../api/pathUrl";
import { postRequest } from "../../api/inventoryApiTwo";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

function Signup() {

const dispatch = useDispatch();
const navigate = useNavigate();
const { resultHandle } = useSelector((state) => state.apiReducer);

    useEffect(() => {
        if (resultHandle.success) {
            dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })
            navigate("/login");
        }
    }, [resultHandle]) 

    const handleSubmitSave = async (values) => {
        values.tenantcode = values.companyname.toLowerCase().replaceAll(' ', '_');
        values.isactive = true;

        //.log("tenantcode --> ", values.tenantcode)
        //.log("signup values --> ", values)

        // "/api/auth/createuser/systemadmin"
        dispatch(postRequest(`${PATH.userPath}createuser/systemadmin`, values))
        // const result = await inventoryApi.signUp(values);
        // //.log("signup result --> ", result)
        //     if(result.errors){
        //         ToastMessage(result.errors,"error");
        //     }
        //     else{
        //         ToastMessage("You are signed up successfully !","success");
        //         navigate("/login");
        //     }
        //     //.log("Result --> ",result)
    };

  const { Formik } = formik;
    return (
        <Container>
            <Row className="login-form p-3 mt-3">
                <Col lg={4} style={{ backgroundImage: "url('/login.jpg')", backgroundSize: "cover", backgroundPosition: "center" }}>

                </Col>
                <Col lg={8} className="login-section">
                    {/* <center>
            <img src="logo6.png" style={{height:"150px", width: "auto"}}/></center> */}
                    <div className="p-4">

                    <Formik
    validationSchema={schemaSignUp}
    onSubmit={handleSubmitSave}
    initialValues={{
   
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        companyname: "",
        companyemail: "",
        companyphone: "",
        street: "",
        city: "",
        state: "",
        pincode: "",
        country: "",
        numberoflicense: "",
        logo: "",
        background_image: "",
        secretkey: "",
        password: "",
        confirmpassword: ""
      }}
  >
        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue}) => (

                        <Form onSubmit={handleSubmitSave}
                              noValidate
                              validated={false}>
                            <div className="mt-1 text-center mb-4">

                                <i className="fa-solid fa-lock-open fa-2xl"></i>
                                <h5 className="mt-2">Sign Up</h5>
                            </div>
                            {/* <Alert variant="danger"></Alert> */}

                            <Row>

                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="firstname">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="firstname"
                                        >
                                            First Name <b className="red-star">*</b>
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="firstname"
                                            placeholder="Enter first name"
                                            onChange={handleChange}
                                        />
                                        {touched.firstname && <div className="form-error">
                                        {errors.firstname}
                                        </div>}
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="formBasicLastName">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicLastName"
                                        >
                                            Last Name <b className="red-star">*</b>
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="lastname"
                                            placeholder="Enter last name"
                                            onChange={handleChange}
                                        />
                                        {touched.lastname && <div className="form-error">
                                        {errors.lastname}
                                        </div>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label htmlFor="formBasicEmail"
                                        >Email address <b className="red-star">*</b></Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            onChange={handleChange}
                                            placeholder="Enter email"
                                            required
                                        />
                                        {touched.email && <div className="form-error">
                                        {errors.email}
                                        </div>}                                
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicPhone"
                                    >
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicPhone"
                                        >
                                            Phone <b className="red-star">*</b>
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            type="phone"
                                            name="phone"
                                            placeholder="Phone"
                                            onChange={handleChange}                                     
                                        />
                                        {touched.phone && <div className="form-error">
                                        {errors.phone}
                                        </div>}                                 
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group className="mb-3" controlId="formBasicCompanyName">
                                <Form.Label>Company Name <b className="red-star">*</b></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="companyname"
                                    onChange={handleChange}
                                    placeholder="Enter company name"
                                    required
                                />
                                {touched.companyname && <div className="form-error">
                                {errors.companyname}
                                </div>}                           
                            </Form.Group>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="formBasicCompanyEmail">
                                        <Form.Label>Company Email address <b className="red-star">*</b></Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="companyemail"
                                            onChange={handleChange}
                                            placeholder="Enter company email"
                                            required
                                        />
                                        {touched.companyemail && <div className="form-error">
                                        {errors.companyemail}
                                        </div>}                                   
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="formBasicCompanyPhone">
                                        <Form.Label
                                            htmlFor="formBasicCompanyPhone"
                                        >
                                            Company Phone <b className="red-star">*</b>
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            type="number"
                                            name="companyphone"
                                            placeholder="Enter company phone"
                                            className="no-arrows"
                                            onChange={handleChange}
                                        />
                                        {touched.companyphone && <div className="form-error">
                                        {errors.companyphone}
                                        </div>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={4}>
                                    <Form.Group className="mb-3" controlId="formBasicStreet">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicStreet"
                                        >
                                            Street
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="street"
                                            placeholder="Enter street"
                                            onChange={handleChange}
                                        />
                                        {touched.street && <div className="form-error">
                                        {errors.street}
                                        </div>}                                        
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3" controlId="formBasicCity">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicCity"
                                        >
                                            City <b className="red-star">*</b>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="city"
                                            placeholder="Enter City"
                                            onChange={handleChange}
                                        />
                                        {touched.city && <div className="form-error">
                                        {errors.city}
                                        </div>}
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3" controlId="formBasicState">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicState"
                                        >
                                            State <b className="red-star">*</b>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="state"
                                            placeholder="Enter State"
                                            onChange={handleChange}
                                        />
                                        {touched.state && <div className="form-error">
                                        {errors.state}
                                        </div>}                                        
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="formBasicPin">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicPin"
                                        >
                                            Pincode <b className="red-star">*</b>
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            required
                                            name="pincode"
                                            placeholder="Enter pincode"
                                            onChange={handleChange}
                                            className="no-arrows"
                                        />
                                        {touched.pincode && <div className="form-error">
                                        {errors.pincode}
                                        </div>}
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="formBasicCountry">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicCountry"
                                        >
                                            Country
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="country"
                                            placeholder="Enter country"
                                            onChange={handleChange}
                                        />
                                        {touched.country && <div className="form-error">
                                        {errors.country}
                                        </div>}                                     
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>

                            <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label
                                            className="form-view-label"
                                        >
                                            Number Of Licenses <b className="red-star">*</b>
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            type="number"
                                            name="numberoflicense"
                                            placeholder="No of licenses"
                                            onChange={handleChange}
                                        />
                                        {touched.numberoflicense && <div className="form-error">
                                        {errors.numberoflicense}
                                        </div>}
                                    </Form.Group>
                                </Col>

                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label
                                            className="form-view-label"
                                        >
                                            Logo <b className="red-star">*</b>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="logo"
                                            placeholder="Enter Logo"
                                            onChange={handleChange}
                                        />
                                        {touched.logo && <div className="form-error">
                                        {errors.logo}
                                        </div>}                                        
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label
                                            className="form-view-label"
                                        >
                                            Backgroud Image
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="background_image"
                                            placeholder="Enter backgroud image"
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                            <Col lg={4}>
                                    <Form.Group className="mb-3" controlId="formBasicSecretKey">
                                        <Form.Label>Secret Key <b className="red-star">*</b></Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="secretkey"
                                            onChange={handleChange}
                                            placeholder="Enter secret key"
                                            required
                                        />
                                        {touched.secretkey && <div className="form-error">
                                        {errors.secretkey}
                                        </div>}                                        
                                    </Form.Group>
                                </Col>

                                        <Col lg={6}>
                                            <Form.Group className="mx-3" controlId="active_features">
                                                <Form.Label className="form-view-label" htmlFor="active_features">
                                                    Additional Features
                                                </Form.Label>
                                                <InputGroup>
                                                    <Typeahead
                                                        required
                                                        id="basic-typeahead-single2"
                                                        name="active_features"
                                                        labelKey="name"
                                                        options={[{ "name": "Restaurant", "val": "Restaurant" }, { "name": "Agent Commission", "val": "Agent_Commission" }]} multiple
                                                        onChange={(event) => {
                                                            //.log('length', event.length)
                                                            //.log('length', event)
                                                            if (event.length > 0) {
                                                                    setFieldValue("active_features",event.map((e)=>{
                                                                        return e.val;
                                                                    }))
                                                            }else{
                                                                setFieldValue("active_features",[])
                                                            }
                                                        }}
                                                        placeholder="Select..."
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                            </Row>

                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Password <b className="red-star">*</b></Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            onChange={handleChange}
                                            placeholder="Password"
                                            required
                                        />
                                        {touched.password && <div className="form-error">
                                        {errors.password}
                                        </div>}                                        
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                                        <Form.Label>Confirm Password <b className="red-star">*</b></Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="confirmpassword"
                                            onChange={handleChange}
                                            placeholder="Confirm password"
                                            required
                                        />                    
                                        {touched.confirmpassword && <div className="form-error">
                                        {errors.confirmpassword}
                                        </div>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <div className="text-center mt-3">
                            <Button variant="primary" type="submit" onClick={handleSubmit}>
                                Sign Up
                            </Button>
                            </div>
                        </Form>
                            )}
                  </Formik>  
                  </div>
                </Col>
            </Row>
            <h6 className="text-center mt-3"> &copy; iBirds Services</h6>
        </Container>
    );
}

export default Signup;