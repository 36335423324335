import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import moment from "moment";
import Confirm from "../Confirm";
import { useSelector, useDispatch } from "react-redux";
import { PATH, TYPE } from "../../api/pathUrl";
import { getRequest, deleteRequest } from "../../api/inventoryApiTwo";
import { useLocation } from "react-router-dom";
import { PricingEdit } from "../index";

const RelatedListPricing = ({ parent, refreshPricingList }) => {
  const location = useLocation();
  let dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);
  const [pricing, setPricing] = React.useState("");
  const [modalShowPricing, setModalShowPricing] = React.useState(false);
  const [body, setBody] = useState([]);

  const { pricingsList, resultHandle } = useSelector(
    (state) => state.apiReducer
  );

  useEffect(() => {
    if (resultHandle.isModal) {
      pricingList();
      //setPricing("");
      setModalShow(false);
      dispatch({ type: "SET_RESULT_HANDLE", payload: {} });
    }
  }, [resultHandle]);

  useEffect(() => {
    pricingList();
  }, [refreshPricingList]);

  useEffect(() => {
    if (pricingsList.length > 0) {
      setBody(pricingsList);
    } else {
      setBody([]);
    }
  }, [pricingsList]);

  const pricingList = () => {
    async function init() {
      dispatch(
        getRequest(
          `${PATH.pricingPath}${location.pathname.split("/")[2]}`,
          TYPE.GET_ALL_PRICINGS
        )
      );
    }
    init();
  };

  const deletePricing = async () => {
    dispatch(deleteRequest(PATH.pricingPath, undefined, pricing.id, true));
  };

  const SubmitPricing = () => {
    setModalShowPricing(false);
    pricingList();
  };

  const header = [
    {
      title: "Number",
      prop: "auto_number",
      cell: (row) => (
        <Link
          onClick={() => {
            setModalShowPricing(true);
            setPricing({ row });
          }}
          state={parent}
        >
          {row.auto_number}
        </Link>
      ),
    },
    {
      title: "Price",
      prop: "price",
      cell: (row) => <>₹&nbsp;{row.price}</>,
    },
    { title: "Status", prop: "status" },
    {
      title: "Start Date",
      prop: "startdate",
      cell: (row) => moment(row.startdate).format("DD-MM-YYYY"),
    },
    {
      title: "End Date",
      prop: "enddate",
      cell: (row) => moment(row.enddate).format("DD-MM-YYYY"),
    },
    { title: "Created By", prop: "createdbyname" },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <>
          <Button
            className="btn-sm mx-2"
            disabled={parent.status === "Not Available"}
            onClick={() => {
              setModalShowPricing(true);
              setPricing({ row });
            }}
            title="Edit"
          >
            <i className="fa-regular fa-pen-to-square"></i>
          </Button>
          <Button
            className="btn-sm mx-2"
            variant="danger"
            disabled={parent.status === "Not Available"}
            onClick={() => {
              setModalShow(true);
              setPricing(row);
            }}
            title="Delete"
          >
            <i class="fa-regular fa-trash-can"></i>
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      {modalShow && (
        <Confirm
          show={modalShow}
          onHide={() => setModalShow(false)}
          deletePricing={deletePricing}
          title="Confirm delete?"
          message="You are going to delete the pricing.  Are you sure?"
          table="pricing"
        />
      )}
      {modalShowPricing && (
        <PricingEdit
          show={modalShowPricing}
          onHide={() => setModalShowPricing(false)}
          parentid={parent.id}
          pricing={pricing}
          table="lead"
          SubmitPricing={SubmitPricing}
        />
      )}

      {body ? (
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 5,
            },
          }}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            ></Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            ></Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            ></Col>
          </Row>
          <Table striped className="related-list-table">
            <TableHeader />
            <TableBody />
          </Table>
          {body && body.length > 0 ? <Pagination /> : ""}
        </DatatableWrapper>
      ) : (
        ""
      )}
    </>
  );
};

export default RelatedListPricing;
