import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import InfoPill from "../InfoPill";
import InputGroup from "react-bootstrap/InputGroup";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRequest } from "../../api/inventoryApiTwo";
import { PATH, TYPE } from "../../api/pathUrl";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";

const ContactList = () => {
  let dispatch = useDispatch();
  const { contactsList } = useSelector((state) => state.apiReducer);
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    dispatch(getRequest(PATH.contactPath, TYPE.GET_ALL_CONTACTS));
  
  }, [])
  

  useEffect(() => {
    // async function init() {
      dispatch({ type: TYPE.SET_CONTACT, payload: {} });
      // if (contactsList.length === 0) {
      // }
      if (contactsList.length > 0) {
        setBody(contactsList);
        setContacts(contactsList);
      }
    // }
    // init();
  }, [contactsList]);

  // Create table headers consisting of 4 columns.
  const header = [
    {
      title: "Name",
      prop: "contactname",
      isFilterable: true,
      cell: (row) => (
        <Link to={"/contacts/" + row.id} state={row} onClick={()=> dispatch({ type: "SET_CONTACT", payload: {} })}>
          {row.firstname + " " + row.lastname}
        </Link>
      ),
    },
    { title: "Email", prop: "email", isFilterable: true },
    { title: "Phone", prop: "phone", isFilterable: true },
    { title: "City", prop: "city", isFilterable: true },
    { title: "State", prop: "state" },
  ];

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };
  const handleFilter = (filterText) => {
    const filteredData = contacts.filter(
      (row) =>
        row?.contactname?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
        row?.email?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
        row?.city?.toLowerCase()?.includes(filterText.toLowerCase()) ||
        row?.state?.toLowerCase()?.includes(filterText.toLowerCase()) ||
        row?.phone?.toLowerCase()?.includes(filterText.toLowerCase())
    );
    //.log("filteredData", filteredData);
    setBody(filteredData);
  };

  const createContact = () => {
    navigate(`/contacts/e`);
  };

  return (
    <Row className="g-0">
    <Col lg={4} className="px-4">
      <CustomSeparator
        currentCmpName="Contacts"
        indexLength="0"
        url="/Contacts"
      ></CustomSeparator>
    </Col>
    <Col lg={12} className = "p-lg-4">

      {body ?
        <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10,
            options: [5, 10, 15, 20]
          }
        }}>
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
            <Col
              xs={2}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-center"
            >
              <div style={{marginTop:"0.4rem"}}>

               <PaginationOptions labels={labels} />
              </div>
               <InfoPill  left="Total" right={body?.length} />

            </Col>
            <Col
              xs={2}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Button className="btn-sm" variant="outline-primary" onClick={() => createContact(true)}>New Contact</Button>
            </Col>
          </Row>
          <Table striped className="data-table">
            <TableHeader />
            <TableBody />
          </Table>
          <Pagination />

        </DatatableWrapper> : ''}
    </Col>
    {/* <Col lg={2}></Col> */}
  </Row>
  );
};

export default ContactList;
