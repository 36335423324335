import * as constants from "../constants/CONSTANT";
import authApi from "../api/authApi";

const inventoryApi = {
  //************************ Users ***********************************//
  async fetchUsers() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const result = await response.json();
    ////.log('----->',JSON.stringify(result))

    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async fetchMyImage() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/myimage", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    // const result = await response.json();
    ////.log('----->',JSON.stringify(result))
    //.log('response--->',response);
    if(response.status === 200){

      const fileBody = await response?.blob();
      return fileBody;
    }else{
      return null
    }
    
  },
  async createUser(user) {
    //.log(user);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/createuser", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(user),
    });
    //.log(response);
    //.log('if user create call');
    return await response.json();
  },

  async saveUser(user) {
    ////.log('if edit save contact call')
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/createuser/" + user.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(user),
      }
    );
    ////.log(response);
    return await response.json();
  },

  async updatePassword(user) {
    ////.log('if edit save contact call')
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/auth/changePassword/" + user.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(user),
      }
    );
    ////.log(response);
    return await response.json();
  },
  
  async getLoginUserData(userId) {
    //.log('userId',userId)
    const token = localStorage.getItem("token");
    let response;
    try {
      
       response = await fetch(constants.API_BASE_URL + "/api/auth/getuser/"+userId, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      });
    } catch (error) {
      //.log('errorin',error)
    }
    //.log('response',response);
    const result = await response?.json();
    //.log('----->', JSON.stringify(result))

    return result;

  },





  async updateUser(user) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/auth/updateProfile/" + user.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(user),
      
      }
    );
    ////.log('response',response)

    return await response.json();

  },


  async saveStaffMemberEditProfile(userid,selectedFiles, staffMember) {
    ////.log("Enter staff data", userid);
    ////.log('selectedFiles',selectedFiles)
    const formData = new FormData();
    formData.append('file', selectedFiles);
    formData.append('staffRecord', staffMember);
    ////.log([...formData])
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL +
      "/api/auth/" +
      userid + "/profile",
      {
        method: "PUT",
        mode: "cors",
        headers: {

          Authorization: token,
        },
        body: formData,
      }
    );
    ////.log(response);
    return await response.json();
  },




  //************************ Contacts ***********************************//
  async fetchContacts() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    ////.log(result)
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async createContact(contact) {
    ////.log('if contact create call');
    ////.log(contact);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(contact),
    });
    ////.log(response);
    return await response.json();
  },

  async createProperty(property) {
    ////.log('if contact create call');
    ////.log(property);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/properties", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(property),
    });
    ////.log(response);
    return await response.json();
  },

  async saveContact(contact) {
    ////.log('if edit save contact call')
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/contacts/" + contact.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(contact),
      }
    );
    ////.log(response);
    return await response.json();
  },

  async saveProperty(property) {
    ////.log('if edit save contact call')
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/properties/" + property.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(property),
      }
    );
    ////.log(response);
    return await response.json();
  },

  async deleteContact(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    return await response.json();
  },
    //************************ Users ***********************************//
    // async fetchRoles() {
    //   const token = localStorage.getItem("token");
    //   //.log(token)
    //   let response = await fetch(constants.API_BASE_URL + "/api/roles", {
    //     method: 'GET',
    //     mode: 'cors',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Authorization': token
    //       // 'Content-Type': 'application/x-www-form-urlencoded',
    //     }
  
    //   });
    //   const result = await response.json();
    //   //.log(result)
    //   if (result.length > 0) {
    //     return result;
    //   }
    //   return null;
  
    // },
    // async fetchPermissions() {
    //   const token = localStorage.getItem("token");
    //   //.log(token)
    //   let response = await fetch(constants.API_BASE_URL + "/api/auth", {
    //     method: 'GET',
    //     mode: 'cors',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Authorization': token
    //       // 'Content-Type': 'application/x-www-form-urlencoded',
    //     }
  
    //   });
    //   const result = await response.json();
    //   //.log(result)
    //   if (result.length > 0) {
    //     return result;
    //   }
    //   return null;
  
    // },
  

  //************************ Lead ***********************************//

  async fetchLeads() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchLead(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const result = await response.json();

    return result;
  },

  //************************ Lead ***********************************//

  async fetchProperties() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/properties", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();

    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchProperty(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/properties/" + id,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },

  async createLead(lead) {
    ////.log('if data enter', lead);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(lead),
    });
    ////.log(response)
    return await response.json();
  },
  // ******************************create product ***************************************
  async createProduct(product) {
    ////.log('if product create call');
    ////.log(product);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/products", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(product),
    });
    ////.log(response);
    return await response.json();
  },

  async saveLead(lead) {
    ////.log('Enter lead data',lead)
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/leads/" + lead.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(lead),
      }
    );
    ////.log(response)
    return await response.json();
  },

  //*************************************save Product********************************************* */
  async saveProduct(product) {
    ////.log('if edit enter for save')
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/products/" + product.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(product),
      }
    );
    ////.log(response)
    return await response.json();
  },
  // ***********************Product************************************//
  async fetchProduct() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/products", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const result = await response.json();
    ////.log(result)
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async deleteProduct(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/products/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  },

  async deleteLead(id) {
    ////.log('delete enter in inventory')
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    ////.log(response)
    return await response.json();
  },
  //************** order **************** */
  async deleteOrder(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    return await response.json();
  },

  async fetchOrder() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/orders", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async deletOrder(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/orders/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    ////.log(response)
    return await response.json();
  },

  async createOrder(order) {
    ////.log('order enter to create in inventory')
    const token = localStorage.getItem("token");
    ////.log(order)
    let response = await fetch(constants.API_BASE_URL + "/api/orders", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(order),
    });
    ////.log(response)
    return await response.json();
  },

  async fetchOrderById(row) {
    ////.log('order enter to show data in pdf with row.id')
    const token = localStorage.getItem("token");
    ////.log(row)
    let response = await fetch(
      constants.API_BASE_URL + "/api/orders/" + row.id,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    ////.log(response)

    return await response.json();
  },

  //************************ Task ***********************************//
  async fetchTasks(pid) {
    const token = localStorage.getItem("token");
    ///"+pid+"/*
    let response = await fetch(
      constants.API_BASE_URL + "/api/tasks/" + pid + "/*",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    ////.log(response)
    const result = await response.json();
    ////.log(result);
    if (result.length > 0) {
      ////.log(result)
      return result;
    }
    return null;
  },

  async createTask(task) {
    const token = localStorage.getItem("token");
    ////.log('task:', task);
    let response = await fetch(constants.API_BASE_URL + "/api/tasks/", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(task),
    });

    return await response.json();
  },

  async saveTask(task) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/tasks/" + task.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(task),
      }
    );

    return await response.json();
  },

  async deleteTask(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/tasks/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    return await response.json();
  },

  async createFile(pid, formData) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/files/" + pid, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: token,
      },
      body: formData,
    });

    return await response.json();
  },

  //************************ files ***********************************//

  async fetchFiles(pid) {
    //.log('in calling fetch file')
    const token = localStorage.getItem("token");
    //

    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + pid + "/all",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    const result = await response.json();
    //.log('result for file',result);
    ////.log(response)
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async downloadFiles(file) {
    ////.log("save",file.id);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + file.id + "/download",
      {
        method: "GET",
        //mode: "cors",

        headers: {
          Authorization: token,
        },
      }
    );
    const fileBody = await response.blob();

    return fileBody;
  },

  async saveFiles(file) {
    ////.log("save",file);

    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + file.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(file),
      }
    );

    return await response.json();
  },

  async deleteFile(id) {
    ////.log('delete call',id)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/files/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    return await response.json();
  },

  async fetchPurchaseOrder() {
    ////.log('enter to fetch purchase order')
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/purchaseorders", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const result = await response.json();
    ////.log(result)
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async createPurchaseOrder(purchase) {
    ////.log('purchase enter to create in order')
    const token = localStorage.getItem("token");
    ////.log(purchase)
    let response = await fetch(constants.API_BASE_URL + "/api/purchaseorders", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(purchase),
    });
    ////.log(response)
    return await response.json();
  },

  async savePurchaseOrder(purchase) {
    const token = localStorage.getItem("token");
    ////.log(purchase);
    let response = await fetch(
      constants.API_BASE_URL + "/api/purchaseorders/" + purchase.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(purchase),
      }
    );
    ////.log(response)
    return await response.json();
  },

  async deletePurchaseOrder(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/purchaseorders/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    ////.log(response)
    return await response.json();
  },

  //************************ Rooms ***********************************//

  async fetchRooms() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/room", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();

    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async fetchRoomsWithBooking() {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/room/roombooking",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();

    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async fetchAvailableRooms() {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/room/availablerooms",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();

    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async fetchRoom(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/room/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },

  async createRoom(room) {
    ////.log('if contact create call');
    ////.log(property);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/room", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(room),
    });
    ////.log(response);
    return await response.json();
  },

  async saveRoom(room) {
    ////.log('if edit save contact call')
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/room/" + room.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(room),
      }
    );
    ////.log(response);
    return await response.json();
  },
  async deleteRoom(id) {
    ////.log('delete enter in inventory')
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/room/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    ////.log(response)
    return await response.json();
  },

  //************************ Bookings ***********************************//

  async fetchBookings() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/booking", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();

    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchTodayBookings() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/booking/getTodayBooking", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();

    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async fetchBooking(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/booking/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },
  async fetchBookingByContact(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/booking/getContactBooking/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },
  async createBooking(booking) {
    ////.log('if contact create call');
    ////.log(property);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/booking", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(booking),
    });
    ////.log(response);
    return await response.json();
  },

  async saveBooking(booking) {
    ////.log('if edit save contact call')
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/booking/" + booking.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(booking),
      }
    );
    ////.log(response);
    return await response.json();
  },
  async deleteBooking(id) {
    ////.log('delete enter in inventory')
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/booking/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    ////.log(response)
    return await response.json();
  },
  //************************ Payment ***********************************//

  async fetchPayments() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/payment", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();

    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchPayment(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/payment/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },

  async createPayment(payment) {
    ////.log('if contact create call');
    ////.log(property);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/payment", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(payment),
    });
    ////.log(response);
    return await response.json();
  },

  async savePayment(payment) {
    ////.log('if edit save contact call')
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/payment/" + payment.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(payment),
      }
    );
    ////.log(response);
    return await response.json();
  },
  async deletePayment(id) {
    ////.log('delete enter in inventory')
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/payment/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    ////.log(response)
    return await response.json();
  },
  //************************ Report ***********************************//

  async fetchReports() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();

    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchReportsById(reportId) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/reports/" + reportId.id,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },

  async fetchPricingBasedRoom(id, checkindate, checkoutdate) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL +
        "/api/pricing/validPricing/" +
        id +
        "&" +
        checkindate +
        "&" +
        checkoutdate,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },
  async createPricing(pricing) {
    ////.log('if contact create call');
    ////.log(property);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/pricing", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(pricing),
    });
    ////.log(response);
    return await response.json();
  },

  async savePricing(pricing) {
    ////.log('if edit save contact call')
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/pricing/" + pricing.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(pricing),
      }
    );
    ////.log(response);
    return await response.json();
  },
  async deletePricing(id) {
    ////.log('delete enter in inventory')
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/pricing/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    ////.log(response)
    return await response.json();
  },

  //************************ Expense ***********************************//

  async fetchExpense() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/expense", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();

    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchExpenseBasedParent(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/expense/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },

  // async fetchExpesneBasedBooking(id,checkindate,checkoutdate) {
  //   const token = localStorage.getItem("token");
  //   let response = await fetch(constants.API_BASE_URL + "/api/pricing/validPricing/" + id+'&'+ checkindate+'&'+checkoutdate, {
  //     method: 'GET',
  //     mode: 'cors',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': token
  //       // 'Content-Type': 'application/x-www-form-urlencoded',
  //     }

  //   });

  //   if(response.status === 401){
  //     authApi.logout();
  //   }
  //   const result = await response.json();

  //   return result;

  // },
  async createExpense(expense) {
    ////.log('if contact create call');
    ////.log(property);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/expense", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(expense),
    });
    ////.log(response);
    return await response.json();
  },

  async saveExpense(expense) {
    ////.log('if edit save contact call')
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/expense/" + expense.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(expense),
      }
    );
    ////.log(response);
    return await response.json();
  },
  async deleteExpense(id) {
    ////.log('delete enter in inventory')
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/expense/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    ////.log(response)
    return await response.json();
  },
  //************************ Pricing ***********************************//

  async fetchPricings() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/pricing", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();

    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchPricing(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/pricing/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },

  async fetchPricingBasedRoom(id, checkindate, checkoutdate) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL +
        "/api/pricing/validPricing/" +
        id +
        "&" +
        checkindate +
        "&" +
        checkoutdate,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },
  async createPricing(pricing) {
    ////.log('if contact create call');
    ////.log(property);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/pricing", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(pricing),
    });
    ////.log(response);
    return await response.json();
  },

  async savePricing(pricing) {
    ////.log('if edit save contact call')
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/pricing/" + pricing.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(pricing),
      }
    );
    ////.log(response);
    return await response.json();
  },
  async deletePricing(id) {
    ////.log('delete enter in inventory')
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/pricing/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    ////.log(response)
    return await response.json();
  },

 //************************ Bill ***********************************//

 
  async createBill(pricing) {
    ////.log('if contact create call');
    ////.log(property);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/bills", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(pricing),
    });
    ////.log(response);
    return await response.json();
  },

  async fetchBills() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/bills", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();

    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchBillsById(billId) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/bills/" + billId.id,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },
//************************ Create Report  ***********************************// 

async createReport(reportName) {
  //.log("reportName",reportName);
  const token = localStorage.getItem("token");

  let response = await fetch(constants.API_BASE_URL + "/api/reports",
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(reportName),

    });
  return await response.json();
},
async runReport(reportName) {
  //.log("reportName"+JSON.stringify(reportName));
  const token = localStorage.getItem("token");
  let response = await fetch(constants.API_BASE_URL + "/api/reports/reportname/", 
  {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify(reportName),
  });

  if (response.status === 401) {
    authApi.logout();
  }
  const result = await response.json();
  return result;
},
 //************************ Bill Item ***********************************//
 
 async createBillItem(bill) {
  ////.log('if contact create call');
  ////.log(property);
  const token = localStorage.getItem("token");
  let response = await fetch(constants.API_BASE_URL + "/api/billitems", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(bill),
  });
  ////.log(response);
  return await response.json();
},

    //************************ Notification ***********************************//

    async fetchNotifications() {
      const token = localStorage.getItem("token");
      let response = await fetch(constants.API_BASE_URL + "/api/notification", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      const result = await response.json();
      //.log("Get notification result --> ",result);
      if (result.data.length > 0) {
        return result.data;
      }
      return null;
    },

    async readNotification(id) {
      const token = localStorage.getItem("token");
      let response = await fetch(
        constants.API_BASE_URL + "/api/notification/" + id,
        {
          method: "PUT",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      const result = await response.json();
      //.log("read notification result --> ",result);
      return result.data;
    },

    async deleteNotification(id) {
      const token = localStorage.getItem("token");
      let response = await fetch(constants.API_BASE_URL + "/api/notification/" + id, {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      const result = await response.json();
      //.log("delete notification result --> ",result);
  
      return result;
    },

    async clearAllNotification() {
      const token = localStorage.getItem("token");
      let response = await fetch(constants.API_BASE_URL + "/api/notification/all", {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      const result = await response.json();
      //.log("alear all notification result --> ",result);
  
      return result;
    },

      //************************ Todo ***********************************//

  async fetchTodos() {
    //.log("fetchTodos");
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/todos/", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    //.log('response' , response)

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();
    //.log("result n fetch-->",result.data)
    if (result.data.length > 0) {
      return result.data;
    }
    return null;
  },

  async createToDO(todo) {
    //.log("createToDO",todo)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/todos/", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(todo),
    });

    const result2 = await response.json();
    //.log("Result in createToDO",result2.data)
    return result2.data;
  },

  async saveTodo(todo) {
    //.log('user in inv',todo);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/todos/" + todo.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(todo),
      }
    );
  const result = await response.json();
   //.log('response in inv',result.data);
    return result.data;
  },

  async deleteDetailTodo(id) {
    //.log("deleteDetailTodo",id)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/todos/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  },



};

export default inventoryApi;
