import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../../api/inventoryApi";
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import ToastMessage from "../ToastMessage";
// import OrderItemEdit from "./OrderItemEdit";
import * as formik from 'formik';
import { useSelector, useDispatch } from "react-redux";
import { expenseInitialValues } from "../InitialValuesHelper";
import { schemaExpenseEdit } from "../ValidateSchemaHelper";
import { TYPE , PATH } from "../../api/pathUrl";
import { getRequest , putRequest , postRequest } from "../../api/inventoryApiTwo";

const ExpensesEdit = (props) => {

    let dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [expense, setExpense] = useState(props.expense ? props.expense.row : {parentid : props.parentid});
    const [order, setOrder] = useState({});
    const [modalShowOrder, setmodalShowOrder] = React.useState(false);

    const { orderDetail , resultHandle } = useSelector((state) => state.apiReducer);


//.log("props exp --> ",props.expense)

    useEffect(() => {
        if (resultHandle.isModal) {
            submitExpense();
            dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })
        }
    }, [resultHandle])

    useEffect(() => {
        if (Object.keys(orderDetail).length > 0) {
            setOrder(orderDetail);
        }
    }, [orderDetail]);


    useEffect(() => {

        if (expense.order_id) {

            dispatch(getRequest(`${PATH.orderPath}${expense.order_id}`, TYPE.SET_ORDER));


            // async function init() {
            // let orderRes = await inventoryApi.fetchOrderByOnlyId(expense.order_id);
            // //.log("order result in expense edit --> ",orderRes)
            // if (orderRes.success) {
            //     setOrder(orderRes.data);

            // }
            // }
            // init();
        }
    }, []);

    const handleSubmitSave = async (values) => {


        if (expense.id) {
            dispatch(putRequest(PATH.expensePath, undefined, values.id, values, true));
        } else {
            dispatch(postRequest(PATH.expensePath, values, undefined, true));
        }





        // //.log('expense',expense)
        // //.log('values',values)
        // // values.parentid = expense.parentid
        // if(expense.id){
        //     values.id = expense.id
        //     const result = await inventoryApi.saveExpense(values);
        //     //.log('result:', result);
        //     if (result) {
        //         ToastMessage('Expense has been updated successfully !','success');
        //         submitExpense(expense.id);
        //     }
        // }else{
        //     const result = await inventoryApi.createExpense(values);
        //     //.log('result insert',result)
        //     if (result) {
        //         if(result.errors){
        //             for (const each of result.errors) {
        //                 ToastMessage(each.msg,'error');
        //               }
        //         }
        //         else{
        //             ToastMessage("Expense has been created successfully !",'success');
        //             submitExpense(expense.id);
        //         }
        //     }
        // }   
    };

    const submitOrder = () => {
        setmodalShowOrder(false);
        // props.refreshOrder();
      };
    
      const editOrder = (row) => {
        setmodalShowOrder(true);
        // setOrder(row);
      };

      
    const submitExpense = (expenseId) => {
        try {
            //.log(props)
            if (expenseId)
                props.submitExpense();
            else
                props.submitExpense();
        } catch (e) {
            //.log(e)
        }
    };

    const { Formik } = formik;

    return (
        <>
            {/* {modalShowOrder && (
                <OrderItemEdit
                    show={modalShowOrder}
                    onHide={() => setmodalShowOrder(false)}
                    // parentid={props.parent.id}
                    //   order={order}
                    table="lead"
                    submitOrder={submitOrder}
                />
            )} */}

            <Formik
                validationSchema={schemaExpenseEdit()}
                onSubmit={handleSubmitSave}
                initialValues={expenseInitialValues(expense)}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (

                    <Modal
                        {...props}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered

                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {expense.id ? (
                                    <>
                                        Edit Expense
                                        <h4>{expense.name}</h4>
                                    </>
                                ) : (
                                    "New Expense"
                                )}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container className="view-form">

                                {expense.order_id && <Row>
                                    <Col >
                                        <Form.Group className="mx-3" controlId="formBasicOrder">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicOrder"
                                            >
                                                Order
                                            </Form.Label>
                                            <Link onClick={() => editOrder({ order })} state={order}>
                                                {order.order_auto_number}
                                            </Link>
                                        </Form.Group>
                                    </Col>
                                </Row>}

                                <Row>
                                    <Col lg={12}>
                                        <Form className="mt-3" noValidate validated={validated} onSubmit={handleSubmit} controlId="expenseEdit">

                                            <Row>
                                                <Col >
                                                    <Form.Group className="mx-3" controlId="formBasicTitle">
                                                        <Form.Label
                                                            className="form-view-label"
                                                            htmlFor="formBasicTitle"
                                                        >
                                                            Price <b class="red-star">*</b>
                                                        </Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="number"
                                                            name="amount"
                                                            placeholder="Enter amount"
                                                            value={values.amount}
                                                            onChange={handleChange}
                                                            className="no-arrows"
                                                            min={0}
                                                        />
                                                        {touched.amount && <div className="form-error">
                                                            {errors.amount}
                                                        </div>}
                                                    </Form.Group>
                                                </Col>

                                                <Col >
                                                    <Form.Group className="mx-3" controlId="formBasicDescription">
                                                        <Form.Label
                                                            className="form-view-label"
                                                            htmlFor="formBasicDescription"
                                                        >
                                                            Type <b class="red-star">*</b>
                                                        </Form.Label>
                                                        <Form.Select required aria-label="Enter Type" name="type" value={values.type} onChange={handleChange}>
                                                            <option value="">Select Type</option>
                                                            <option value="Laundary">Laundary</option>
                                                            <option value="Restaurant">Restaurant</option>
                                                            <option value="Additional Charges">Additional Charges</option>
                                                            <option value="Other">Other</option>
                                                        </Form.Select>
                                                        {touched.type && <div className="form-error">
                                                            {errors.type}
                                                        </div>}

                                                    </Form.Group>
                                                </Col>

                                                <Col lg={12}>
                                                    <Form.Group className="mx-3" controlId="formBasicDescription">
                                                        <Form.Label
                                                            className="form-view-label"
                                                            htmlFor="formBasicDescription"
                                                        >
                                                            Remarks
                                                        </Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            name="remarks"
                                                            placeholder="Enter Remarks"
                                                            value={values.remarks}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={handleSubmit}  >Save</Button>
                            <Button onClick={props.onHide} variant="light">Close</Button>
                        </Modal.Footer>

                    </Modal>
                )}
            </Formik>
        </>
    )
}
export default ExpensesEdit;
