import React, { useState,useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../../api/inventoryApi";
import Modal from 'react-bootstrap/Modal';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from "react-router-dom";
import ToastMessage from "../ToastMessage";
import {States} from "../../constants/CONSTANT";
import validator from "validator";
import {MSG} from "../Messages";
import {EMAIL_REGEX, MOBILE_REGEX, NAME_REGEX,PIN_REGEX} from "../Regex";
import * as formik from 'formik';
import * as yup from 'yup';
import { schemaContactFromBooking } from "../ValidateSchemaHelper";
import { contactFromBookingInitialValues } from "../InitialValuesHelper";
import { useDispatch , useSelector } from "react-redux";
import { postRequest} from "../../api/inventoryApiTwo";
import { PATH , TYPE } from "../../api/pathUrl";


const CreateContactForBooking = (props) => {
  const { resultHandle } = useSelector((state) => state.apiReducer);

    const location = useLocation();
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    const [contact, setContact] = useState(location.state ? location.state : {country : 'India'});
    const [phoneValid, isPhoneValid] = useState(false);
    const [message, setMessage] = useState(false);
    const [pinValid, isPinValid] = useState(false);
    let dispatch = useDispatch();

    //const [booking, setbooking] = useState('');

    //Access all the value input values 

    const [stateList, setStateList] = useState(false);
    const [cityList, setCityList] = useState(false);
  
    useEffect(() => {
      var temp =[];
      States.map((ele)=>{
        if(!temp.includes(ele.state)){
          //.log('state',ele.state)
          temp.push(ele.state);
          
        }
      })
      setStateList(temp.sort());
      var tempList= States.filter((ele)=> ele.state === contact.state)
      //.log(tempList)
       setCityList(tempList.sort());
    }, []);
  
    const  handleChangeCity = (event) => {
     var temp= States.filter((ele)=> ele.state === event.target.value)
     //.log(temp)
      setCityList(temp.sort());
      setContact({ ...contact, [event.target.name]: event.target.value });
    };

    const handleChange = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value });
        //.log(contact)
    };
    const handlePinChange = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value });
        
        
          ////.log('(contact.pincode).toString().length ->', (contact.pincode).toString().length)
          //.log('pincode',(e.target.value).length);
          if((e.target.value)?.length !== 6 || (e.target.value) === ''){
            //.log('in if pin')
            isPinValid(true)
          }else{
            isPinValid(false)
          
        }
      
        // else{
        //   isPinValid(false)
        // }
       // //.log('gusest valid in bottom ->', phoneValid)
      };
    const handlePhoneChange = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value });

        if ((e.target.value).toString().length !== 10) {
            //.log('length', (e.target.value).toString().length);
            isPhoneValid(true)
        }
        else {
            isPhoneValid(false)
            setValidated(false);
            //.log('length', (e.target.value).toString().length);
        }
    };

    // const checkRequredFields = () => {

    //     if ((contact.firstname && contact.firstname.trim() !== '') && (contact.lastname && contact.lastname.trim() !== '') && (contact.phone && contact.phone.trim() !== '') && (contact.phone).toString().length == 10) {
    //         return false;
    //     }

    //     return true;
    // };
    const handleEmailChange = (e) =>{
        setContact({ ...contact, [e.target.name]: e.target.value });
        var email = e.target.value;
    
        if (validator.isEmail(email)) {
          setMessage("");
        } else {
          setMessage("Please, enter valid Email!");
        }
      }

      useEffect(() => {
          if (resultHandle.success) {
            //.log('resultHandle.data.id',resultHandle.data,resultHandle.data.id);
            props.handleClose(resultHandle.data.id);
            props.onHide();
            dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })
            
      }  
    }, [resultHandle]) 

    const handleSubmitSave = async (values) => {
        //.log('if handle submit for create contact ');
      //  e.preventDefault();

        // if (checkRequredFields()) {
        //     setValidated(true);
        //     return;
        // }
        // else {
        //     setValidated(false);
        // }
      //.log('values',values)
       try{ //var result = await inventoryApi.createContact(values);
        dispatch(postRequest(PATH.contactPath, values, TYPE.GET_ALL_CONTACTS));
      }
       catch(e){
        //.log('error->',e)
       }
        // //.log('result -->', result);
        // if (result) {
        //     //.log('result', result.id)
        //     props.handleClose(result.id);
        //     ToastMessage('Contact has been created successfully !', 'success');
           
        // }
    };

    const handleCancel = () => {
        props.handleClose();
    };

    const { Formik } = formik;

    return (
      <Formik
      validationSchema={schemaContactFromBooking}
      onSubmit={handleSubmitSave}
      initialValues={contactFromBookingInitialValues(contact)}
    >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered

        >
                 
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Create Contact
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Container className="view-form">
      <Row>
        <Col></Col>
        {/* <Col lg={8} style={{ backgroundColor: "#fff", paddingBottom: "2rem" }}> */}
       
          <Form
            className="mt-3"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
          >
            {/* <Row className="view-form-header align-items-center">
              <Col lg={3}>Edit Contact</Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button 
                className="btn-sm mx-2"
                 onClick={handleSubmit}>
                  Save
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row> */}
            <Row>
              <Col className="d-flex justify-content-between">
                <Form.Group className="mx-3" controlId="formBasicsalutation">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicsalutation"
                  >
                    Salutation<b class="red-star">*</b>
                  </Form.Label>
                  <Form.Select
                    aria-label="Enter Salutation"
                    name="salutation"
                    value={values.salutation}
                    onChange={handleChange}
                   // isInvalid={!!errors.salutation}
                  >
                    <option value="">Salutation</option>
                    <option value="Mr">Mr</option>
                    <option value="Miss">Miss</option>
                    <option value="Mrs">Mrs</option>
                  </Form.Select>
                  {touched.salutation && <div className="form-error">
                       {errors.salutation}
                     </div>}
                </Form.Group>
                <Form.Group className="mx-3" controlId="formBasicFirstName">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    First Name <b class="red-star">*</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="firstname"
                    placeholder="Enter firstname"
                    required
                    style={{textTransform:"capitalize"}}
                    value={values.firstname}
                    onChange={handleChange}
                   // isInvalid={!!errors.firstname}
                  />
          {touched.firstname && <div className="form-error">
                       {errors.firstname}
                     </div>}
                </Form.Group>
                <Form.Group className="mx-3" controlId="formBasicLastName">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicLastName"
                  >
                    Last Name <b class="red-star">*</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    style={{textTransform:"capitalize"}}
                    name="lastname"
                    placeholder="Enter lastname"
                    value={values.lastname}
                    onChange={handleChange}
                   // isInvalid={!!errors.lastname}
                  />
                {touched.lastname && <div className="form-error">
                       {errors.lastname}
                     </div>}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicEmail">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicEmail"
                  >
                    Email
                  </Form.Label>
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    value={values.email}
                    onChange={handleChange}
                    //isInvalid={!!errors.email}
                  />
                {touched.email && <div className="form-error">
                       {errors.email}
                     </div>}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicPhone">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicPhone"
                  >
                    Phone <b class="red-star">*</b>
                  </Form.Label>
                  <Form.Control
                    required
                    type="number"
                    name="phone"
                   // isInvalid={phoneValid}
                    placeholder="Enter phone"
                    value={values.phone}
                    onChange={handleChange}
                    className="no-arrows"
                   // isInvalid={!!errors.phone}
                  />
               {touched.phone && <div className="form-error">
                       {errors.phone}
                     </div>}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicTitle">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicTitle"
                  >
                    Title<b class="red-star">*</b>
                  </Form.Label>
                  <Form.Select
                    aria-label="Enter"
                    name="title"
                    disabled
                    value={values.title}
                    onChange={handleChange}
                   // isInvalid={!!errors.title}
                  >
                    <option value="">--Select--</option>
                    <option value="Customer">Customer</option>
                    <option value="Agent">Agent</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                  {touched.title && <div className="form-error">
                       {errors.title}
                     </div>}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicStreet">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicStreet"
                  >
                    Street<b class="red-star">*</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="street"
                    placeholder="Enter street"
                    value={values.street}
                    onChange={handleChange}
                   // isInvalid={!!errors.street}
                  />
                  {touched.street && <div className="form-error">
                       {errors.street}
                     </div>}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicState">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicState"
                  >
                    State<b class="red-star">*</b>
                  </Form.Label>
                  <Form.Select
                    name="state"
                    value={values.state}
                    onChange={(event)=>  {var temp = States.filter((ele) => ele.state === event.target.value);
                      setCityList(temp.sort());
                      handleChange(event)}}
                   // isInvalid={!!errors.state}
                  >
                    <option value="">State</option>
                    {stateList &&
                      stateList.map((state) => (
                        <option value={state}>{state}</option>
                      ))}
                  </Form.Select>
                  {touched.state && <div className="form-error">
                       {errors.state}
                     </div>}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicCity">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCity"
                  >
                    City<b class="red-star">*</b>
                  </Form.Label>
                  {(values.state)?
                  
                  <Form.Select
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    //isInvalid={!!errors.city}
                  >
                    <option value="">City</option>
                    {cityList &&
                      cityList.map((ele) => (
                        <option value={ele.name}>{ele.name}</option>
                      ))}
                  </Form.Select>
               :(
                <Form.Select
                    name="city"
                    disabled
                    value={values.city}
                    onChange={handleChange}
                   // isInvalid={!!errors.city}
                  >
                    <option value="">City</option>
                    {cityList &&
                      cityList.map((ele) => (
                        <option value={ele.name}>{ele.name}</option>
                      ))}
                  </Form.Select>
               ) }
                 {touched.city && <div className="form-error">
                       {errors.city}
                     </div>}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicPin">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicPin"
                  >
                    Pincode<b class="red-star">*</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    required
                    name="pincode"
                   // isInvalid={pinValid}
                    placeholder="Enter pincode"
                    value={values.pincode}
                    onChange={handleChange}
                    className="no-arrows"
                    //isInvalid={!!errors.pincode}
                  />
 {touched.pincode && <div className="form-error">
                       {errors.pincode}
                     </div>}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mx-3" controlId="formBasicCountry">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCountry"
                  >
                    Country<b class="red-star">*</b>
                  </Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="country"
                    placeholder="Enter country"
                    value={values.country}
                    defaultValue={"India"}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

          </Form>
             
        {/* </Col> */}
        <Col></Col>
      </Row>
    </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success"  onClick={handleSubmit}>Save</Button>
                <Button onClick={props.onHide} variant="light">Close</Button>
            </Modal.Footer>
        </Modal>
         )}
         </Formik>
    )
}

export default CreateContactForBooking