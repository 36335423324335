import * as yup from "yup";
import { EMAIL_REGEX, MOBILE_REGEX, NAME_REGEX, PASSWORD_REGEX, } from "./Regex";
import { MSG } from "./Messages";

function schemaAgentCommission(roomprice) {
  return yup.object().shape({
    agentid: yup.string().required(MSG.AGENT_NAMEREQ),
    commission: yup
      .number()
      .min(1, MSG.COMMISSION_MIN)
      .max(roomprice - 1, MSG.COMMISSION_MAX)
      .required(MSG.AGENT_COMREQ),
    commissiondate: yup
      .date()
      .transform((value, commissiondate) => {
        const date = new Date(commissiondate);
        return isNaN(date) ? undefined : date;
      })
      .required(MSG.DATEREQ),
  });
}

function schemaRoomEdit() {
  return yup.object().shape({
    name: yup.string().required(MSG.ROOMNAME),
    roomno: yup
      .number()
      .min(1)
      .max(9999)
      .required(MSG.ROOMNO)
      .typeError(MSG.NUM_ONLY),
    occupancy: yup.string().required(MSG.OCCUPANCY_REQ),
    floor: yup.string().required(MSG.FLOOR_REQ),
    type: yup.string().required(MSG.TYPE_REQ),
    acnonac: yup.string().required(MSG.ACNONAC_REQ),
    // status: yup.string().required(MSG.STATUS_REQ),
    block: yup.string().required(MSG.BLOCK_REQ),
  });
}

function schemaCategoryEdit() {
  return yup.object().shape({
    category_name: yup.string().required(MSG.CATEGORY_REQ),
    category_order: yup.string().required(MSG.ORDER_REQ)
  });
}

function schemaItemEdit() {
  return yup.object().shape({
    item_name: yup.string().required(MSG.ITEM_NAME),
    item_type: yup.string().required(MSG.ITEM_TYPE),
    item_price: yup.number()
      .min(1, MSG.AMOUNT_VAL)
      .max(100000, MSG.AMOUNT_VAL)
      .required(MSG.ITEM_PRICE_REQ),
    item_order: yup.number()
      .min(0, MSG.ITEM_ORDER_MIN)
      .max(10000, MSG.ITEM_ORDER_MAX)
      .required(MSG.ITEM_ORDER_REQ),
    category_id: yup.string().required(MSG.CATE_REQ),
  });
}

function schemaContactEdit() {
  return yup.object().shape({
    salutation: yup.string().required(MSG.SAL_REQ),
    firstname: yup
      .string()
      .matches(NAME_REGEX, MSG.VALIDNAMREQ)
      .required(MSG.FNAMEREQ),
    lastname: yup
      .string()
      .matches(NAME_REGEX, MSG.VALIDNAMREQ)
      .required(MSG.LNAMEREQ),
    email: yup.string().nullable().matches(EMAIL_REGEX, MSG.INVEMAILREQ),
    phone: yup
      .string()
      .matches(MOBILE_REGEX, MSG.INVMOBNUMREQ)
      .required(MSG.MOBNUMREQ),
    street: yup.string().required(MSG.STREETSREQ),
    state: yup.string().required(MSG.STATEREQ),
    city: yup.string().required(MSG.CITYREQ),
    title: yup.string().required(MSG.TITLE_REQ),
    occupation: yup.string().when("title", (val, schema) => {
      //.log("val", val);
      if (val.includes("Agent")) {
        return yup.string().required(MSG.OCCUPATION_REQ);
      } else {
        return yup.string().notRequired();
      }
    }),
    pincode: yup
      .string()
      .matches(/^\d{6}$/, MSG.VLDPINCODE_REQ)
      .required(MSG.PIN_REQ),
  });
}

function schemaUserEdit(user) {
  return yup.object().shape({
    // salutation: yup.string().required(MSG.SAL_REQ),
    firstname: yup.string().matches(NAME_REGEX, MSG.VALIDNAMREQ).required(MSG.FNAMEREQ),
    lastname: yup.string().matches(NAME_REGEX, MSG.VALIDNAMREQ).required(MSG.LNAMEREQ),
    email: yup.string().matches(EMAIL_REGEX, MSG.INVEMAILREQ).required(MSG.EMAILSREQ),
    phone: yup.string().matches(MOBILE_REGEX, MSG.INVMOBNUMREQ).required(MSG.MOBNUMREQ),
    rolename: yup.string().required(MSG.ROLE_REQ),
    password: user.id ? '' : yup.string().matches(PASSWORD_REGEX, MSG.STRNGPWD).required(MSG.PASSREQ),
    confirmpassword: user.id ? '' : yup.string().oneOf([yup.ref('password'), null], MSG.MATCH_PASS).required(MSG.CPASSREQ),
  });
}

function schemaEditProfile() {
  return yup.object().shape({
    firstname: yup.string()
      .matches(NAME_REGEX, MSG.VALIDNAMREQ)
      .required(MSG.FNAMEREQ),
    lastname: yup.string()
      .matches(NAME_REGEX, MSG.VALIDNAMREQ)
      .required(MSG.LNAMEREQ),
    email: yup.string()
      .matches(EMAIL_REGEX, MSG.INVEMAILREQ)
      .required(MSG.EMAILSREQ),
    phone: yup.string()
      .matches(MOBILE_REGEX, MSG.INVMOBNUMREQ)
      .required(MSG.MOBNUMREQ),
  });
}

function schemaPassword() {
  return yup.object().shape({
    password: yup.string().required(MSG.OLDPASSREQ),
    newpassword: yup.string()
      .required(MSG.NEWPASSREQ)
      .matches(PASSWORD_REGEX, MSG.EIGHTCHAR),
    confirmpassword: yup.string()
      .required(MSG.CPASSREQ)
      .test('password-match', 'Passwords must match', function (value) {
        return value === this.parent.newpassword
      }),
  });
}

function schemaTaskEdit() {
  return yup.object().shape({
    type: yup.string().required(MSG.VALIDTYPE_REQ),
    title: yup.string().required(MSG.TITLE_REQ),
    priority: yup.string().required(MSG.PRIORITY_REQ),
    status: yup.string().required(MSG.STATUS_REQ),
    targetdate: yup
      .date()
      .transform((value, targetdate) => {
        const date = new Date(targetdate);
        return isNaN(date) ? undefined : date;
      })
      .required(MSG.TARGETDATE_REQ),
    ownerid: yup.string().required(MSG.STAFF_REQ),
  });
}

function schemaPricingEdit() {
  return yup.object().shape({
    price: yup.number()
      .min(1, MSG.AMOUNT_VALUE)
      .max(100000, MSG.AMOUNT_VALUE)
      .required(MSG.PRICE_REQ),
    status: yup.string().required(MSG.STATUS_REQ),
    startdate: yup.date()
      .transform((value, startdate) => {
        const date = new Date(startdate);
        return isNaN(date) ? undefined : date;
      })
      .required(MSG.STARTDATE_REQ),
    enddate: yup.date()
      .transform((value, enddate) => {
        const date = new Date(enddate);
        return isNaN(date) ? undefined : date;
      })
      .required(MSG.ENDDATE_REQ).min(yup.ref('startdate'), MSG.ENDDATE_VAL),
    extra_bed: yup.number()
      .min(1, MSG.AMOUNT_VALUE)
      .max(100000, MSG.AMOUNT_VALUE)
      .required(MSG.EXTRABED_REQ),
  });
}

function schemaExpenseEdit() {
  return yup.object().shape({
    amount: yup.number()
      .min(1, MSG.PRICE_VALUE)
      .max(100000, MSG.PRICE_VALUE)
      .required(MSG.PRICE_REQ),
    type: yup.string().required(MSG.VALIDTYPE_REQ),
  })
}

function schemaContactFromBooking() {
  return yup.object().shape({
    salutation: yup.string().required(MSG.SAL_REQ),
    firstname: yup.string().matches(NAME_REGEX, MSG.VALIDNAMREQ).required(MSG.FNAMEREQ),
    title: yup.string().required(MSG.TITLE_REQ),
    lastname: yup.string().matches(NAME_REGEX, MSG.VALIDNAMREQ).required(MSG.LNAMEREQ),
    email: yup.string().matches(EMAIL_REGEX, MSG.INVEMAILREQ),
    phone: yup.string().matches(MOBILE_REGEX, MSG.INVMOBNUMREQ).required(MSG.MOBNUMREQ),
    street: yup.string().required(MSG.STREETSREQ),
    state: yup.string().required(MSG.STATEREQ),
    city: yup.string().required(MSG.CITYREQ),
    pincode: yup.string()
      .matches(/^\d{6}$/, MSG.VLDPINCODE_REQ)
      .required(MSG.PIN_REQ),
  })
}

function schemaPaymentEdit() {
  return yup.object().shape({
    amount: yup.number()
      .min(1, MSG.AMOUNT_VALUE)
      .max(100000, MSG.AMOUNT_VALUE)
      .required(MSG.AMOUNT_REQ),
    date: yup.date()
      .transform((value, date) => {
        const tdate = new Date(date);
        return isNaN(tdate) ? undefined : tdate;
      })
      .required(MSG.VALDATE_REQ),
    method: yup.string().required(MSG.METHOD_REQ),
    type: yup.string().required(MSG.VALIDTYPE_REQ),

  });
}
function schemaBookingEdit(Booking,room) {
//.log('Booking12',Booking,room)
  return  yup.object().shape({
    roomid: yup.string().required(MSG.ROOMNO),
    contactid: yup.string().required(MSG.CONTACT_REQ),
    goingto: yup
      .string()
      .matches(NAME_REGEX, MSG.VALIDNAMREQ)
      .required(MSG.GOINGTO_REQ),
    comingfrom: yup
      .string()
      .matches(NAME_REGEX, MSG.VALIDNAMREQ)
      .required(MSG.COMINGFROM_REQ),

    noofguest: yup
      .number()
      .required(MSG.NOOFGUEST_REQ)
      .min(1, MSG.GUEST_VAL_MIN),
      // .max(room?.occupancy === null ? 10000 :  room?.occupancy , MSG.GUEST_VAL_MAX),
      
    check_in_time: yup.string().required(MSG.C_IN_TIME_REQ),
    check_out_time: yup
      .string()
      .required(MSG.C_OUT_TIME_REQ)
      .test(
        "is-greater",
        "Check-out time must be greater than Check-in time",
        function (value) {
          const { check_in_time, checkindate, checkoutdate } = this.parent;
          if (!check_in_time || !value) return true;
          const checkInTime = parseInt(check_in_time.replace(":", ""), 10);
          const checkOutTime = parseInt(value.replace(":", ""), 10);
          if (new Date(checkindate) < new Date(checkoutdate)) return true;
          else return checkOutTime > checkInTime;
        }
      ),
    checkindate: yup
      .date()
      .transform((value, checkindate) => {
        const date = new Date(checkindate);
        return isNaN(date) ? undefined : date;
      })
      .required(MSG.C_IN_DATE_REQ),
    checkoutdate: yup
      .date()
      .transform((value, checkoutdate) => {
        const date = new Date(checkoutdate);
        return isNaN(date) ? undefined : date;
      })
      .min(yup.ref("checkindate"), MSG.C_OUT_DATE_VAL)
      .required(MSG.C_OUT_DATE_REQ)
      .when("checkindate", (checkindate, schema) =>
        checkindate
          ? schema.test(
              "is-greater",
              MSG.C_OUT_DATE_VAL,
              function (checkoutdate) {
                const { timeformat } = this.parent;
                if (timeformat === "11 AM") {
                  return new Date(checkoutdate) > new Date(checkindate);
                } else {
                  return true;
                }
              }
            )
          : schema
      ),
    timeformat: yup.string().required(MSG.TIMEFORMAT_REQ),
    discount_percentage: yup
      .number()
      .min(0, MSG.DIS_VAL)
      .max(100000, MSG.DIS_VAL),
  });
}
function AdvanceschemaBookingEdit(Booking) {
  return  yup.object().shape({
    //roomid: yup.string().required(MSG.ROOMNO),
    contactid: yup.string()
    .required(MSG.CONTACT_REQ),
    // goingto: yup
    //   .string()
    //   .matches(NAME_REGEX, MSG.VALIDNAMREQ),
    //  // .required(MSG.GOINGTO_REQ),
    // comingfrom: yup
     // .string()
     // .matches(NAME_REGEX, MSG.VALIDNAMREQ),
     // .required(MSG.COMINGFROM_REQ),
    // noofguest: yup
    //   .number()
    //   .required(MSG.NOOFGUEST_REQ)
    //   .min(1, MSG.GUEST_VAL_MIN)
    //   .max(roomdetail?.occupancy, MSG.GUEST_VAL_MAX),
    check_in_time: yup.string()
    .required(MSG.C_IN_TIME_REQ),
    check_out_time: yup
      .string()
      .required(MSG.C_OUT_TIME_REQ)
      .test(
        "is-greater",
        "Check-out time must be greater than Check-in time",
        function (value) {
          const { check_in_time, checkindate, checkoutdate } = this.parent;
          if (!check_in_time || !value) return true;
          const checkInTime = parseInt(check_in_time.replace(":", ""), 10);
          const checkOutTime = parseInt(value.replace(":", ""), 10);
          if (new Date(checkindate) < new Date(checkoutdate)) return true;
          else return checkOutTime > checkInTime;
        }
      ),
    checkindate: yup
      .date()
      .transform((value, checkindate) => {
        const date = new Date(checkindate);
        return isNaN(date) ? undefined : date;
      })
     .required(MSG.C_IN_DATE_REQ),
    checkoutdate: yup
      .date()
      .transform((value, checkoutdate) => {
        const date = new Date(checkoutdate);
        return isNaN(date) ? undefined : date;
      })
      .min(yup.ref("checkindate"), MSG.C_OUT_DATE_VAL)
     .required(MSG.C_OUT_DATE_REQ)
      .when("checkindate", (checkindate, schema) =>
        checkindate
          ? schema.test(
              "is-greater",
              MSG.C_OUT_DATE_VAL,
              function (checkoutdate) {
                const { timeformat } = this.parent;
                if (timeformat === "11 AM") {
                  return new Date(checkoutdate) > new Date(checkindate);
                } else {
                  return true;
                }
              }
            )
          : schema
      ),
    timeformat: yup.string()
    .required(MSG.TIMEFORMAT_REQ),
    advanceamount: yup
      .number()
      .min(0, MSG.ADVAMT_VAL)
      .max(100000, MSG.ADVAMT_VAL),
      method: yup.string()
    .when(
      'advanceamount',
      (advanceamount, schema) => advanceamount > 0 ? schema.required('Method is required when Advance Amount is greater than 0') : schema.nullable()
    ),
   });
}
function ConformBookingSchema(Booking) {
  return  yup.object().shape({
    roomid: yup.string().required(MSG.ROOMNO),
    extra_bed: yup.number()
    .min(1, MSG.EXTRABED_MIN)
   });
}
function CheckoutSchema(Booking) {
  return  yup.object().shape({
    //type: yup.string().required(MSG.TYPE_REQ),
    amount: yup.string().required(MSG.AMOUNT_REQ),
    category: yup.string().required(MSG.CATE_REQ),
   // occupancy: yup.string().required(MSG.OCCUPANCY_REQ),
    title: yup.string().required(MSG.TITLE_REQ),
    transactiondate: yup.string().required(MSG.DATEREQ),
    status: yup.string().required(MSG.STATUS_REQ),
   });
}

function schemaSignUp() {
  return yup.object().shape({
    firstname: yup.string().matches(NAME_REGEX, MSG.VALIDNAMREQ).required(MSG.FNAMEREQ),
    lastname: yup.string().matches(NAME_REGEX, MSG.VALIDNAMREQ).required(MSG.LNAMEREQ),
    email: yup.string().nullable().matches(EMAIL_REGEX, MSG.INVEMAILREQ).required(MSG.EMAILSREQ),
    phone: yup.string().matches(MOBILE_REGEX, MSG.INVMOBNUMREQ).required(MSG.MOBNUMREQ),
    companyname: yup.string().required(MSG.CNAMEREQ),
    companyemail: yup.string().nullable().matches(EMAIL_REGEX, MSG.INVEMAILREQ).required(MSG.EMAILSREQ),
    companyphone: yup.string().matches(MOBILE_REGEX, MSG.INVMOBNUMREQ).required(MSG.MOBNUMREQ),
    street: yup.string().required(MSG.STREETSREQ),
    city: yup.string().required(MSG.CITYREQ),
    state: yup.string().required(MSG.STATEREQ),
    pincode: yup.string()
      .matches(/^\d{6}$/, MSG.VLDPINCODE_REQ)
      .required(MSG.PIN_REQ),
    country: yup.string().required(MSG.COUNTRYREQ),
    numberoflicense: yup.number().min(1, MSG.MINVALNOL).required(MSG.NOLREQ),
    logo: yup.string().required(MSG.LOGOREQ),
    secretkey: yup.string().required(MSG.SECKEYREQ),
    password: yup.string().matches(PASSWORD_REGEX, MSG.STRNGPWD).required(MSG.PASSREQ),
    confirmpassword: yup.string().oneOf([yup.ref('password'), null], MSG.MATCH_SIGNUP_PASS).required(MSG.CPASSREQ)
  });
}

export {
  schemaAgentCommission,
  schemaRoomEdit,
  schemaCategoryEdit,
  schemaItemEdit,
  schemaContactEdit,
  schemaUserEdit,
  schemaEditProfile,
  schemaPassword,
  schemaTaskEdit,
  schemaPricingEdit,
  schemaExpenseEdit,
  schemaContactFromBooking,
  schemaPaymentEdit,
  schemaSignUp,
  schemaBookingEdit,
  AdvanceschemaBookingEdit,
  ConformBookingSchema,
  CheckoutSchema
}
