import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import * as formik from 'formik';
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { schemaPricingEdit } from "../ValidateSchemaHelper";
import { pricingInitialValues } from "../InitialValuesHelper";
import { PATH } from "../../api/pathUrl";
import { postRequest , putRequest } from "../../api/inventoryApiTwo";

const PricingEdit = (props) => {

    const location = useLocation();
    let dispatch = useDispatch();
    const[ pricing,setPricing] = useState(props.pricing ? props.pricing.row : {parentid : props.parentid});
    const [disabled, setDisabled] = useState(location.state ? location.state.status === "Not Available" ? true : false : false);
    const { resultHandle } = useSelector((state) => state.apiReducer);

    useEffect(() => {
        if (resultHandle.isModal) {
            submitPricing(pricing.id);
            dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })
        }
      }, [resultHandle]) 
   
    const handleSubmitSave = async (values) => {

        if (pricing.id) {
            dispatch(putRequest(PATH.pricingPath, undefined, values.id, values, true));      
        } else {
            dispatch(postRequest(PATH.pricingPath, values, undefined, true));
        }
    };

    const submitPricing = (pricingId) => {
        try {
            if(pricingId)
                props.SubmitPricing();
            else
                props.submitPricing();
        }catch(e){
        }
        
    };

    const { Formik } = formik;

    return (
      <Formik
      validationSchema={schemaPricingEdit()}
      onSubmit={handleSubmitSave}
      initialValues={pricingInitialValues(pricing)}
    >
          {({ handleSubmit, handleChange, values,  errors,touched }) => (

        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered

        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {pricing.id ? 'Edit Pricing' : 'New Pricing'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="view-form">
                    <Row>
                        <Col lg={12}>
                            <Form className="mt-3" 
                          
                           
                             onSubmit={handleSubmitSave} 
                             
                             >
                            <Row>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicTitle">
                                            <Form.Label
                                           
                                            >
                                               Price  <b class="red-star">*</b>
                                            </Form.Label>
                                            <Form.Control
                                                className="no-arrows"
                                                type="number"
                                                name="price"
                                                placeholder="Enter amount"
                                                value={values.price}
                                                onChange={handleChange}
                                                disabled={disabled}
                                                />
                                                {touched.price && <div className="form-error">
                                              {errors.price}
                                                </div>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicPriority">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicPriority"
                                            >
                                                Status  <b class="red-star">*</b>
                                            </Form.Label>
                                            <Form.Select 
                                            disabled={disabled}
                                            name="status" value={values.status}
                                             onChange={handleChange}>
                                                <option value="">Select Method</option>
                                                <option value="Available">Available</option>
                                                <option value="Not Available">Not Available</option>
                                            </Form.Select>
                 
                                            {touched.status && <div className="form-error">
                                              {errors.status}
                                                </div>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicTitle">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicTitle"
                                            >
                                            Start Date  <b class="red-star">*</b>
                                            </Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="startdate"
                                                placeholder="Enter title"
                                                min={moment(new Date()).format('YYYY-MM-DD')}
                                                value={values.startdate}
                                                onChange={handleChange}
                                                disabled={disabled}
                                                />
                                                {touched.startdate && <div className="form-error">
                                              {errors.startdate}
                                                </div>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicTitle">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicTitle"
                                            >
                                            End Date  <b class="red-star">*</b>
                                            </Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="enddate"
                                                placeholder="Enter title"
                                                min={moment(new Date()).format('YYYY-MM-DD')}
                                                value={values.enddate}
                                                onChange={handleChange}
                                                disabled={disabled}
                                                />
                                                 {touched.enddate && <div className="form-error">
                                              {errors.enddate}
                                                </div>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicTitle">
                                            <Form.Label
                                             
                                            >
                                            Pricing Per Extra Bed  <b class="red-star">*</b>
                                            </Form.Label>
                                            <Form.Control
                                                className="no-arrows"
                                                type="number"
                                                name="extra_bed"
                                                placeholder="Enter Extra Bed Amount"
                                                value={values.extra_bed}
                                                onChange={handleChange}
                                                disabled={disabled}
                                                />
                                                 {touched.extra_bed && <div className="form-error">
                                              {errors.extra_bed}
                                                </div>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                    </Col>
                                </Row>
                                <Row>
                                    
                                    <Col >
                                        <Form.Group className="mx-3" controlId="formBasicDescription">
                                            <Form.Label
                                                className="form-view-label"
                                                htmlFor="formBasicDescription"
                                            >
                                                Description
                                            </Form.Label>
                                            <Form.Control
                                            as="textarea"
                                            name="description"
                                            placeholder="Enter description"
                                            value={values.description}
                                            onChange={handleChange}
                                            disabled={disabled}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>  
                        </Col>        
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
            { disabled ? "" : <Button variant="success" onClick={handleSubmit}  >Save</Button>}
                <Button onClick={props.onHide} variant="light">Close</Button>
            </Modal.Footer>
        </Modal>
          )}
          </Formik>
    )
}

export default PricingEdit;
