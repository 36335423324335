import React,{ useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from 'react-bootstrap';
import BarChart from '../charts/BarChart';
import PieChart from '../charts/PieChart';
import DashboardRoom from './DashboardRoom';
import { RoomView } from "../index"


const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) navigate("/login");
   
  }, []); 
  return (
    <>
    {/* <Container>
    <Row >
    <Col lg={6} className="text-center">
    <p>Hotel sales monthly</p>
    <BarChart/>
    </Col>
    <Col lg={6} className="text-center">
    <p className="d-block">Area wise properties</p>
    <div className="text-center" style={{height: '400px'}}>
    <PieChart/>
    </div>
    
    </Col>
    </Row>
    
    </Container> */}
    <Container className='home-main'>
    {/* <TodoWrapper></TodoWrapper> */}
      <DashboardRoom />
    </Container>
    </>
    
   
  )
}

export default Home