import React, { useState, useEffect } from "react";
import { Col, Container, InputGroup, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../../api/inventoryApi";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import ToastMessage from "../ToastMessage";
import { MSG } from "../Messages";
import * as formik from "formik";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { getRequest } from "../../api/inventoryApiTwo";
import { PATH , TYPE } from "../../api/pathUrl";

const CheckRoomAvailability = (props) => {
  
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [checkin, setCheckin] = useState(false);
  const [checkout, setCheckout] = useState(false);
  const [roomData, setRoomData] = useState({});
  const { availableRoomData } = useSelector((state) => state.apiReducer);
  
  useEffect(() => {
    //.log("availableRoomData",availableRoomData);
    
    if (availableRoomData.length > 0) {

      // values.roomPrice = resultHandle.data[0].pricing_details[0];
      // values.roomPrice = result.response.data[0].pricing_details[0];
      // navigate(`/bookings/e`, { state: { room: values } });
      
      //.log("loction data",{...roomData, roomPrice : availableRoomData[0].pricing_details[0]});

      navigate(`/bookings/e`, { state: { room: {...roomData, roomPrice : availableRoomData[0].pricing_details[0]} } });
      
      // setRoomData({...roomData, roomPrice : resultHandle.data[0].pricing_details[0]})


    }
  
  
  }, [ availableRoomData ])
  

  // //.log('hii',resultHandle);

  const handleSubmitSave = async (values) => {
    //.log("values",values);
    values.room = props.room;
    //.log("values",values);
    if (values) {
      // const result = await inventoryApi.checkRoomAvailablity(
      //   checkin,
      //   checkout,
      //   props.room.id
      // );

      setRoomData(values);

      dispatch(getRequest(`${PATH.roomPath}check-room-availability?startdate=${checkin}&enddate=${checkout}&roomid=${props.room.id}`, TYPE.GET_AVAILABLE_ROOMS));
      // //.log('roomList',roomList,resultHandle);
      // if (roomList.length > 0) {
      //   //.log('roomList',roomList);
      //   values.roomPrice = roomList[0].pricing_details[0];
      //   ToastMessage("Room is available to book on given dates", "success");
      //   navigate(`/bookings/e`, { state: { room: values } });
      //   props.onHide();
      // } else if (roomList.success === true && roomList.length===0) {
      //   ToastMessage(roomList.message, "error");
      //   //props.onHide();
      // }
    }
  };

  const { Formik } = formik;
  const schema = yup.object().shape({
    check_in_time: yup.string().required(MSG.C_IN_TIME_REQ),
    check_out_time: yup
      .string()
      .required(MSG.C_OUT_TIME_REQ)
      .test(
        "is-greater",
        "Check-out time must be greater than Check-in time",
        function (value) {
          const { check_in_time, checkindate, checkoutdate } = this.parent;
          if (!check_in_time || !value) return true;
          const checkInTime = parseInt(check_in_time.replace(":", ""), 10);
          const checkOutTime = parseInt(value.replace(":", ""), 10);
          if (new Date(checkindate) < new Date(checkoutdate)) return true;
          else return checkOutTime > checkInTime;
        }
      ),
    checkindate: yup
      .date()
      .transform((value, checkindate) => {
        const date = new Date(checkindate);
        return isNaN(date) ? undefined : date;
      })
      .required(MSG.C_IN_DATE_REQ),

    checkoutdate: yup
      .date()
      .transform((value, checkoutdate) => {
        const date = new Date(checkoutdate);
        return isNaN(date) ? undefined : date;
      })
      .min(yup.ref("checkindate"), MSG.C_OUT_DATE_VAL)
      .required(MSG.C_OUT_DATE_REQ)
      .when("checkindate", (checkindate, schema) =>
        checkindate
          ? schema.test(
              "is-greater",
              MSG.C_OUT_DATE_VAL,
              function (checkoutdate) {
                const { timeformat } = this.parent;
                if (timeformat === "11 AM") {
                  return new Date(checkoutdate) > new Date(checkindate);
                } else {
                  return true;
                }
              }
            )
          : schema
      ),

    timeformat: yup.string().required(MSG.TIMEFORMAT_REQ),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmitSave}
      initialValues={{
        timeformat: "",
        check_out_time: "",
        check_in_time: "",
        checkindate: "",
        checkoutdate: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        touched,
        values,
        errors,
        setFieldValue,
        handleBlur,
      }) => (
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Check Room Availability
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="mt-3" onoValidate onSubmit={handleSubmitSave}>
              <Container className="view-form">
                <Row>
                  <Col lg={12}>
                    <Col>
                      <Form.Group className="mx-3" controlId="timeformat">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="timeformat"
                        >
                          Time Format <b class="red-star">*</b>
                        </Form.Label>
                        <Form.Select
                          aria-label="Enter"
                          name="timeformat"
                          onBlur={handleBlur}
                          value={values.timeformat}
                          onChange={(e) => {
                            if (e.target.value === "11 AM") {
                              setFieldValue("check_in_time", "11:00:00");
                              setFieldValue("check_out_time", "11:00:00");
                              handleChange(e);
                            } else {
                              setFieldValue("check_in_time", "");
                              setFieldValue("check_out_time", "");
                              handleChange(e);
                            }
                          }}
                        >
                          <option value="">--Select--</option>
                          <option value="24 hrs">24 Hrs</option>
                          <option value="11 AM">11 AM</option>
                        </Form.Select>
                        {touched.timeformat && (
                          <div className="form-error">{errors.timeformat}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mx-3" controlId="formBasicEmail">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicEmail"
                        >
                          Check-In-Date & Time <b class="red-star">*</b>
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="date"
                            name="checkindate"
                            placeholder="Enter"
                            required
                            onBlur={handleBlur}
                            min={moment(new Date()).format("YYYY-MM-DD")}
                            value={values.checkindate}
                            onChange={(e) => {
                              if (e.target.value && values.checkoutdate)
                                if (
                                  values.checkoutdate &&
                                  values.roomid &&
                                  e.target.value
                                ) {
                                  //setFieldValue("noofdays",calculateDays( e.target.value,getFieldProps('checkoutdate').value));
                                }
                              setCheckin(e.target.value);
                              handleChange(e);
                            }}
                          />
                          <Form.Control
                            type="time"
                            name="check_in_time"
                            placeholder=""
                            required
                            disabled={
                              values.timeformat === "11 AM" ||
                              values.timeformat === ""
                            }
                            value={values.check_in_time}
                            onChange={handleChange}
                          />
                          {touched.checkindate && (
                            <div className="form-error">
                              {errors.checkindate}
                            </div>
                          )}
                          {!errors.checkindate && touched.check_in_time && (
                            <div className="form-error">
                              {errors.check_in_time}
                            </div>
                          )}
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mx-3" controlId="formBasicPhone">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicPhone"
                        >
                          Check-Out-Date & Time <b class="red-star">*</b>
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            required
                            type="date"
                            onBlur={handleBlur}
                            name="checkoutdate"
                            min={moment(new Date()).format("YYYY-MM-DD")}
                            placeholder="Enter"
                            value={values.checkoutdate}
                            onChange={(e) => {
                              if (values.checkindate && e.target.value)
                                // setFieldValue("noofdays",calculateDays( getFieldProps('checkindate').value,e.target.value,));
                                setCheckout(e.target.value);
                              handleChange(e);
                            }}
                          />
                          <Form.Control
                            required
                            type="time"
                            name="check_out_time"
                            disabled={
                              values.timeformat === "11 AM" ||
                              values.timeformat === ""
                            }
                            placeholder=""
                            value={values.check_out_time}
                            onChange={handleChange}
                          />
                          {touched.checkoutdate && (
                            <div className="form-error">
                              {errors.checkoutdate}
                            </div>
                          )}
                          {!errors.checkoutdate && touched.check_out_time && (
                            <div className="form-error">
                              {errors.check_out_time}
                            </div>
                          )}
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-sm mx-2" onClick={handleSubmit}>
              {" "}
              Book
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};

export default CheckRoomAvailability;
