import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as formik from "formik";
import moment from "moment";
import { checkOutEditInitialValues } from "./InitialValuesHelper";
import { CheckoutSchema } from "./ValidateSchemaHelper";
import { useDispatch, useSelector } from "react-redux";
import { putRequest, postRequest } from "../api/inventoryApiTwo";
import { PATH, TYPE } from "../api/pathUrl";

const CashOutEdit = (props) => {
  //.log("props", props);
  const { Formik } = formik;
  const location = useLocation();
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const { resultHandle } = useSelector((state) => state.apiReducer);
  const[ checkout,setCheckout] = useState(props.transaction.row  ? props.transaction.row : ({}));


    //const [checkout, setCheckout] = useState(location.state ? location.state : {});
  //.log("checkout", checkout);

  useEffect(() => {
    if (resultHandle.success) {
      props.onHide();
      dispatch({ type: "SET_RESULT_HANDLE", payload: {} });
    }
  }, [resultHandle]);

  const handleSubmitSave = async (event) => {
    //.log('event',event);
    if (checkout.id) {
      dispatch(putRequest(PATH.transactionPath, TYPE.GET_ALL_TRANSACTIONS, event.id, event, true)); 
    } else {
      dispatch(
        postRequest(PATH.transactionPath, event, TYPE.GET_ALL_TRANSACTIONS)
      );
    }
  };

  return (
    <Formik
      validationSchema={CheckoutSchema()}
      onSubmit={handleSubmitSave}
      initialValues={checkOutEditInitialValues(checkout)}
    >
      {({
        handleSubmit,
        handleChange,
        touched,
        values,
        errors,
        setFieldValue,
      }) => (
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            {props.type ==='Income' ? 'Add Cash In' : 'Add Cash Out'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="view-form">
              <Form
                onSubmit={handleSubmitSave}
                controlId="transactionRecEdit"
              >
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicTitle">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicTitle"
                      >
                        Transaction Type
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="type"
                        value={props.type}
                        disabled
                       
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicPriority">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPriority"
                      >
                        Category<b class="red-star">*</b>
                      </Form.Label>
                      <Form.Select
                        required
                        aria-label="Enter Priority"
                        name="category"
                        value={values.category}
                        onChange={(e)=>{ setFieldValue('type',props.type)
                        handleChange(e)
                      }}
                      >
                        <option value="">Select Type</option>
                        <option value="Commission">Commission</option>
                        <option value="Salary">Salary</option>
                        <option value="Others">Others</option>
                      </Form.Select>
                      {touched.category && (
                        <div className="form-error ">{errors.category}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicTitle">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicTitle"
                      >
                        Summary<b class="red-star">*</b>
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="title"
                        placeholder="Enter title"
                        value={values.title}
                        onChange={handleChange}
                      />
                      {touched.title && (
                        <div className="form-error ">{errors.title}</div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicTargetdate"
                      >
                        Transaction Date
                        {/* {new Date(values.targetdate).toISOString} */}
                      </Form.Label>

                      <Form.Control
                        required
                        type="date"
                        name="transactiondate"
                        placeholder="Enter transactiondate"
                        value={moment(values.transactiondate).format("YYYY-MM-DD")}
                        onChange={handleChange}
                      />
                      {touched.transactiondate && (
                        <div className="form-error ">
                          {errors.transactiondate}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicStatus">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicStatus"
                      >
                        Amount<b class="red-star">*</b>
                      </Form.Label>
                      <Form.Control
                        required
                        type="number"
                        name="amount"
                        placeholder="Enter Amount"
                        value={values.amount}
                        onChange={handleChange}
                      />
                      {touched.amount && (
                        <div className="form-error ">{errors.amount}</div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicStatus">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicStatus"
                      >
                        Payment Status
                      </Form.Label>
                      <Form.Select
                        required
                        aria-label="Enter status"
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                      >
                        <option value="">Select Status</option>
                        <option value="Paid">Paid</option>
                        <option value="Partially Paid">Partially Paid </option>
                        <option value="Not Paid">Not Paid</option>
                      </Form.Select>
                      {touched.status && (
                        <div className="form-error ">{errors.status}</div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={12}>
                    <Form.Group
                      className="mx-3"
                      controlId="formBasicDescription"
                    >
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicDescription"
                      >
                        Description
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="description"
                        placeholder="Enter description"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleSubmit}>
              Save
            </Button>
            <Button onClick={props.onHide} variant="light">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
export default CashOutEdit;
