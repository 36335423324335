import React, { useState , useEffect } from "react";
import { Col , Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../../api/inventoryApi";
import ToastMessage from "../ToastMessage";
import * as formik from "formik";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { schemaPassword } from "../ValidateSchemaHelper";
import { putRequest } from "../../api/inventoryApiTwo";
import { PATH } from "../../api/pathUrl";

const ChangePasswordProfile = (props) => {

    //.log("props inside cpp --> ",props)
    let dispatch = useDispatch();
    const [profile, setProfile] = useState(props.profile ? props.profile : {});
    const { Formik } = formik;

    const { resultHandle } = useSelector((state) => state.apiReducer);

  useEffect(() => {
    if (resultHandle.success) {
      dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })
      props.onHide();
    }
  }, [resultHandle]) 

    async function handleChangeSubmit(values) {

      //.log("values inside change pass --> ",values)
      // values.id = profile.id;
      dispatch(putRequest(`${PATH.userPath}/changePassword/`, undefined, values.id, values, false));


        // const result = await inventoryApi.updatePassword(values);
        // if (result.success) {
        //   ToastMessage(result.message, "success");
        //   props.onHide();
        // } else {
        //   ToastMessage(result.errors, "error");
        // }
      }

  return (
    <Formik
    validationSchema={schemaPassword()}
    onSubmit={handleChangeSubmit}
    initialValues={{
      id: profile.id,
      password: '',
      newpassword: '',
      confirmpassword: '',
    }}
  >
    {({ handleSubmit, handleChange, values, errors }) => (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col></Col>
            <Col lg={12}>
              <Form className="mt-3">
                <Row>
                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicFirstName">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Current Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Enter your current password"
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicFirstName">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        New Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="newpassword"
                        placeholder="Enter new password"
                        onChange={handleChange}
                        required
                        isInvalid={!!errors.newpassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.newpassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicPhone">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPhone"
                      >
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="confirmpassword"
                        placeholder="Enter confirm password"
                        onChange={handleChange}
                        required
                        isInvalid={!!errors.confirmpassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmpassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
          <Button variant="danger" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )}
  </Formik>
  );
};

export default ChangePasswordProfile;
