import React, { useState, useEffect } from "react";

import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Confirm from "../Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../../api/inventoryApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { RelatedListTask, FilesCreate, RelatedListFiles, TaskEdit } from "../index"



const LeadView = (props) => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const [lead, setLead] = useState(location.state ? location.state : {});

  //.log('lead---->>>',JSON.stringify(lead))
  const [modalShow, setModalShow] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [relatedListTasks, setRelatedListTasks] = useState(true);
  const [relatedListFiles, setRelatedListFiles] = useState(false);
  const [modalShowTaskfile, setModalShowFile] = useState(false);
  

  useEffect(() => {
    fetchLead();
  }, []);

  const fetchLead = () => {
    //.log('lead id - ', lead.id);
    async function initLead() {
      let result = await inventoryApi.fetchLead(lead.id);
      //.log('result===>:', result);
      if (result){
        setLead(result);
      }else{
        setLead({});
      }
    }
    initLead();
  }

  
 
  const deleteLead = async () => {
    const result = await inventoryApi.deleteLead(lead.id);
    if (result.success) navigate(`/leads`);
  };

  const editLead = () => {
    navigate(`/leads/${lead.id}/e`, { state: lead });
  };

  const submit = () => {
    setModalShowTask(false);
    navigate(`/leads/${lead.id}`, { state: lead });
  };


  const submitTasks = () => {
    setModalShowTask(false);

    
  };
  const submitfiles = () => {
    setModalShowFile(false);
  
  
  };

  const handleSelect = (key) => {
    if (key === 'tasks') {
      setRelatedListTasks(true);
      setRelatedListFiles(false);
    } else if (key === 'files') {
      setRelatedListTasks(false);
      setRelatedListFiles(true);
    } 
  }
  return (
    <div>
      {lead && <Container>
        {modalShow &&
          <Confirm
            show={modalShow}
            onHide={() => setModalShow(false)}
            deleteLead={deleteLead}
            title="Confirm delete?"
            message="You are going to delete the record. Are you sure?"
            table="lead"
          />}
        <Row className="view-form">
          <Col></Col>
          <Col lg={8}>
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                Lead
                <h4>Aslam Bari</h4>
              </Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={() => editLead(true)}>
                  <i class="fa-regular fa-pen-to-square"></i>
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={() => setModalShow(true)}
                >
                  Delete
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <label>Name</label>
                <span>
                  {lead.salutation} {lead.firstname} {lead.lastname}
                </span>
              </Col>
              <Col lg={6}>
                <label>Title</label>
                <span>{lead.title}</span>
              </Col>
              <Col lg={6}>
                <label>Status</label>
                <span>{lead.status}</span>
              </Col>
              <Col lg={6}>
                <label>Email</label>
                <span>{lead.email}</span>
              </Col>
              <Col lg={6}>
                <label>Phone</label>
                <span>{lead.phone}</span>
              </Col>
              <Col lg={6}>
                <label>Company</label>
                <span>{lead.company}</span>
              </Col>
              <Col lg={6}>
                <label>Street</label>
                <span>{lead.street}</span>
              </Col>
              <Col lg={6}>
                <label>City</label>
                <span>{lead.city}</span>
              </Col>
              <Col lg={6}>
                <label>State</label>
                <span>{lead.state}</span>
              </Col>
              <Col lg={6}>
                <label>Pincode</label>
                <span>{lead.pincode}</span>
              </Col>
              <Col lg={6}>
                <label>Country</label>
                <span>{lead.country}</span>
              </Col>
              <Col lg={6}>
                <label>Assigned Staff</label>
                <span>{lead.ownername}</span>
              </Col>
              <Col lg={6}>
                <label>Created By</label>
                <span>{lead.createdbyname}</span>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
        <Card bg="light" text="light" className="mb-2 mt-4">
          <Card.Header className="d-flex justify-content-between">
          <Tabs defaultActiveKey="tasks" id="uncontrolled-tab-example" onSelect={(key) => handleSelect(key)}>
                <Tab eventKey="tasks" title="Tasks"></Tab>
                <Tab eventKey="files" title="Files"></Tab>
              </Tabs>            
              {relatedListTasks && (
              <Button
                className="float-right btn-sm"
                onClick={() => setModalShowTask(true)}
              >
                New Task
              </Button>
            )}
            {modalShowTask && (
              <TaskEdit
                show={modalShowTask}
                onHide={() => setModalShowTask(false)}
                parentid={lead.id}
                table="lead"
                submitTasks={submitTasks}
              />
            )}
            {relatedListFiles && (
              <Button
                className="float-right btn-sm"
                onClick={() => setModalShowFile(true)}
              >
                Upload File
              </Button>
            )}
            {modalShowTaskfile && (
              <FilesCreate show={modalShowTaskfile} onHide={() => setModalShowFile(false)} parent={lead} table="lead"
                submitfiles={submitfiles} />
            )}
          </Card.Header>
          <Card.Body>
            {relatedListTasks ? <RelatedListTask parent={lead}/> : ""}
            {relatedListFiles ? <RelatedListFiles parent={lead} /> : ""}

          </Card.Body>
        </Card>
      </Container>}
    </div>
  );
};

export default LeadView;
