import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import inventoryApi from "../../api/inventoryApi";
import { useNavigate } from "react-router-dom";
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import moment from "moment";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
const OrderList = () => {
  const [values, setValues] = useState([]);
  const [orderItem, setOrderItem] = useState([]);
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [orderLineItem, setOrderLineItem] = useState([]);
  useEffect(() => {
    async function init() {
      const orders = await inventoryApi.fetchOrder();
      if (orders) //.log(orders);
      setBody(orders);
      setValues(orders);
    }
    init();
  }, []);

  const printDocument = async (e, row) => {
    //.log(row.id);
    const apiresult = await inventoryApi.fetchOrderById(row);
    //.log("result", apiresult);
    if (apiresult) {
      //.log("if enter ", apiresult);
      setOrderItem(apiresult);
      pdfRow();
    }
    

    
  };

  const pdfRow = () => {

    const pdfTable = document.getElementById("divToPrint");
    //.log("pdf table", pdfTable);
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize: true,
    });
    //.log(html);
    const documentDefinition = { content: html };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).open();
  }

  const header = [
    {
      title: "Order Number",
      prop: "ordernumber",
      isFilterable: true,
      cell: (row) => (
        <Link
        
          to={"/orders/" + row.id}
          state={row}
          
        >
          {row.ordernumber}
        </Link>
      ),
    },
    {
      title: "Order Date",
      prop: "orderdate",
      cell: (row) => moment(row.targetdate).format("DD-MM-YYYY"),
    },
    { title: "Status", prop: " status", isFilterable: true },
    { title: "Description ", prop: "description", isFilterable: true },
    { title: "Contact Id ", prop: "contactid", isFilterable: true },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <Button
          className="btn-sm mx-2"
          variant="danger"
          onClick={(e) => printDocument(e, row)}
        >
          <i class="fa fa-file-pdf-o"></i>
        </Button>
      ),
    },
  ];

  const labels = {
    beforeSelect: " ",
  };

  const createOrder = () => {
    navigate(`/orders/e`);
  };

  return (
    <>
      <Row className="g-0">
        <Col lg={2} className="mx-2">
          <Link className="nav-link">
            Home <i class="fa-solid fa-chevron-right"></i> Order
          </Link>
        </Col>
        <Col lg={10} className="p-lg-4">
          {body ? (
            <DatatableWrapper
              body={body}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 10,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Row className="mb-4">
                <Col
                  xs={12}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Filter />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-start align-items-start"
                >
                  <PaginationOptions labels={labels} />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
              <Button className="btn-sm" variant="outline-primary" onClick={() => createOrder(true)}>New  Order</Button>

                </Col>
              </Row>

              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            ""
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>

      
        <div id="divToPrint" style={{display : 'None'}}>
          <div className="d-flex justify-content-center">
            <table>
              <tr>
                <td style={{ width: "100px", border: "None" }}></td>
                <td style={{ width: "140px", border: "None" }}></td>
                <td style={{ width: "200px", border: "None" }}>
                  <h2>Garg Granites</h2>
                </td>
                <td style={{ width: "200px", border: "None" }}></td>
              </tr>
            </table>
          </div>

          <table>
            <td style={{ width: "150px", border: "None" }}>
              <b>To</b>:{orderItem.contactname}
            </td>
            <td style={{ width: "140px", border: "None" }}></td>
            <td style={{ width: "260px", border: "None" }}></td>
            <td style={{ width: "200px", border: "None" }}>
              <b>Date</b>: {moment(orderItem.orderdate).format("DD-MM-YYYY")}
            </td>
          </table>
          <table>
            <tbody>
              <tr>
                <td style={{ width: "100px", border: "None"}}>
                  <b>Stone</b>: {orderItem.productid}
                </td>
                <td style={{ width: "140px", border: "None" }}></td>
                <td style={{ width: "260px", border: "None" }}></td>
                <td style={{ width: "70px", border: "None" }}></td>
                <td style={{ width: "100px", border: "None" }}>
                  <b>No</b>: {orderItem.ordernumber}
                </td>
              </tr>
            </tbody>
          </table>
          <table class="myTable">
            <tr>
              <td style={{ width: "160px" }}>Slab No.</td>
              <td style={{ width: "200px" }}>Length(in)</td>
              <td style={{ width: "150px" }}>Width(in)</td>
              <td style={{ width: "160px" }}>Area (sq in)</td>
            </tr>
            <tbody>
              {orderItem?.orderlineitem?.map((item, index) => (
                <div>
                  <tr key={index}>
                    <td>{item.productname}</td>
                    <td>{item.length}</td>
                    <td>{item.width}</td>
                    <td>{}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td></td>
                    <td>Total Area (sq in)</td>
                    <td>{}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td></td>
                    <td>No Of Items</td>
                    <td>{item.totalitems}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>Amount Per (sq in)</td>
                    <td>{item.price}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>{}</td>
                    <td>Total Amount</td>
                    <td>{item.totalamount}</td>
                  </tr>
                </div>
              ))}
            </tbody>
          </table>
        </div>
      
    </>
  );
};

export default OrderList;
