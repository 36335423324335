import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import Confirm from "../Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../../api/inventoryApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { RelatedListTask, FilesCreate, RelatedListFiles, TaskEdit } from "../index"


const ProductView = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const product = location.state;
    
    const [modalShow, setModalShow] = useState(false);
    const [modalShowTask, setModalShowTask] = useState(false);
    const [relatedListTasks, setRelatedListTasks] = useState(true);
    const [relatedListFiles, setRelatedListFiles] = useState(false);
    const [modalShowTaskfile, setModalShowFile] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [files, setFiles] = useState([]);
    
    useEffect(() => {
      taskList();
      filesList();
    }, []);
  
    
    const taskList = () => {
      async function init() {
        let task = await inventoryApi.fetchTasks(product.id);
        if (task && task?.length > 0){
          setTasks(task);
        }else{
          setTasks([]);
        }
      }
      init();
    }
    const filesList = () => {
      async function init() {
        let file = await inventoryApi.fetchFiles(product.id);
        //.log(file)
        if (file && file?.length > 0) {
          setFiles(file);
        } else {
          setFiles([]);
        }
      }
      init();
    }



    const deleteProduct = async () => {
        const result = await inventoryApi.deleteProduct(product.id);
        if (result.success) navigate(`/products`);
      };
    
      const editProduct = () => {
        navigate(`/products/${product.id}/e`, { state: product });
      };

      const submitTasks = () => {
        setModalShowTask(false);
        setTasks([]);
        taskList();
      };
      const submitfiles = () => {
        setModalShowFile(false);
        setFiles([]);
        filesList();
      };
    
      const handleSelect = (key) => {
        if (key === 'tasks') {
          setRelatedListTasks(true);
          setRelatedListFiles(false);
        } else if (key === 'files') {
          setRelatedListTasks(false);
          setRelatedListFiles(true);
        }
      } 
      
  return (
    <div>
      {product && <Container>
        {modalShow &&
          <Confirm
            show={modalShow}
            onHide={() => setModalShow(false)}
            deleteLead={deleteProduct}
            title="Confirm delete?"
            message="You are going to delete the record. Are you sure?"
            table="lead"
          />}
        <Row className="view-form">
          <Col></Col>
          <Col lg={8}>
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                Product 
                <h4>Aslam Bari</h4>
              </Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={() => editProduct(true)}>
                  <i className="fa-regular fa-pen-to-square"></i>
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={() => setModalShow(true)}
                >
                  Delete
                </Button>
              </Col>
            </Row>
            <Row>
              
              <Col lg={6}>
                <label>Product Name</label>
                <span>{product.name}</span>
              </Col>
              <Col lg={6}>
                <label>Product Code</label>
                <span>{product.productcode}</span>
              </Col>
              <Col lg={6}>
                <label>Category</label>
                <span>{product.category}</span>
              </Col>
              <Col lg={6}>
                <label>Product SubCategory</label>
                <span>{product.subcategory}</span>
              </Col>
              <Col lg={6}>
                <label>Description</label>
                <span>{product.description}</span>
              </Col>
              <Col lg={6}>
                <label>Target Date</label>
                <span>{product.targetdate}</span>
              </Col>
              <Col lg={6}>
                <label>Created Date</label>
                <span>{product.createddate}</span>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
        <Card bg="light" text="light" className="mb-2 mt-4">
          <Card.Header className="d-flex justify-content-between">
          <Tabs defaultActiveKey="tasks" id="uncontrolled-tab-example" onSelect={(key) => handleSelect(key)}>
                <Tab eventKey="tasks" title="Tasks"></Tab>
                <Tab eventKey="files" title="Files"></Tab>
              </Tabs>            
              {relatedListTasks && (
              <Button
                className="float-right btn-sm"
                onClick={() => setModalShowTask(true)}
              >
                New Task
              </Button>
            )}
            {modalShowTask && (
              <TaskEdit
                show={modalShowTask}
                onHide={() => setModalShowTask(false)}
                parent={product}
                table="lead"
                submitTasks={submitTasks}
              />
            )}
            {relatedListFiles && (
              <Button
                className="float-right btn-sm"
                onClick={() => setModalShowFile(true)}
              >
                Upload File
              </Button>
            )}
            {modalShowTaskfile && (
              <FilesCreate show={modalShowTaskfile} onHide={() => setModalShowFile(false)} parent={product} table="lead"
                submitfiles={submitfiles} />
            )}
          </Card.Header>
          <Card.Body>
            {relatedListTasks ? <RelatedListTask parent={product}/> : ""}
            {relatedListFiles ? <RelatedListFiles parent={product} files={files}/> : ""}
          </Card.Body>
        </Card>
      </Container>}
    </div>
  )
}

export default ProductView
