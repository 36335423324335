import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table} from "react-bootstrap";
import {useNavigate} from 'react-router-dom'
import inventoryApi from "../../api/inventoryApi";
import Form from "react-bootstrap/Form";
import jwt_decode from "jwt-decode";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRequest } from "../../api/inventoryApiTwo";
import { PATH , TYPE } from "../../api/pathUrl";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";
import Modal from 'react-bootstrap/Modal';


const UserList = () => {
  
  const decoded = localStorage.getItem("token")? jwt_decode(localStorage.getItem("token")) : '';
  const { usersList } = useSelector((state) => state.apiReducer);
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [showContactModal, setShowContactModal] = useState(false)
  const [userRole, setUserRole] = useState("")
  // const[user,setUser] = useState([]);
  let dispatch = useDispatch();

  useEffect(() => {
    try {
      setUserRole(
        jwt_decode(localStorage.getItem("token"))?.role?.at(0).name
      );
    } catch (error) {
      console.log("Err",error);
    }
  }, [localStorage.getItem("token")])
  

  useEffect( () => {
    //.log('decoded',decoded)

      //  async function init(){
        dispatch({ type:  TYPE.SET_USER , payload: {} })
        // if(usersList.length ===  0){
           dispatch(getRequest(PATH.userPath, TYPE.GET_ALL_USERS));
        // }

        // //.log('UserList',usersList)
        // if(usersList.length === 0)
        //   dispatch(getUser());
        // if(usersList.length > 0)
        //   setUser(usersList);
        //   setBody(usersList.filter(e=>e.id == decoded?.id));
      // }
      // init();
  }, []);

  useEffect(() => {

    if (usersList.length > 0) {
      setBody(usersList);
      //.log("inside usersList res --> ",usersList);
      //setBody(usersList.filter(e=>e.id !== decoded?.id));
    }
  
  }, [usersList])
  

// Create table headers consisting of 4 columns.
const header = [
  { title: 'User Name', prop: 'username' , isFilterable: true ,
  cell: (row) => (
    <Link
      to={"/users/" + row.id}
      state={row}
      onClick={()=> dispatch({ type: "SET_USER", payload: {} })}
    >
      {row.username}
    </Link>
  ) },
  { title: 'Role', prop: 'rolename' , isFilterable: true }, 
  { title: 'Email', prop: 'email' , isFilterable: true }, 
  { title: 'Phone', prop: 'phone' , isFilterable: true }, 
  {title: 'Active Status', prop: 'isactive',
  cell: (row) => (
    <>
    {row.isactive ? "Active" : "Inactive"}
    </>
  ) },
];

// Randomize data of the table columns.
// Note that the fields are all using the `prop` field of the headers.
const labels = {
  beforeSelect: " "
}

const createUser = () =>{

  if(userRole && userRole === "SYSADMIN") navigate(`/users/e`);
  else setShowContactModal(!showContactModal)
    
    //.log('if user create call');
}

  return (
    <Row className="g-0">

      <Col lg={4} className="px-4">
        <CustomSeparator
          currentCmpName="Users"
          indexLength="0"
          url="/users"
        ></CustomSeparator>
      </Col>

    <Col lg={12} className = "p-lg-4">
      {body ?
        <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10,
            options: [5, 10, 15, 20]
          }
        }}>
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
            <Col
              xs={8}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >
              <PaginationOptions labels={labels} />

            </Col>
            <Col
              xs={4}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Button className="btn-sm" variant="outline-primary" onClick={() => createUser(true)}>New User</Button>
            </Col>
          </Row>
          <Table striped className="data-table">
            <TableHeader />
            <TableBody />
          </Table>
          <Pagination />

        </DatatableWrapper> : ''}
    </Col>

    <Modal centered size="lg" show={showContactModal} onHide={() => setShowContactModal(!showContactModal)}>
      <Modal.Header closeButton className="bg-primary text-light">
        <Modal.Title>
          <h2>Contact us</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="fs-5 p-3">
        <p>
          It seems like you need to add more users to your account. Don't worry, we've got you covered.
          Simply reach us at <strong className="fs-5">contact@ibirdsservices.com</strong>, and we'll assist you with adding those extra licenses.
        </p>
        <p className="text-muted">Thank You.</p>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button className="btn-primary" onClick={() => setShowContactModal(!showContactModal)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>

  </Row>
  );
};

export default UserList;