import React, { useState } from "react";
import { Button, Col, Container, Row,Form } from "react-bootstrap";
import inventoryApi from "../../api/inventoryApi";
import ToastMessage from "../ToastMessage";
import jwt_decode from "jwt-decode";

const ChangePassword = ({userId}) => {
const [validated, setValidated] = useState(false);
const [error, setError] = useState(false);
const [user, setUser] = useState(
        {id:"",
        password:"",
        newpassword:"",
        confirmpassword:""
        });
const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
};



async function handleSubmit(e) {

    //.log("User --> ",user)

    if(!user.hasOwnProperty("password") || user.password === ""){
        setError("Please enter your current password !");
        return;
    }
    else{
        setError(false); 
    }
    
    if(user.hasOwnProperty("newpassword") && user.hasOwnProperty("confirmpassword")){
        if(user.newpassword !== user.confirmpassword){
            setValidated("New password and confirmed password did not match !");
            return;
        }
        else if(user.password === user.confirmpassword){
            setValidated("New password should not be the same as old password !");
            return;
        }
        else{
            if(CheckPassword(user.confirmpassword)){
                setValidated("Password must be 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter !");
                return;
            }
        }
    }

    e.preventDefault();
    
        //.log('user',user)
            const result = await inventoryApi.updatePassword(user);
            //.log(result)
            if(result.success){
                setValidated(false);
                ToastMessage(result.message,"success");
                setUser({
                    password: "",
                    newpassword: "",
                    confirmpassword: "",
                });
            }
            else{
                setValidated(false);
                ToastMessage(result.errors,"error");
                setError("You entered wrong current password !")
            }
}

    function CheckPassword(inputtxt) {
        var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
        if (inputtxt.match(passw)) {
            return false;
        }
        else {
            return true;
        }
    }



  return (
    <div>
       <Container className="view-form">
            <Row>
                <Col></Col>
                <Col lg={8}>
                    <Form className="mt-3" onSubmit={handleSubmit}>
                        <Row className="view-form-header align-items-center">
                            <Col lg={3}>
                                 Change Password
                                
                            </Col>
                            
                        </Row>
                        <Row>
                            <Col >
                               
                                <Form.Group className="mx-3" controlId="formBasicFirstName">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Current Password
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        placeholder="Enter Your current password"
                                        isInvalid ={error}
                                        onChange={handleChange}
                                        value={user.password} 
                                    />
                                      <Form.Control.Feedback type="invalid">
                                          {error}
                                      </Form.Control.Feedback>
                                      {/* <Form.Control.Feedback type="invalid">
                                          Please enter your current password !
                                      </Form.Control.Feedback> */}
                                </Form.Group>
                               
                                </Col>
                               
                        </Row>
                        <Row>
                            <Col >
                               
                                <Form.Group className="mx-3" controlId="formBasicFirstName">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        New Password
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="newpassword"
                                        placeholder="Enter Your password"
                                        onChange={handleChange} 
                                        value={user.newpassword} 
                                    />
                                </Form.Group>
                                </Col>
                               
                        </Row>
                        <Row>                           
                            <Col>
                                <Form.Group className="mx-3" controlId="formBasicPhone">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicPhone"
                                    >
                                       Confirm Password
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="confirmpassword"
                                        placeholder="Enter confirm password"
                                        isInvalid ={validated}
                                        onChange={handleChange}
                                        value={user.confirmpassword} 
                                    />
                                      <Form.Control.Feedback type="invalid">
                                          {validated}
                                      </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col></Col>
            </Row>
           
            
        </Container>
        <Container>
            <Row>
                <Col lg={12} className=" p-3 d-flex justify-content-center p">
                    <Button className="mx-2" onClick={handleSubmit}>
                        Save
                    </Button>
                                
                </Col>
            </Row>
        </Container>
    </div>
  );
};

export default ChangePassword;