import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { schemaContactEdit } from "../ValidateSchemaHelper";
import { States } from "../../constants/CONSTANT";
import { MSG } from "../Messages";
import { EMAIL_REGEX, MOBILE_REGEX, NAME_REGEX } from "../Regex";
import * as formik from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { contactEditInitialValues } from "../InitialValuesHelper";
import { putRequest, postRequest } from "../../api/inventoryApiTwo";
import { PATH, TYPE } from "../../api/pathUrl";

const ContactEdit = (props) => {
  const [validated] = useState(false);
  const [stateList, setStateList] = useState(false);
  const [cityList, setCityList] = useState(false);
  let dispatch = useDispatch();
  const { resultHandle, contactDetail } = useSelector(
    (state) => state.apiReducer
  );

  const location = useLocation();
  const [contact, setContact] = useState(location.state ? location.state : {});
  useEffect(() => {
    var temp = [];
    States.map((ele) => {
      if (!temp.includes(ele.state)) {
        temp.push(ele.state);
      }
    });
    setStateList(temp.sort());
    var tempList = States.filter((ele) => ele.state === contactDetail.state);
    setCityList(tempList.sort());
  }, []);

  useEffect(() => {
    if (resultHandle.success) {
      navigate(`/contacts/${resultHandle.data.id}`, {
        state: resultHandle.data,
      });
      dispatch({ type: "SET_RESULT_HANDLE", payload: {} });
    }
  }, [resultHandle]);

  //const location = useLocation();
  const navigate = useNavigate();
  // const [contact, setContact] = useState(
  //   location.state ? location.state : { country: "India" }
  // );

  const handleSubmitSave = async (values) => {
    //.log('values',values)
    dispatch({ type: TYPE.SET_CONTACT, payload: {} });
    //.log('contactDetail',contactDetail)
    if (contactDetail?.id) {
      //.log('contactDetail',contactDetail)
      dispatch(
        putRequest(
          PATH.contactPath,
          TYPE.GET_ALL_CONTACTS,
          location.pathname.split("/")[2],
          values,
          false
        )
      );
    } else {
      dispatch(postRequest(PATH.contactPath, values, TYPE.GET_ALL_CONTACTS));
    }
  };

  const handleCancel = () => {
    if (contactDetail.id)
      navigate("/contacts/" + contactDetail.id, { state: contactDetail });
    else navigate("/contacts");
  };
  const { Formik } = formik;
  const schema = yup.object().shape({
    salutation: yup.string().required(MSG.SAL_REQ),
    firstname: yup
      .string()
      .matches(NAME_REGEX, MSG.VALIDNAMREQ)
      .required(MSG.FNAMEREQ),
    lastname: yup
      .string()
      .matches(NAME_REGEX, MSG.VALIDNAMREQ)
      .required(MSG.LNAMEREQ),
    email: yup.string().nullable().matches(EMAIL_REGEX, MSG.INVEMAILREQ),
    phone: yup
      .string()
      .matches(MOBILE_REGEX, MSG.INVMOBNUMREQ)
      .required(MSG.MOBNUMREQ),
    street: yup.string().required(MSG.STREETSREQ),
    state: yup.string().required(MSG.STATEREQ),
    city: yup.string().required(MSG.CITYREQ),
    title: yup.string().required(MSG.TITLE_REQ),
    occupation: yup.string().when("title", (val, schema) => {
      //.log("val", val);
      if (val.includes("Agent")) {
        return yup.string().required(MSG.OCCUPATION_REQ);
      } else {
        return yup.string().notRequired();
      }
    }),
    pincode: yup
      .string()
      .matches(/^\d{6}$/, MSG.VLDPINCODE_REQ)
      .required(MSG.PIN_REQ),
  });
  return (
    <Container className="view-form">
      <Row>
        <Col></Col>
        <Col lg={8} style={{ backgroundColor: "#fff", paddingBottom: "2rem" }}>
          <Formik
            validationSchema={schemaContactEdit()}
            onSubmit={handleSubmitSave}
            initialValues={contactEditInitialValues(contact)}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              values,
              touched,
              errors,
            }) => (
              <Form
                className="mt-3"
                onSubmit={handleSubmitSave}
                // noValidate
                // validated={validated}
              >
                <Row
                  className="view-form-header align-items-center"
                  style={{
                    marginTop: "-15px",
                  }}
                >
                  <Col lg={3}>
                    {contact.id ? (
                      <>
                        Edit Room
                        <h4>
                          {(contact.salutation !== null
                            ? contact.salutation
                            : "") +
                            "" +
                            contact.firstname +
                            " " +
                            contact.lastname}
                        </h4>
                      </>
                    ) : (
                      "New Contact"
                    )}
                  </Col>
                  <Col lg={9} className="d-flex justify-content-end">
                    <Button className="btn-sm mx-2" onClick={handleSubmit}>
                      Save
                    </Button>
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-between">
                    <Form.Group
                      className="mx-3"
                      controlId="formBasicsalutation"
                    >
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicsalutation"
                      >
                        Salutation<b class="red-star">*</b>
                      </Form.Label>
                      <Form.Select
                        aria-label="Enter Salutation"
                        name="salutation"
                        value={values.salutation}
                        onChange={handleChange}
                      >
                        <option value="">Salutation</option>
                        <option value="Mr. ">Mr</option>
                        <option value="Miss">Miss</option>
                        <option value="Mrs">Mrs</option>
                      </Form.Select>
                      {touched.salutation && (
                        <div className="form-error">{errors.salutation}</div>
                      )}
                    </Form.Group>
                    <Form.Group className="mx-3" controlId="formBasicFirstName">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        First Name <b class="red-star">*</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstname"
                        placeholder="Enter firstname"
                        required
                        value={values.firstname}
                        onChange={handleChange}
                      />
                      {touched.firstname && (
                        <div className="form-error">{errors.firstname}</div>
                      )}
                    </Form.Group>
                    <Form.Group className="mx-3" controlId="formBasicLastName">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicLastName"
                      >
                        Last Name <b class="red-star">*</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        required
                        name="lastname"
                        placeholder="Enter lastname"
                        value={values.lastname}
                        onChange={handleChange}
                      />
                      {touched.lastname && (
                        <div className="form-error">{errors.lastname}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicEmail">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicEmail"
                      >
                        Email
                      </Form.Label>
                      <Form.Control
                        required
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {touched.email && (
                        <div className="form-error">{errors.email}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicPhone">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPhone"
                      >
                        Phone <b class="red-star">*</b>
                      </Form.Label>
                      <Form.Control
                        className="no-arrows"
                        required
                        type="number"
                        name="phone"
                        // isInvalid={phoneValid}
                        placeholder="Enter phone"
                        value={values.phone}
                        onChange={(e) => {
                          setFieldValue("phone", e.target.value.slice(0, 10));
                        }}
                      />
                      {touched.phone && (
                        <div className="form-error">{errors.phone}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicTitle">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicTitle"
                      >
                        Title<b class="red-star">*</b>
                      </Form.Label>
                      <Form.Select
                        aria-label="Enter"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                      >
                        <option value="">--Select--</option>
                        <option value="Manager">Manager</option>
                        <option value="Director">Director</option>
                        <option value="CEO">CEO</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                      {touched.title && (
                        <div className="form-error">{errors.title}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasiccompany">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasiccompany"
                      >
                        Company
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="company"
                        placeholder="Enter company"
                        value={values.company}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicStreet">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicStreet"
                      >
                        Street<b class="red-star">*</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="street"
                        placeholder="Enter street"
                        value={values.street}
                        onChange={handleChange}
                      />
                      {touched.street && (
                        <div className="form-error">{errors.street}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicState">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicState"
                      >
                        State<b class="red-star">*</b>
                      </Form.Label>
                      <Form.Select
                        name="state"
                        value={values.state}
                        onChange={(event) => {
                          var temp = States.filter(
                            (ele) => ele.state === event.target.value
                          );
                          setCityList(temp.sort());
                          handleChange(event);
                        }}
                        // isInvalid={!!errors.state}
                      >
                        <option value="">Select State</option>
                        {stateList &&
                          stateList.map((state) => (
                            <option value={state}>{state}</option>
                          ))}
                      </Form.Select>
                      {touched.state && (
                        <div className="form-error">{errors.state}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicCity">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicCity"
                      >
                        City<b class="red-star">*</b>
                      </Form.Label>
                      <Form.Select
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        disabled={!values.state}
                        // isInvalid={!!errors.city}
                      >
                        <option value="">Select City</option>
                        {cityList &&
                          cityList.map((ele) => (
                            <option value={ele.name}>{ele.name}</option>
                          ))}
                      </Form.Select>

                      {touched.city && (
                        <div className="form-error">{errors.city}</div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicPin">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPin"
                      >
                        Pincode<b class="red-star">*</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        required
                        name="pincode"
                        // isInvalid={pinValid}
                        placeholder="Enter pincode"
                        value={values.pincode}
                        onChange={handleChange}
                      />

                      {touched.pincode && (
                        <div className="form-error">{errors.pincode}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicCountry">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicCountry"
                      >
                        Country
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        name="country"
                        placeholder="Enter country"
                        value="India"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default ContactEdit;
