import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-toastify/dist/ReactToastify.css";
import inventoryApi from "../../api/inventoryApi";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
import CreateContactForBooking from "../Contact/CreateContactForBooking";
import { MSG } from "../Messages";
import { NAME_REGEX } from "../Regex";
import * as formik from "formik";
import * as yup from "yup";
import ToastMessage from "../ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import { createBooking, fetchBookings, updateBooking } from "../APIs";
import { calculateDays, calculateAmount } from "../Helper";
import { bookingEditInitialValues } from "../InitialValuesHelper";
import { getRequest, postRequest, putRequest } from "../../api/inventoryApiTwo";
import { PATH, TYPE } from "../../api/pathUrl";
import {
  AdvanceschemaBookingEdit,
  schemaBookingEdit,
} from "../ValidateSchemaHelper";

const BookingEdit = (props) => {
  const ref = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [booking, setBooking] = useState(
    location.state
      ? location.state.room
        ? { isgst: false }
        : location.state
      : { isgst: false }
  );
  const [roomSelected, setRoomSelected] = useState("");
  const [listpricing, setListPricing] = useState();
  const [extrabed, setExtraBed] = useState();
  const [roomdetail, setRoomDetail] = useState();
  const [showContact, setShowContact] = useState(false);
  const [contactdetail, setContactDetail] = useState();
  const [checkin, setCheckin] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [checkout, setCheckout] = useState();
  const [availableRooms, setAvailableRooms] = useState(false);
  const [contactId, setContactId] = useState();
  const [contactList, setContactList] = useState([]);
  const [defaultContact, setDefaultContact] = useState([]);
  const [roomsList, setRoomList] = useState([]);
  const [defaultRoom, setdefaultRoom] = useState([]);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [toastError, setToastError] = useState();
  const [room, setRoom] = useState(
    location.state ? (location.state.room ? location.state.room.room : {}) : {}
  );

  const {
    bookingDetail,
    roomList,
    bookingRoomList,
    contactsList,
    resultHandle,
  } = useSelector((state) => state.apiReducer);

  useEffect(() => {
    async function intit1() {
      // dispatch(getRequest(PATH.roomPath, TYPE.GET_ALL_ROOMS));
      // //.log("All room for dates --> ",resultRoom)
      // //.log("booking.roomid --> ",booking.roomid)
      // if (resultRoom) {
      //   if (booking.id) {
      //     setAvailableRooms(true);
      //     setRoomDetail(resultRoom.find((ele) => ele.id === booking.roomid));
      //   }
      // }
    }
    intit1();
  }, []);

  useEffect(() => {
    // setDefaultContact([]);

    async function init() {
      dispatch(getRequest(PATH.roomPath, TYPE.GET_ALL_ROOMS));

      dispatch(getRequest(PATH.contactPath, TYPE.GET_ALL_CONTACTS));

      // const conResult = await inventoryApi.fetchContacts();
      // //.log("Contact result --> ",conResult)

      // if (conResult.data) {
      //   setContactList(conResult.data.map((data) => {return {...data,contactname: data.firstname + "." + data.lastname + " - " + data.phone}}));
      //   if (booking.id) {
      //     setDefaultContact([{ id: booking.contactid, contactname: booking.contact_name }]);
      //     setContactDetail(conResult.data.find((ele) => ele.id === booking.contactid));
      //   } else if (contactId) {
      //     let contactDetail = conResult.data.find((ele) => ele.id === contactId);
      //     setDefaultContact([{ id: contactId,contactname:contactDetail.firstname +"." +contactDetail.lastname +" - " +contactDetail.phone}]);
      //     setBooking({ ...booking, contactid: contactId });
      //     setContactDetail(contactDetail);
      //   } else {
      //     setDefaultContact([{ id: "", contactname: "" }]);
      //   }
      // } else {
      //   setDefaultContact([{ id: "", contactname: "" }]);
      // }
    }

    init();
  }, [showContact]);

  useEffect(() => {
    //.log("rooms list in bookigEdit --> ", roomList);
    //.log("contacts list in bookigEdit --> ", contactsList);

    // async function intit1() {

    //   //.log("All room for dates --> ",resultRoom)
    //   //.log("booking.roomid --> ",booking.roomid)
    if (roomList.length > 0) {
      if (booking.id) {
        setAvailableRooms(true);
        setRoomDetail(roomList.find((ele) => ele.id === booking.roomid));
      }
    }

    if (booking.id) {
      //.log("inside if", booking);
      setdefaultRoom([{ id: booking.roomid, roomname: booking.roomname }]);
    } else if (room.id) {
      //.log("inside else if");
      setAvailableRooms(true);
      setdefaultRoom([
        { id: room.id, roomname: `${room.block} Block | Room No - ${room.roomno}` },
      ]);
      setRoomDetail(room);
      setBooking({ ...booking, roomid: room.id });
    } else {
      //.log("inside else");
      setdefaultRoom([{ id: "", roomname: "" }]);
    }

    // if (contactsList.length > 0) {
    if (contactsList.length > 0)
      setContactList(
        contactsList.map((data) => {
          return {
            ...data,
            contactname:
              data.firstname + "." + data.lastname + " - " + data.phone,
          };
        })
      );
    if (booking.id) {
      setDefaultContact([
        { id: booking.contactid, contactname: booking.contact_name },
      ]);
      setContactDetail(
        contactsList.find((ele) => ele.id === booking.contactid)
      );
    } else if (contactId) {
      let contactDetail = contactsList.find((ele) => ele.id === contactId);
      setDefaultContact([
        {
          id: contactId,
          contactname:
            contactDetail.firstname +
            "." +
            contactDetail.lastname +
            " - " +
            contactDetail.phone,
        },
      ]);
      setBooking({ ...booking, contactid: contactId });
      setContactDetail(contactDetail);
    } else {
      setDefaultContact([{ id: "", contactname: "" }]);
    }
    // } else {
    //   setDefaultContact([{ id: "", contactname: "" }]);
    // }

    // }
    // intit1();
  }, [roomList, contactsList]);

  useEffect(() => {
    async function intit() {
      // var temp;
      if (checkin && checkout && availableRooms) {
        let url;
        if (booking.roomid)
          url = `getroomsbydate?startdate=${checkin}&enddate=${checkout}&roomid=${booking.roomid}&bookingid=${booking.id}`;
        else
          url = `getroomsbydate/?startdate='${checkin}'&enddate='${checkout}'`;

        //create path
        dispatch(
          getRequest(`${PATH.roomPath}${url}`, TYPE.GET_ROOM_FOR_BOOKING)
        );

        // const result = await inventoryApi.bookingRoom(
        //   checkin,
        //   checkout,
        //   booking.roomid,
        //   booking.id
        // );
        //   //.log("result by date---->", result);
        //   if (result.success && Array.isArray(result.data)) {
        //     setRoomList(
        //       result.data.map((data) => {
        //         return { ...data, roomname: data.name };
        //       })
        //     );
        //     setToastError();
        //   } else if (result.success && !result.response.success) {
        //     setToastError(result.message);
        //     ToastMessage(result.message, "error");
        //   }
        // }

        // if (temp) return;
        // if (booking.id) {
        //   //.log("inside if",booking)
        //   setdefaultRoom([{ id: booking.roomid, roomname: booking.roomname }]);
        // } else if (room.id) {
        //   //.log("inside else if")
        //   setAvailableRooms(true);
        //   setdefaultRoom([
        //     { id: room.id, roomname: room.name + " - " + room.roomno },
        //   ]);
        //   setRoomDetail(room);
        //   setBooking({ ...booking, roomid: room.id });
        // } else {
        //   //.log("inside else")
        //   setdefaultRoom([{ id: "", roomname: "" }]);
      }
    }

    intit();
  }, [availableRooms]);

  useEffect(() => {
    if (resultHandle.success) {
      if (bookingSuccess === true) {
        navigate(`/booking/${resultHandle.data.id}`, {
          state: resultHandle.data,
        });
      } else {
        setBookingSuccess(false);
      }
      dispatch({ type: "SET_RESULT_HANDLE", payload: {} });
    }
  }, [resultHandle]);

  useEffect(() => {
    if (bookingRoomList.length > 0) {
      // navigate(`/booking/${resultHandle.data.id}`, { state: resultHandle.data });
      // dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })

      // if (result.success && Array.isArray(result.data)) {
      setRoomList(
        bookingRoomList.map((data) => {
          return { ...data, roomname: `${data.block} Block | Room No - ${data.roomno}` };
        })
      );
      //   setToastError();
      // } else if (result.success && !result.response.success) {
      //   setToastError(result.message);
      //   ToastMessage(result.message, "error");
      // }
    }

    // if (booking.id) {
    //   //.log("inside if", booking)
    //   setdefaultRoom([{ id: booking.roomid, roomname: booking.roomname }]);
    // } else if (room.id) {
    //   //.log("inside else if")
    //   setAvailableRooms(true);
    //   setdefaultRoom([
    //     { id: room.id, roomname: room.name + " - " + room.roomno },
    //   ]);
    //   setRoomDetail(room);
    //   setBooking({ ...booking, roomid: room.id });
    // } else {
    //   //.log("inside else")
    //   setdefaultRoom([{ id: "", roomname: "" }]);
    // }
  }, [bookingRoomList]);

  const handleSubmitSave = async (values) => {
    // let result = {};
    setBookingSuccess(true);

    if (!toastError) {
      if (booking.id) {
        dispatch(
          putRequest(
            PATH.bookingPath,
            TYPE.GET_ALL_BOOKINGS,
            location.pathname.split("/")[2],
            values,
            false
          )
        );

        // const result = await dispatch(updateBooking(values));
        // if (result.success) {
        //   dispatch(fetchBookings());
        //   navigate(`/booking/${values.id}`, { state: values });
        // }

        // // values.id = booking.id;
        // result = await inventoryApi.saveBooking(values);
        // if (result.success) {
        //   // ToastMessage("Booking has been updated successfully!", "success");
        // }
      } else {
        //values.isgst = values.isgst ? true : false;
        // values["status"] = "Advance booking";
        values["per_day_amount"] = listpricing;
        values["per_day_extrabed_amount"] = extrabed;
        // result = await inventoryApi.createBooking(values);

        dispatch(postRequest(PATH.bookingPath, values, TYPE.GET_ALL_BOOKINGS));

        // result = await dispatch(createBooking(values));
        // if (result.success) {
        // if (result.errors) {
        //   ToastMessage(result.errors, "error");
        // } else {
        // ToastMessage("Booking has been created successfully!", "success");
        // dispatch(fetchBookings());
        // navigate(`/booking/${result.data.id}`, { state: result.data });
        // }
        // }
      }
    } else {
      ToastMessage(toastError, "error");
    }
  };

  const handleCancel = () => {
    if (booking.id) navigate("/booking/" + booking.id, { state: booking });
    else navigate("/bookings");
  };

  const { Formik } = formik;

  return (
    <Container className="view-form">
      <Row className="d-flex justify-content-between">
        <Col lg={8} style={{ backgroundColor: "#fff" }}>
          <Formik
            validationSchema={schemaBookingEdit(booking, roomdetail)}
            onSubmit={handleSubmitSave}
            initialValues={bookingEditInitialValues(booking)}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              handleBlur,
              setFieldValue,
              getFieldProps,
              touched,
            }) => (
              <Form className="mt-3" onSubmit={handleSubmitSave}>
                <Row
                  className="view-form-header align-items-center"
                  style={{
                    marginTop: "-15px",
                  }}
                >
                  <Col lg={3}>
                    {booking.id ? <>Edit booking</> : "New Booking"}
                  </Col>
                  <Col lg={9} className="d-flex justify-content-end">
                    <Button className="btn-sm mx-2" onClick={handleSubmit}>
                      Save
                    </Button>
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Group className="mx-3" controlId="timeformat">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="timeformat"
                    >
                      Time Format <b class="red-star">*</b>
                    </Form.Label>
                    <Form.Select
                      style={{ width: "100%" }}
                      aria-label="Enter"
                      name="timeformat"
                      onBlur={handleBlur}
                      disabled={availableRooms}
                      value={values.timeformat}
                      onChange={(e) => {
                        if (e.target.value === "11 AM") {
                          setFieldValue("check_in_time", "11:00:00");
                          setFieldValue("check_out_time", "11:00:00");
                          handleChange(e);
                        } else {
                          setFieldValue("check_in_time", "");
                          setFieldValue("check_out_time", "");
                          handleChange(e);
                        }
                      }}
                    >
                      <option value="">--Select--</option>
                      <option value="24 hrs">24 Hrs</option>
                      <option value="11 AM">11 AM</option>
                    </Form.Select>
                    {touched.timeformat && (
                      <div className="form-error">{errors.timeformat}</div>
                    )}
                  </Form.Group>

                  <Form.Group className="mx-3" controlId="formBasicEmail">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicEmail"
                    >
                      Check-In-Date & Time <b class="red-star">*</b>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        style={{ width: "40%" }}
                        type="date"
                        name="checkindate"
                        placeholder="Enter"
                        required
                        onBlur={handleBlur}
                        disabled={availableRooms}
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        value={values.checkindate}
                        onChange={(e) => {
                          if (e.target.value && values.checkoutdate)
                            setFieldValue(
                              "noofdays",
                              calculateDays(
                                e.target.value,
                                getFieldProps("checkoutdate").value
                              )
                            );

                          if (booking.id) {
                            setFieldValue(
                              "totalamount",
                              calculateAmount(
                                e.target.value,
                                getFieldProps("checkoutdate").value,
                                getFieldProps("extra_bed").value != null
                                  ? getFieldProps("extra_bed").value
                                  : 0,
                                booking.per_day_amount,
                                booking.per_day_extrabed_amount
                              )
                            );
                          }

                          if (
                            values.checkoutdate &&
                            values.roomid &&
                            e.target.value
                          ) {
                          }
                          setCheckin(e.target.value);
                          handleChange(e);
                        }}
                      />
                      <Form.Control
                        style={{ width: "35%" }}
                        type="time"
                        name="check_in_time"
                        placeholder=""
                        required
                        disabled={
                          values.timeformat === "11 AM" ||
                          values.timeformat === ""
                        }
                        value={values.check_in_time}
                        onChange={handleChange}
                      />
                      {touched.checkindate && (
                        <div className="form-error">{errors.checkindate}</div>
                      )}
                      {!errors.checkindate && touched.check_in_time && (
                        <div className="form-error">{errors.check_in_time}</div>
                      )}
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mx-3" controlId="formBasicPhone">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicPhone"
                    >
                      Check-Out-Date & Time <b class="red-star">*</b>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        style={{ width: "40%" }}
                        required
                        type="date"
                        onBlur={handleBlur}
                        disabled={availableRooms}
                        name="checkoutdate"
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        placeholder="Enter"
                        value={values.checkoutdate}
                        onChange={(e) => {
                          if (values.checkindate && e.target.value) {
                            setFieldValue(
                              "noofdays",
                              calculateDays(
                                getFieldProps("checkindate").value,
                                e.target.value
                              )
                            );
                            if (booking.id) {
                              setFieldValue(
                                "totalamount",
                                calculateAmount(
                                  getFieldProps("checkindate").value,
                                  e.target.value,
                                  getFieldProps("extra_bed").value != null
                                    ? getFieldProps("extra_bed").value
                                    : 0,
                                  booking.per_day_amount,
                                  booking.per_day_extrabed_amount
                                )
                              );
                            }
                          }
                          setCheckout(e.target.value);
                          handleChange(e);
                        }}
                      />
                      <Form.Control
                        style={{ width: "35%" }}
                        required
                        type="time"
                        name="check_out_time"
                        disabled={
                          values.timeformat === "11 AM" ||
                          values.timeformat === ""
                        }
                        placeholder=""
                        value={values.check_out_time}
                        onChange={handleChange}
                      />
                      {touched.checkoutdate && (
                        <div className="form-error">{errors.checkoutdate}</div>
                      )}
                      {!errors.checkoutdate && touched.check_out_time && (
                        <div className="form-error">
                          {errors.check_out_time}
                        </div>
                      )}
                    </InputGroup>
                  </Form.Group>

                  {booking.id && !availableRooms && (
                    <Form.Group className="mx-3" controlId="formBasicPhone">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPhone"
                      >
                        Room No
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="goingto"
                        placeholder="Going to"
                        value={booking.roomname}
                        disabled
                      />
                    </Form.Group>
                  )}

                  <div style={{ marginTop: "38px" }}>
                    {availableRooms ? (
                      <Button
                        style={{ heigth: "2rem" }}
                        className="btn-sm"
                        variant="primary "
                        onClick={(e) => {
                          setAvailableRooms(false);
                        }}
                      >
                        <i className="fa-regular fa-pen-to-square"></i>
                      </Button>
                    ) : (
                      <Button
                        className="btn-sm"
                        variant="success"
                        disabled={
                          values.timeformat === undefined ||
                          values.timeformat === "" ||
                          values.checkindate === undefined ||
                          values.checkindate === "" ||
                          values.checkoutdate === undefined ||
                          values.checkoutdate === "" ||
                          values.checkoutdate === "Invalid date" ||
                          values.check_in_time === undefined ||
                          values.check_in_time === "" ||
                          values.check_out_time === undefined ||
                          values.check_out_time === ""
                        }
                        onClick={(e) => {
                          setAvailableRooms(true);
                        }}
                      >
                        {booking.id ? "Check" : "Search Rooms"}
                      </Button>
                    )}
                  </div>
                </div>

                {availableRooms && !toastError ? (
                  <div>
                    <Row>
                      <Col lg={6}>
                        <Form.Group
                          className="mx-3 position-relative"
                          controlId="roomname"
                        >
                          <Form.Label
                            className="form-view-label"
                            htmlFor="roomname"
                          >
                            Room No <b className="red-star">*</b>
                          </Form.Label>
                          {values.roomid && !booking.roomid && (
                            <span
                              className="clear-input-field"
                              onClick={() => {
                                ref.current.clear();
                                setdefaultRoom([{ id: "", roomname: "" }]);
                                setRoomDetail();
                                setFieldValue("roomid", "");
                                setFieldValue("totalamount", "");
                              }}
                            >
                              &#x2715;
                            </span>
                          )}
                          {defaultRoom.length > 0 ? (
                            <Typeahead
                              required
                              ref={ref}
                              id="basic-typeahead-roomno-single2"
                              defaultSelected={defaultRoom}
                              name="roomid"
                              labelKey="roomname"
                              value={values.roomid}
                              options={roomsList}
                              onChange={(event) => {
                                if (event.length > 0) {
                                  setFieldValue("roomid", event[0].id);
                                  setRoomDetail(
                                    roomsList.find(
                                      (ele) => ele.id === event[0].id
                                    )
                                  );
                                  setListPricing(
                                    event[0].pricing_details[0].price
                                  );
                                  setExtraBed(
                                    event[0].pricing_details[0].extra_bed
                                  );

                                  setFieldValue(
                                    "totalamount",
                                    values.noofdays *
                                      (event[0].pricing_details[0].price +
                                        (values.extra_bed
                                          ? values.extra_bed
                                          : 0) *
                                          event[0].pricing_details[0].extra_bed)
                                  );
                                } else {
                                  setRoomDetail();
                                  setFieldValue("totalamount", "");
                                  setFieldValue("roomid", "");
                                }
                              }}
                              placeholder="Choose a Room..."
                              disabled={booking.roomid ? true : false}
                            />
                          ) : (
                            "Room does not have pricing"
                          )}
                          {touched.roomid && (
                            <div className="form-error">{errors.roomid}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mx-3" controlId="contact">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="contact"
                          >
                            Contact <b class="red-star">*</b>
                          </Form.Label>
                          {defaultContact.length > 0 ? (
                            <InputGroup>
                              <Typeahead
                                required
                                id="basic-typeahead-single2"
                                defaultSelected={defaultContact}
                                name="contactid"
                                labelKey="contactname"
                                options={contactList}
                                onChange={(event) => {
                                  if (event.length > 0) {
                                    //.log("event", event[0].id);
                                    setFieldValue("contactid", event[0].id);
                                    setContactDetail(
                                      contactList.find(
                                        (data) => data.id === event[0].id
                                      )
                                    );
                                  } else {
                                    setFieldValue("contactid", "");
                                    setContactDetail();
                                  }
                                }}
                                placeholder="Choose a contact..."
                              />
                              <Button
                                variant="primary"
                                onClick={() => {
                                  setShowContact(true);
                                }}
                              >
                                <b>+</b>
                              </Button>
                              {touched.contactid && (
                                <div className="form-error">
                                  {errors.contactid}
                                </div>
                              )}
                            </InputGroup>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                        {showContact && (
                          <CreateContactForBooking
                            show={showContact}
                            onHide={() => {
                              setShowContact(false);
                            }}
                            handleClose={(data) => {
                              if (data) {
                                setContactId(data);
                                setFieldValue("contactid", data);
                                //.log("data", data);
                              }
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="mx-3" controlId="formBasicArea">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicTitle"
                          >
                            No of Days
                          </Form.Label>
                          <Form.Control
                            required
                            type="number"
                            name="noofdays"
                            placeholder="Enter No of days"
                            value={values.noofdays}
                            onChange={handleChange}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mx-3" controlId="formBasicPin">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicPin"
                          >
                            Total Amount
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="totalamount"
                            placeholder="Enter Amount"
                            value={values.totalamount}
                            disabled
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={6}>
                        <Form.Group
                          className="mx-3"
                          controlId="formBasicStreet"
                        >
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicStreet"
                          >
                            No of Guest<b class="red-star">*</b>
                          </Form.Label>
                          <Form.Control
                            required
                            type="number"
                            name="noofguest"
                            className="no-arrows"
                            min={1}
                            placeholder="Enter No of guest"
                            value={values.noofguest}
                            onChange={handleChange}
                          />
                          {touched.noofguest && (
                            <div className="form-error">{errors.noofguest}</div>
                          )}
                          {values.noofguest && roomdetail?.occupancy !== null &&
                          values.noofguest > roomdetail?.occupancy ? (
                            <div className="form-error">
                              Number of guest is greater than occupancy. Please
                              select appropriate extra bed
                            </div>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mx-3" controlId="formBasicEmail">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicEmail"
                          >
                            Extra Bed
                          </Form.Label>
                          <Form.Select
                            aria-label="Enter"
                            name="extra_bed"
                            value={values.extra_bed}
                            onChange={(e) => {
                              setFieldValue("extra_bed", e.target.value);
                              if (values.checkoutdate) {
                                if (booking.id) {
                                  setFieldValue(
                                    "totalamount",
                                    calculateAmount(
                                      getFieldProps("checkindate").value,
                                      getFieldProps("checkoutdate").value,
                                      e.target.value,
                                      booking.per_day_amount,
                                      booking.per_day_extrabed_amount
                                    )
                                  );
                                } else {
                                  setFieldValue(
                                    "totalamount",
                                    values.noofdays *
                                      (listpricing + e.target.value * extrabed)
                                  );
                                }
                              }
                            }}
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </Form.Select>
                          {!errors.noofguest &&
                          roomdetail?.occupancy !== null &&
                          values.noofguest + values.extra_bed >
                            roomdetail?.occupancy ? (
                            <div className="form-error">
                              Number of guest and Number of bed are greater than
                              occupancy
                            </div>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mx-3" controlId="formBasicCity">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicCity"
                          >
                            Coming From <b class="red-star">*</b>
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="comingfrom"
                            placeholder="Coming from"
                            value={values.comingfrom}
                            onChange={handleChange}
                          />
                          {touched.comingfrom && (
                            <div className="form-error">
                              {errors.comingfrom}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mx-3" controlId="formBasicState">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicState"
                          >
                            Going To <b class="red-star">*</b>
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="goingto"
                            placeholder="Going to"
                            value={values.goingto}
                            onChange={handleChange}
                          />
                          {touched.goingto && (
                            <div className="form-error">{errors.goingto}</div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={6}>
                        <Form.Group className="mx-3" controlId="formBasicState">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicState"
                          >
                            Source
                          </Form.Label>
                          <Form.Select
                            required
                            name="source"
                            value={values.source}
                            onChange={handleChange}
                          >
                            <option value="">Select Source </option>
                            <option value="MakeMyTrip">MakeMyTrip</option>
                            <option value="Agoda">Agoda</option>
                            <option value="OYO">OYO</option>
                            <option value="Other">Other</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group
                          className="mx-3"
                          controlId="formBasicDiscount"
                        >
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicDiscount"
                          >
                            Discount
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              min={0}
                              max={100}
                              className="no-arrows"
                              name="discount_percentage"
                              placeholder="Enter Discount"
                              value={values.discount_percentage}
                              onChange={handleChange}
                              aria-describedby="basic-addon"
                            />
                            <InputGroup.Text id="basic-addon">
                              ₹
                            </InputGroup.Text>

                            {touched.discount_percentage && (
                              <div className="form-error">
                                {errors.discount_percentage}
                              </div>
                            )}
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Check
                          inline-flex
                          label="Is GST apply"
                          name="isgst"
                          type="switch"
                          checked={values.isgst}
                          onChange={handleChange}
                          style={{ paddingLeft: "3rem" }}
                        />
                      </Col>

                      <Col lg={6}>
                        <Form.Group
                          className="mx-3"
                          controlId="formBasicDescription"
                        >
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicDescription"
                          >
                            Description/Arrival
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            name="description"
                            placeholder="Enter Description/Arrival"
                            value={values.description}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>{" "}
                  </div>
                ) : (
                  ""
                )}
              </Form>
            )}
          </Formik>
        </Col>

        <Col lg={3} style={{ backgroundColor: "#fff" }}>
          <Row className="view-form-header ">
            <Col>
              <center>Room Detail</center>
            </Col>
          </Row>
          {roomdetail ? (
            <>
              <Row className="mt-3 ms-2">
                <Col lg="6">Room No :</Col>
                <Col lg="4">
                  <b> {roomdetail && roomdetail.roomno}</b>
                </Col>
              </Row>
              <Row className="mt-3 ms-2">
                <Col lg="6">Ac / Non-Ac :</Col>
                <Col lg="4">
                  <b> {roomdetail && roomdetail.acnonac}</b>
                </Col>
              </Row>
              <Row className="mt-3 ms-2">
                <Col lg="6">Type :</Col>
                <Col>
                  <b> {roomdetail && roomdetail.type}</b>
                </Col>
              </Row>
              <Row className="mt-3 ms-2">
                <Col lg="6">Occupancy :</Col>
                <Col>
                  <b> {roomdetail && roomdetail.occupancy}</b>
                </Col>
              </Row>

              <Row className="mt-3 ms-2">
                <Col lg="6">Floor :</Col>
                <Col>
                  <b> {roomdetail && roomdetail.floor}</b>
                </Col>
              </Row>
              <Row className="mt-3 ms-2">
                <Col lg="6">Price :</Col>
                <Col>
                  <b>
                    {" "}
                    {getSymbolFromCurrency("INR")}
                    {listpricing
                      ? listpricing
                      : booking.per_day_amount
                      ? booking.per_day_amount
                      : ""}
                  </b>
                </Col>
              </Row>
              <Row className="mt-3 ms-2">
                <Col lg="6">Extra Bed :</Col>
                <Col>
                  <b>
                    {" "}
                    {getSymbolFromCurrency("INR")}
                    {extrabed
                      ? extrabed
                      : booking.per_day_extrabed_amount
                      ? booking.per_day_extrabed_amount
                      : ""}
                  </b>
                </Col>
              </Row>
              <Row className="mt-3">
                <hr></hr>
              </Row>
              <Row className="ms-2 m-3">
                <Col>
                  {booking.totalamount && (
                    <h6>
                      {" "}
                      Total Amount : {getSymbolFromCurrency("INR")}
                      {booking.totalamount}
                    </h6>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <center className="mt-3">Not Selected any room</center>
          )}

          <Row
            className="view-form-header"
            style={{ backgroundColor: "#f8f9fa" }}
          ></Row>
         
          <Row className="view-form-header align-items-center justify-content-center">
            <Col>
              <center>Contact Detail</center>
            </Col>
          </Row>
          {contactdetail ? (
            <>
              <Row className="mt-3 ms-2">
                <Col lg="4">Name :</Col>
                <Col className="text-left">
                  <b>
                    {" "}
                    {contactdetail.firstname + " " + contactdetail.lastname}
                  </b>
                </Col>
              </Row>
              <Row className="mt-3 ms-2">
                <Col lg="4">Phone :</Col>
                <Col className="text-left">
                  <b> {contactdetail.phone}</b>
                </Col>
              </Row>
              <Row className="mt-3 ms-2">
                <Col lg="4">Street :</Col>
                <Col>
                  <b> {contactdetail.street}</b>
                </Col>
              </Row>
              <Row className="mt-3 ms-2">
                <Col lg="4">Email :</Col>
                <Col>
                  <b> {contactdetail.email}</b>
                </Col>
              </Row>
              <Row className="mt-3 ms-2">
                <Col lg="4">City :</Col>
                <Col>
                  <b> {contactdetail.city}</b>
                </Col>
              </Row>
              <Row className="mt-3 ms-2 pb-3">
                <Col lg="4">State :</Col>
                <Col>
                  <b> {contactdetail.state}</b>
                </Col>
              </Row>
            </>
          ) : (
            <center className="mt-3">Not Selected any contact</center>
          )}
          
        </Col>
      </Row>
    </Container>
  );
};

export default BookingEdit;
