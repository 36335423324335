import { useState } from 'react';
import * as constants from '../constants/CONSTANT';
import { createSocketConnection } from '../socket';
import jwt_decode from "jwt-decode";
const authApi = {
 
    async login(email, password,dispatch) {

        //.log("url",constants.API_BASE_URL + "/api/auth/login");
        let response = await fetch(constants.API_BASE_URL + "/api/auth/login", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });

        const result = await response.json();
        //.log('result',result)
        if (result.success) {
            //.log("result----->",result);
            localStorage.setItem("token", result.data.authToken);
            var decoded = jwt_decode(result.data.authToken);
            //.log('decoded',decoded);
            dispatch({ type: "SET_COMPANY_SETTING", payload: result?.companySetting})
            //const arrayPermissions = [{ name: 'MODIFY_ALL' }, { name: 'VIEW_PRODUCT' }, { name: 'VIEW_CONTACT' }, { name: 'VIEW_ORDER' }, { name: 'VIEW_LEAD' }]; // result.permissions
            var string = result.data.permissions.map(function (obj) {
                return obj.name;
            }).join(';')
        }
        createSocketConnection()
        return result;
    },


    logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("myimage");
        window.location.href='/login';
    },
}

export default authApi
