
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table} from "react-bootstrap";
import {useNavigate} from 'react-router-dom'
import inventoryApi from "../../api/inventoryApi";
import { useLocation } from "react-router-dom";

// import { ExportJsonCsv } from 'react-export-json-csv';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import moment from "moment";
import csvDownload from 'json-to-csv-export'
import { useSelector, useDispatch } from "react-redux";
import { getRequest  } from "../../api/inventoryApiTwo";
import { PATH , TYPE } from "../../api/pathUrl";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";

const ReportView = () => {
  const { reportDetail } = useSelector((state) => state.apiReducer);
  const [arrName, setArrName] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const report = location.state;
  const [body, setBody] = useState([]);
  let dispatch = useDispatch();
  
  const resultdata = location.state;
  //.log("check-->",resultdata)   

  useEffect(() => {
    if (reportDetail.length > 0) {
      setArrName(Object.keys(reportDetail[0]));
      //.log("arrName", arrName);
      setBody(reportDetail);
    }
  }, [reportDetail]);
  
  useEffect(() => {
    dispatch(getRequest(PATH.reportPath + location.pathname.split("/")[2], TYPE.SET_REPORT));
  }, []);

  const header = arrName.map((name) => ({
    title: name.charAt(0).toUpperCase() + name.slice(1),
    prop: name,
    isFilterable: true,
  }));
    
  const labels = {
      beforeSelect: " "
    }
    //.log(arrName)
    const ReportDataCSV = {
      data: body,
      filename: 'Report',
      delimiter: ',',
      headers: arrName
    }
return (
  <Row className="g-0">
  <Col lg={4} className="px-4">
        <CustomSeparator
          cmpListName="Reports"
          currentCmpName={resultdata?.name}
          indexLength="2"
          url="/Reports"
        ></CustomSeparator>
      </Col>
      <br></br>
    <Col lg={2} className="mx-2"></Col>

    <Col lg={12} className="p-lg-4">
      {reportDetail ? (
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 100,
            },
          }}
        >
          <div class="row">
            <div class="col-6 col-sm-3" style={{ fontSize: "17px" }}>
              <b>{resultdata?.name} Report</b>
            </div>
            <div class="col-6 col-sm-9 text-end">
              <button
                class="btn btn-sm btn-outline-primary"
                onClick={() => csvDownload(ReportDataCSV)}
              >
                Export CSV
              </button>
            </div>
          </div>
          <br />
          <Table striped className="data-table">
            <TableHeader />
            <TableBody />
          </Table>
          <Pagination />
        </DatatableWrapper>
      ) : (
        ""
      )}
    </Col>
    <Col lg={2}></Col>
  </Row>
);
}

export default ReportView