//this file contains all backend paths and redux reducer types 

export const PATH = {

    roomPath: "/api/room/",
    bookingPath: "/api/booking/",
    AdvancebookingPath: "/api/advancebooking/",
    confirmbookingPath: "/api/advancebooking/confirm-booking/",
    pricingPath: "/api/pricing/",
    taskPath: "/api/tasks/",
    userPath: "/api/auth/",
    filePath: "/api/files/",
    billPath: "/api/bills/",
    agentcommPath: "/api/agentcommission/",
    contactPath: "/api/contacts/",
    advancebookingPath: "/api/advancebooking/",
    paymentPath: "/api/payment/",
    orderPath: "/api/order/",
    expensePath: "/api/expense/",
    categoryPath: "/api/category/",
    itemPath: "/api/item/",
    reportPath: "/api/reports/",
    linereportPath: "/api/reports/api-name/booking_report_by_month",
    todoPath: "/api/todos/",
    transactionPath: "/api/transaction/",
    
}

export const TYPE = {

    GET_ALL_ROOMS: "GET_ROOMS",
    GET_DASHBOARD_ROOMS: "GET_DASHBOARD_ROOMS",
    GET_AVAILABLE_ROOMS: "GET_AVAILABLE_ROOMS",
    SET_ROOM: "SET_ROOM",
    GET_ALL_PRICINGS: "GET_PRICING",
    GET_ALL_TASKS: "GET_TASKS",
    GET_ALL_FILES: "GET_FILES",
    GET_ALL_USERS: "GET_USERS",
    GET_ALL_BOOKINGS: "GET_BOOKINGS",
    SET_TRANSACTION: "SET_TRANSACTION",
    GET_ALL_TRANSACTIONS: "GET_TRANSACTIONS",
    GET_ADV_BOOKINGS: "GET_ADV_BOOKINGS",
    GET_ROOM_FOR_BOOKING: "GET_BOOKING_ROOM",
    SET_BOOKING: "SET_BOOKING",
    GET_ALL_PAYMENTS: "GET_PAYMENTS",
    SET_PAYMENT: "SET_PAYMENT",
    GET_ALL_EXPENSES: "GET_EXPENSES",
    SET_EXPENSE: "SET_EXPENSE",
    GET_ALL_ORDERS: "GET_ORDERS",
    SET_ORDER: "SET_ORDER",
    GET_ALL_BILLS: "GET_BILLS_DATA",
    GET_BILL_CONTACT: "BILL_DATA_CONTACT",
    SET_PROFILE: "GET_PROFILE",
    GET_ALL_CONTACTS: "GET_CONTACTS",
    GET_ALL_ADVANCEBOOKINGS: "GET_ADVANCEBOOKINGS",
    GET_AGENTCOMM: "GET_AGENTCOMM",
    SET_RESULT: "SET_RESULT_HANDLE",
    SET_FILE: "SET_FILE",
    SET_CONTACT: "SET_CONTACT",
    SET_ADVANCEBOOKING: "SET_ADVANCEBOOKING",
    SET_USER: "SET_USER",
    GET_ALL_CATEGORY: "GET_CATEGORY",
    SET_CATEGORY: "SET_CATEGORY",
    GET_ALL_ITEMS: "GET_ITEM",
    SET_ITEM: "SET_ITEM",
    SET_AGENTCOMM: "SET_AGENTCOMM",
    GET_ALL_REPORTS: "GET_REPORT",
    SET_REPORT: "SET_REPORT",
    GET_ALL_TODOS: "GET_TODOS",
    SET_TODO: "SET_TODO",
    SET_ReportLine:"SET_ReportLine"
}