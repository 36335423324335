import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
// import products from './data';
// import products from './product';
import moment from "moment";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../../api/inventoryApi";


// function OrderCreate(){
const OrderCreate = (props) => {
    // interface option {
    //     firstname: string;
    //     lastname: string;
    // }
    const navigate = useNavigate();
    let current = new Date();
    const [rowsData, setRowsData] = useState([]);
    const [order, setOrder] = useState([]);
    const currentDate = moment(current).format('YYYY-MM-DD');
    const [todaydate, setTodayDate] = useState(currentDate);
    const [sheetNo, setSheetNo] = useState('');
    const [stoneNameError, setStoneNameError] = useState('');
    const [partyNameError, setPartyNameError] = useState('');

    const [totalArea, setTotalArea] = useState('0');
    const [netAmt, setNetAmt] = useState('0');
    const [productList, setProductList] = useState([]);
    const [partyNameList, setPartyNameList] = useState([]);
    const [stoneProductId, setStoneProductId] = useState('');

    const companyname = 'Grag Granites'

    


    const handleChange1 = (e) => {

        if (e.target.name === 'orderdate') {
            setTodayDate(e.target.value);
        }

        setOrder({ ...order, [e.target.name]: e.target.value });
        //.log('setOrder >>> ', order);
    };
    const handlePartyName = (e) => {
        let cid = e[0].id;
        setOrder({ ...order, contactid: cid });

    }
    const handleStoneName = (e) => {
        let pid = e[0].id;
        let sheetNoTemp = e[0].productcode;
        //.log('temp sheet no ', sheetNoTemp);
        setStoneProductId(pid);
        setSheetNo(sheetNoTemp);
        setOrder({ ...order, sheetno: sheetNoTemp });
    }

    const addTableRows = () => {

        let rowsInput = [{
            length: '',
            width: '',
            commonitem: '', 
            area: '',
            price: '',
            totalamount: '',
            productid: ''
        }];
        setRowsData([...rowsData, ...rowsInput]);
    }

    const deleteTableRows = (index) => {
        // let rows = [...rowsData];
        rowsData.splice(index, 1);
        setRowsData(rowsData);
        handleCalculate();
    }

    const handleCalculate = () => {
        //.log('delete', stoneProductId)
        let tArea = Number(0)
        let totarea = ''
        let totalSum = Number(0);

        rowsData.map((row) => {
            if (row.length !== '' && row.width !== '' && row.commonitem !== '') {
                let tempTotal = Number(row.length) * Number(row.width);
                totarea = Math.round(tempTotal);
                row.area = totarea;

            } else {
                row.area = '';
            }


            tArea += Number(row.area);
            let priceKey = row.price;
            if (priceKey && priceKey !== 0 && priceKey > 0) {
                let tprice = Number(priceKey) * Number(tArea);
                //  setTotalPrice(tprice)
                row.totalamount = tprice;
                totalSum += tprice;
            }
            row.price = priceKey;
            row.productid = stoneProductId;
            setNetAmt(totalSum);
            setOrder({ ...order, totalamount: totalSum });
        });
        setTotalArea(tArea);
        setOrder({ ...order, totalarea: totalArea });

    }

    const handleChange = (index, evnt) => {

        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        handleCalculate();
        setRowsData(rowsInput);
        //.log('setRowsData--', setRowsData)
    }



    const handleCancel = () => {
        navigate("/orders");
    };


    const handleSave = async (e) => {

        e.preventDefault();
        if (!sheetNo) {
            setStoneNameError('Please Enter Stone Name');
            return false;
        }
        if(!order.contactid){
            setPartyNameError('Please Enter Party Name');
            return false;
        }

        if(!order.orderdate) {
            order.orderdate = todaydate;
        }
        
        let rowsdatas = [];
        for (var item of rowsData) {
            rowsdatas.push(item)
        }
        let tempOrder = order;
        tempOrder.orderlineitems = rowsdatas;

        setOrder(tempOrder);
        const result = await inventoryApi.createOrder(order);
        if (result) {
            //.log('result data >>>>>>>  ', JSON.stringify(result));
            navigate(`/orders/${result.id}`, { state: result });
        }


    }


    // measurement type json
    const measurementTypes = [
        { label: 'Measurement Wise', value: 'measurementWise' },
        { label: 'Row Wise', value: 'rowWise' },
    ];

    // measurement unit 
    const MeasurementUnits = [
        { label: 'Inch', value: 'inch' },
        { label: 'Meter', value: 'meter' },
        { label: 'Centimeter', value: 'centimeter' },
        { label: 'Millimeter', value: 'millimeter' },
        { label: 'Foot', value: 'foot' },
    ];

    
    // area unit
    const areaUnits = [
        { label: 'Square inch', value: 'squareInch' },
        { label: 'Square ft', value: 'squareFt' },
        { label: 'Square Meter', value: 'squareMeter' },
    ];

    // currency 
    const Currencies = [
        { label: 'INR', value: 'INR' },
        { label: 'USDT ft', value: 'USDT' },
        { label: 'TRY', value: 'TRY' },
        { label: 'EUR', value: 'EUR' },
    ];

    useEffect(() => {

        async function stone() {
            let products = await inventoryApi.fetchProduct();
            setProductList(products);
        }

        async function partyName() {
            const contacts = await inventoryApi.fetchContacts();
            setPartyNameList(contacts)
        }

        stone();
        partyName();
        addTableRows();


    }, [])




    return (
        <Container className="create-form">
            <Row>
                <Col lg={10}>
                    <Row>
                        <Col>
                            <Form.Group className="mx-3" >
                                <Form.Label
                                    className="form-view-label"
                                // htmlFor="formBasicSelectType"
                                >
                                    Select Type
                                </Form.Label>
                                <select class="form-select" aria-label="Default select example" name='measurementtype' onChange={handleChange1}>
                                    <option selected>---Select---</option>
                                    {measurementTypes.map((option) => (
                                        <option value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group className="mx-3" controlId="formBasiccompanyName">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasiccompanyName"
                                >
                                    Company Name
                                </Form.Label>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="totalPrice">
                                        {companyname}
                                    </label>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: "2%" }}>
                        <Col>
                            <Form.Group className="mx-3" controlId="formBasicStoneName">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicType"
                                >
                                    Stone Name
                                </Form.Label>

                                <Typeahead
                                    id="basic-example"
                                    labelKey="name"
                                    onChange={handleStoneName}
                                    options={productList}
                                    name='productid'
                                    placeholder="Select Stone Name..." />
                                <div style={{color: "red"}}>{stoneNameError}</div>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mx-3" controlId="formBasicSheetNo">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicSheetNo"
                                >
                                    Sheet No
                                </Form.Label>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="totalPrice">
                                        {sheetNo}
                                    </label>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: "2%" }}>
                        <Col>
                            <Form.Group className="mx-3" controlId="formBasicPartyName">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicPartyName"
                                >
                                    Party Name
                                </Form.Label>

                                <Typeahead
                                    id="basic-example"
                                    // labelKey={(option: option) => `${option.firstname} ${option.lastname}`}
                                    onChange={handlePartyName}
                                    options={partyNameList}
                                    name='contactid'
                                    placeholder="Select Party Name..."
                                />
                                <div style={{color: "red"}}>{partyNameError}</div>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mx-3" controlId="formBasicMeasurementDate">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicMeasurementDate"
                                >
                                    Measurement Date
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    name="orderdate"
                                    placeholder="Select Measurement..."
                                    onChange={handleChange1}
                                    value={todaydate}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mx-3" controlId="formBasicPartyName">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicPartyName"
                                >

                                    Length/Height Unit
                                </Form.Label>
                                <select class="form-select" aria-label="Default select example" name='measurementunit' onChange={handleChange1}>
                                    <option selected>---Select---</option>
                                    {MeasurementUnits.map((option) => (
                                        <option value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mx-3" controlId="formBasicAreaUnit">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicAreaUnit"
                                >
                                    Area Unit
                                </Form.Label>

                                <select class="form-select" aria-label="Default select example" name='areaunit' onChange={handleChange1}>
                                    <option selected>---Select---</option>
                                    {areaUnits.map((option) => (
                                        <option value={option.value}>{option.label}</option>
                                    ))}
                                </select>

                            </Form.Group>
                        </Col>
                    </Row>

                    <Row >
                        <Col >
                            <Form.Group className="mx-3" controlId="formBasicCurrency">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicCurrency"
                                >
                                    Currency
                                </Form.Label>

                                <select class="form-select" aria-label="Default select example" name='currency' onChange={handleChange1}>
                                    <option selected>---Select---</option>
                                    {Currencies.map((option) => (
                                        <option value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mx-3" controlId="formBasicTotalItems">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicTotalItems"
                                >
                                    Total Items
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="totalitems"
                                    onChange={handleChange1}
                                    placeholder="Enter Total Items"

                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row >
                        <Col>
                            <Form.Group className="mx-3" controlId="formBasicRemarks">
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicRemarks"
                                >
                                    Remarks
                                </Form.Label>
                                <textarea
                                    name="description"
                                    onChange={handleChange1}
                                    rows={4}
                                    cols={40}
                                />

                            </Form.Group>
                        </Col>
                        <Col></Col>
                    </Row>

                    <Row>
                        <Col >
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Length</th>
                                        <th>Width</th>
                                        <th>Common Item</th>
                                        <th>price</th>
                                        <th>Area (Sq Ft)</th>
                                        <th>Total Amt</th>

                                        <th><button className="btn btn-outline-success" onClick={addTableRows} ><i class="fas fa-plus"></i></button></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {rowsData.map((data, index) => (
                                        <tr key={index}>

                                            <td><input type="number" value={data.length} onChange={(evnt) => (handleChange(index, evnt))} name="length" className="form-control" /> </td>
                                            <td><input type="number" value={data.width} onChange={(evnt) => (handleChange(index, evnt))} name="width" className="form-control" /> </td>
                                            <td><input type="number" value={data.commonitem} onChange={(evnt) => (handleChange(index, evnt))} name="commonitem" className="form-control" /></td>
                                            <td><input type="number" value={data.price} onChange={(evnt) => (handleChange(index, evnt))} name="price" className="form-control" /> </td>
                                            <td>{data.area}</td>
                                            <td>{Number(data.totalamount).toFixed(2)}</td>
                                            <td><Button className='btn-sm mx-2' variant='danger' onClick={() => (deleteTableRows(index))} ><i class="fa-regular fa-trash-can"></i></Button></td>
                                        </tr>
                                    ))}

                                </tbody>

                            </table>
                            <div align="left" style={{ marginLeft: "55%" }}>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="totalArea">
                                        Total Area (Sq Ft) : {totalArea}
                                    </label>
                                </div>
                                {/* <div className="mb-3" >
                                    <label className="form-label" >
                                        Price (Sq Ft) : <input type="text" onChange={handleChange1} name="price" className="form-control" />
                                    </label>
                                </div> */}

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="totalPrice">
                                        Amount (Exc. GST): {Number(netAmt).toFixed(2)}
                                    </label>
                                </div>
                            </div>

                            <div className="d-flex flex-col ">
                                <button className="btn btn-primary d-flex justify-content-between" onClick={handleSave}>Save</button>

                                <button className="btn btn-danger d-flex justify-content-between" style={{ marginLeft: "4%" }} onClick={handleCancel}>Cancel</button>

                            </div>

                        </Col>
                    </Row>

                </Col>
            </Row>
        </Container>
    );


}
export default OrderCreate   