import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import InfoPill from "../InfoPill";
import InputGroup from "react-bootstrap/InputGroup";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRequest } from "../../api/inventoryApiTwo";
import { PATH, TYPE } from "../../api/pathUrl";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";

const RoomList = () => {

  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [body, setBody] = useState([]);
  const [filteredText, setFilteredText] = useState();
  const [filteredSlected, setfilteredSelected] = useState();
  const [blockFilter, setBlockFilter] = useState('');
  const { roomList } = useSelector((state) => state.apiReducer);

  useEffect(() => {
    // async function init() {
      dispatch({ type: TYPE.SET_ROOM, payload: {} });
      dispatch({ type: TYPE.GET_ALL_PRICINGS, payload: [] });
      dispatch({ type: TYPE.GET_ALL_TASKS, payload: [] });
      dispatch({ type: TYPE.GET_ALL_FILES, payload: [] });
      // if (roomList.length === 0) {
        dispatch(getRequest(PATH.roomPath, TYPE.GET_ALL_ROOMS));
      // }
      // if (roomList.length > 0) {
      //   setBody(roomList);
      // }
    // }
    // init();
  }, []);

  useEffect(() => {
    // async function init() {
    //   if(bookingList.length ===  0){
    //   dispatch(getRequest(PATH.bookingPath, TYPE.GET_ALL_BOOKINGS));
    // }
    if (roomList.length > 0) {
      setBody(roomList);
    }
    // }
    // init();
  }, [roomList]);

  const header = [
    {
      title: "Room No",
      prop: "roomno",
      isFilterable: true,
      cell: (row) => (
        <Link
          to={"/room/" + row.id}
          state={row}
          onClick={() => dispatch({ type: TYPE.SET_ROOM, payload: {} })}
        >
          {row.roomno}
        </Link>
      ),
      isSortable: true,
    },
    { title: "Room Name", prop: "name", isFilterable: true },
    { title: "Type", prop: "type", isFilterable: true, isSortable: true },
    {
      title: "Occupancy",
      prop: "occupancy",
      isFilterable: true,
      isSortable: true,
    },
    { title: "Ac/NonAc", prop: "acnonac", isFilterable: true },
    { title: "Status", prop: "status", isFilterable: true },
    { title: "Floor", prop: "floor", isFilterable: true, isSortable: true },
    { title: "Block", prop: "block", isFilterable: true, isSortable: true },
  ];

  const labels = {
    beforeSelect: " ",
  };

  const createRoom = () => {
    navigate(`/room/e`);
  };

  const handleFilter = (filterSelected, filterText, block) => {
    var filteredData = roomList;
    filteredData = filteredData
      .filter((row) =>
        filterText
          ? row?.name?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
            row?.status?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
            String(row?.roomno)?.includes(filterText) ||
            row?.type?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
            row?.occupancy?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
            row?.acnonac?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
            row?.floor?.toLowerCase()?.includes(filterText?.toLowerCase())
          : row
      )
      .filter((row) =>
        filterSelected
          ? (row.type || "").toLowerCase() ===
            (filterSelected || "").toLowerCase()
          : row
      )
      .filter((row) =>
      block
          ? (row.block || "").toLowerCase() === block.toLowerCase()
          : row
      );
    setBody(filteredData);
  };

  return (
    <Row className="g-0">
      <Col lg={4} className="px-4">
        <CustomSeparator
          currentCmpName="Rooms"
          indexLength="0"
          url="/Rooms"
        ></CustomSeparator>
      </Col>

      <Col lg={12} className="p-lg-4">
        {body ? (
          <DatatableWrapper
            body={body}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 10,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                sm={12}
                lg={2}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              <Col
                xs={10}
                sm={6}
                lg={8}
                className="d-flex flex-col justify-content-start align-items-center"
              >
                <div style={{ marginTop: "0.4rem" }}>
                  <PaginationOptions labels={labels} />
                </div>
                <Form.Group className="mx-1 mt-4" controlId="formBasicStatus">
                  <Form.Select
                    aria-label="Enter status"
                    name="type"
                    onChange={(e) => {
                      setfilteredSelected(e.target.value);
                      handleFilter(e.target.value, filteredText, blockFilter);
                    }}
                  >
                    <option value="">-Select Room Type-</option>
                    <option value="Without Bed">Without Bed</option>
                    <option value="With Bed">With Bed</option>
                    {/* Add more options as needed */}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mx-1 mt-4" controlId="formBasicBlock">
                  <Form.Select
                    aria-label="Enter block"
                    name="block"
                    onChange={(e) => {
                      setBlockFilter(e.target.value);
                      handleFilter(
                        filteredSlected,
                        filteredText,
                        e.target.value
                      );
                    }}
                  >
                    <option value="">-Select Block-</option>
                    <option value="A">Block A</option>
                    <option value="B">Block B</option>
                    <option value="C">Block C</option>
                    <option value="D">Block D</option>
                    <option value="E">Block E</option>
                    <option value="F">Block F</option>
                    <option value="G">Block G</option>
                  </Form.Select>
                </Form.Group>

                <InfoPill className="mt-4" left="Total" right={body?.length} />
              </Col>
              <Col
                xs={2}
                sm={6}
                lg={2}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Button
                  className="btn-sm"
                  variant="outline-primary"
                  onClick={() => createRoom(true)}
                >
                  New Room
                </Button>
              </Col>
            </Row>
            <Table striped className="data-table">
              <TableHeader />
              <TableBody />
            </Table>
            <Pagination />
          </DatatableWrapper>
        ) : (
          ""
        )}
      </Col>
    </Row>
  );
};

export default RoomList;
