import React, { useState, useEffect } from 'react'
import { Button, Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import inventoryApi from "../../api/inventoryApi";
import moment from 'moment';
import Confirm from '../Confirm';
import PurchaseOrderEdit from './PurchaseOrderEdit';
import PurchaseOrderCreate from './PurchaseOrderCreate';
const PurchaseOrderList = ({parent}) => {
  
  const [modalShow, setModalShow] = useState(false);
  const [modalShowPo, setModalShowPo] = useState(false);
  const [modalShowPoCreate, setModalShowPoCreate] = useState(false);

  const [purchase, setpurchase] = useState('');

  const [body,setBody] = useState([]);

  useEffect(() => {
    purchaseOrderLists();
    
 }, []);


  const purchaseOrderLists = () => {
    async function init(){
      const purchaseorders = await inventoryApi.fetchPurchaseOrder();
      //.log(purchaseorders);
      if(purchaseorders)
        setBody(purchaseorders);
        //.log(body);
    }
    init();
  }
  const handleDelete = (row) => {
    setModalShow(true);
    setpurchase(row);
  }

  const labels = {
    beforeSelect: " "
  }

  const deletePurchaseOrder = async () => {
    const result = await inventoryApi.deletePurchaseOrder(purchase.id);
    if (result.success) {
      setpurchase('');
      setModalShow(false);
      purchaseOrderLists();
    }
  }

  const submitPurchaseOrder = () => {
    setModalShowPo(false);
    setModalShowPoCreate(false);
    purchaseOrderLists();
  }

  const editPurchase = (row) => {
    setModalShowPo(true)
    setpurchase(row);
  }

  const header = [
    {
      title: 'Invoice', prop: 'invoicenumber',
      cell: (row) => (
        <Link
          onClick={() => editPurchase( {row} )}
          state={row}
        >
          {row.invoicenumber}
        </Link>
      )
    },
  { title: 'Particulars', prop: 'particulars'  },
  { title: 'Date', prop: 'orderdate', cell: (row) => (moment(row.orderdate).format('DD-MM-YYYY'))    },
  { title: 'Status', prop: 'paymentstatus', isFilterable: true  },
  { title: 'Amount', prop: 'totalpayment' },
  { title: 'Received Payment', prop: 'paymentreceived'  },
  { title: 'Due Payment', prop: 'paymentdue' },

    {
      title: 'Actions', prop: 'id', cell: (row) => (
        <><Button className='btn-sm mx-2' onClick={() => editPurchase({ row })}><i className="fa-regular fa-pen-to-square"></i></Button>
          <Button className='btn-sm mx-2' variant='danger' onClick={() => handleDelete(row)}><i class="fa-regular fa-trash-can"></i></Button>
        </>
      )
    }
  ];
  return (
    <>
      {modalShow &&
      <Confirm
        show={modalShow}
        onHide={() => setModalShow(false)}
        deletePurchaseOrder={deletePurchaseOrder}
        title="Confirm delete?"
        message="You are going to delete the record. Are you sure?"
        table="Purchase"
      />}
       {modalShowPo &&
        <PurchaseOrderEdit
          show={modalShowPo}
          onHide={() => setModalShowPo(false)}
          
          purchase={purchase}
          
          submitPurchaseOrder={submitPurchaseOrder}
        />
        }

        {modalShowPoCreate &&
        <PurchaseOrderCreate
          show={modalShowPoCreate}
          onHide={() => setModalShowPoCreate(false)}
          submitPurchaseOrder={submitPurchaseOrder}
        />
        } 

     <Row className="g-0 ">
      <Col lg={3} >
      
      <Link className="nav-link">Home <i class="fa-solid fa-chevron-right"></i> Purchase Order</Link>
      </Col>
      <Col lg={10} className = "p-lg-4">
       
      {body ?  
      <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
        initialState: {
          rowsPerPage: 10,
          options: [5, 10, 15, 20]
        }
      }}>
      <Row className="mb-4">
        <Col
          xs={12}
          lg={4}
          className="d-flex flex-col justify-content-end align-items-end"
        >
          <Filter />
        </Col>
        <Col
          xs={12}
          sm={6}
          lg={4}
          className="d-flex flex-col justify-content-start align-items-start"
        >
        <PaginationOptions labels={labels}/>
        
        </Col>
        <Col
          xs={12}
          sm={6}
          lg={4}
          className="d-flex flex-col justify-content-end align-items-end"
        >
          <Button className="btn-sm" variant="outline-primary"  onClick={() => setModalShowPoCreate(true)}>New Purchase Order</Button>
        </Col>
      </Row>
      <Table striped className="data-table">
        <TableHeader />
        <TableBody />
      </Table>
      <Pagination />
      
    </DatatableWrapper> : '' }
      </Col>
      <Col lg={2}></Col>
    </Row>
    </>
  );
}
      

export default PurchaseOrderList