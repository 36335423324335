import { parseDateWithMoment, validateUndefinedDate } from "./Helper";
import moment from "moment";
import {decoded} from './User/UserEdit';

function roomEditInitialValues(room) {
    return {
        id: room.id,
        type: room.type,
        block:room.block,
        roomno: room.roomno,
        name: room.name,
        occupancy: room.occupancy,
        floor: room.floor,
        acnonac: room.acnonac,
        status: room.status ? room.status : "Available",
        description: room.description
    }
}
function checkOutEditInitialValues(room) {
    return {
        id: room.id,
        type: room.type,
        amount:room.amount,
        category: room.category,
        parentid: room.parentid,
        occupancy: room.occupancy,
        paymentstatus: room.paymentstatus,
        title: room.title,
        status: room.status ? room.status : "Available",
        transactiondate: room.transactiondate ? room.transactiondate : new Date() ,
        description: room.description
    }
}

function bookingEditInitialValues(booking) {
    return {
        id: booking.id,
        roomid: booking.roomid,
        extra_bed: booking.extra_bed,
        goingto: booking.goingto,
        comingfrom: booking.comingfrom,
        noofguest: booking.noofguest,
        noofdays: booking.noofdays,
        timeformat: booking.timeformat,
        check_out_time: booking.check_out_time,
        check_in_time: booking.check_in_time,
        checkindate: validateUndefinedDate(booking.checkindate),
        checkoutdate: parseDateWithMoment(new Date(booking.checkoutdate)),
        contactid: booking.contactid,
        totalamount: booking.totalamount,
        discount_percentage: booking.discount_percentage,
        source: booking.source,
        description: booking.description,
        isgst: booking.isgst ? booking.isgst : false,
        per_day_amount: booking.per_day_amount,
        per_day_extrabed_amount: booking.per_day_extrabed_amount,
        status: booking.status ? booking.status : "Advance booking"
    }
}
function conformBookingInitialValues(booking) {
    return {
        id: booking.id,
        roomid: booking.roomid,
        extra_bed: booking.extra_bed,
        goingto: booking.goingto,
        comingfrom: booking.comingfrom,
        noofguest: booking.noofguest,
        noofdays: booking.noofdays,
        timeformat: booking.timeformat,
        check_out_time: booking.check_out_time,
        check_in_time: booking.check_in_time,
        method:booking.method,
       checkindate: validateUndefinedDate(booking.checkindate),
        checkoutdate: parseDateWithMoment(new Date(booking.checkoutdate)),
        contactid: booking.contactid,
        totalamount: booking.totalamount,
       source: booking.source,
       advanceamount: booking.advanceamount,
       description: booking.description,
        isgst: booking.isgst ? booking.isgst : false,
       per_day_amount: booking.per_day_amount,
       per_day_extrabed_amount: booking.per_day_extrabed_amount,
        status: booking.status ? booking.status : "Advance booking"
    }
}
function AdvancebookingEditInitialValues(booking) {
    return {
        id: booking.id,
        method: booking.method,
        extra_bed: booking.extra_bed,
        goingto: booking.goingto,
        comingfrom: booking.comingfrom,
        noofguest: booking.noofguest,
        noofdays: booking.noofdays,
        timeformat: booking.timeformat,
        check_out_time: booking.check_out_time,
        check_in_time: booking.check_in_time,
       checkindate: validateUndefinedDate(booking.checkindate),
        checkoutdate: parseDateWithMoment(new Date(booking.checkoutdate)),
        contactid: booking.contactid,
       // totalamount: booking.totalamount,
        source: booking.source,
        advanceamount: booking.advanceamount,
        description: booking.description,
        //isgst: booking.isgst ? booking.isgst : false,
       // per_day_amount: booking.per_day_amount,
       // per_day_extrabed_amount: booking.per_day_extrabed_amount,
        //status: booking.status ? booking.status : "Advance booking"
    }
}

function contactEditInitialValues(contact) {
    return {
        id: contact.id,
        salutation: contact.salutation,
        firstname: contact.firstname,
        lastname: contact.lastname,
        email: contact.email,
        phone: contact.phone,
        street: contact.street,
        company:contact.company,
        title: contact.title,
        state: contact.state,
        city: contact.city,
        pincode: contact.pincode,
        country: 'India',
        occupation: contact.occupation,
    }
}

function userEditValues(user,decoded) {
    
    return {
        id : user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        phone: user.phone,
        rolename: user.rolename,
        password: user.password,
        confirmpassword: user.confirmpassword,
        isactive: user.isactive,
        companyid : decoded.companyid
    }
}

function agentCommisionValues(agentcommission, props) {
    return {
        agentid: agentcommission.agentid,
        commission: agentcommission.commission,
        commissiondate: validateUndefinedDate(agentcommission.commissiondate),
        roomprice: agentcommission?.roomprice
            ? agentcommission.roomprice
            : props.booking.totalamount,
        auto_number: agentcommission?.bookingnumber
            ? agentcommission.bookingnumber
            : props.booking.auto_number,
    }
}

function categoryInitialValues(category) {
    return {
        category_name: category.category_name,
        category_order: category.category_order,
        description: category.description
    }
}

function itemInitialValues(item) {
    return {
        item_name: item.item_name,
        item_type: item.item_type,
        item_price: item.item_price,
        item_order: item.item_order,
        category_id: item.category_id,
        item_description: item.item_description
    }
}

function profileInitialValues(profile) {
    return {
        id: profile?.id,
        firstname: profile?.firstname,
        lastname: profile?.lastname,
        email: profile?.email,
        phone: profile?.phone,
    }
}

function taskInitialValues(task, decoded) {
    return {
        id: task.id,
        parentid: task.parentid,
        title: task.title,
        type: task.type,
        description: task.description,
        priority: task.priority,
        status: task.status,
        targetdate: validateUndefinedDate(task.targetdate),
        createdbyid: decoded.id,
        lastmodifieddate: validateUndefinedDate(new Date()),
        lastmodifiedbyid: decoded.id,
        ownerid: task.ownerid,
    }
}

function pricingInitialValues(pricing) {
    return {
        id: pricing.id,
        parentid: pricing.parentid,
        price: pricing.price,
        status: pricing.status,
        description: pricing.description,
        startdate: parseDateWithMoment(pricing.startdate),
        enddate: parseDateWithMoment(pricing.enddate),
        extra_bed: pricing.extra_bed,
    }
}

function paymentInitialValues(payment) {
    return {
        id: payment.id,
        parentid: payment.parentid,
        amount: payment.amount,
        date: validateUndefinedDate(payment.date),
        method: payment.method,
        type: payment.type,
        description: payment.description,
    }
}

function expenseInitialValues(expense) {
    return {
        id: expense.id,
        parentid: expense.parentid,
        amount: expense.amount,
        type: expense.type,
        remarks: expense.remarks
    }
}

function contactFromBookingInitialValues(contact) {
    return {
        salutation: contact.salutation,
        firstname: contact.firstname,
        lastname: contact.lastname,
        email: contact.email,
        phone: contact.phone,
        street: contact.street,
        state: contact.state,
        city: contact.city,
        pincode: contact.pincode,
        country: contact.country,
        title: 'Customer'
    }
}

function roomShiftingInitialValues(booking) {
    return {
        check_out_time: booking.check_out_time,
        check_in_time: booking.check_in_time,
        checkindate: moment(new Date()).format("YYYY-MM-DD"),
        checkoutdate: moment(booking.checkoutdate).format("YYYY-MM-DD"),
        comingfrom: booking.comingfrom,
        contactid: booking.contactid,
        description: booking.description,
        discount_percentage: booking.discount_percentage,
        extra_bed: booking.extra_bed,
        goingto: booking.goingto,
        is_bill_generated: booking.is_bill_generated,
        isgst: booking.isgst,
        noofdays: booking.noofdays,
        noofguest: booking.noofguest,
        status: "Booked",
        timeformat: booking.timeformat,
    }
}

export {
    roomEditInitialValues,
    bookingEditInitialValues,
    contactEditInitialValues,
    agentCommisionValues,
    userEditValues,
    categoryInitialValues,
    itemInitialValues,
    profileInitialValues,
    taskInitialValues,
    pricingInitialValues,
    paymentInitialValues,
    expenseInitialValues,
    contactFromBookingInitialValues,
    roomShiftingInitialValues,
    AdvancebookingEditInitialValues,
    conformBookingInitialValues,
    checkOutEditInitialValues
}