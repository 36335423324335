import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../../api/inventoryApi";

const PropertyEdit = (props) => {
    const [validated, setValidated] = useState(false);




    //const { accounts } = useSelector((state) => state.api);

    /*useEffect(() => {
      let fetchedAccounts = [];
      accounts.map((item) => {
        fetchedAccounts.push({
          label: item.firstname,
          population: 4780127,
          capital: "Montgomery",
          region: "South",
        });
      });
      setOptions(fetchedAccounts);
    }, [accounts]);*/

    const location = useLocation();
    const navigate = useNavigate();

    const [property, setProperty] = useState(location.state ? location.state : {});

    const handleChange = (e) => {
        setProperty({ ...property, [e.target.name]: e.target.value });
    };

    
    const [contactList, setContactList] = useState([]);
    const [defaultContact, setDefaultContact] = useState([]);

    const [ownerList, setownerList] = useState([]);
    const [defaultOwner, setDefaultOwner] = useState([]);
    useEffect(() => {
        async function init() {
            
            // Fetch Owners from user
            const result = await inventoryApi.fetchUsers();
            if(result) {
                setownerList(result);
                if(property.id){
                    //.log('edit case', property);
                    setDefaultOwner([{'id':property.ownerid, 'username':property.ownername}]);
                } else {
                //.log('create  case');
                    setDefaultOwner([{'id':'', 'username':''}]);
                }
            }

            // Fetch Contacts
            const conResult = await inventoryApi.fetchContacts();
            //.log(property);
            if(conResult) {
                setContactList(conResult);
                if(property.id){
                    //.log('edit case', property);
                    setDefaultContact([{'id':property.contactid, 'contactname':property.contactname}]);
                } else {
                    //.log('create  case');
                    setDefaultContact([{'id':'', 'contactname':''}]);
                }
            }
        }
        init();
    }, []);  


    const handleOwnerName = (event) => {
        if(event.length > 0){
            setProperty({ ...property, ownerid:event[0].id});
        } else {
            setProperty({ ...property, ownerid:''});
        }
    }

    const handleContactName = (event) => {
        //.log('handle contact name', event.length);
        if(event.length > 0){
            setProperty({ ...property, contactid:event[0].id});
        } else {
            setProperty({ ...property, contactid:''});
        }
    }


    const checkRequredFields = () => {

       //.log(property.name);
       //.log(property.phone);
       //.log(property.type);
       //.log(property.ownerid);
       //.log(property.contactid);

       if((property.name && property.name.trim() !== '') && (property.phone && property.phone.trim() !== '') &&  
          (property.type && property.type.trim() !== '') && (property.ownerid && property.ownerid.trim() !== '') &&
          (property.contactid && property.contactid.trim() !== '')) {
            return false;
       } 
       return true;
    }

    const handleSubmit = async (e) => {
        //.log('====handle submit====');
        e.preventDefault();
        if(checkRequredFields()) {
            setValidated(true);
            return;
        }
        
        
        let result = {};
        //.log('property: ',JSON.stringify(property));

        if(property.id){
            //.log('====edit====');
            result = await inventoryApi.saveProperty(property);
            if (result.success) {
                //.log('if result true');
                //const resultProperty = inventoryApi.fetchProperty(property.id);
                navigate(`/properties/${property.id}`, { state: property });
                //window.location.reload();
            }
        }            
        else{
            //.log('====create====');
            result = await inventoryApi.createProperty(property);
            if (result) {
                //const resultProperty = inventoryApi.fetchProperty(property.id);
                navigate(`/properties/${result.id}`, { state: result });
            }
        }
           

        //.log('result:', result);
        
    };

    const handleCancel = () => {
        if(property.id)
            navigate("/properties/" + property.id, { state: property });
        else
            navigate("/properties");
    };


    return (
        <Container className="view-form">
            <Row>
                <Col></Col>
                <Col lg={8}>
                    <Form className="mt-3"  onSubmit={handleSubmit} noValidate validated={validated}>
                        <Row className="view-form-header align-items-center">
                            <Col lg={3}>
                                {property.id ? 
                                <>Edit Property
                                <h4>Aslam Bari</h4></> : 'New Property'}
                               
                            </Col>
                            <Col lg={9} className="d-flex justify-content-end">
                                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                                    Save
                                </Button>
                                <Button
                                    className="btn-sm"
                                    variant="danger"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                
                                <Form.Group className="mx-3" controlId="formBasicFirstName">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Name
                                    </Form.Label>
                                    <Form.Control required
                                        type="text"
                                        name="name"
                                        placeholder="Enter Name"
                                        value={property.name}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                
                            </Col>
                            <Col lg={6}>
                            <Form.Group className="mx-3" controlId="formBasicLastName">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicLastName"
                                    >
                                        Property Code
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="code"
                                        placeholder="Enter code"
                                        value={property.code}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mx-3" controlId="formBasicEmail">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicEmail"
                                    >
                                        Email
                                    </Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        placeholder="Enter email"
                                        value={property.email}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group  className="mx-3" controlId="formBasicPhone">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicPhone"
                                    >
                                        Phone
                                    </Form.Label>
                                    <Form.Control 
                                        required
                                        type="phone"
                                        name="phone"
                                        placeholder="Enter phone"
                                        value={property.phone}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicType">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicTitle"
                                    >
                                        Type
                                    </Form.Label>
                                    <Form.Select
                                        required
                                        aria-label="Enter Payment Status"
                                        name="type"
                                        value={property.type}
                                        onChange={handleChange}
                                    >
                                        <option value=''>--Select--</option>
                                        <option value="Flat">Flat </option>
                                        <option value="Villa">Villa</option>
                                        <option value="Plot">Plot</option>
                                        <option value="Land">Land</option>
                                        <option value="House">House</option>
                                        <option value="Shop">Shop</option>                         
                                        <option value="Apartment">Apartment </option>               
                                        <option value="Commercial Space">Commercial Space</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>



                          







                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicArea">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicTitle"
                                    >
                                        Total Area
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="area"
                                        placeholder="Enter area"
                                        value={property.area}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            
                           
                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicStreet">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicStreet"
                                    >
                                        Street
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="street"
                                        placeholder="Enter street"
                                        value={property.street}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col> 
                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicCity">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCity"
                                    >
                                        City
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        placeholder="Enter city"
                                        value={property.city}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicState">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicState"
                                    >
                                        State
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="state"
                                        placeholder="Enter state"
                                        value={property.state}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicPin">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicPin"
                                    >
                                        Pincode
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="pincode"
                                        placeholder="Enter pincode"
                                        value={property.pincode}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicCountry">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCountry"
                                    >
                                        Country
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="country"
                                        placeholder="Enter country"
                                        value={property.country}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                            <Form.Group className="mx-3" controlId="formBasicOwner">
                                <Form.Label className="form-view-label" htmlFor="formBasicOwner" >
                                    Assign Staff
                                </Form.Label>

                                {defaultOwner.length > 0 ?
                                <Typeahead
                                required
                                    id="basic-typeahead-single"
                                    defaultSelected={defaultOwner}
                                    name="owner"
                                    labelKey="username"
                                    options={ownerList}
                                    onChange={handleOwnerName}
                                    placeholder="Choose a state..."
                                    /> : ''}
                            
                            </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicContactId">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicStreet"
                                    >
                                        Contact Person
                                    </Form.Label>
                                    {defaultContact.length > 0 ?
                                        <Typeahead
                                            required
                                            id="basic-typeahead-single2"
                                            defaultSelected={defaultContact}
                                            name="contact"
                                            labelKey="contactname"
                                            options={contactList}
                                            onChange={handleContactName}
                                            placeholder="Choose a contact..."
                                            /> : ''}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicDescription">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicStreet"
                                    >
                                        Description
                                    </Form.Label>
                                    <Form.Control
                    as="textarea"
                    name="description"
                    placeholder="Enter Particulars"
                    value={property.description}
                    onChange={handleChange}
                  />
                                </Form.Group>
                            </Col>
                        </Row>
                        
                    </Form>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );
};

export default PropertyEdit;
