import React, { useContext } from 'react';
import { ToastContainer, toast} from 'react-toastify';

const ToastMessage = (data, vari) => {

    if (vari === "success") {
        toast.success(data, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
        });
    }
    else if (vari === "warn") {
        toast.warn(data, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
        });
    }
    else if (vari === "error") {
        toast.error(data, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
        });
    }
};

export default ToastMessage;
