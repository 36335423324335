import React, { useEffect, useState, useRef } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import getSymbolFromCurrency from "currency-symbol-map";
import { useLocation } from "react-router-dom";
import inventoryApi from "../../api/inventoryApi";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Table from "react-bootstrap/Table";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import { PATH , TYPE } from "../../api/pathUrl";
import { getRequest, postRequest } from "../../api/inventoryApiTwo";
import { useSelector , useDispatch } from "react-redux";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";

// // Create styles
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: "row",
//     backgroundColor: "#E4E4E4",
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1,
//   },
// });

const MyDocument = () => {

  let dispatch = useDispatch();
  const [expense, setExpense] = useState([]);
  const [expensePaymentAmount, setExpensePaymentAmount] = useState(0);
  const location = useLocation();
  const [booking, setBooking] = useState(location.state ? location.state : {});
  const [room, setRoom] = useState({});
  const [contact, setContact] = useState();
  const [paymentLists, setPaymentList] = useState([]);
  const printRef = useRef();
  const company = localStorage.getItem("token")? jwt_decode(localStorage.getItem("token")).companyinfo[0] : '';
  const { roomList , billsData , billContactDetail, resultHandle } = useSelector((state) => state.apiReducer);


  useEffect(() => {

    if (booking.hasOwnProperty("is_bill_generated") && !booking.is_bill_generated) {
      
      dispatch(
        postRequest(
          `${PATH.billPath}generate-bill/${booking.id}`,
          undefined,
          undefined
        )
      );
    } else {
      getBill();
    }
  }, [])
  
  useEffect(() => {
  
    if(resultHandle.hasOwnProperty("success") && resultHandle.success){ 

      getBill()
      dispatch({ type: TYPE.SET_RESULT, payload: {} });
    }
  }, [ resultHandle ])
  


  useEffect(() => {

    if (roomList.length > 0) {
      setRoom(roomList.filter((ele) => ele.id === booking.roomid)[0]);
    }

    // if (Object.keys(billsData).length > 0) {
    //   setExpense(billsData.bill_items.filter((ele) => ele.item_transaction_type === "charges"));
    //   setPaymentList(billsData.bill_items.filter((ele) => ele.item_transaction_type === "credit"));
    // }

    if (billContactDetail.length > 0) {
      setContact(billContactDetail.find((ele) => ele.id === booking.contactid));
    }
  // }, [roomList, billsData, billContactDetail])
  }, [roomList, billContactDetail])
  

  useEffect(() => {

    dispatch(getRequest(PATH.roomPath, TYPE.GET_ALL_ROOMS));

    // dispatch(getRequest(`${PATH.billPath}get-bill-data/${booking.id}`, TYPE.GET_ALL_BILLS));

    dispatch(getRequest(PATH.contactPath, TYPE.GET_BILL_CONTACT));
  }, [])


  useEffect(() => {

    if (Object.keys(billsData).length > 0) {
      setExpense(billsData.bill_items.filter((ele) => ele.item_transaction_type === "charges"));
      setPaymentList(billsData.bill_items.filter((ele) => ele.item_transaction_type === "credit"));
    }
  }, [billsData])
  
  
  const getBill = () => {

    dispatch(getRequest(`${PATH.billPath}get-bill-data/${booking.id}`, TYPE.GET_ALL_BILLS));

  }


  // useEffect(() => {
  //   fetchRoom();
  // }, []);

  // useEffect(() => {
  //   getPaymentsAndExpenses();
  // }, []);

  // const fetchRoom = () => {
  //   async function initRoom() {
  //     let result = await inventoryApi.fetchRooms();
  //     if (result) {
  //       //.log("room: ", result);
  //       setRoom(result.filter((ele) => ele.id === booking.roomid)[0]);
  //     } else {
  //       setRoom({});
  //     }
  //   }
  //   initRoom();
  // };

  // const getPaymentsAndExpenses = () => {
  //   async function initRoom() {
  //     let resultViewBill = await inventoryApi.getBillData(booking);
  //     //.log("result --> ",resultViewBill)
  //     if (resultViewBill.message) {
  //       //.log("room: ", resultViewBill);

  //       setExpense(resultViewBill.data.bill_items.filter((ele) => ele.item_transaction_type === "charges"));

  //       setPaymentList(resultViewBill.data.bill_items.filter((ele) => ele.item_transaction_type === "credit"));

  //     } else {
  //       setExpense([]);
  //       setPaymentList([]);
  //     }
  //   }
  //   initRoom();
  // };

  // useEffect(() => {
  //   async function init() {
  //     const contacts = await inventoryApi.fetchContacts();
  //     if (contacts)
  //       setContact(contacts.data.find((ele) => ele.id === booking.contactid));
  //   }
  //   init();
  // }, []);

  // useEffect(() => {
  //   async function init() {
  //     const contacts = await inventoryApi.fetchContacts();
  //     if (contacts)
  //       setContact(contacts.data.find((ele) => ele.id === booking.contactid));
  //   }
  //   init();
  // }, []);

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
    //.log("Completed");
  };

  return (
    <div>

      <Row>
        <Col className="mx-3">

          <CustomSeparator
            cmpListName={booking.auto_number}
            currentCmpName="Bill"
            indexLength="2"
            url={`/booking/${booking.id}`}
          ></CustomSeparator>

          <center >
            <Button onClick={handleDownloadPdf}>Download as PDF</Button>
          </center>
        </Col>
      </Row> 

      <div ref={printRef}>
        <div className="p-5">

          <center><h6><b>Invoice</b></h6></center>
          <Table bordered>
            <tbody>
              <tr>
                <td>
                  <h6>
                    <b style={{fontSize:"15px"}}>From :</b>
                  </h6>
                   <p style={{fontSize:"14px"}} className="p-2">
                   {company.companyname}
                    <br />
                    {company.street} <br />
                    {company.city} <br />
                    {company.state} {company.pincode}<br />
                    {company.country} <br />
                    Phone - {company.phone}
                  </p>
                </td>
                <td>
                  <h6>
                    <b style={{fontSize:"15px"}}> Bill To :</b>

                  </h6>
                  {contact &&   <p style={{fontSize:"14px"}} className="p-2">
                    {contact.firstname + ' '+ contact.lastname }
                    <br />
                     {contact.street} <br />
                     {contact.city} <br />
                     {contact.state} <br />
                     {contact.country}
                  </p>}

                </td>
              </tr>
            </tbody>
          </Table>
          <Table bordered>
            <tbody  style={{fontSize:"13px"}}>
              <tr>

                <td ><center>Room No :  {room && room.roomno}</center></td>
                <td><center>Room Type :   {room && room.type}</center> </td>
                <td><center>No.of Person :   {booking.noofguest}</center></td>
                <td><center>Arrival : {booking.comingfrom}</center></td>
                <td><center>Check-In Date : {moment(new Date(booking.checkindate)).format('MMMM Do YYYY')} {booking.check_in_time}</center></td>
              </tr>
              <tr>
                <td><center>Booking No :  {booking.auto_number}</center></td>
                <td><center>Ac / Non - Ac :  {room && room.acnonac} </center></td>
                <td><center>Nationality :  {contact && contact.country}</center></td>
                <td><center>Departure : {booking.goingto}</center></td>
                <td><center>Check-Out Date : {moment(new Date(booking.checkoutdate)).format('MMMM Do YYYY')} {booking.check_out_time}</center></td>
              </tr>
              <table></table>
              
               
                {/* <Row>
                  <Col lg={4}></Col>
                  <Col>
                    <Row>
                      <Col>Sub Total :</Col>
                      <Col>
                        {expensePaymentAmount + parseInt(booking.totalamount)}
                        .00
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>GST (18%):</Col>
                      <Col>
                        {(expensePaymentAmount +
                          parseInt(booking.totalamount)) * 18  / 100 }
                        .00
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row className="mt-2">
                      <Col>
                        <b>Total</b>
                      </Col>
                      <Col>
                        <b>
                          {expensePaymentAmount + parseInt(booking.totalamount) + (expensePaymentAmount +
                          parseInt(booking.totalamount)) * 18 / 100 }
                          .00
                        </b>
                      </Col>
                    </Row>
                  </Col>
                </Row> */}
                
             
            </tbody>
          </Table>
          <Table responsive="sm" style={{fontSize:"13px"}}>
                  <thead>
                    <tr>
                    <th>Date</th>
                      <th colSpan={3}>Description </th>
                      <th>{getSymbolFromCurrency('INR')}Charges</th>
                      <th>{getSymbolFromCurrency('INR')}Credit</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{moment(booking.createddate).format("DD-MM-YYYY")}</td>
                      <td colSpan={3}>Room charges</td>
                    <td >{getSymbolFromCurrency("INR")}{booking.totalamount}</td>
                      <td ></td>
                    </tr>
                      {expense &&
                        expense.map((data) => {
                          return (
                            <tr key={data.bill_id}>
                              <td>
                                {moment(data.item_date).format("DD-MM-YYYY")}
                              </td>
                              <td colSpan={3}>{data.item_type}</td>
                              <td>
                                {getSymbolFromCurrency("INR")}
                                {data.item_amount}
                              </td>
                              <td> </td>
                            </tr>
                          );
                        })}
                      {paymentLists &&
                        paymentLists.map((data) => {
                          return (
                            <tr key={data.bill_id}>
                              <td>
                                {moment(data.item_date).format("DD-MM-YYYY")}
                              </td>
                              <td colSpan={3}>{data.item_type}</td>
                              <td> </td>
                              <td>
                                {getSymbolFromCurrency("INR")}
                                {data.item_amount}
                              </td>
                            </tr>
                          );
                        })}
                      { booking.isgst ? <tr>
                        <td></td>
                        <td colSpan={3}>GST (18 %)</td>
                        <td>
                          {getSymbolFromCurrency("INR")}
                          {((expensePaymentAmount +
                            parseInt(booking.totalamount)) *
                            18) /
                            100}
                        </td>
                        <td></td>
                      </tr> : ""}
                      <tr>
                        <th></th>
                        <th colSpan={3}>Total</th>
                        <th>
                          {getSymbolFromCurrency("INR")}
                          {(parseFloat(booking.totalamount_with_gst) + parseFloat(booking.expense_amount)).toFixed(2) }

                        </th>
                        <th>
                          {getSymbolFromCurrency("INR")}
                          {paymentLists && paymentLists.reduce((accumulator, item) => {
                            return accumulator + item.item_amount;
                          }, 0)}
                        </th>
                      </tr>

                  </tbody>
                </Table>

        </div>
      </div>
    </div>
  );
};

export default MyDocument;
