import React, { useEffect, useState } from "react";
import inventoryApi from "../../api/inventoryApi";
import { useNavigate, Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import moment from "moment";
import { Col, Row, Container } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';


import { Line } from 'react-chartjs-2';
import { useSelector, useDispatch } from "react-redux";
import { getRequest } from "../../api/inventoryApiTwo";
import { PATH, TYPE } from "../../api/pathUrl";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = () => {
  let dispatch = useDispatch();
  const { lineReport } = useSelector((state) => state.apiReducer);
  const [report, setReport] = useState([]);
  const [name, setname] = useState("Bookings");


  useEffect(() => {
    dispatch(getRequest(PATH.linereportPath, TYPE.SET_ReportLine));
  }, []);

  useEffect(() => {
    if (lineReport.length > 0) {
      setReport(lineReport);
      //.log("lineReport", lineReport);
    }
  }, [lineReport]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Number of Bookings',
          },
        },
        x: {
          type: 'category',
          labels: report?.map((item) => item.yourLabelProperty) || [],
          title: {
            display: true,
            text: 'Month-Year',
          },
        },
      },
    },
  };

  ////.log("check-->",Object.keys(report)?.map((item) => item))
  const labels = report.map((report) => report["Year Month"]);

  const data = {
    labels,
    datasets: [
      {
        label: name,
        data: report.map((report) => report["No of Booking"]),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return <Line options={options} data={data} />;
};

export default LineChart;
