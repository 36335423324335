import * as constants from "../constants/CONSTANT";
import authApi from "../api/authApi";
import ToastMessage from "../components/ToastMessage";

//************************ common APIs ***********************************//

const getRequest = (path, type) => async (dispatch) => {

    //.log("inside get req --> ", path, type)

    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + path, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: token,

        },
    });

    if (response.status === 401) {
        authApi.logout();
    }

    const result = await response.json();

    //.log("get req result --> ", result);

    if (result.success) {
        dispatch({ type: type, payload: result.data })
    } else if (result.errors) {
        ToastMessage(result.errors, "error");
    }
};



const getDownloadRequest = async (path) => {

    //.log("inside get req --> ", path)

    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + path, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: token,

        },
    });


    const fileBody = await response.blob();



    return fileBody;
    // //.log("get req result --> ", result);

    // if (result.success) {
    //     dispatch({ type: type, payload: result.data })
    // }
};



const postRequest = (path, body, setAllRec, modal, raw) => async (dispatch) => {

    //.log("inside post req --> ",path, body, setAllRec, modal, raw);

    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + path, {
        method: "POST",
        mode: "cors",

        headers: raw ?  {

            Authorization: token,
        } : {
            "Content-Type": "application/json",
            Authorization: token,
        },

        body: raw ? body : JSON.stringify(body),
    });

    const result = await response.json();
    //.log("post req result --> ",result)

    if (result.success) {
        if (!(path.includes("getroomsfordashboard"))) ToastMessage(result.message, "success");
        dispatch({ type: 'SET_SUCCESS', payload: { success: true, data: result.data, isModal: modal } })
        if (setAllRec) dispatch(getRequest(path, setAllRec));
    } else if (result.errors) {
        ToastMessage(result.errors, "error");
    }
};    


const putRequest = (path, type, id, body, modal, raw) => async (dispatch) => {
    //.log("inside put req putRequest");
    const token = localStorage.getItem("token");
    let response = await fetch(
            constants.API_BASE_URL + path + id,
        {
            method: "PUT",
            mode: "cors",
            headers: raw ? {
                "Authorization": token,
            } : {
                "Content-Type": "application/json",
                "Authorization": token,
            },
            body: raw ? body : JSON.stringify(body),
        }
    );

    const result = await response.json();
    //.log("after update result --> ", result)

    if (result.success) {
        ToastMessage(result.message, "success");
        dispatch({ type: 'SET_SUCCESS', payload: { success: true, data: result.data, isModal: modal} })
        if(type) dispatch(getRequest(path, type));
    } else if (result.errors) {
        ToastMessage(result.errors, "error");
    }
};


const deleteRequest = (path, type, id, modal) => async (dispatch) => {

    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + path + id, {
        method: "DELETE",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: token,
        },
    });

    const result = await response.json()

    //.log("delete result --> ",result)

    if (result.success) {
        dispatch({ type: 'SET_SUCCESS', payload: { success: true, data: result.data, isModal: modal} })
        ToastMessage(result.message, "success");
        if(type) dispatch(getRequest(path, type));
        return result;

    } else if (result.errors) {
        ToastMessage(result.errors, "error");
    }
};

export {
    getRequest,
    getDownloadRequest,
    postRequest,
    putRequest,
    deleteRequest
}