import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
    useRef,
  } from "react";
  import { Button, Col, Row, Table } from "react-bootstrap";
  import { Link } from "react-router-dom";
  import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader,
  } from "react-bs-datatable";
  import inventoryApi from "../../api/inventoryApi";
  import moment from "moment";
  import { useLocation } from "react-router-dom";
  import Confirm from "../Confirm";
  import { useSelector, useDispatch } from "react-redux";
  import { PATH, TYPE } from "../../api/pathUrl";
  import { getRequest } from "../../api/inventoryApiTwo";
  import { PaymentEdit } from "../index"
  
  const RelatedListBookings = ({ refreshContactList, parent }) => {
    const { bookingList } = useSelector((state) => state.apiReducer);
  
    const [modalShow, setModalShow] = React.useState(false);
    const [contact, setContact] = React.useState("");
    const [booking, setBooking] = React.useState("");
    const [modalShowPayment, setModalShowPayment] = React.useState(false);
    let dispatch = useDispatch();
    let location = useLocation();
  
    // Create table headers consisting of 4 columns.
  
    const [body, setBody] = useState([]);
  
    useEffect(() => {
      async function init() {
        bookingTableList();
      }
      init();
    }, [refreshContactList]);
  
    const bookingTableList = () => {
      async function init() {
        if (bookingList.length === 0) {
          //.log("booking list", bookingList);
          dispatch(
            getRequest(
              PATH.bookingPath + location.pathname.split("/")[2],
              TYPE.GET_ALL_BOOKINGS
            )
          );
        }
        if (bookingList.length > 0) {
          //.log("parent --> ");
          setBody(bookingList.filter((data) => data.contactid === parent.id));
        }
        // //.log('bookingList',bookingList)
        // if(!bookingList){
        //     dispatch(fetchBookings());
        //     setBody([]);
        // }
        // if(bookingList ){
        //     setBooking(bookingList);
        //     setBody(bookingList.filter((data) =>data.contactid === parent.id ));
        // }
      }
      init();
    };
  
    const handleDelete = (row) => {
      setModalShow(true);
      setContact(row);
    };
  
    const labels = {
      beforeSelect: " ",
    };
  
    const deletePayment = async () => {
      const result = await inventoryApi.deletePayment(contact.id);
      if (result.success) {
        setContact("");
        setModalShow(false);
        bookingTableList();
      }
    };
  
    const submitPayment = () => {
      setModalShowPayment(false);
      bookingTableList();
    };
  
    const editPayment = (row) => {
      setModalShowPayment(true);
      setContact(row);
    };
  
    const header = [
      {
        title: "Booking",
        prop: "auto_number",
        // cell: (row) => (
        //     <Link onClick={() => editPayment({ row })} state={parent}>
        //         {row.method}
        //     </Link>
        // )
      },
      {
        title: "Room",
        prop: "roomname",
        // cell: (row) => (
        //     <Link onClick={() => editPayment({ row })} state={parent}>
        //         ₹{row.amount}
        //     </Link>
        // )
      },
      {
        title: "Check-In Date",
        prop: "checkindate",
        cell: (row) => moment(row.checkindate).format("DD-MM-YYYY"),
      },
      {
        title: "Check-Out Date",
        prop: "checkoutdate",
        cell: (row) => moment(row.checkoutdate).format("DD-MM-YYYY"),
      },
      { title: "Days", prop: "noofdays" },
      { title: "Discount", prop: "discount_percentage" },
      { title: "Amount", prop: "totalamount_with_gst" },
      { title: "Status", prop: "status" },
    ];
  
    return (
      <>
        {modalShow && (
          <Confirm
            show={modalShow}
            onHide={() => setModalShow(false)}
            deletePayment={deletePayment}
            title="Confirm delete?"
            message="You are going to delete the record. Are you sure?"
            table="contact"
          />
        )}
        {modalShowPayment && (
          <PaymentEdit
            show={modalShowPayment}
            onHide={() => setModalShowPayment(false)}
            parentid={parent.id}
            payment={contact}
            table="lead"
            submitPayment={submitPayment}
          />
        )}
  
        {body ? (
          <DatatableWrapper
            body={body}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 5,
              },
            }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-start align-items-start"
              ></Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-start align-items-start"
              ></Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              ></Col>
            </Row>
            <Table striped className="related-list-table">
              <TableHeader />
              <TableBody />
            </Table>
            {body && body.length > 0 ? <Pagination /> : ""}
          </DatatableWrapper>
        ) : (
          ""
        )}
      </>
    );
  };
  
  export default RelatedListBookings;
  