import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../../api/inventoryApi";

const LeadEdit = (props) => {
    //const { accounts } = useSelector((state) => state.api);
    const [validated, setValidated] = useState(false);

    /*useEffect(() => {
      let fetchedAccounts = [];
      accounts.map((item) => {
        fetchedAccounts.push({
          label: item.firstname,
          population: 4780127,
          capital: "Montgomery",
          region: "South",
        });
      });
      setOptions(fetchedAccounts);
    }, [accounts]);*/

    
     // Fetach all users and set to owner
     const [ownerList, setownerList] = useState([]);
     const [defaultOwner, setDefaultOwner] = useState([]);
     useEffect(() => {
         async function init() {
          const result = await inventoryApi.fetchUsers();
             //.log('Owners: ',result);
             if(result) {
                 //result.push({"id": '', "username": ''});
                 setownerList(result);

                 if(lead.id){
                    //.log('edit lead', lead);
                    setDefaultOwner([{'id':lead.ownerid, 'username':lead.ownername}]);
                    //.log(defaultOwner);
               } else {
                    //.log('create  case');
                    setDefaultOwner([{'id':'', 'username':''}]);
               }
            }
           }
           init();
        }, []);

    const location = useLocation();
    const navigate = useNavigate();
    const [lead, setLead] =useState(location.state ? location.state : {});
    
    //
    const handleChange = (e) => {
        setLead({ ...lead, [e.target.name]: e.target.value });
    };

    const checkRequredFields = () => {

        //.log(lead.firstname);
        //.log(lead.lastname);
        //.log(lead.status);
        //.log(lead.phone);
        //.log(lead.ownerid);
 
        if((lead.firstname && lead.firstname.trim() !== '') && (lead.lastname && lead.lastname.trim() !== '') &&  
           (lead.status && lead.status.trim() !== '') && (lead.phone && lead.phone.trim() !== '') &&
           (lead.ownerid && lead.ownerid.trim() !== '')) {
             return false;
        } 
        return true;
     }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(checkRequredFields()) {
            setValidated(true);
            return;
        }
        
        let result = {};
        //.log('-----');

        //========= Logic to perform Create or Edit ======
        if(lead.id){
            //.log('---true---'+lead.id);
            result = await inventoryApi.saveLead(lead);
            if (result.success) {
                //.log('if result true');
                navigate(`/leads/${lead.id}`, { state: lead });
            }
        }            
        else{
            //.log('--false---');
            result = await inventoryApi.createLead(lead);
            if (result) {
                navigate(`/leads/${result.id}`, { state: result });
            }

            
        }
    };

    const handleCancel = () => {
       navigate("/leads/" + lead.id, { state: lead });
    };

    const handleOwnerName = (event) => {
        if(event.length > 0){
            setLead({ ...lead, ownerid:event[0].id});
        } else {
            setLead({ ...lead, ownerid:''});
        }
        
    }


    return (
        <Container className="view-form">
            
            <Row>
                <Col></Col>
                <Col lg={8}>
                    <Form className="mt-3" onSubmit={handleSubmit} noValidate validated={validated}>
                        <Row className="view-form-header align-items-center">
                            <Col lg={3}>
                                Edit Lead
                                <h4>Aslam Bari</h4>
                            </Col>
                            <Col lg={9} className="d-flex justify-content-end">
                                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                                    Save
                                </Button>
                                <Button
                                    className="btn-sm"
                                    variant="danger"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-between">
                                <Form.Group className="mx-3" controlId="formBasicFirstName">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Salutation
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="salutation"
                                        placeholder="Enter salutation"
                                        value={lead.salutation}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mx-3" controlId="formBasicFirstName">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        First Name
                                    </Form.Label>
                                    <Form.Control
                                    required
                                        type="text"
                                        name="firstname"
                                        placeholder="Enter firstname"
                                        value={lead.firstname}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mx-3" controlId="formBasicLastName">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicLastName"
                                    >
                                        Last Name
                                    </Form.Label>
                                    <Form.Control
                                    required
                                        type="text"
                                        name="lastname"
                                        placeholder="Enter lastname"
                                        value={lead.lastname}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mx-3" controlId="formBasicEmail">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicEmail"
                                    >
                                        Email
                                    </Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        placeholder="Enter email"
                                        value={lead.email}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mx-3" controlId="formBasicPhone">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicPhone"
                                    >
                                        Phone
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="phone"
                                        name="phone"
                                        placeholder="Enter phone"
                                        value={lead.phone}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicTitle">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicTitle"
                                    >
                                        Title
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        placeholder="Enter title"
                                        value={lead.title}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            
                            <Col>
                                <Form.Group className="mx-3" controlId="formBasicStatus">
                                    <Form.Label className="form-view-label" htmlFor="formBasicStatus">
                                         Status
                                    </Form.Label>

                                    <Form.Select required aria-label="Select Status" name="status" value={lead.status} onChange={handleChange}>
                                        <option value="">--Select--</option>
                                        <option value="Not Contacted">Not Contacted</option>
                                        <option value="Contacted">Contacted</option>
                                        <option value="Interested">Interested</option>
                                        <option value="Converted">Converted</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            
                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicStreet">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicStreet"
                                    >
                                        Company
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="company"
                                        placeholder="Enter company"
                                        value={lead.company}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicOwner">
                                        <Form.Label className="form-view-label" htmlFor="formBasicOwner" >
                                            Assign Staff
                                        </Form.Label>
                                    {defaultOwner.length > 0 ?
                                     <Typeahead
                                            required
                                            id="basic-typeahead-single"
                                            defaultSelected = {defaultOwner}
                                            name="owner"
                                            labelKey="username"
                                            options={ownerList}
                                            onChange={handleOwnerName}
                                            placeholder="Choose a state..."
                                            /> : ''}
                                        
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicStreet">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicStreet"
                                    >
                                        Street
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="street"
                                        placeholder="Enter street"
                                        value={lead.street}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicCity">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCity"
                                    >
                                        City
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        placeholder="Enter city"
                                        value={lead.city}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicPin">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicPin"
                                    >
                                        Pincode
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="pincode"
                                        placeholder="Enter pincode"
                                        value={lead.pincode}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>


                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicState">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicState"
                                    >
                                        State
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="state"
                                        placeholder="Enter state"
                                        value={lead.state}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                           
                            <Col lg={6}>
                                <Form.Group className="mx-3" controlId="formBasicCountry">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCountry"
                                    >
                                        Country
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="country"
                                        placeholder="Enter country"
                                        value={lead.country}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            
                        </Row>
                        
                    </Form>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );
};

export default LeadEdit;
