import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import inventoryApi from "../../api/inventoryApi";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
import CreateContactForBooking from "../Contact/CreateContactForBooking";
import "react-toastify/dist/ReactToastify.css";
import { MSG } from "../Messages";
import { NAME_REGEX } from "../Regex";
import * as formik from "formik";
import * as yup from "yup";
import ToastMessage from "../ToastMessage";
import { calculateDays } from "../Helper";
import { useDispatch , useSelector } from "react-redux";
import { createBooking , fetchBookings } from "../APIs";
import { PATH , TYPE } from "../../api/pathUrl";
import { getRequest , postRequest } from "../../api/inventoryApiTwo";

const BookingEditFromHome = (props) => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showContact, setShowContact] = useState(false);
  const [contactdetail, setContactDetail] = useState();
  const [contactId, setContactId] = useState();
  const [contactList, setContactList] = useState([]);
  const [defaultContact, setDefaultContact] = useState([]);

  const [room, setRoom] = useState(
    location.state ? (location.state.room ? location.state.room.room : {}) : {}
  );
  const [roomdetail, setRoomDetail] = useState(room);
  const [defaultRoom, setdefaultRoom] = useState([
    { id: room.id, roomname: `${room.block} Block | Room No - ${room.roomno}` },
  ]);
  const [roomList, setRoomList] = useState([
    { id: room.id, roomname: `${room.block} Block | Room No - ${room.roomno}` },
  ]);
  const [datesData, setDatesData] = useState(
    location.state ? (location.state.room ? location.state.room : {}) : {}
  );
  const { contactsList , resultHandle } = useSelector((state) => state.apiReducer);

  useEffect(() => {
    if (resultHandle.success) {
      navigate(`/booking/${resultHandle.data.id}`, { state: resultHandle.data });
      dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })
    }
  }, [resultHandle])


  useEffect(() => {
    
    if (contactsList.length>0) {
      setContactList(
        contactsList.map((data) => {
          return {
            ...data,
            contactname:
              data.firstname + "." + data.lastname + " - " + data.phone,
          };
        })
      );
      if (contactId) {
        let contactDetail = contactsList.find((ele) => ele.id === contactId);
        setDefaultContact([
          {
            id: contactId,
            contactname:
              contactDetail.firstname +
              "." +
              contactDetail.lastname +
              " - " +
              contactDetail.phone,
          },
        ]);
        setContactDetail(contactDetail);
      } else {
        setDefaultContact([{ id: "", contactname: "" }]);
      }
    } else {
      setDefaultContact([{ id: "", contactname: "" }]);
    }
  
  }, [ contactsList , showContact])


  useEffect(() => {
  
    dispatch(getRequest(PATH.contactPath, TYPE.GET_ALL_CONTACTS));
  
  }, [])



  // useEffect(() => {
  //   setDefaultContact([]);
  //   async function init() {
  //     const conResult = await inventoryApi.fetchContacts();
  //     if (conResult) {
  //       setContactList(
  //         conResult.data.map((data) => {
  //           return {
  //             ...data,
  //             contactname:
  //               data.firstname + "." + data.lastname + " - " + data.phone,
  //           };
  //         })
  //       );
  //       if (contactId) {
  //         let contactDetail = conResult.data.find((ele) => ele.id === contactId);
  //         setDefaultContact([
  //           {
  //               id: contactId,
  //             contactname:
  //               contactDetail.firstname +
  //               "." +
  //               contactDetail.lastname +
  //               " - " +
  //               contactDetail.phone,
  //           },
  //         ]);
  //         setContactDetail(contactDetail);
  //       } else {
  //         setDefaultContact([{ id: "", contactname: "" }]);
  //       }
  //     } else {
  //       setDefaultContact([{ id: "", contactname: "" }]);
  //     }
  //   }
  //   // init();
  // }, [showContact]);

  const handleSubmitSave = async (values) => {
    // let result = {};
    values.isgst = values.isgst ? true : false;
    values["status"] = "Advance booking";
    values["per_day_amount"] = datesData.roomPrice?.price;
    values["per_day_extrabed_amount"] = datesData.roomPrice?.extra_bed;
    // result = await inventoryApi.createBooking(values);


    dispatch(postRequest(PATH.bookingPath, values, TYPE.GET_ALL_BOOKINGS));

    // result = await inventoryApi.createBooking(values);
    // result = await dispatch(createBooking(values));
    // if (result.success) {
      // if (result.errors) {
      //   ToastMessage(result.errors, "error");
      // } else {
        // ToastMessage("Booking has been created successfully!", "success");
        // dispatch(fetchBookings());
        // navigate(`/booking/${result.data.id}`, { state: result.data });
      // }
    // }

    // if (result) {
    //   if (result.errors) {
    //     ToastMessage(result.errors, "error");
    //   } else {
    //     ToastMessage("Booking has been created successfully!", "success");
    //     navigate(`/booking/${result.id}`, { state: result });
    //   }
    // }
  };

  const handleCancel = () => {
    navigate("/bookings");
  };

  const { Formik } = formik;
  const schema = yup.object().shape({
    roomid: yup.string().required(MSG.ROOMNO),
    contactid: yup.string().required(MSG.CONTACT_REQ),
    goingto: yup
      .string()
      .matches(NAME_REGEX, MSG.VALIDNAMREQ)
      .required(MSG.GOINGTO_REQ),
    comingfrom: yup
      .string()
      .matches(NAME_REGEX, MSG.VALIDNAMREQ)
      .required(MSG.COMINGFROM_REQ),
    noofguest: yup
      .number()
      .required(MSG.NOOFGUEST_REQ)
      .min(1, MSG.GUEST_VAL_MIN)
      .max(roomdetail?.occupancy, MSG.GUEST_VAL_MAX),
    check_in_time: yup.string().required(MSG.C_IN_TIME_REQ),
    check_out_time: yup
      .string()
      .required(MSG.C_OUT_TIME_REQ)
      .test(
        "is-greater",
        "Check-out time must be greater than Check-in time",
        function (value) {
          const { check_in_time, checkindate, checkoutdate } = this.parent;
          if (!check_in_time || !value) return true;
          const checkInTime = parseInt(check_in_time.replace(":", ""), 10);
          const checkOutTime = parseInt(value.replace(":", ""), 10);
          if (new Date(checkindate) < new Date(checkoutdate)) return true;
          else return checkOutTime > checkInTime;
        }
      ),
    checkindate: yup
      .date()
      .transform((value, checkindate) => {
        const date = new Date(checkindate);
        return isNaN(date) ? undefined : date;
      })
      .required(MSG.C_IN_DATE_REQ),

    checkoutdate: yup
      .date()
      .transform((value, checkoutdate) => {
        const date = new Date(checkoutdate);
        return isNaN(date) ? undefined : date;
      })
      .min(yup.ref("checkindate"), MSG.C_OUT_DATE_VAL)
      .required(MSG.C_OUT_DATE_REQ)
      .when("checkindate", (checkindate, schema) =>
        checkindate
          ? schema.test(
              "is-greater",
              MSG.C_OUT_DATE_VAL,
              function (checkoutdate) {
                const { timeformat } = this.parent;
                if (timeformat === "11 AM") {
                  return new Date(checkoutdate) > new Date(checkindate);
                } else {
                  return true;
                }
              }
            )
          : schema
      ),

    timeformat: yup.string().required(MSG.TIMEFORMAT_REQ),
    discount_percentage: yup
      .number()
      .min(1, MSG.DIS_VAL)
      .max(100000, MSG.DIS_VAL),
  });
  return (
    <Container className="view-form">
      <Row className="pb-4">
        <Col></Col>
        <Col lg={7} style={{ backgroundColor: "#fff" }}>
          <Formik
            validationSchema={schema}
            onSubmit={handleSubmitSave}
            initialValues={{
              roomid: room?.id ? room.id : "",
              extra_bed: "",
              goingto: "",
              comingfrom: "",
              noofguest: "",
              noofdays: room?.id
                ? calculateDays(
                    moment(new Date(datesData.checkindate)).format(
                      "YYYY-MM-DD"
                    ),
                    moment(new Date(datesData.checkoutdate)).format(
                      "YYYY-MM-DD"
                    )
                  )
                : "",
              timeformat:
                datesData && datesData.timeformat ? datesData.timeformat : "",
              check_out_time:
                datesData && datesData.check_out_time
                  ? datesData.check_out_time
                  : "",
              check_in_time:
                datesData && datesData.check_in_time
                  ? datesData.check_in_time
                  : "",
              checkindate:
                datesData && datesData.checkindate
                  ? moment(new Date(datesData.checkindate)).format("YYYY-MM-DD")
                  : moment(new Date()).format("YYYY-MM-DD"),
              checkoutdate:
                datesData && datesData.checkoutdate
                  ? moment(new Date(datesData.checkoutdate)).format(
                      "YYYY-MM-DD"
                    )
                  : "",
              contactid: "",
              totalamount:
                calculateDays(
                  moment(new Date(datesData.checkindate)).format("YYYY-MM-DD"),
                  moment(new Date(datesData.checkoutdate)).format("YYYY-MM-DD")
                ) * datesData.roomPrice?.price,
              source: "",
              description: "",
              isgst: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              handleBlur,
              setFieldValue,
              getFieldProps,
              touched,
            }) => (
              <Form className="mt-3" onSubmit={handleSubmitSave}>
                <Row className="view-form-header align-items-center">
                  <Col lg={3}>New Booking</Col>
                  <Col lg={9} className="d-flex justify-content-end">
                    <Button className="btn-sm mx-2" onClick={handleSubmit}>
                      Save
                    </Button>
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group className="mx-3" controlId="timeformat">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="timeformat"
                      >
                        Time Format <b class="red-star">*</b>
                      </Form.Label>
                      <Form.Select
                        aria-label="Enter"
                        name="timeformat"
                        onBlur={handleBlur}
                        disabled
                        value={values.timeformat}
                      >
                        <option value="">--Select--</option>
                        <option value="24 hrs">24 Hrs</option>
                        <option value="11 AM">11 AM</option>
                      </Form.Select>
                      {touched.timeformat && (
                        <div className="form-error">{errors.timeformat}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicEmail">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicEmail"
                      >
                        Check-In-Date & Time <b class="red-star">*</b>
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          style={{ width: "70px", marginLeft: "-20px" }}
                          type="date"
                          name="checkindate"
                          placeholder="Enter"
                          required
                          disabled
                          onBlur={handleBlur}
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          value={values.checkindate}
                          
                        />
                        <Form.Control
                          type="time"
                          name="check_in_time"
                          placeholder=""
                          required
                          disabled
                          value={values.check_in_time}
                          onChange={handleChange}
                        />
                        {touched.checkindate && (
                          <div className="form-error">{errors.checkindate}</div>
                        )}
                        {!errors.checkindate && touched.check_in_time && (
                          <div className="form-error">
                            {errors.check_in_time}
                          </div>
                        )}
                      </InputGroup>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicPhone">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPhone"
                      >
                        Check-Out-Date & Time <b class="red-star">*</b>
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          style={{ width: "70px", marginLeft: "-20px" }}
                          required
                          disabled
                          type="date"
                          onBlur={handleBlur}
                          name="checkoutdate"
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          placeholder="Enter"
                          value={values.checkoutdate}
                         
                        />
                        <Form.Control
                          required
                          type="time"
                          name="check_out_time"
                          disabled
                          placeholder=""
                          value={values.check_out_time}
                          onChange={handleChange}
                        />
                        {touched.checkoutdate && (
                          <div className="form-error">
                            {errors.checkoutdate}
                          </div>
                        )}
                        {!errors.checkoutdate && touched.check_out_time && (
                          <div className="form-error">
                            {errors.check_out_time}
                          </div>
                        )}
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                <div>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mx-3" controlId="roomname">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="roomname"
                        >
                          Room No <b class="red-star">*</b>
                        </Form.Label>
                        {defaultRoom.length > 0 ? (
                          <Typeahead
                            required
                            disabled
                            id="basic-typeahead-single2"
                            defaultSelected={defaultRoom}
                            name="roomid"
                            labelKey="roomname"
                            value={values.roomid}
                            options={roomList}
                            
                            placeholder="Choose a Room..."
                          />
                        ) : (
                          "Room does not have pricing"
                        )}
                        {touched.roomid && (
                          <div className="form-error">{errors.roomid}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mx-3" controlId="contact">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="contact"
                        >
                          Contact <b class="red-star">*</b>
                        </Form.Label>

                        {defaultContact.length > 0 ? (
                          <InputGroup>
                            <Typeahead
                              required
                              id="basic-typeahead-single2"
                              defaultSelected={defaultContact}
                              name="contactid"
                              labelKey="contactname"
                              options={contactList}
                              onChange={(event) => {
                                if (event.length > 0) {
                                  //.log("event", event[0].id);
                                  setFieldValue("contactid", event[0].id);
                                  setContactDetail(
                                    contactList.find(
                                      (data) => data.id === event[0].id
                                    )
                                  );
                                } else {
                                  setFieldValue("contactid", "");
                                  setContactDetail();
                                }
                              }}
                              placeholder="Choose a contact..."
                            />
                            <Button
                              variant="primary"
                              onClick={() => {
                                setShowContact(true);
                              }}
                            >
                              <b>+</b>
                            </Button>
                            {touched.contactid && (
                              <div className="form-error">
                                {errors.contactid}
                              </div>
                            )}
                          </InputGroup>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                      {showContact && (
                        <CreateContactForBooking
                          show={showContact}
                          onHide={() => setShowContact(false)}
                          handleClose={(data) => {
                            if (data) {
                              setContactId(data);
                              setFieldValue("contactid", data);
                            }
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mx-3" controlId="formBasicArea">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicTitle"
                        >
                          No of Days
                        </Form.Label>
                        <Form.Control
                          required
                          type="number"
                          name="noofdays"
                          placeholder="Enter No of days"
                          value={values.noofdays}
                          onChange={handleChange}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mx-3" controlId="formBasicPin">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicPin"
                        >
                          Total Amount
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="totalamount"
                          placeholder="Enter Amount"
                          value={values.totalamount}
                          disabled
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={6}>
                      <Form.Group className="mx-3" controlId="formBasicStreet">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicStreet"
                        >
                          No of Guest <b class="red-star">*</b>
                        </Form.Label>
                        <Form.Control
                          required
                          type="number"
                          name="noofguest"
                          className="no-arrows"
                          min={1}
                          placeholder="Enter No of guest"
                          value={values.noofguest}
                          onChange={handleChange}
                        />
                        {touched.noofguest && (
                          <div className="form-error">{errors.noofguest}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mx-3" controlId="formBasicEmail">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicEmail"
                        >
                          Extra Bed
                        </Form.Label>
                        <Form.Select
                          aria-label="Enter"
                          name="extra_bed"
                          value={values.extra_bed}
                          onChange={(e) => {
                            setFieldValue("extra_bed", e.target.value);
                            if (values.checkoutdate) {
                                setFieldValue(
                                    "totalamount",
                                    values.noofdays *
                                      ( datesData.roomPrice?.price + e.target.value * datesData.roomPrice?.extra_bed)
                                  );
                            }
                          }}
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mx-3" controlId="formBasicCity">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicCity"
                        >
                          Coming From <b class="red-star">*</b>
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="comingfrom"
                          placeholder="Coming from"
                          value={values.comingfrom}
                          onChange={handleChange}
                        />
                        {touched.comingfrom && (
                          <div className="form-error">{errors.comingfrom}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mx-3" controlId="formBasicState">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicState"
                        >
                          Going To <b class="red-star">*</b>
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="goingto"
                          placeholder="Going to"
                          value={values.goingto}
                          onChange={handleChange}
                        />
                        {touched.goingto && (
                          <div className="form-error">{errors.goingto}</div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col lg={6}>
                      <Form.Group className="mx-3" controlId="formBasicState">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicState"
                        >
                          Source
                        </Form.Label>
                        <Form.Select
                          required
                          name="source"
                          value={values.source}
                          onChange={handleChange}
                        >
                          <option value="">Select Source </option>
                          <option value="MakeMyTrip">MakeMyTrip</option>
                          <option value="Agoda">Agoda</option>
                          <option value="OYO">OYO</option>
                          <option value="Other">Other</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group
                        className="mx-3"
                        controlId="formBasicDiscount"
                      >
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicDiscount"
                        >
                          Discount
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            min={0}
                            max={100}
                            className="no-arrows"
                            name="discount_percentage"
                            placeholder="Enter Discount"
                            value={values.discount_percentage}
                            onChange={handleChange}
                            aria-describedby="basic-addon"
                          />
                          <InputGroup.Text id="basic-addon">₹</InputGroup.Text>

                          {touched.discount_percentage && (
                            <div className="form-error">
                              {errors.discount_percentage}
                            </div>
                          )}
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Check
                        inline-flex
                        label="Is GST apply"
                        name="isgst"
                        type="switch"
                        checked={values.isgst}
                        onChange={handleChange}
                        style={{ paddingLeft: "3rem" }}
                      />
                    </Col>

                    <Col lg={6}>
                      <Form.Group
                        className="mx-3"
                        controlId="formBasicDescription"
                      >
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicDescription"
                        >
                          Description/Arrival
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          name="description"
                          placeholder="Enter Description/Arrival"
                          value={values.description}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>{" "}
                </div>
              </Form>
            )}
          </Formik>
        </Col>
        <Col></Col>
        <Col lg={4}>
          <Row>
            <Row className="view-form-header mt-3">
              <Col>
                <center>Room Detail</center>
              </Col>
            </Row>
            {roomdetail ? (
              <>
                <Row className="mt-3">
                  <Col lg="4">Ac / Non-Ac :</Col>
                  <Col>
                    <b> {roomdetail && roomdetail.acnonac}</b>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg="4">Type :</Col>
                  <Col>
                    <b> {roomdetail && roomdetail.type}</b>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg="4">Occupancy :</Col>
                  <Col>
                    <b> {roomdetail && roomdetail.occupancy}</b>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col lg="4">Floor :</Col>
                  <Col>
                    <b> {roomdetail && roomdetail.floor}</b>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg="4">Price :</Col>
                  <Col>
                    <b>
                      {" "}
                      {getSymbolFromCurrency("INR")}
                      {datesData.roomPrice?.price}
                    </b>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg="4">Extra Bed :</Col>
                  <Col>
                    <b>
                      {" "}
                      {getSymbolFromCurrency("INR")}
                      {datesData.roomPrice?.extra_bed}
                    </b>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <hr></hr>
                </Row>
                <Row>
                  <Col>
                    {/* {booking.totalamount && (
                      <h6>
                        {" "}
                        Total Amount : {getSymbolFromCurrency("INR")}
                        {booking.totalamount}
                      </h6>
                    )} */}
                  </Col>
                </Row>
              </>
            ) : (
              <center className="mt-3">Not Selected any room</center>
            )}
            <Row className="view-form-header  mt-3">
              <Col>
                <center>Contact Detail</center>
              </Col>
            </Row>
            {contactdetail ? (
              <>
                <Row className="mt-3">
                  <Col lg="4">Name :</Col>
                  <Col className="text-left">
                    <b>
                      {" "}
                      {contactdetail.firstname + " " + contactdetail.lastname}
                    </b>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg="4">Phone :</Col>
                  <Col className="text-left">
                    <b> {contactdetail.phone}</b>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg="4">Street :</Col>
                  <Col>
                    <b> {contactdetail.street}</b>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg="4">Email :</Col>
                  <Col>
                    <b> {contactdetail.email}</b>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg="4">City :</Col>
                  <Col>
                    <b> {contactdetail.city}</b>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg="4">State :</Col>
                  <Col>
                    <b> {contactdetail.state}</b>
                  </Col>
                </Row>
              </>
            ) : (
              <center className="mt-3">Not Selected any contact</center>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default BookingEditFromHome;
