export const MSG = {
  FNAMEREQ: "First name is required",
  LNAMEREQ: "Last name is required",
  CNAMEREQ: "Company name is required",
  COUNTRYREQ: "Country name is required",
  NOLREQ: "No of Licenses is required",
  MINVALNOL: "No of Licenses minimum value must be 1",
  SECKEYREQ: "Secret key is required",
  LOGOREQ: "Logo URL is required",
  DATEREQ: "Date is required",
  ALPHAREQ: "This field can only contain letters",
  EMAILREQ: "Email/Username is required",
  EMAILSREQ: "Email is required",
  STREETSREQ: "Street is required",
  STATESREQ: "State is required",
  CITYSREQ: "City is required",
  SAL_REQ: "Salutation is required",
  INVEMAILREQ: "Please enter a valid email address",
  UNAMEREQ: "Username is required",
  PASSREQ: "Please enter your password",
  CPASSREQ: "Please enter your confirm password",
  CONPASSREQ: "Password dosn't matched",
  NEWPASSREQ: "Please enter your new password",
  OLDPASSREQ: "Please enter your current password",
  CITYREQ: "City is required",
  MOBNUMREQ: "Phone number is required",
  INVMOBNUMREQ: "Invalid Mobile number",
  NUMREQ: "Only numbers allowed",
  STATEREQ: "State is required",
  HELPTAB: "Need Help Information ?",
  VALIDNAMREQ: "Name contains only characters",
  STRNGPWD: "Password should contain at least 8 characters which include capital letters(A-Z), Digit(0-9), and Special Characters(@,#,…etc)",
 // ONENUM: "At least one number",
  EIGHTCHAR: "A minimum 8 characters password contains a combination of uppercase and lowercase letter and number are required.",
  // UPPRLWRCHAR: "At least one uppercase and one lowercase character",
  // SPCLCHAR: "At least one special character like “& . $ @”",
  PWDNOTMTCH: "Passwords do not match",
  CARDREQ: "Card number is required",
  EXPDATEREQ: "Expiry date is required",
  CVVREQ: "CVV is required",
  VALIDCARD: "This does not appear to be a valid credit card",
  VALIDEXP: "Please enter a valid expiration date",
  VALIDCVV: "This does not appear to be a valid CVV",
  REQEMAIL: "Please enter your email",
  REQNAME: "Please enter your name",
  MATCH_PASS: "Current password and new password cannot be same",
  MATCH_SIGNUP_PASS: "Password and Confirm password should match",
  ROOMNO:"Room number is required",
  ROOMNAME:"Room name is required",
  CONTACT_REQ:"Contact is required",
  OCCUPANCY_REQ:"Occupancy is required",
  FLOOR_REQ:"Floor is required",
  TYPE_REQ:"Type is required",
  ACNONAC_REQ:"Ac/Non-Ac is required",
  STATUS_REQ:"Status is required",
  ROLE_REQ:"Role is required",
  PINCODE_REQ:"Pincode is required",
  VLDPINCODE_REQ:"Enter valid Pincode",
  OCCUPATION_REQ:"Occupation is required",
  AGENT_NAMEREQ: "Agent Name is required",
  AGENT_COMREQ: "Commission is required",
  ROOM_PRICE: "Room Price is required",
  AMOUNT_REQ:"Amount is required",
  VALDATE_REQ:"Date is required",
  METHOD_REQ:"Method is required",
  VALIDTYPE_REQ:"Type is required",
  STAFF_REQ:"Assigned staff is required",
  PRICE_REQ :"Price is required",
  TITLE_REQ : "Title is required",
  CON_REQ : "Contact is required",
  TIMEFORMAT_REQ : "Time format is required",
  PRIORITY_REQ : "Priority is required",
  NOOFGUEST_REQ : "No.of Guest is required",
  TARGETDATE_REQ :"Target date is required",
  DESTROOM_REQ : "Destination Room is required",
  ADDAMOUNT_REQ : "Additional Amount is required",
  SHIFTDATE_REQ : "Shifting date is required",
  STARTDATE_REQ:"Start date is required",
  ENDDATE_REQ:"End date is required",
  EXTRABED_REQ:"Extra Bed is required",
  EXTRABED_MIN:"Extra Bed must be grater than or equal to 1",
  COMINGFROM_REQ:"Coming from is required",
  GOINGTO_REQ:"Going To is required",
  ENDDATE_VAL:'End date must be greater than or equal to start date',
  SHIFTDTAE_VAL:'Shifting date must be greater than or equal to check in date',
  SHIFTMAXDATE_VAL:'Shifting date must be less than or equal to check out date',
  C_IN_DATE_VAL : "Check in date must be less than check out date ",
  C_OUT_DATE_VAL : "Check out date must be greater than check in date ",
  C_IN_DATE_REQ : "Check in date required",
  C_OUT_DATE_REQ : "Check in date required",
  C_IN_TIME_REQ : "Check in time required ",
  C_OUT_TIME_REQ : "Check out time required ",
  VALIDTITREQ: "Title contains only characters",
  AMOUNT_VAL:"Commission must be less then room price",
  AMOUNT_VALUE:"Amount must be in between 1 to 100000",
  PRICE_VALUE:"Expense price should be greater than zero",
  DIS_VAL:"Discount must be in between 0 than 100000",
  ADVAMT_VAL:"Advance Amount must be in between 0 than 100000",
  GUEST_VAL_MIN:"Guest must be in more than 1",
  GUEST_VAL_MAX:"Number of guest is greater than occupancy. Please select appropriate extra bed",
  PIN_REQ:'Pincode is required',
  ITEM_NAME:'Item name is required',
  ITEM_TYPE:'Item type is required',
  ITEM_PRICE_REQ:"Price is required",
  ITEM_ORDER_MIN:"Minimum order must be 0",
  ITEM_ORDER_MAX:"Maximum order must be 10000",
  ITEM_ORDER_REQ:"Order is required",
  CATE_REQ:"Category is required",
  CATEGORY_REQ :'Category is required',
  ORDER_REQ :'Order is required',
  COMMISSION_MIN:"Commission must be greater than zero",
  COMMISSION_MAX:"Commission must be less than the room price",
  BLOCK_REQ:"Block is required"
};